
import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../components/SideMenu'; 

import { faCamera, faChevronRight, faHandPointer, faMobile, faMobileScreenButton, faPaperPlane, faShoppingBag, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
const Body = () =>  {
    
    return(
    <div className="container-fluid">
        <div className="container-fluid  bg-lightgray">
                    <div className="container  pt-3 pb-3 checkout_container">
                        <div className="row ">
                            <SideMenu/>
                            
                            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                                <div className="bg-white p-2">
                                    <img src={require('../img/about.png')} className="w-100" alt="" />
                                    <div className="mt-5">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-6 mt-3">
                                                    <h5>Head Office</h5>
                                                    <p>Impulse City Center (5th Floor),O.R Nizam Orad Gol Pahar More, Panchlaish, Chattogram</p>
                                                </div>
                                                <div className="col-sm-6 mt-3">
                                                    <h5 className='m-0'>Support</h5>
                                                    <p>+880 1873 898 898</p>
                                                </div>
                                                
                                                <div className="col-sm-6 mt-3">
                                                    <h5 className='m-0'>Email Address</h5>
                                                    <p>support@self-shopping.com</p>
                                                </div>
                                                <div className="col-md-6 col-12 mt-3">
                                                    <a href='https://www.facebook.com/SelfShopOfficial/' target='_blank' className="btn btn-primary me-2 w-100 mb-2">
                                                    <FontAwesomeIcon className='me-2' icon={faFacebook} />  Official Facebook Page
                                                    </a>
                                                    <a href='https://www.facebook.com/groups/187228249017123' target='_blank' className="btn btn-primary me-2 w-100 mb-2">
                                                    <FontAwesomeIcon className='me-2' icon={faFacebook} /> Official Facebook Group
                                                    </a>
                                                    <a href='https://www.youtube.com/@selftechsupport' target='_blank' className="btn btn-danger w-100 mb-2">
                                                    <FontAwesomeIcon className='me-2' icon={faYoutube} /> Official Youtube Channel
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 mt-2">
                                                    <input type="text" placeholder='Name' className="form-control" />
                                                </div>
                                                <div className="col-sm-6 mt-2">
                                                    <input type="text" placeholder='Mobile' className="form-control" />
                                                </div>
                                                <div className="col-sm-6 mt-2">
                                                    <input type="text" placeholder='Email' className="form-control" />
                                                </div>
                                                <div className="col-sm-6 mt-2">
                                                    <input type="text" placeholder='Subject' className="form-control" />
                                                </div>
                                                <div className="col-sm-12 mt-2">
                                                    <textarea placeholder="message" id="" cols="30" rows="10" className="form-control"></textarea>
                                                </div>
                                                <div className="col-12 mt-2">
                                                    <input type="submit" value="SEND MESSAGE" className="w-100 btn btn-primary" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
   )
}

export default Body;