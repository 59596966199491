
import React,{useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../SideMenu'; 
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import { faRectangleList, faSquare, faTimes,faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import {useParams} from 'react-router-dom';
const Body = () =>  {
    const params = useParams();
    const [key,setKey] = useState(params.key)
    const flatListRef = React.useRef();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [nodata, setNodata] = useState(false);
    const [loadingMore,setLoadingMore] = useState(false)
    const [category, setCategory] = useState('0');
    const [ResellerStatus,setResellerStatus] = useState(1);
    const shoputils = require('../../utils/shoputils.json');
    const [search,setSearch] = useState('')
    const [searchstring,setSearchstring] = useState('')
    const [searchtype,setsearchtype] = useState('input')

    useEffect(() => {
        if(key == 'search'){
            setsearchtype('input')
        }else{
            setsearchtype('product')
        }
        setCategory('0');
            if(nodata == false){
              fetchData(key)
              getResellerStatus();
            }
      }, []);
      const fetchData = async (string) => {
        
        try {
            setLoadingMore(true);
        
        
            const random = Math.random();
            const response = await fetch(shoputils.url+'?random='+random+'&searchedproduct='+string);
            const json = await response.json();
            
            if(json.success == 1){
                setData([...json.searchedproduct]);
                setNodata(true)
                setLoadingMore(false);
            }else{
                setNodata(true);
                setLoadingMore(false);
            }
            setLoadingMore(false);
            
        } catch (error) {
            console.error(error);
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
        } 
        
    };
    const getResellerStatus = async() => {
        try {
          setLoading(true);
          
          const users =  localStorage.getItem("userinfo");
          if(users){
            const user = JSON.parse(users);
            const random = Math.random()
            const response = await fetch(shoputils.url+'?getResellerStatus=1&random='+random+'&auth='+user.auth)
            const json = await response.json();
          
            if(user.status == 1){
              setResellerStatus(json.ResellerStatus)
            }
          }
          setLoading(false);
         } catch (error) {
           console.error(error);
         }  finally {
           setLoading(false)
         }
      }
     
    const renderItem = (item,index) => {
        return (
            
            <div key={index} className="col-lg-3 text-dark col-md-3 col-sm-6 col-6  p-2 ">
                <div className='rounded border  shadow-sm rounded overflow-hidden' style={{position:'relative'}}>
                   
                    <a href={'/shop/product/'+item.id+'/'+item.name}>
                        <div className='overflow-hidden' style={{height:200}}>
                        <img src={shoputils.imageurl+item.img1} className="d-block w-100" alt="..."/>
                    </div>
                    <div className="bg-lightgray text-dark ps-2 pe-2">
                        <p className='m-0 text-left onlinetext'>{item.name} </p>
                        {
                            ResellerStatus == 0 ?
                            item.d == 'flash' ?
                                <div className='d-flex flex-row justify-content-between'>
                                <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                                <h6 className='fw-bold mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>
                                
                                </div>
                                
                                : item.d == 'discount' ?
                                <div className='d-flex flex-row justify-content-between'>
                                    <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                                    <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>
                                
                                </div>
                                
                                : item.d == 'none' ?
                                
                                
                                <div className='d-flex flex-row justify-content-between'>
                                    <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_price)}</h6>
                                </div >
                                
                                    :
                                    null
                            : item.d == 'flash' ?
                            <div className='d-flex flex-row justify-content-between'>
                                <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>
                            
                            </div>
                            
                            : item.d == 'discount' ?
                            <div className='d-flex flex-row justify-content-between'>
                                <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>
                            
                            </div >
                            
                            : item.d == 'none' ?
                            <div className='d-flex flex-row justify-content-between'>
                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.price)}</h6>
                            </div >
                                
                            
                            
                                :
                                null
                        }
                    </div>
                    </a>
                </div>
                
            </div>
        );
      }
      const searchnow = async(e) => {
        let searchstrings = e.target.value;
        setSearchstring(e.target.value)
        if(searchstring.length > 1){
        try{
          const random = Math.random();
            const response = await fetch(shoputils.url+'?random='+random+'&searchproduct='+searchstrings);
            const json = await response.json();
            setSearch([]);
            if(json.success == 1){
              setSearch(json.searchproduct)
            }
          
        } catch (error) {
          console.error(error);
        }  finally {
          setLoading(false)
        }
        }else{
            setSearch([]);
        }
      }  
      const  takestring = (e) => {
        setKey(e.target.innerHTML)
        fetchData(e.target.innerHTML);
        setsearchtype('product')
      } 
   return(
    <div className="container-fluid bg-lightgray">
        <div className="container-fluid  ">
            <div className="container  pt-3 pb-3 checkout_container">
                <div className="row ">
                    <SideMenu/>
                    
                    <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2 mt-2" >
                        <div className="selfbgcolor rounded-top p-2 text-center text-white fs-5">প্রোডাক্ট সার্চ</div>
                        <div className="container bg-white p-2">
                            <div className="row ">
                                
                                {
                                    searchtype == 'input' ? 
                                    <>
                                    <div className="input-group  ">
                                        <input list='searchlist' type="text" onChange={searchnow} autoComplete="false" className="form-control" placeholder="নাম দিয়ে সার্চ করুন" aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                        <a href={'/shop/search/'+searchstring} className="btn btn-warning justify-content-center d-flex" type="button" id="button-addon2">
                                            <FontAwesomeIcon className=' fs-4  text-white' icon={faMagnifyingGlass} />  
                                        </a>
                                        
                                           
                                        
                                    </div>
                                    <div>
                                         {
                                                search ?
                                                search.length > 0 ?
                                                search.map((item,index) => {
                                                    return <div className='p-1' onClick={takestring} value={item} key={index} >{item}</div>
                                                })
                                                :null
                                                :null
                                            }
                                    </div>
                                    </>
                                    : searchtype == 'product' ?   
                                data.map((item,index) => {
                                    return renderItem(item,index)
                                })
                                :null    
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
   )
}

export default Body;