
import React,{useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../components/SideMenu'; 

import { faCamera, faComment, faFile, faHandPointer, faMobile, faMobileScreenButton, faPaperPlane, faShare, faUser } from '@fortawesome/free-solid-svg-icons';
import { faCommentAlt, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {ErrorToast} from '../utils/Utils';
const Body = () =>  {
    const [isError,setIsError] = useState(false);
    const [errorMsg,setErrorMsg] =useState('')
    const shoputils = require('../utils/shoputils.json');
    const [loadMore,setLoadMore] = useState(false);
    const [nodata,setNodata] = useState(false)
    const [data,setData] = useState([])
    const [loadingMore,setLoadingMore] = useState(false)
    const [typedcomment,settypedcomment] = useState('')
    const [currentid,setcurrentid] = useState(null)
    const [username,setUsername] = useState('')
    const [loggedin, setLoggedin] = useState(false)
    const [userpic,setUserpic] = useState('')
    const [newPostValue,setNewPostValue] = useState('')
    const [file,setFile] = useState('')
    useEffect(() => {
        if(nodata == false){
            getData();
        }
        
      },[])
    const showError = (msg) => { 
        setIsError(true)
        setErrorMsg(msg) 
        setTimeout(() => {
            setIsError(false)
        }, 3000);
    }
    const getData = async () => {
        try {
            setLoadingMore(true);
            const users =  localStorage.getItem("userinfo");
            
            const random = Math.random();
            let response = '';
            if(users){
                let token = localStorage.getItem('token');
                if(token){
                    setLoggedin(true);
                }
                const user = JSON.parse(users);
                 response = await fetch(shoputils.webget+'?random='+random+'&getUsername='+user.auth+'&getNewsFeeds=0');
                 const json = await response.json();
                 if(json.success == 1){
                     //console.log(json)
                     setUsername(json.username)
                     setUserpic(json.pic)
                     setData(json.data);
                     setNodata(true)
                 }else{
                     setData([]);
                     setNodata(true);
                 }
            }else{
                 response = await fetch(shoputils.webget+'?random='+random+'&getNewsFeeds=0');
                 const json = await response.json();
                if(json.success == 1){
                    //console.log(json)
                    
                    setData(json.data);
                    setNodata(true)
                }else{
                    setData([]);
                    setNodata(true);
                }
            }
            
            setLoadingMore(false);
        } catch (error) {
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
        } 
    }  
    const commenttyped = (e) => {
        settypedcomment(e.target.value)
    }
    const submitcomment = async() => {
        try {
            setLoadingMore(true);
            const users =  localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            const random = Math.random();
            let formData = new FormData();
                
            formData.append('auth',user.auth);
            formData.append('newsfeedcomment',typedcomment);
            formData.append('commentid',currentid);
           
            const response = await fetch(shoputils.webpost+'?random='+random,{
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                },
                body: formData
            });
            const json = await response.json();
            //console.log(json)
            if(json.success == 1){
                showError('আপনার কমেন্ট সাবমিট হয়েছে')
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                
                
            }else{
                
                showError('দুঃখিত আবার চেষ্টা করুন')
            }
            
        } catch (error) {
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
        } 
    }
    if(loadMore == false){
        window.onscroll = async function(ev) {
            
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
                setLoadMore(true)
                
                try {
                setLoadingMore(true);
                var index = data.length;
                var lastitem = data[index - 1]; //get 1st item in the array
                
                if (index > 19) {
                    
                    
                    const users =  localStorage.getItem("userinfo");
                    const user = JSON.parse(users);
                    const random = Math.random();
                    const response = await fetch(shoputils.webget+'?random='+random+'&getNewsFeeds='+lastitem.id);
                    const json = await response.json();
                    
                    if(json.data.length > 0){
                        const responsedata = json.data;
                        let arry = [...data, ...responsedata];
                        setData(arry);
                        setLoadMore(false)
                    }else{
                        setLoadMore(false)
                    }
                }
                setLoadMore(false);
            } catch (e) {
                setLoadingMore(false);
            } finally {
                setLoadMore(false);
            }
            }
        };
    } 
    const reactNow = async(type,itemid) => {
        try {
            setLoadingMore(true);
            const users =  localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            const random = Math.random();
            let formData = new FormData();
            formData.append('auth',user.auth);
            formData.append('type',type);
            formData.append('reactid',itemid);
            const response = await fetch(shoputils.webpost+'?random='+random,{
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                },
                body: formData
            });
            const json = await response.json();
            //console.log(json)
            if(json.success == 1){
                window.location.reload();
                
            }else{
                
                showError('দুঃখিত আবার চেষ্টা করুন')
            }
            
        } catch (error) {
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
        } 
    }
    const newpostvaluechanged = (e) => {
        setNewPostValue(e.target.value)
    }
    const currentDate = () => {
        var currentDate = new Date();
        return  currentDate.toLocaleString()
    }
    const handleFileChange = (e) => {
        if (e.target.files) {
          setFile(e.target.files[0]);
        }
      };
    const postData = async() => {
        try {
            setLoadingMore(true);
            const users =  localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            const random = Math.random();
            let formData = new FormData();
            formData.append('auth',user.auth);
            formData.append('newsfeedpost',newPostValue);
            formData.append('file',file);
            const response = await fetch(shoputils.webpost+'?random='+random,{
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                },
                body: formData
            });
            const json = await response.json();
            //console.log(json)
            if(json.success == 1){
                showError(json.message)
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }else{
                showError(json.message)
            }
            
        } catch (error) {
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
        } 
    } 
   return(
    <div className="container-fluid">
        <div className="container-fluid  bg-lightgray">
                    <div className="container  pt-3 pb-3">
                        <div className="row ">
                            <SideMenu/>
                            
                            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                                
                                <img src={require('../img/banner/business-branding.png')} className="rounded w-100 mb-3" alt="" />
                                <div className='d-flex flex-row border-bottom pb-3 justify-content-start'>
                                    <img src={require('../img/smartwatch.jpg')} style={{width:50,height:50,borderRadius:50}} alt="" />
                                    {
                                        loggedin  == true ? 
                                        <div  data-bs-target="#postmodal" data-bs-toggle="modal" className='align-text-center border border-danger text-start ps-4  rounded-pill w-100' style={{lineHeight:'44px'}} >আপনার পোস্ট লিখুন</div>
                                        :
                                        <div  data-bs-target="#login_modal" data-bs-toggle="modal" className='align-text-center border border-danger text-start ps-4  rounded-pill w-100' style={{lineHeight:'44px'}} >আপনার পোস্ট লিখুন</div>
                                    }
                                    <div className="modal" id="postmodal" >
                                        <div className="modal-dialog">
                                            <div className="modal-content p-3">
                                                <div className="modal-header">
                                                    নতুন পোস্ট 
                                                </div>
                                                <div className='d-flex bg-white flex-row p-3 mt-2 pb-3 justify-content-start '>
                                                    <img src={'http://103.36.103.164:8080/self_digital/s_p_r/api/downloadFile/profile/' + userpic} className="me-3" style={{width:50,height:50,borderRadius:50}} alt="" />
                                                    <div className='align-virtical'>
                                                        <b className='text-uppercase' >{username}</b> 
                                                        <br/>
                                                        {currentDate()}
                                                    </div>
                                                    
                                                </div>
                                                <textarea defaultValue={newPostValue} onChange={newpostvaluechanged} className='form-control' id="" cols="30" rows="10" placeholder='আপনার পোস্ট লিখুন'></textarea>
                                                <input type="file" onChange={handleFileChange} className='d-none newsfeeduploadphotoinput' />
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div className="w-100 bg-lightgray border-end p-2 text-center newsfeeduploadphoto">
                                                        ছবি আপলোড করুন
                                                        <FontAwesomeIcon className='ms-2 fs-6  ' icon={faFile} />
                                                    </div>
                                                    
                                                </div>
                                                <div className="btn btn-danger" onClick={() => postData()}>পোস্ট করুন</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    data.length > 0 ?
                                        data.map((item,index) => {

                                            return <div key={index} className="singlepost">
                                               
                                                <div className='d-flex bg-white flex-row p-3 mt-2 pb-3 justify-content-start '>
                                                    
                                                    {item.user.pic !== 'null' ?
                                                    <img src={'http://103.36.103.164:8080/self_digital/s_p_r/api/downloadFile/profile/' + item.user.pic} className="me-3" style={{width:50,height:50,borderRadius:50}} alt="" />
                                                    :<FontAwesomeIcon className='me-2 fs-5  text-dark' icon={faUser} /> 
                                                    }
                                                    <div className='align-virtical'>
                                                        <b className='text-uppercase' >{item.user.username}</b> Posted in business feed
                                                        <br/>
                                                        {item.date}
                                                    </div>
                                                    
                                                </div>
                                                <div className='bg-white p-3'>
                                                    
                                                    <p>{item.description}</p>
                                                </div>
                                                <div className="bg-white p-3">
                                                    <div className=" w-50 m-auto">
                                                    <img src={'http://103.36.103.164:8080/self_digital/s_p_r/api/downloadFile/newsfeed/' + item.upload_file} className="w-100" alt="" />
                                                    </div>
                                                    <hr />
                                                    <div className='d-flex flex-row justify-content-between'>
                                                        <div data-bs-toggle="modal" onClick={() => setcurrentid(item.id)} data-bs-target={'#comments_modal_'+item.id} >
                                                        <FontAwesomeIcon className='me-2 fs-6  ' icon={faCommentAlt} />
                                                        {item.comments} Comment
                                                        </div>  
                                                        <div className="modal" id={'comments_modal_'+item.id}>
                                                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                                                <div className="modal-content">
                                                                {
                                                                    isError == true ? 
                                                                    ErrorToast(errorMsg)
                                                                    :null
                                                                }
                                                                <div className="modal-header">
                                                                    <h1 className="modal-title small  onlinetext me-3" id="exampleModalLabel">{item.description}</h1>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                   {
                                                                    item.replay ?
                                                                    
                                                                    <div>
                                                                        
                                                                        {
                                                                           item.replay.map((citem,cindex)=> {
                                                                            return <div key={cindex} className='d-flex flex-row justify-content-left align-items-center'>
                                                                            <div>
                                                                            {citem.cmter.pic !== null ?
                                                                            <img src={'http://103.36.103.164:8080/self_digital/s_p_r/api/downloadFile/profile/' + citem.cmter.pic} className="me-3" style={{width:50,height:50,borderRadius:50}} alt="" />
                                                                            :<FontAwesomeIcon className='me-2 fs-5  text-dark border rounded-pill p-3' icon={faUser} /> 
                                                                            }
                                                                            </div>
                                                                            <div className=' rounded p-2 ps-4 pe-4 w-50 mt-2 mb-2' style={{backgroundColor:'#efefef'}}>
                                                                                <p className="text-dark">{citem.cmter.username}</p>
                                                                                <p className="text-secondary small">{citem.date}</p>
                                                                                <p className='text-dark'>{citem.description}</p>
                                                                            </div>
                                                                            </div>
                                                                           })
                                                                        } 
                                                                    </div>
                                                                
                                                                    :null
                                                                   }
                                                                </div>
                                                                <div className="p-3 d-flex flex-row justify-content-left align-items-center">
                                                                    <textarea defaultValue={typedcomment} onChange={commenttyped} className='form-control align-items-center rounded-pill me-2' rows="1" placeholder="কমেন্ট করুন"></textarea>
                                                                    <FontAwesomeIcon onClick={() => submitcomment()} className='me-2 fs-4  text-danger border border-danger rounded-pill p-2' icon={faPaperPlane} />
                                                                </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div >
                                                            <div className={'custom_popover d-none react_btn_'+item.id}>
                                                                <img onClick={() => reactNow('haha',item.id)} style={{width:30,height:30,marginRight:5}} src={require('../assets/reactIcon/haha.gif')} alt="" />
                                                                <img onClick={() => reactNow('like',item.id)} style={{width:30,height:30,marginRight:5}} src={require('../assets/reactIcon/like.gif')} alt="" />
                                                                <img onClick={() => reactNow('love',item.id)} style={{width:30,height:30,marginRight:5}} src={require('../assets/reactIcon/love.gif')} alt="" />
                                                                <img  onClick={() => reactNow('angry',item.id)} style={{width:30,height:30,marginRight:5}} src={require('../assets/reactIcon/angry.gif')} alt="" />
                                                                <img  onClick={() => reactNow('wow',item.id)} style={{width:30,height:30,marginRight:5}} src={require('../assets/reactIcon/wow.gif')} alt="" />
                                                            </div>
                                                        <span className='react_click_btn' data-id={item.id}>
                                                        <FontAwesomeIcon className='me-2 fs-6 ' icon={faThumbsUp} />
                                                        Like
                                                        </span>
                                                        </div>
                                                        <div>
                                                        <FontAwesomeIcon className='me-2 fs-6  ' icon={faShare} />
                                                        Share
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        })
                                    :null

                                }
                                
                                {
                                    loadMore == true ?
                                    <div className="col-12 text-center mb-4 pb-4">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    :null
                                }
                            </div>
                           
                           
                        </div>
                    </div>
                </div>
    </div>
   )
}

export default Body;