import React, { useEffect, useState } from 'react';
import topimage from '../img/Free-delivery-top-strip.gif';
import selflogo from '../img/selflogo.png';
import whatsapp from '../assets/whatsapp.webp';
import Menu from './Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'

import { ErrorToast } from '../utils/Utils';
import { faBriefcase, faCog, faHouse, faList, faListAlt, faLock, faPhone, faQuestion, faShield, faShieldHalved, faSignIn } from '@fortawesome/free-solid-svg-icons';
import { faBars, faBell, faCartArrowDown, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import StgCheckoutContentbox from './Stg/StgCheckoutContentbox';
const Header = () => {
    const shoputils = require('../utils/shoputils.json');
    const [loading, setLoading] = useState('')
    const [logindisplay, setLogindisplay] = useState('')
    const [loggedin, setLoggedin] = useState(false);

    const [isError, setIsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [pin1, setpin1] = useState('');
    const [pin2, setpin2] = useState('');
    const [pin3, setpin3] = useState('');
    const [regnumber, setRegnumber] = useState();
    const [pin4, setpin4] = useState('');
    const [otp1, setotp1] = useState('');
    const [otp2, setotp2] = useState('');
    const [otp3, setotp3] = useState('');
    const [otp4, setotp4] = useState('');
    const [otp5, setotp5] = useState('');
    const [otp6, setotp6] = useState('');
    const [votp, setvotp] = useState(null)
    const [forgototp, setforgototp] = useState();
    const [newpass, setnewpass] = useState('');
    const [conpass, setconpass] = useState('');
    const [regname, setRegname] = useState('');
    const [regpassword, setRegpassword] = useState('');
    const [regconpassword, setRegconpassword] = useState('');
    const [regrefer, setRegrefer] = useState('');
    const [currentStep, setCurrentStep] = useState('login')
    const [regstep, setregstep] = useState('otp')
    const [searchstring, setSearchstring] = useState('')
    const [error, setError] = useState([
        { number: null },
        { password: null },
        { regnumber: null },
        { regpassword: null },
        { regconpassword: null },
        { regrefer: null }
    ])
    const [search, setSearch] = useState([]);
    const [data, setData] = useState([])
    const [number, setNumber] = useState('')
    const [password, setPassword] = useState('')
    const [nodata, setNodata] = useState(false)
    const [status, setStatus] = useState('')
    const [whatsapplink, setwhatsapplink] = useState('');
    const [total, settotal] = useState(0)
    const [totalpoint, settotalpoint] = useState(0)
    const [cart, setcart] = useState([]);
    const [pdata, setPdata] = useState([]);

    useEffect(() => {
        //localStorage.removeItem('stgcart')
        tokenset();
        let userinfo = localStorage.getItem('userinfo');
        let user = JSON.parse(userinfo);
        
        let productsdata = localStorage.getItem('pdata');
        //console.log(JSON.parse(productsdata))
        setPdata(JSON.parse(productsdata));
        counttotalpoint()
        if (user) {
            let token = localStorage.getItem('token');
            if (token) {
                setLoggedin(true);
            }
        } else {
            if (window.location.href == 'http://localhost:3000/shop/myorder/pending' || window.location.href == 'https://self-shopping.com/shop/myorder/pending') {
                setLogindisplay('d-block')
            }
        }
        if (nodata == false) {
            getData()

        }
    }, [])
    useEffect(() => {
        setcart(JSON.parse(localStorage.getItem('stgcart')))
    },[])
    const counttotalpoint = () => {
        let pdata = JSON.parse(localStorage.getItem('pdata'))
        if (pdata?.length > 0) {
            let npoint = 0;
            let nprice = 0;
            let cart = JSON.parse(localStorage.getItem('stgcart'))

            cart?.map((item, index) => {

                //alert(item)
                nprice += parseInt(pdata[item].price);
                npoint = npoint + + +pdata[item].points;
                settotalpoint(npoint);
                settotal(nprice);
            })
        }
    }
    const tokenset = async () => {
        //localStorage.removeItem('devicetoken');
        let devicetoken = localStorage.getItem('devicetoken');

        if (!devicetoken) {
            try {
                const random = Math.random()
                let userinfo = localStorage.getItem('userinfo');
                let uid = 0;
                if (userinfo) {
                    let user = JSON.parse(userinfo);
                    //console.log(user.auth)
                    uid = user.auth;
                }
                //console.log(uid)
                let formData = new FormData();
                formData.append('generateToken', 1);
                formData.append('uid', uid);
                const response = await fetch(shoputils.webpost + '?random=' + random + '&webpost=1', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                    },
                    //upload images
                    body: formData
                });
                const json = await response.json();

                if (json.success == 1) {
                    //console.log(json)
                    localStorage.setItem('devicetoken', json.deviceid.toString());
                    localStorage.setItem('token', json.string);
                }
            } catch (error) {
            }
        } else {
            let userinfo = localStorage.getItem('userinfo');
            if (userinfo) {
                let user = JSON.parse(userinfo);
                try {
                    const random = Math.random()

                    //console.log(uid)
                    let formData = new FormData();
                    let token = localStorage.getItem('token');
                    formData.append('checklgn', user.auth);
                    formData.append('devicetoken', devicetoken);
                    formData.append('token', token);
                    const response = await fetch(shoputils.webpost + '?random=' + random + '&webpost=1', {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                        },
                        //upload images
                        body: formData
                    });
                    const json = await response.json();
                    console.log(json)
                    if (json.success == 0) {
                        localStorage.removeItem('userinfo');
                        localStorage.removeItem('devicetoken')
                        localStorage.removeItem('token')
                        window.location.reload();
                    }
                } catch (error) {
                }

            }
        }
    }
    const getData = async () => {
        try {
            setLoading(true);

            const users = localStorage.getItem('userinfo');
            const user = JSON.parse(users)
            const random = Math.random()
            let formData = new FormData();
            formData.append('getProfileInfo', user.auth);
            const response = await fetch(shoputils.webpost + '?random=' + random + '&webpost=1', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                //upload images
                body: formData
            });
            const json = await response.json();
            setLoading(false);
            if (json.success == 1) {
                //console.log(json)
                setData(json.profileinfo)
                setwhatsapplink(json.whatsapplink)
                setStatus(profilePercentageState(json.profileinfo, 'all'));
            }
            setNodata(true);
        } catch (error) {
            //console.error(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    const profilePercentageState = (user, type) => {
        let result = 0;
        if (type == 'all') {
            result += profilePercentageState(user, 'basic');
            result += profilePercentageState(user, 'document');
            result += profilePercentageState(user, 'personal');
            result += profilePercentageState(user, 'address');
            result += profilePercentageState(user, 'nominee');
            result += profilePercentageState(user, 'additional');
            var divide = result / 10;
            result = divide * 100 / 60
            return Math.round(result);
        } else if (type == 'basic') {
            if (user.username && user.username.length) {
                result += 20;
            } if (user.number) {
                result += 20;
            } if (user.status == 1) {
                result += 20;
            } if (user.email && user.email.length) {
                result += 20;
            } if (user.pic && user.pic.length) {
                result += 20;
            }
            return result;
        } else if (type == 'document') {
            if (user.nid && user.nid.length) {
                result += 40;
            } if (user.nid_front && user.nid_front.length) {
                result += 30;
            } if (user.nid_back && user.nid_back.length) {
                result += 30;
            }
            return result;
        } else if (type == 'personal') {
            if (user.father_name && user.father_name.length) {
                result += 20;
            } if (user.mother_name && user.mother_name.length) {
                result += 20;
            } if (user.birth_date && user.birth_date.length) {
                result += 20;
            } if (user.gender && user.gender.length) {
                result += 10;
            } if (user.blood_group && user.blood_group.length) {
                result += 10;
            } if (user.religion && user.religion.length) {
                result += 10;
            } if (user.marital && user.marital.length) {
                result += 10;
            }
            return result;
        } else if (type == 'address') {
            if (user.country && user.country.length) {
                result += 5;
            } if (user.divisionid && user.divisionid > 0) {
                result += 15;
            } if (user.districtid && user.districtid > 0) {
                result += 15;
            } if (user.upazilaid && user.upazilaid > 0) {
                result += 15;
            } if (user.unionid && user.unionid > 0) {
                result += 15;
            } if (user.livingdistrictid && user.livingdistrictid > 0) {
                result += 15;
            } if (user.location && user.location.length) {
                result += 20;
            }
            return result;
        } else if (type == 'nominee') {
            if (user.nominee_name && user.nominee_name.length) {
                result += 30;
            } if (user.nominee_mobile && user.nominee_mobile.length) {
                result += 25;
            } if (user.nominee_nid && user.nominee_nid.length) {
                result += 25;
            } if (user.nominee_relation && user.nominee_relation.length) {
                result += 20;
            }
            return result;
        } else if (type == 'additional') {
            if (user.education && user.education.length) {
                result += 20;
            } if (user.profession && user.profession.length) {
                result += 20;
            } if (user.incomeLevel && user.incomeLevel.length) {
                result += 15;
            } if (user.hobby && user.hobby.length) {
                result += 15;
            } if (user.interest && user.interest.length) {
                result += 15;
            } if (user.lifestyle && user.lifestyle.length) {
                result += 15;
            }
            return result;
        }
        return result;
    }
    const showError = (msg) => {
        setIsError(true)
        setErrorMsg(msg)
        setTimeout(() => {
            setIsError(false)
        }, 3000);
    }
    const textInputChange = (e) => {
        setNumber(e.target.value);
        if (e.target.value.length == 11) {
            setError({ ...error, number: false })
        } else {
            setError({ ...error, number: 'মোবাইল নাম্বার অবশ্যই ১১ ডিজিটের হতে হবে।' })
        }

    }
    const regNumberChange = (e) => {
        setRegnumber(e.target.value);
        if (e.target.value.length == 11) {
            setError({ ...error, regnumber: false })
        } else {
            setError({ ...error, regnumber: 'মোবাইল নাম্বার অবশ্যই ১১ ডিজিটের হতে হবে।' })
        }

    }
    const changeRegname = (e) => {
        setRegname(e.target.value);


    }
    const changeRegpassword = (e) => {
        setRegpassword(e.target.value);
        if (e.target.value.length >= 8) {
            setError({ ...error, regpassword: false })
        } else {
            setError({ ...error, regpassword: 'পাসওয়ার্ড ৮ অক্ষর বা তার বেশি হতে হবে' })
        }

    }
    const changeRegconpassword = (e) => {
        setRegconpassword(e.target.value);
        if (e.target.value == regpassword) {
            setError({ ...error, regconpassword: false })
        } else {
            setError({ ...error, regconpassword: 'পাসওয়ার্ড মিলেনি' })
        }

    }
    const changeRegRefer = (e) => {
        setRegrefer(e.target.value);


    }
    const otpHandle = async () => {
        try {
            setLoading(true);

            const min = 111111;
            const max = 999999;
            const random = Math.floor(min + Math.random() * (max - min));
            let formData = new FormData();
            let token = localStorage.getItem('token');
            formData.append('sendotp', regnumber);
            formData.append('token', token);


            const response = await fetch(shoputils.webpost + '?random=' + random + '&webpost=1', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                //upload images
                body: formData
            });
            const json = await response.json();
            setLoading(false);
            if (json.success == 1) {
                setregstep('verify')
            } else {
                showError(json.message);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    const verificationHandle = async () => {
        let recentotp = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;
        try {
            const token = localStorage.getItem('token');
            const random = Math.random();
            const response = await fetch(shoputils.other + '/otpvalidate?otp=' + recentotp + '&tokenid=' + token + '&random=' + random);
            const json = await response.json();

            if (json.success == 1) {
                setregstep('regform')
            } else {
                showError(json.message)

            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    };
    const regHandle = async () => {
        try {
            setLoading(true);


            const random = Math.random()
            let formData = new FormData();
            formData.append('regname', regname);
            formData.append('regnumber', regnumber);
            formData.append('regreferer', regrefer);
            formData.append('regpassword', regpassword);
            const response = await fetch(shoputils.webpost + '?random=' + random + '&webpost=1', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                //upload images
                body: formData
            });
            const json = await response.json();
            setLoading(false);

            if (json.success == 1) {

                localStorage.setItem('userinfo', JSON.stringify(json.userinfo));
                window.location.reload();
            } else {
                showError(json.message);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }
    const loginHandle = () => {
        if (number.length < 11 && password.length < 2) {
            showError('মোবাইল নাম্বার অবশ্যই ১১ ডিজিটের হতে হবে।')
        } else {
            callLoginApi();
        }
    }
    const pinHandle = async () => {

        if (pin1.length < 1 || pin2.length < 1 || pin3.length < 1 || pin4.length < 1) {
            showError('আপনার ৪ ডিজিটের গোপন পিনটি লিখুন')

        } else {

            try {
                setLoading(true);

                let formData = new FormData();
                formData.append('securitypin', pin1 + pin2 + pin3 + pin4);
                formData.append('number', number);

                const random = Math.random();
                const response = await fetch(shoputils.securitypinpost + '?random=' + random + '&webpost=1&securitypincheck=add', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                    },
                    body: formData
                });
                const json = await response.json();
                //console.log(JSON.stringify(json.userinfo), "user info")
                setLoading(false);
                if (json.success == 1) {
                    // console.log(json)
                    localStorage.removeItem('devicetoken');
                    localStorage.setItem('userinfo', JSON.stringify(json.userinfo));
                    window.location.reload();
                } else {
                    showError('সফল হয়নি');
                }
            } catch (error) {
                console.error(error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }
    }
    const callLoginApi = async () => {
        try {
            const random = Math.random();
            let formData = new FormData();
            formData.append('number', number);
            formData.append('password', password);
            const response = await fetch(shoputils.webpost + '?random=' + random, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                body: formData
            });
            const json = await response.json();
            if (json.success == 1) {
                setCurrentStep('pin');
            } else {
                showError(json.message)
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        } finally {
            setLoading(false)
        }

    }
    const textPasswordChange = (e) => {
        setPassword(e.target.value);
    }
    const pin1change = (e) => {
        setpin1(e.target.value);
    }
    const pin2change = (e) => {
        setpin2(e.target.value);
    }
    const pin3change = (e) => {
        setpin3(e.target.value);
    }
    const pin4change = (e) => {
        setpin4(e.target.value);
    }
    const otp1change = (e) => {
        setotp1(e.target.value);
    }
    const otp2change = (e) => {
        setotp2(e.target.value);
    }
    const otp3change = (e) => {
        setotp3(e.target.value);
    }
    const otp4change = (e) => {
        setotp4(e.target.value);
    }
    const otp5change = (e) => {
        setotp5(e.target.value);
    }
    const otp6change = (e) => {
        setotp6(e.target.value);
    }
    const searchnow = async (e) => {
        let searchstrings = e.target.value;
        setSearchstring(e.target.value)
        if (searchstring.length > 1) {
            try {
                const random = Math.random();
                const response = await fetch(shoputils.url + '?random=' + random + '&searchproduct=' + searchstrings);
                const json = await response.json();
                setSearch([]);
                if (json.success == 1) {
                    setSearch(json.searchproduct)
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)
            }
        } else {
            setSearch([]);
        }
    }
    const currentStepChange = () => {
        //setCurrentStep('forgot')
        showError('পাসওয়ার্ড পরিবর্তন করতে আমাদের অ্যাপ ব্যবহার করুন')
    }
    const forgototpinput = (e) => {
        setforgototp(e.target.value);
    }
    const forgotnewpass = (e) => {
        setnewpass(e.target.value);
    }
    const forgotconpass = (e) => {
        setconpass(e.target.value);
    }
    const proceedTonext = () => {
        localStorage.setItem("stgcartdetails", JSON.stringify({
            
            totalpoint: totalpoint,
            totalproductprice: total,
        }));
        window.location.href = '/stg/address';
    }
    const forgotconfirm = async () => {
        try {
            const token = localStorage.getItem('token');
            const random = Math.random();
            const response = await fetch(shoputils.other + '/otpvalidate?otp=' + forgototp + '&tokenid=' + token + '&random=' + random);
            const json = await response.json();

            if (json.success == 1) {
                if (newpass !== '' && conpass !== '') {
                    if (newpass == conpass) {
                        try {
                            setLoading(true);


                            const random = Math.random();
                            let formData = new FormData();
                            formData.append('resetotp', forgototp);
                            formData.append('resetnewpass', newpass);
                            formData.append('resetconpass', conpass);
                            formData.append('resetnumber', number);
                            formData.append('token', token);

                            const response = await fetch(shoputils.webpost + '?random=' + random + '&webpost=1', {
                                method: 'POST',
                                headers: {
                                    Accept: 'application/json',
                                },
                                //upload images
                                body: formData
                            });
                            const json = await response.json();
                            setLoading(false);
                            if (json.success == 1) {
                                setTimeout(
                                    showError(json.message
                                    ), 3000);
                                window.location.reload();

                            } else {
                                showError(json.message);
                            }
                        } catch (error) {
                            console.error(error);
                            setLoading(false);
                        } finally {
                            setLoading(false);
                        }
                    } else {
                        showError('পাসওয়ার্ড দুটি মিল নেয়')
                    }
                } else {
                    showError('সকল তথ্য প্রয়োজন')
                }
            } else {
                showError(json.message)

            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }
    const forgotHandle = async () => {
        if (number.length == 11) {
            try {
                setLoading(true);

                const min = 111111;
                const max = 999999;
                const random = Math.floor(min + Math.random() * (max - min));
                const token = localStorage.getItem('token')
                let formData = new FormData();
                formData.append('sendforgototp', number);
                formData.append('token', token);



                const response = await fetch(shoputils.webpost + '?random=' + random + '&webpost=1', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                    },
                    //upload images
                    body: formData
                });
                const json = await response.json();
                setLoading(false);
                if (json.success == 1) {
                    setCurrentStep('forgotreset')
                } else {
                    showError(json.message);
                }
            } catch (error) {
                console.error(error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        } else {
            showError('নম্বর সঠিক নয়')
        }
    }
    return (
        <div className='w-100'>
            <div className='d-lg-block d-md-none d-sm-none d-none d-flex'>
                <div className='d-flex flex-row justify-content-between'>
                    <div className='bg-warning p-2 flex-fill text-center fs-2 fw-bold text-white' >#1 DIGITAL BUSINESS PLATFORM IN BANGLADESH</div>
                    <div className="bg-info p-2 fs-5 fw-bold text-center ps-5 pe-5">Call for Support <br /> <span className='text-white'>+880 1873 898 898 (whatsapp)</span></div>
                </div>
            </div>
            <div className="container-fluid mobileheader shadow-sm d-md-block d-sm-block d-lg-none">
                <div className='container  '>
                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-header d-block">
                            <button type="button" className="btn-close float-end" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        {
                            loggedin == true ?
                                <>
                                    <div className="text-center menu_top_text">

                                        <img src={selflogo} alt="" style={{ width: 60, height: 60 }} />
                                        <p className="fs-4 fw-bold accent_color m-0" >{data.username}</p>
                                        <p className="m-0 fs-4 selfcolor">{data.number}</p>
                                        <p className="m-0 fs-6">{data.status == 0 ? 'Customer' : data.status == 1 ? 'Business Associate' : null}</p>
                                        {
                                            data.status == 1 ?
                                                <p className="m-0 fs-6">Business Affiliate ID: {data.id + 112233}</p>
                                                : null
                                        }

                                    </div>
                                    <div className="btn rounded-pill w-75 mb-2 mt-2 p-1 m-auto btn_gradient">
                                        <p className="m-0 small text-white fw-bold">Profile Complete {status}%</p>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="text-center menu_top_text">
                                        <img src={selflogo} alt="" style={{ width: 70, height: 70 }} />

                                    </div>
                                    <p className='align-self-center fs-4'>www.self-shopping.com</p>
                                </>
                        }
                        <div className="offcanvas-body hidescroll">
                            <Menu />

                        </div>
                    </div>
                    <div className='row p-2 d-flex flex-row'>
                        <div className='col-2 align-self-center' >
                            <FontAwesomeIcon data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" className='me-2 fs-1 mt-2 font-weight-bold' icon={faBars} />
                        </div>
                        <a href="/" className='col-8 text-center align-self-center' >
                            <img src={selflogo} alt="" style={{ width: 50, height: 50 }} />
                        </a>

                        <div className='col-2 pe-0 text-end align-self-center'>
                            <FontAwesomeIcon className='me-2 fs-2 mt-2 font-weight-bold' icon={faBell} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-white shadow-sm">
                <div className='container  '>
                    <div className='row top_bar d-flex justify-content-center align-items-center p-3'>
                        <a href="/" className='col-2 d-md-none d-lg-block d-none' >
                            <img src={selflogo} alt="" style={{ width: 50, height: 50 }} />
                        </a>
                        <div className='col-md-12 col-lg-6'>

                            <div className="input-group  ">
                                <input list='searchlist' type="text" onChange={searchnow} autoComplete="false" className="form-control" placeholder="প্রোডাক্ট সার্চ" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                <a href={'/shop/search/' + searchstring} className="btn btn-warning justify-content-center d-flex" type="button" id="button-addon2">
                                    <FontAwesomeIcon className=' fs-4  text-white' icon={faMagnifyingGlass} />
                                </a>
                                <datalist id="searchlist">
                                    {
                                        search ?
                                            search.length > 0 ?
                                                search.map((item, index) => {
                                                    return <option className='p-1' key={index} value={item} />
                                                })
                                                : null
                                            : null
                                    }
                                </datalist>
                            </div>


                        </div>
                        <div className='col-4 pe-0 d-md-none d-lg-block d-none'>
                            <ul className='list-group text-end' style={{ flexDirection: 'row', justifyContent: 'flex-end' }} >

                                {
                                    loggedin == true ?
                                        <>
                                            <li className='list-group-item p-1 me-3 ms-3  header_links fs-5  border-0 font-weight-bold' >
                                                <div className="profile_pic_box float-start">
                                                    <img src={'https://shopapp.self-shopping.com/public/viewimage?filename=profile/' + data.pic} className=' profile_mini_image' alt="" />
                                                </div>
                                                <div className="dropdown-center float-end">
                                                    <span data-bs-toggle='dropdown'>Account</span>
                                                    <ul className="dropdown-menu ">
                                                        <div className="text-center menu_top_text pt-3 pb-3" style={{ width: 500 }}>

                                                            <p className="fs-4 fw-bold accent_color mb-2" >{data.username}</p>
                                                            <p className="mb-2 fs-4 selfcolor">{data.number}</p>
                                                            <p className="mb-2 fs-6">{data.status == 0 ? 'Customer' : data.status == 1 ? 'Business Associate' : null}</p>
                                                            {
                                                                data.status == 1 ?
                                                                    <p className="m-0 fs-6">Business Affiliate ID: {data.id + 112233}</p>
                                                                    : null
                                                            }
                                                            <a href='/profile' className="btn rounded-pill w-75 mb-2 mt-2 p-1 m-auto btn_gradient">
                                                                <p className="m-0 small text-white fw-bold">Profile Complete {status}%</p>
                                                            </a>
                                                        </div>

                                                    </ul>
                                                </div>
                                            </li>

                                        </>
                                        :
                                        <>
                                            <a data-bs-toggle="modal" data-bs-target='#login_modal' aria-expanded="false" className='list-group-item p-1 me-3 ms-3  header_links fs-5  border-0 font-weight-bold' >
                                                একাউন্ট</a>
                                        </>
                                }

                                <li className='list-group-item p-1 me-3 ms-3 header_links fs-5  border-0 font-weight-bold' >
                                    <FontAwesomeIcon className='me-2' icon={faQuestion} />
                                    হেল্প
                                </li>
                                <li className='list-group-item p-1  ms-3 fs-5 header_links float-end text-end border-0 font-weight-bold' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" >
                                    <span style={{ fontSize: 12, padding: 5 }} className="position-absolute top-0 start-100 translate-middle badge rounded bg-danger">
                                        {cart?.length}
                                        <span className="visually-hidden">unread messages</span>
                                    </span>
                                    <FontAwesomeIcon className='me-2' icon={faCartArrowDown} />
                                    কার্ট
                                </li>

                            </ul>
                            {
                            
                            window.location.pathname == '/stg/address' ? 
                            null 
                            :      window.location.pathname == '/stg/payment' ? 
                            null
                            :
                            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                                <div className="offcanvas-header p-3">
                                    <h5 className="offcanvas-title fs-6" id="offcanvasRightLabel">চেক আউট</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div className="offcanvas-body">
                                    {pdata?.length > 0 ?
                                        cart?.map((item, index) => {

                                            return <StgCheckoutContentbox key={index} data={pdata[item]} total={total} settotal={settotal} totalpoint={totalpoint} settotalpoint={settotalpoint} />

                                        })
                                        : null
                                    }

                                    
                                </div>
                                <div className="offcanvas-footer p-3">
                                    <div >
                                        <div className='d-flex flex-row justify-content-between'>
                                            <p style={{ fontWeight: "bold" }}>মোট মূল্য</p>
                                            <p style={{ fontWeight: "bold" }}>{total} TK</p>
                                        </div>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <p style={{ fontWeight: "bold" }}>মোট পয়েন্ট</p>
                                            <p style={{ fontWeight: "bold" }}>{totalpoint} পয়েন্ট</p>
                                        </div>
                                    </div>
                                    <div
                                        //onPress={() => navigation.navigate('StgMultiCartPreview',{"cashback": 0, "color": "", "currentImage": "pimage11678731661849.jpg", "district": "Barishal", "formaddress": "Test", "formcontact": "01813404900", "formcontact2": "01813404901", "formname": "Test", "inputprice": "445", "orderamount": 445, "pid": undefined, "qnty": 1, "size": "", "totalincome": 256, "totalproductprice": 189})}
                                        onClick={() => {
                                            proceedTonext()
                                        }}
                                        style={{
                                            backgroundColor: "#F4511E",
                                            padding: 10,
                                            borderRadius: 5,
                                            marginBottom: 20,
                                        }}>
                                        <div  className='d-block text-center' style={{ width: '100%', textAlign: 'center', color: 'white', textTransform: 'uppercase', alignSelf: 'center' }}>পরবর্তী ধাপে যান</div>
                                    </div>

                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <a href={whatsapplink} target="_blank" className='chat_button'>
                <img src={whatsapp} className="w-50" alt="" />
                <div className=" small text-white badge bg-secondary">সাপোর্ট সেন্টার</div>
            </a>
            <div className={'modal ' + logindisplay} id="login_modal" >
                <div className="modal-dialog ">
                    <div className="modal-content overflow-scroll hidescroll login_popup">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        {
                            currentStep == 'login' ?
                                <div className="modal-body selfbggradient text-white p-0 pt-5 login_body rounded text-center">
                                    <h4 className="m-0 fw-bold" >লগইন ফর্ম</h4>
                                    {/* <p className="m-0">----</p>
                                <p className="fs-5 m-0"> </p> */}
                                    <p className="m-0 mb-5">আমাদের সাথে চলুন</p>
                                    <div className="login_input_box bg-white rounded-bottom p-3 pt-5 d-flex flex-column">

                                        <div className="d-flex flex-row mb-3 justify-content-between align-items-center">
                                            <FontAwesomeIcon className=' text-secondary fs-5 login_icons' icon={faPhone} />
                                            <input type="number" onChange={textInputChange} value={number} className='form-control  p-2 ps-5 border-0 border-bottom ' placeholder='মোবাইল নাম্বার' />
                                        </div>
                                        <div className='text-start text-danger float-start'>{error.number}</div>

                                        <div className="d-flex flex-row mb-3 justify-content-between align-items-center">
                                            <FontAwesomeIcon className=' text-secondary fs-5 login_icons' icon={faLock} />
                                            <input type="password" onChange={textPasswordChange} value={password} className='form-control  p-2 ps-5 border-0 border-bottom ' placeholder='পাসওয়ার্ড' />
                                        </div>
                                        {
                                            isError == true ?
                                                ErrorToast(errorMsg)
                                                : null
                                        }
                                        <p onClick={currentStepChange} className="text-primary float-end align-self-end mb-3">পাসওয়ার্ড ভুলে গেছেন?</p>
                                        <div className='text-start text-danger float-start'>{error.password}</div>
                                        <button data-id="2"
                                            onClick={loginHandle}
                                            className="btn text-white" style={{ backgroundColor: 'darkblue' }}>লগইন করুন </button>

                                        {/* <div className="btn btn-primary">ওটিপি পাঠান</div> */}
                                        <p className='text-dark mt-3' >এখনও রেজিস্ট্রেশন করেন নি ?</p>
                                        <div className="btn btn-outline-danger mb-5" data-bs-toggle="modal" data-bs-target="#registration_modal">রেজিস্ট্রেশন করুন</div>
                                    </div>

                                </div>
                                : currentStep == 'pin' ?
                                    <div className="modal-body selfbggradient text-white p-0 pt-5 login_body rounded text-center">
                                        <FontAwesomeIcon className=' text-white  mb-3' style={{ fontSize: 60 }} icon={faShieldHalved} />
                                        <h4 className="m-0 fw-bold" >সিকিউরিটি পিন</h4>
                                        {/* <p className="m-0">----</p>
                                <p className="fs-5 m-0"> </p> */}
                                        <p className="m-0 mb-5 mt-4">আপনার ৪ ডিজিটের গোপন পিনটি লিখুন</p>
                                        <div className="login_input_box bg-white rounded-bottom p-3 pt-5 d-flex flex-column">

                                            <div className="d-flex mb-4">
                                                <input type="number" value={pin1} onChange={pin1change} autoComplete="off" maxLength="1" className='pininput inputpins_1 text-center flex-fill ms-2 me-2 form-control' data-pin="1" />
                                                <input type="number" value={pin2} onChange={pin2change} autoComplete="off" maxLength="1" className='pininput inputpins_2 text-center flex-fill ms-2 me-2 form-control' data-pin="2" />
                                                <input type="number" value={pin3} onChange={pin3change} autoComplete="off" maxLength="1" className='pininput inputpins_3 text-center flex-fill ms-2 me-2 form-control' data-pin="3" />
                                                <input type="number" value={pin4} onChange={pin4change} autoComplete="off" maxLength="1" className='pininput inputpins_4 text-center flex-fill ms-2 me-2 form-control' data-pin="4" />

                                            </div>


                                            {
                                                isError == true ?
                                                    ErrorToast(errorMsg)
                                                    : null
                                            }
                                            <p className='text-danger font_small'>(পিন নম্বরটি নিজের কাছে সংরক্ষণ করুন এবং কারো সাথে শেয়ারে করা থেকে বিরত থাকুন)</p>
                                            <div className='text-start text-danger float-start'>{error.password}</div>
                                            <button data-id="2" onClick={pinHandle} className="btn btn-danger">প্রবেশ করুন</button>
                                            {/* <div className="btn btn-primary">ওটিপি পাঠান</div> */}
                                            <p className='text-dark mt-3' >এখনও রেজিস্ট্রেশন করেন নি ?</p>
                                            <div className="btn btn-outline-danger mb-5">রেজিস্ট্রেশন করুন</div>

                                        </div>
                                    </div>
                                    : currentStep == 'forgot' ?
                                        //         <div className="modal-body selfbggradient text-white p-0 pt-5 login_body rounded text-center">
                                        //             <h4 className="m-0 fw-bold" >পাসওয়ার্ড উদ্ধার করুন </h4>
                                        //             {/* <p className="m-0">----</p>
                                        // <p className="fs-5 m-0"> </p> */}
                                        //             <p className="m-0 mb-5">আপনার সেল্ফ একাউন্টের মোবাইল নম্বর টি লিখুন </p>
                                        //             <div className="login_input_box bg-white rounded-bottom p-3 pt-5 d-flex flex-column">

                                        //                 <div className="d-flex flex-row mb-3 justify-content-between align-items-center">
                                        //                     <FontAwesomeIcon className=' text-secondary fs-5 login_icons' icon={faPhone} />
                                        //                     <input type="number" onChange={textInputChange} value={number} className='form-control  p-2 ps-5 border-0 border-bottom ' placeholder='মোবাইল নাম্বার' />
                                        //                 </div>


                                        //                 {
                                        //                     isError == true ?
                                        //                         ErrorToast(errorMsg)
                                        //                         : null
                                        //                 }


                                        //                 <button data-id="2"
                                        //                     onClick={forgotHandle}
                                        //                     className="btn text-white" style={{ backgroundColor: 'darkblue' }}>ওটিপি পাঠান</button>
                                        //                 <a href='/' className="btn btn-outline-danger mb-5 mt-3" >বন্ধ করুন</a>
                                        //             </div>
                                        //         </div>
                                        null
                                        : currentStep == 'forgotreset' ?
                                            <div className="modal-body selfbggradient text-white p-0 pt-5 login_body rounded text-center">
                                                <h4 className="m-0 fw-bold" >পুনরায় পাসওয়ার্ড দিন</h4>
                                                <p className="m-0 mb-5">আপনার মোবাইল নম্বরে ৬ ডিজিটের একটি পাসওয়ার্ড (ও টি পি ) পাঠানো হয়েছে</p>
                                                <div className="login_input_box bg-white rounded-bottom p-3 pt-5 d-flex flex-column">
                                                    <div className="d-flex flex-row mb-3 justify-content-between align-items-center">
                                                        <FontAwesomeIcon className=' text-secondary fs-5 login_icons' icon={faPhone} />
                                                        <input autoComplete='false' type="number" name="otp" onChange={forgototpinput} value={forgototp} className='form-control  p-2 ps-5 border-0 border-bottom ' placeholder='৬ ডিজিটের ওটিপি টি লিখুন ' />
                                                    </div>
                                                    <div className="d-flex flex-row mb-3 justify-content-between align-items-center">
                                                        <FontAwesomeIcon className=' text-secondary fs-5 login_icons' icon={faPhone} />
                                                        <input type="password" name="newpassword" onChange={forgotnewpass} value={newpass} className='form-control  p-2 ps-5 border-0 border-bottom ' placeholder='নতুন পাসওয়ার্ড লিখুন' />
                                                    </div>
                                                    <div className="d-flex flex-row mb-3 justify-content-between align-items-center">
                                                        <FontAwesomeIcon className=' text-secondary fs-5 login_icons' icon={faPhone} />
                                                        <input type="password" name="confirmnewpassword" onChange={forgotconpass} value={conpass} className='form-control  p-2 ps-5 border-0 border-bottom ' placeholder='পুনরায় নতুন পাসওয়ার্ড লিখুন' />
                                                    </div>


                                                    {
                                                        isError == true ?
                                                            ErrorToast(errorMsg)
                                                            : null
                                                    }


                                                    <button data-id="2" onClick={forgotconfirm} className="btn text-white" style={{ backgroundColor: 'darkblue' }}>পাসওয়ার্ড সেট করুন</button>

                                                    {/* <div className="btn btn-primary">ওটিপি পাঠান</div> */}

                                                    <a href='/' className="btn btn-outline-danger mb-5 mt-3" >বন্ধ করুন</a>
                                                </div>

                                            </div>
                                            : null
                        }
                    </div>
                </div>
            </div>
            {/* global welcome bonus
            global performance income */}
            <div className="modal" id="registration_modal">
                <div className="modal-dialog">
                    <div className="modal-content  overflow-scroll hidescroll bg-lightgray rounded-0 login_popup">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="modal-body p-0">
                            {
                                regstep == 'otp' ?
                                    <div className="modal-body selfbggradient text-white p-0 pt-5 login_body rounded text-center">
                                        <h4 className="m-0 fw-bold" >স্বাগতম </h4>
                                        <p className="m-0">----</p>
                                        <p className="fs-5 m-0">রেজিস্ট্রেশন ফর্ম </p>
                                        <p className="m-0 mb-5">আপনাকে পেয়ে আমরা আনন্দিত </p>
                                        <div className="login_input_box bg-white rounded-bottom p-3 pt-5 d-flex flex-column">
                                            <div className="d-flex flex-row mb-3 justify-content-between align-items-center">
                                                <FontAwesomeIcon className=' text-secondary fs-5 login_icons' icon={faPhone} />
                                                <input type="number" onChange={regNumberChange} value={regnumber} className='form-control  p-2 ps-5 border-0 border-bottom ' placeholder='মোবাইল নাম্বার' />
                                            </div>
                                            <div className='text-start text-danger float-start'>{error?.regnumber}</div>


                                            {
                                                isError == true ?
                                                    ErrorToast(errorMsg)
                                                    : null
                                            }

                                            <div className='text-start text-danger float-start'>{error.password}</div>
                                            <button data-id="2"
                                                onClick={otpHandle}
                                                className="btn text-white" style={{ backgroundColor: 'darkblue' }}>ওটিপি পাঠান  </button>

                                            {/* <div className="btn btn-primary">ওটিপি পাঠান</div> */}
                                            <p className='text-dark mt-3 ' >একাউন্ট করা আছে ?</p>
                                            <div className="btn btn-outline-danger mb-5" data-bs-toggle="modal" data-bs-target="#login_modal" >লগইন করুন</div>
                                        </div>

                                    </div>
                                    : regstep == 'verify' ?
                                        <div className="modal-body bg-lightgray text-white p-0 pt-5 login_body rounded text-center">
                                            <img src={require('../img/OTP6.png')} style={{ width: 100, height: 100 }} alt="" />


                                            <p className="fs-5 text-dark m-0">ভেরিফিকেশন</p>
                                            <p className="m-0 text-dark mb-5">আপনার মোবাইল নম্বরে পাঠানো ৬ ডিজিটের কোড টি লিখুন</p>
                                            <div className="login_input_box  bg-lightgray rounded-bottom p-3 pt-5 d-flex flex-column">

                                                <div className="d-flex mb-4">
                                                    <input type="number" value={otp1} onChange={otp1change} autoComplete="off" maxLength="1" className='fw-bold otpinput otpinput_1 text-center flex-fill ms-2 me-2 form-control' data-pin="1" />
                                                    <input type="number" value={otp2} onChange={otp2change} autoComplete="off" maxLength="1" className='fw-bold otpinput otpinput_2 text-center flex-fill ms-2 me-2 form-control' data-pin="2" />
                                                    <input type="number" value={otp3} onChange={otp3change} autoComplete="off" maxLength="1" className='fw-bold otpinput otpinput_3 text-center flex-fill ms-2 me-2 form-control' data-pin="3" />
                                                    <input type="number" value={otp4} onChange={otp4change} autoComplete="off" maxLength="1" className='fw-bold otpinput otpinput_4 text-center flex-fill ms-2 me-2 form-control' data-pin="4" />
                                                    <input type="number" value={otp5} onChange={otp5change} autoComplete="off" maxLength="1" className='fw-bold otpinput otpinput_5 text-center flex-fill ms-2 me-2 form-control' data-pin="5" />
                                                    <input type="number" value={otp6} onChange={otp6change} autoComplete="off" maxLength="1" className='fw-bold otpinput otpinput_6 text-center flex-fill ms-2 me-2 form-control' data-pin="6" />

                                                </div>


                                                {
                                                    isError == true ?
                                                        ErrorToast(errorMsg)
                                                        : null
                                                }


                                                <button data-id="2" onClick={verificationHandle} className="btn text-white" style={{ backgroundColor: 'darkblue' }}>সামনে এগিয়ে যান  </button>

                                                {/* <div className="btn btn-primary">ওটিপি পাঠান</div> */}

                                                <div className="btn btn-outline-danger mt-4 mb-5 " data-bs-toggle="modal" data-bs-target="#login_modal" >পুনরায় ওটিপি পাঠান </div>
                                                <div className="mb-4"></div>
                                            </div>

                                        </div>
                                        : regstep == 'regform' ?
                                            <div className="modal-body selfbggradient text-white p-0 pt-5 login_body rounded text-center">

                                                <p className="fs-5 mb-5">রেজিস্ট্রেশন ফর্ম </p>

                                                <div className="login_input_box bg-white rounded-bottom p-3 pt-5 d-flex flex-column">

                                                    <div className="d-flex flex-row mb-3 justify-content-between align-items-center">
                                                        <FontAwesomeIcon className=' text-secondary fs-5 login_icons' icon={faUser} />
                                                        <input type="text" onChange={changeRegname} value={regname} className='form-control  p-2 ps-5 border-0 border-bottom ' placeholder='আপনার নাম লিখুন' />
                                                    </div>
                                                    <div className="d-flex flex-row mb-3 justify-content-between align-items-center">
                                                        <FontAwesomeIcon className=' text-secondary fs-5 login_icons' icon={faLock} />
                                                        <input type="text" onChange={changeRegpassword} value={regpassword} className='form-control  p-2 ps-5 border-0 border-bottom ' placeholder='পাসওয়ার্ড দিন' />
                                                    </div>
                                                    <div className="d-flex flex-row mb-3 justify-content-between align-items-center">
                                                        <FontAwesomeIcon className=' text-secondary fs-5 login_icons' icon={faLock} />
                                                        <input type="text" onChange={changeRegconpassword} value={regconpassword} className='form-control  p-2 ps-5 border-0 border-bottom ' placeholder='পুনরায় পাসওয়ার্ড দিন' />
                                                    </div>
                                                    <div className="d-flex flex-row mb-3 justify-content-between align-items-center">
                                                        <FontAwesomeIcon className=' text-secondary fs-5 login_icons' icon={faBriefcase} />
                                                        <input type="text" onChange={changeRegRefer} value={regrefer} className='form-control  p-2 ps-5 border-0 border-bottom ' placeholder='বিজনেস এফিলিয়েট আইডি' />
                                                    </div>
                                                    <div className='text-start text-danger float-start'>{error?.regpass}</div>


                                                    {
                                                        isError == true ?
                                                            ErrorToast(errorMsg)
                                                            : null
                                                    }

                                                    <div className='text-start text-danger float-start'>{error.password}</div>
                                                    <p className="font-small text-dark">
                                                        রেজিস্ট্রেশন বাটন এ ক্লিক করার মাধ্যমে আপনি আমাদের <b>টার্ম কন্ডিশন</b> এবং <b>গোপনীয়তা নীতি</b> গ্রহণ করে নিয়েছেন
                                                    </p>
                                                    <button data-id="2" onClick={regHandle} className="btn text-white" style={{ backgroundColor: 'darkblue' }}>রেজিস্টার</button>

                                                    {/* <div className="btn btn-primary">ওটিপি পাঠান</div> */}

                                                    <div className="btn btn-outline-danger mt-3 mb-5" data-bs-toggle="modal" data-bs-target="#login_modal" >লগইন</div>
                                                </div>

                                            </div>
                                            : null
                            }

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Header;