import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../SideMenu';
import { useParams } from 'react-router-dom';
import { faCamera, faChevronRight, faCloudDownload, faHandPointer, faMobile, faMobileScreenButton, faPaperPlane, faShoppingBag, faShoppingCart, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { saveAs } from 'file-saver';
const Body = () => {
  const shoputils = require('../../utils/shoputils.json');
  const params = useParams();
  const [productid, setProductId] = useState(params.id);
  const [loggedin, setLoggedin] = useState(false);
  const [pdata, setPdata] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentimage, setCurrentImage] = useState(null)
  const [refreshing, setRefreshing] = useState(null)
  const [userstatus, setuserstatus] = useState(null)
  const [ResellerStatus, setResellerStatus] = useState(0)
  const [justforyou, setJustForYou] = useState([]);
  const [nodata, setNodata] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [data, setData] = useState(false);
  useEffect(() => {
    if (nodata == false) {
      fetchData()



      
      let products = [{ pid: params.id }];
      localStorage.setItem('product', JSON.stringify(products));
    }
  },[]);
  const downloadImage = () => {
    saveAs(shoputils.imageurl + currentimage, currentimage);
  }
  
  const fetchData = async () => {
    try {
      setLoading(true)
      const random = Math.random();
      const users = localStorage.getItem("userinfo");
      
      
        
      //let  response = await fetch(shoputils.stgapi + '/getsingleproductdetails?webpost=1&random=' + random + '&vid=1&id=' + productid);
      const response = await fetch(shoputils.stgapi + '/getsingleproductdetails?webpost=1&random=' + random + '&vid=1&id=' + productid)
      const json = await response.json();

      // console.log(json.singleproduct)
      //const json = await response.json();

      setPdata(json.singleproduct)
      fetchProduct(json.singleproduct.subcat);

      setCurrentImage(json.singleproduct.image1)
      setuserstatus(json.usertype);
      //setPhoneNumber(json.vendornumber)
      //setReports(json.reports)
      setLoading(false)
      setNodata(true);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
      setRefreshing(false)
    }


    setLoading(false);
  }
  const fetchProduct = async (subcat) => {
    try {
      setLoading(true)
      const random = Math.random();

      const response = await fetch(shoputils.url + '?random=' + random + '&sectioned=category&subcatid=' + subcat + '&name=' + subcat);
      const json = await response.json();
      setJustForYou([...justforyou, ...json.products]);
      setLoading(false)
      setNodata(true);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
      setRefreshing(false)
    }


    setLoading(false);
  }
  const renderItem = (item, index) => {
    return (
      <a href={'/shop/product/' + item.id + '/' + item.name} key={index} className="text-dark col-lg-3 col-md-3 hvr-pop col-sm-6 col-6  p-2 ">
        <div className='rounded border  shadow-sm rounded overflow-hidden'>

          <div className='w-100 overflow-hidden justforyouitem'>
            <img src={shoputils.imageurl + item.img1} className="d-block w-100" alt="..." />
          </div>
          <div className="bg-lightgray ps-2 pe-2">
            <p className='m-0 onlinetext fw-bold selfcolor'>{item.name}</p>


            {
              ResellerStatus == 0 ?
                item.d == 'flash' ?
                  <div className='d-flex flex-row justify-content-between'>
                    <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                    <h6 className='fw-bold mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>

                  </div>

                  : item.d == 'discount' ?
                    <div className='d-flex flex-row justify-content-between'>
                      <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                      <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>

                    </div>

                    : item.d == 'none' ?


                      <div className='d-flex flex-row justify-content-between'>
                        <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_price)}</h6>
                      </div >

                      :
                      null
                : item.d == 'flash' ?
                  <div className='d-flex flex-row justify-content-between'>
                    <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                    <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>

                  </div>

                  : item.d == 'discount' ?
                    <div className='d-flex flex-row justify-content-between'>
                      <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                      <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>

                    </div >

                    : item.d == 'none' ?
                      <div className='d-flex flex-row justify-content-between'>
                        <h6 className='fw-bold  mb-2'>৳ {Math.round(item.price)}</h6>
                      </div >
                      :
                      null
            }

          </div>
        </div>

      </a>
    );
  }
  const Stars = (props) => {
    var stars = props.stars
    if ((props.size)) {
      var size = props.size;
    } else {
      size = 10;
    }
    if (stars == 5) {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
      </div>
    } else if (stars == 4) {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
      </div>
    } else if (stars == 3) {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
      </div>
    } else if (stars == 2) {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
      </div>
    } else if (stars == 1) {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
      </div>
    } else if (stars == 0) {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1 ' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
      </div>
    } else {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
      </div>
    }
  }
  if (loadMore == false) {
    window.onscroll = async function (ev) {
      if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
        setLoadMore(true)
        try {
          setLoadingMore(true);
          var index = justforyou.length;
          var lastitem = justforyou[index - 1]; //get 1st item in the array
          if (lastitem) {
            const random = Math.random();
            const response = await fetch(shoputils.url + '?random=' + random + '&lastid=' + lastitem.id + '&sectionedmore=category&subcatid=' + pdata.subcat + '&name=' + pdata.subcat);
            const json = await response.json();
            const responsedata = json.products;
            if (json.products.length > 0) {
              let arry = [...justforyou, ...responsedata];
              setJustForYou(arry);
              setLoadMore(false)
            }
          }
          setLoadingMore(false);
        } catch (e) {
          setLoadingMore(false);
        } finally {
          setLoadingMore(false);
        }
      }
    };
  }
  return (
    nodata == true ?
      <div className="container-fluid">
        <div className="container-fluid  bg-lightgray">
          <div className="container  pt-2 pb-3">

            <div className="row ">
              <div className="d-flex text-nowrap bg-white text-secondary small mb-2  align-items-baseline">
                <a href='/' className='m-0 me-2 text-secondary'>Home</a>
                <FontAwesomeIcon className=' me-2 mt-1' icon={faChevronRight} />
                <a href={'/shop/category/category/' + pdata.cat} className='m-0 me-2 text-secondary'>{pdata.cat}</a>
                <FontAwesomeIcon className=' me-2 mt-1' icon={faChevronRight} />
                <a href={'/shop/category/category/' + pdata.subcat} className='m-0 me-2 text-secondary'>{pdata.subcat}</a>
                <FontAwesomeIcon className=' me-2 mt-1' icon={faChevronRight} />
                <a href={'/shop/category/category/' + pdata.cocat} className='m-0 me-2 text-secondary'>{pdata.cocat}</a>
              </div>
              <SideMenu />

              <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">

                <div className="row">
                  <div className='col-lg-6 bg-white rounded col-md-6 col-sm-12 col-12 pt-2'>
                    <div className="modal" id="imagepreview">
                      <div className="modal-dialog modal-md">
                        <div className="modal-content">
                          <FontAwesomeIcon className=' me-2 mt-1 border border-danger text-danger rounded-pill p-2 ' data-bs-dismiss="modal" style={{ position: 'absolute', zIndex: 1, right: 5, top: 5, width: 10, height: 10 }} icon={faTimes} />
                          <div className="modal-body p-0">
                            <img className='w-100' src={shoputils.stgimageurl + currentimage} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div data-bs-toggle="modal" data-bs-target="#imagepreview" className=" shop_pd_mainimage_container overflow-hidden p-1 text-center border shadow-sm mb-3 m-auto">
                      <img className='shop_pd_mainimage' src={shoputils.stgimageurl + currentimage} alt="" />
                    </div>

                  </div>
                  <div className='col-lg-6 bg-white p-2 rounded col-md-6 col-sm-12 col-12'>
                    <div className="d-flex flex-row justify-content-between">
                      <img className='w-25  border p-1 shadow-sm' onClick={() => setCurrentImage(pdata.image1)} src={shoputils.stgimageurl + pdata.image1} alt="..." />
                      <img className='w-25  border p-1 shadow-sm' onClick={() => setCurrentImage(pdata.image2)} src={shoputils.stgimageurl + pdata.image2} alt="..." />
                      <img className='w-25  border p-1 shadow-sm' onClick={() => setCurrentImage(pdata.image3)} src={shoputils.stgimageurl + pdata.image3} alt="..." />
                      <img className='w-25 border p-1 shadow-sm' onClick={() => setCurrentImage(pdata.image4)} src={shoputils.stgimageurl + pdata.image4} alt="..." />
                    </div>
                    <div className="d-flex justify-content-between p-2 ps-0 ">
                      <div>
                        <p className='me-3 shop_product_name flex-fill '>{pdata.Name}</p>
                        <div className='d-flex flex-row justify-content-start'>
                          <p className='m-0 me-1 align-self-center'>প্রোডাক্ট কোড :</p>
                          <p className='selfcolor mb-0 fs-6 fw-bold'>{pdata.code}</p>
                        </div>
                      </div>


                      <div className='text-nowrap align-self-stretch self_pd_stock align-text-center pt-2'>
                        <a href={'/stg/productdetail/' + productid + '/' + pdata.urlname} class="btn selfbgcolor text-white" >বিস্তারিত দেখুন</a>
                      </div>

                    </div>





                  </div>
                </div>


              </div>



            </div>
          </div>
        </div>
        <div className="container-fluid   bg-lightgray pt-3 pb-5" style={{ marginBottom: 35 }}>
          <div className="container p-0">
            <h4 className='  selfbgcolor p-2 mb-0 rounded-top text-center text-white moreproduct_heading'>আপনার প্রয়োজনীয় আরো পণ্য</h4>
          </div>
          <div className="container bg-white  shadow-sm  pt-4 pb-4">

            <div className="row">
              {justforyou.map((item, index) => {
                return renderItem(item, index)
              })}
            </div>
            {
              loadMore == true ?
                <div className="col-12 text-center mb-4 pb-4">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
                : null
            }
          </div>

        </div>
      </div>
      : null
  )
}

export default Body;