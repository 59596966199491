import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faEnvelope, faGlobe, faHome, faHomeAlt, faMessage, faPiggyBank, faSuitcase, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
const Footer = () =>  {

    return( 
    <div className='p-0 m-0'>
        <div className="webfooter  d-lg-block d-md-block d-sm-none d-none">
            <div className=" selfbgcolor small container-fluid"  > 
                <div className="container  pt-4  ">  
                    <div className="row">    
                        <div className="col-lg-3  col-md-6 col-sm-12 mb-4">     
                            <h6 className="fw-bold text-white border-bottom border-warning pb-3">STAY CONNECTED WITH US</h6>
                            <span className="footer_socials "  >
                            <a href='https://self-shopping.com' className=" bg-primary me-2 footer_link  fw-bold"    ><FontAwesomeIcon className=' fs-4 text-white' icon={faFacebook} /> </a>
                            <a href='https://self-shopping.com' className=" bg-primary me-2 footer_link fw-bold"  ><FontAwesomeIcon className=' fs-4 text-white' icon={faFacebook} /> </a>
                            <a href='https://self-shopping.com' className=" bg-danger me-2 footer_link fw-bold"    ><FontAwesomeIcon className=' fs-4 text-white' icon={faYoutube} />  </a>
                            <a href='https://self-shopping.com' className=" bg-danger me-2 footer_link fw-bold"    ><FontAwesomeIcon className=' fs-4 text-white' icon={faGlobe} /></a>
                            </span>
                            <div className="clearfix"></div>
                            <span className="footer_socials "  >
                            <span className="me-2 ms-1 text-white text-uppercase fw-bold"  >Group</span>
                            <span className="me-2 ms-2 text-white  text-uppercase fw-bold"  >Page</span>
                            <span className="me-1 ms-2 text-white  text-uppercase fw-bold"  >Channel</span>
                            <span className="me-2 ms-0 text-white  text-uppercase fw-bold" >Web App</span>
                            </span>                            
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4"> 
                            <h6 className="fw-bold border-bottom border-warning pb-3" >CONTACT US</h6>
                            <a href='https://self-shopping.com'   className="m-0 d-block text-warning text-decoration-none h6" ><FontAwesomeIcon className='  text-warning' icon={faEnvelope} /> Support :: </a>
                            <a href='https://self-shopping.com'  className="m-0 d-block text-white text-decoration-none h6 ">support@self-shopping.com</a>
                            <a href='https://self-shopping.com'  className="m-0 d-block text-warning text-decoration-none h6" > <FontAwesomeIcon className='  text-warning' icon={faEnvelope} /> Career ::</a>
                            <a href='https://self-shopping.com'  className="m-0 d-block text-white  text-decoration-none h6 ">career@self-shopping.com</a>
                            
                            <a href='https://self-shopping.com'  className="m-0 d-bloc text-warning  text-decoration-none h6" > <FontAwesomeIcon className='  text-warning' icon={faEnvelope} /> Information ::</a>
                            <a href='https://self-shopping.com'  className="m-0 d-block text-white  text-decoration-none h6 ">info@self-shopping.com</a>

                            <a href='https://self-shopping.com'  className="m-0 d-block text-warning text-decoration-none h6" > <FontAwesomeIcon className='text-warning' icon={faEnvelope} /> Administration :: </a>
                            <a href='https://self-shopping.com'  className="m-0 d-block text-white  text-decoration-none h6 ">admin@self-shopping.com</a>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4"> 
                            <h6 className="fw-bold border-bottom border-warning pb-3" >Menu</h6>
                            <a href='/aboutus' className="m-0 d-bloc text-white  text-decoration-none h6" > <FontAwesomeIcon className='text-warning' icon={faEnvelope} /> About Us</a><br></br>
                            <a href='/managements' className="m-0 d-bloc text-white  text-decoration-none h6" > <FontAwesomeIcon className='text-warning' icon={faEnvelope} /> Our Managements</a>
                            <a href='/termscondition' className="m-0 d-block text-white text-decoration-none h6" ><FontAwesomeIcon className='text-warning' icon={faEnvelope} /> Terms and Condition </a>
                            <a href='/returnrefundpolicy' className="m-0 d-block text-white text-decoration-none h6" > <FontAwesomeIcon className='text-warning' icon={faEnvelope} /> Return, Refund and Cancellation Policy</a>
                            <a href='/privecypolicy' className="m-0 d-block text-white text-decoration-none h6" > <FontAwesomeIcon className='text-warning' icon={faEnvelope} /> Privacy Policy</a>
                            <a href='/AccountDelete' className="m-0 d-block text-white text-decoration-none h6" > <FontAwesomeIcon className='text-warning' icon={faEnvelope} /> Account Delete Application</a>
                            </div>

                        <div className="col-lg-3 col-md-12 col-sm-12 mb-4">
                            <h6 className="fw-bold border-bottom border-warning pb-3">LICENSE INFORMATION</h6>
                                <div className="row">                                    
                                    <div className="col-12 footer_payments ">   
                                        {/* <span className="float-start  me-2 bg-light footer_boxs py-1 mb-2"><img className="footer_images"   src="https://self-shopping.com/img/payments/bkash.png" alt="" /> </span>
                                        <span className="float-start me-2 bg-light footer_boxs  py-1  mb-2"><img className="footer_images" src="https://self-shopping.com/img/payments/nagad.png" alt="" /> </span>  
                                        <span className="float-start me-2 bg-light footer_boxs  py-1  mb-2"><img className="footer_images" src="https://self-shopping.com/img/payments/rocket.jpg" alt="" /></span>
                                        <span className="float-start me-2 bg-light footer_boxs  py-1  mb-2"><img className="footer_images" src="https://self-shopping.com/img/payments/dbbl.jpg" alt="" /> </span>
                                        <span className="float-start me-2 bg-light footer_boxs  py-1  mb-2"><img className="footer_images" src="https://self-shopping.com/img/payments/ibbl.jpg" alt="" /> </span>
                                        <span className="float-start me-2 bg-light footer_boxs  py-1  mb-2"><img className="footer_images" src="https://self-shopping.com/img/payments/city.png" alt="" />  </span> */}
                                    <a href='https://self-shopping.com'   className="m-0 d-block text-warning text-decoration-none h6" ><FontAwesomeIcon className='  text-warning' icon={faEnvelope} /> Trade License Number :: </a>
                                    <a href='https://self-shopping.com'  className="m-0 d-block text-white text-decoration-none h6 ">64641</a>
                                    <a href='https://self-shopping.com'  className="m-0 d-block text-warning text-decoration-none h6" > <FontAwesomeIcon className='  text-warning' icon={faEnvelope} /> TIN Certificate ::</a>
                                    <a href='https://self-shopping.com'  className="m-0 d-block text-white  text-decoration-none h6 ">295866854660</a>
                                    <a href='https://self-shopping.com'  className="m-0 d-block text-warning text-decoration-none h6" > <FontAwesomeIcon className='  text-warning' icon={faEnvelope} /> Office Address ::</a>
                                    <a href='https://self-shopping.com'  className="m-0 d-block text-white  text-decoration-none h6 ">Impulse City Center (5th Floor),O.R Nizam Orad Gol Pahar More, Panchlaish, Chattogram</a>
                                        
                                    </div>
                                </div>                                
                            
                        </div>
                    </div>
                </div>  
            </div>
            
            {/* <div className='container'>
                <div className='row'>
                   
                        <img className="" src={require('../img/aamarpay.jpg')} alt="" />
                
                </div>
            </div> */}
            <div className=" container-fluid " style={{backgroundColor:'#d23c01'}} >
                <div className="  text-center p-4  text-white">
                    Copyright © 2018 - 2022 | All Rights Reserved by self-shopping.com
                </div> 
            </div>
        </div>
        <div className="d-lg-none d-md-none d-sm-block d-block pb-5">
            
        </div>
        <div className="mobileFooter d-lg-none d-md-none d-sm-block d-block selfbgcolor p-2 " >
            <div className="row">
                <div className="col-3 text-center border-end border-warning text-white">
                    <FontAwesomeIcon className=' fs-4 text-white' icon={faHomeAlt} />
                    <br/>
                    হোম
                </div>
                <div className="col-3 text-center border-end border-warning text-white">
                    <FontAwesomeIcon className=' fs-4 text-white' icon={faSuitcase} />
                    <br/>
                    অফার
                </div>
                <div className="col-3 text-center border-end border-warning text-white">
                    <FontAwesomeIcon className=' fs-4 text-white' icon={faUserFriends} />
                    <br/>
                    এফিলিয়েট
                </div>
                <div className="col-3 text-center text-white">
                    <FontAwesomeIcon className=' fs-4 text-white' icon={faMessage} />
                    <br/>
                    লাইভ চ্যাট
                </div>
            </div>
        </div>
    </div>
    )
}

export default Footer;
                                