import React,{ useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faHouse, faList,faListAlt, faCog, faShield,faPhone,faQuestion, faSignIn } from '@fortawesome/free-solid-svg-icons';

const Menu = () =>  {
    const [loggedin,setLoggedin] = useState(false)
    useEffect(() => {
        let userinfo = localStorage.getItem('userinfo');
        let user = JSON.parse(userinfo);
        if(user){
            setLoggedin(true);
        }
    },[])
    return(
         <>
        {
            loggedin == true ?
            <ul className="list-group  shadow-sm">
                <a href='/' className="list-group-item sige_menu_item border-0 p-2">
                    <FontAwesomeIcon className='me-2' icon={faHouse} />  
                    হোম
                </a>
                <a href='/profile' className="list-group-item sige_menu_item border-0 p-2">
                    <FontAwesomeIcon className='me-2' icon={faListAlt} />  
                    আমার প্রোফাইল 
                </a>
                {/* <a className="list-group-item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faList} />              
                    উপার্জিত পয়েন্টস
                </a>
                <li className="list-group-item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faList} />  
                    ভাউচার পয়েন্টস 
                </li>
                <li className="list-group-item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faList} /> 
                    রিচার্জ পয়েন্টস
                </li>
                <li data-bs-target="#login_modal" data-bs-toggle="modal" className="list-group-item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faList} /> 
                    রিচার্জ মার্চেন্ট পয়েন্টস
                </li> */}
                <a href='/aboutus' className="list-group-item sige_menu_item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faQuestion} />  
                    আমাদের সম্পর্কে
                </a>
                <a href="/privecypolicy" className="list-group-item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faShield} />  
                    গোপনীয়তা নীতি
                </a>
                <a href="/termscondition" className="list-group-item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faShield} />  
                    টার্ম কন্ডিশন
                </a>
                <a href='/contactus' className="list-group-item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faPhone} />  
                    যোগাযোগ
                </a>
                <li className="list-group-item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faCog} />  
                    সেটিংস
                </li>
                <a href="/logout" className="list-group-item sige_menu_item border-0 p-2 ">
                
                <FontAwesomeIcon className='me-2' icon={faSignIn} /> 
                    লগ আউট
                
                </a>
                
            </ul>
            :
            <ul className="list-group  shadow-sm">
                <a href='/' className="list-group-item sige_menu_item border-0 p-2">
                    <FontAwesomeIcon className='me-2' icon={faHouse} />  
                    হোম
                </a>
                
                <a href='/aboutus' className="list-group-item sige_menu_item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faHouse} />  
                    আমাদের সম্পর্কে
                </a>
                <a href='/privecyPolicy' className="list-group-item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faListAlt} />  
                    গোপনীয়তা নীতি
                </a>
                <a href="/termscondition" className="list-group-item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faShield} />  
                    টার্ম কন্ডিশন
                </a>
                <a href='contactus' className="list-group-item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faList} />  
                    যোগাযোগ
                </a>
                
                <a data-bs-target="#login_modal" data-bs-toggle="modal" className="list-group-item sige_menu_item border-0 p-2 ">
                    <FontAwesomeIcon className='me-2' icon={faQuestion} /> 
                    লগইন / রেজিস্ট্রেশন 
                </a>
                
            </ul>
        }
        </>
    )
}

export default Menu;
                            
