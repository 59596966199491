import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SideMenu from "../SideMenu";
import { useParams } from "react-router-dom";
import {
  faCamera,
  faChevronRight,
  faCloudDownload,
  faHandPointer,
  faMobile,
  faMobileScreenButton,
  faPaperPlane,
  faShoppingBag,
  faShoppingCart,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { saveAs } from "file-saver";
// import { Helmet } from 'react-helmet';
import { ErrorToast } from "../../utils/Utils";
const Body = () => {
  const shoputils = require("../../utils/shoputils.json");
  const params = useParams();
  const [productid, setProductId] = useState(params.id);
  const [loggedin, setLoggedin] = useState(false);
  const [pdata, setPdata] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentimage, setCurrentImage] = useState(null);
  const [refreshing, setRefreshing] = useState(null);
  const [favoritestatus, setFevoriteStatus] = useState(0);
  const [userstatus, setuserstatus] = useState(null);
  const [ResellerStatus, setResellerStatus] = useState(0);
  const [justforyou, setJustForYou] = useState([]);
  const [nodata, setNodata] = useState(false);
  const [reports, setReports] = useState([]);
  const [inputvisible, setInputvisible] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [data, setData] = useState(false);
  const [reportselected, setreportselected] = useState("");
  const [inputvalue, setinputvalue] = useState("");
  useEffect(() => {
    if (nodata == false) {
      fetchData();
      getResellerStatus();
      let products = [{ pid: params.id }];
      localStorage.setItem("product", JSON.stringify(products));
    }
  }, []);
  const [redirect, setRedirect] = useState(true);
  const [cartitem, setCartitem] = useState([])
  const showError = (msg) => {
    setIsError(true);
    setErrorMsg(msg);
    setTimeout(() => {
      setIsError(false);
    }, 3000);
  };
  const downloadImage = () => {
    saveAs(shoputils.imageurl + currentimage, currentimage);
  };
  const getResellerStatus = async () => {
    try {
      setLoading(true);

      const users = localStorage.getItem("userinfo");
      if (users) {
        const user = JSON.parse(users);
        const random = Math.random();

        const response = await fetch(
          shoputils.url +
          "?getResellerStatus=1&random=" +
          random +
          "&auth=" +
          user.auth
        );
        const json = await response.json();

        if (user.status == 1) {
          setResellerStatus(json.ResellerStatus);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const addToFevorite = async () => {
    setLoading(true);

    const users = localStorage.getItem("userinfo");
    const user = JSON.parse(users);
    if (user) {
      setLoading(true);

      setLoading(false);

      const random = Math.random();
      const response = await fetch(
        shoputils.url +
        "?random=" +
        random +
        "&addtofevorite=" +
        productid +
        "&auth=" +
        user.auth
      );
      const json = response.json();
      showError("ফেভারিট করা হয়েছে");
      setTimeout(() => window.location.reload(), 3000);
    } else {
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const random = Math.random();
      const response = await fetch(
        shoputils.stgapi + "/getsingleproductdetails?random=" +
        random +
        "&uid=1&vid=1&justforyoumorenew=0&id=" +
        productid
      );

      const json = await response.json();

      setPdata(json?.singleproduct);
      setFevoriteStatus(json?.fevoritestatus);
      setJustForYou(json?.justforyoumorenew);
      setCurrentImage(json?.singleproduct.image1);
      setuserstatus(json?.usertype);
      //setPhoneNumber(json.vendornumber)
      setReports(json?.reports);
      setLoading(false);
      setNodata(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }

    setLoading(false);
  };
  const renderItem = (item, index) => {
    return (
      <a
        href={"/stg/product/" + item.id + "/" + item.name}
        key={index}
        className="text-dark col-lg-3 col-md-3 hvr-pop col-sm-6 col-6  p-2 ">
        <div className="rounded border  shadow-sm rounded overflow-hidden">
          <div className="w-100 overflow-hidden justforyouitem">
            <img
              src={shoputils.imageurl + item.img1}
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="bg-lightgray ps-2 pe-2">
            <p className="m-0 onlinetext fw-bold selfcolor">{item.name}</p>
            <div className="d-flex flex-row justify-content-between">

              <h6 className="fw-bold  mb-2">
                ৳ {Math.round(item.price)}
              </h6>
            </div>

          </div>
        </div>
      </a>
    );
  };
  const Stars = (props) => {
    var stars = props.stars;
    if (props.size) {
      var size = props.size;
    } else {
      size = 10;
    }
    if (stars == 5) {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
        </div>
      );
    } else if (stars == 4) {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else if (stars == 3) {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else if (stars == 2) {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else if (stars == 1) {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else if (stars == 0) {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1 " icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    }
  };
  if (loadMore == false) {
    window.onscroll = async function (ev) {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 100
      ) {
        setLoadMore(true);
        try {
          setLoadingMore(true);
          var index = justforyou.length;
          var lastitem = justforyou[index - 1]; //get 1st item in the array

          if (lastitem) {
            const random = Math.random();
            const response = await fetch(
              shoputils.url + "?random=" + random + '&justforyoumorenew=""'
            );
            const json = await response.json();
            const responsedata = json?.justforyoumorenew;

            if (json?.justforyoumorenew.length > 0) {
              let arry = [...justforyou, ...responsedata];
              setJustForYou(arry);
              setLoadMore(false);
            }
          }
          setLoadingMore(false);
        } catch (e) {
          setLoadingMore(false);
        } finally {
          setLoadingMore(false);
        }
      }
    };
  }
  const changeSelectValue = (e) => {
    setreportselected(e.target.value);
  };
  const submitreport = async () => {
    if (reportselected == "অন্যান্য") {
      try {
        const users = localStorage.getItem("userinfo");

        if (users) {
          const user = JSON.parse(users);
          const random = Math.random();
          const response = await fetch(
            shoputils.url +
            "?random=" +
            random +
            "&reportadmin=" +
            inputvalue +
            "&pid=" +
            productid +
            "&auth=" +
            user.auth
          );
          const json = await response.json();

          if (json?.success == 1) {
            showError(response.message);
          }
        } else {
          window.location.href = "/";
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const users = localStorage.getItem("userinfo");

        if (users) {
          const user = JSON.parse(users);
          const random = Math.random();
          const response = await fetch(
            shoputils.url +
            "?random=" +
            random +
            "&reportadmin=" +
            reportselected +
            "&pid=" +
            productid +
            "&auth=" +
            user.auth
          );
          const json = await response.json();
          if (json?.success == 1) {
            showError(response.message);
          }
        } else {
          window.location.href = "/";
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const alreadyfevorite = () => {
    showError("আপনি ইতিমধ্যে প্রোডাক্টটি ফেভারিট করে রেখেছেন");
  };
  const stockUpdate = async (index) => {
    
    let stgcart = localStorage.getItem('stgcart');
    //alert(index)
    if (stgcart) {
        let temp_cart = JSON.parse(stgcart);
        let found = 0;
        let foundindex = null;
        temp_cart.map((items, indexs) => {
            if (items == index) {
                found = 1;
                foundindex = indexs;
            }
        })
        if (found == 0) {
            temp_cart.push(index)
            localStorage.setItem('stgcart', JSON.stringify(temp_cart))
            setCartitem(temp_cart)
        } else {
            let newtmp = temp_cart.slice(foundindex + 1)
            //alert(newtmp);
            localStorage.setItem('stgcart', JSON.stringify(newtmp))
            setCartitem(newtmp)
        }
    } else {
        let nstgcart = [index];
        localStorage.setItem('stgcart', JSON.stringify(nstgcart))
        setCartitem(nstgcart)
    }
    
}
  return nodata == true ? (
    <div className="container-fluid">

      {isError == true ? ErrorToast(errorMsg) : null}
      <div className="modal" id="refundPolicy">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div style={{ padding: 20, alignItems: "center" }}>
                <p className="h3 mb-3">রিফান্ড পলিসি </p>

                <p className="mb-4">
                  প্রোডাক্ট আনবক্সিং করার সময় মোবাইলে ভিডিও ধারণ করে রাখুন। এতে
                  প্রোডাক্টে কোনো সমস্যা থাকলে সেটা আপনার প্রমাণ হিসেবে কাজ
                  করবে। তারপর প্রোডাক্ট রিটার্ন দিয়ে দিন।{" "}
                </p>

                <p className="mb-4">
                  ভেন্ডর যদি প্রোডাক্ট রিটার্ন নিতে অস্বীকার করে অথবা তার
                  প্রোডাক্ট ঠিক আছে দাবী করে তাহলে আপনার ধারণকৃত আনবক্সিং
                  ভিডিওটি প্রমাণ হিসেবে চাওয়া হতে পারে। আনবক্সিং ভিডিও ছাড়া
                  রিটার্ন গ্রহণযোগ্য হবে না।{" "}
                </p>

                <p className="mb-4">
                  প্রোডাক্টে সমস্যা প্রমাণিত হলে ১০০% রিটার্ন গ্রহণ করা হবে।
                  এরপর প্রোডাক্ট রিপ্লেসমেন্ট অথবা রিফান্ড, যেকোনো একটি দাবী
                  গ্রহণযোগ্য হবে।
                </p>

                <p className="mb-4">
                  গ্যারান্টি/ওয়ারেন্টি যুক্ত প্রোডাক্ট এর ক্ষেত্রে অবশ্যই
                  গ্যারান্টি/ওয়ারেন্টি কার্ড সংরক্ষণ করবেন।
                  গ্যারান্টি/ওয়ারেন্টির মেয়াদ থাকাকালীন সুনির্দিষ্ট শর্তের আলোকে
                  গ্যারান্টি/ওয়ারেন্টি কার্যকর হবে।
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="reports">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              {/* <AntDesign onPress={() => setModalVisible(!modalVisible)} style={{ position: 'absolute', right: 8, top: 8, }} name="closecircleo" size={24} color="black" /> */}
              <p
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  color: "red",
                  marginBottom: 10,
                  marginTop: 30,
                }}>
                এই প্রোডাক্ট সম্পর্কে আপনার কোন অভিযোগ থাকলে অ্যাডমিন কে তথ্য
                দিন{" "}
              </p>
              <div style={{ width: "100%" }}>
                <select
                  onChange={changeSelectValue}
                  className="form-control report_content mb-3"
                  style={{
                    marginBottom: 10,
                    borderColor: "#ddd",
                    borderWidth: 1,
                  }}>
                  {reports.map((item, index) => {
                    return (
                      <option
                        color="black"
                        key={index}
                        label={item}
                        value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                <textarea
                  className="form-control d-none report_orhters mb-3"
                  placeholder={"অন্য কোনো সমস্যা থাকলে লিখুন"}></textarea>
                <button
                  onClick={submitreport}
                  className="btn btn-warning w-100 text-dark">
                  <p style={{ color: "#222" }}>রিপোর্ট সাবমিট করুন</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid  bg-lightgray">
        <div className="container  pt-2 pb-3">
          <div className="row ">
            <div className="d-flex text-nowrap bg-white text-secondary small mb-2  align-items-baseline">
              <a href="/" className="m-0 me-2 text-secondary">
                Home
              </a>
              <FontAwesomeIcon className=" me-2 mt-1" icon={faChevronRight} />
              <a
                href={"/shop/category/category/" + pdata.cat}
                className="m-0 me-2 text-secondary">
                {pdata.cat}
              </a>
              <FontAwesomeIcon className=" me-2 mt-1" icon={faChevronRight} />
              <a
                href={"/shop/category/category/" + pdata.cocat}
                className="m-0 me-2 text-secondary">
                {pdata.cocat}
              </a>
              <FontAwesomeIcon className=" me-2 mt-1" icon={faChevronRight} />
              <a
                href={"/shop/category/category/" + pdata.subcat}
                className="m-0 me-2 text-secondary">
                {pdata.subcat}
              </a>
            </div>
            <SideMenu />

            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
              <div
                className="totorial_button fw-normal badge bg-warning rounded-0 pe-4 rounded-pill text-dark fs-6"
                data-bs-toggle="modal"
                data-bs-target="#tutorial_modal">
                অর্ডার করার পদ্ধতি
              </div>
              <div className="modal" id="tutorial_modal">
                <div className="modal-dialog">
                  <div
                    className="modal-content"
                    style={{ height: 500, width: "100%" }}>
                    <div className="modal-body p-0">
                      <div className="selfbgcolor  rounded-top text-center text-white fs-5 p-2">
                        অর্ডার করার পদ্ধতি
                        <FontAwesomeIcon
                          data-bs-dismiss="modal"
                          className=" me-2 mt-1 float-end"
                          icon={faTimes}
                        />
                      </div>
                      <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/KM0rrxWR-4s"
                        title="YouTube video player"
                        
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 bg-white rounded col-md-6 col-sm-12 col-12 pt-2">
                  <div className="modal" id="imagepreview">
                    <div className="modal-dialog modal-md">
                      <div className="modal-content">
                        <FontAwesomeIcon
                          className=" me-2 mt-1 border border-danger text-danger rounded-pill p-2 "
                          data-bs-dismiss="modal"
                          style={{
                            position: "absolute",
                            zIndex: 1,
                            right: 5,
                            top: 5,
                            width: 10,
                            height: 10,
                          }}
                          icon={faTimes}
                        />
                        <div className="modal-body p-0">
                          <img
                            className="w-100"
                            src={shoputils.stgimageurl + currentimage}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#imagepreview"
                    className=" shop_pd_mainimage_container overflow-hidden p-1 text-center border shadow-sm mb-3 m-auto">
                    <img
                      className="shop_pd_mainimage"
                      src={shoputils.stgimageurl + currentimage}
                      alt=""
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <img
                      className="w-25  border p-1 shadow-sm"
                      onClick={() => setCurrentImage(pdata.image1)}
                      src={shoputils.stgimageurl + pdata.image1}
                      alt="..."
                    />
                    <img
                      className="w-25  border p-1 shadow-sm"
                      onClick={() => setCurrentImage(pdata.image2)}
                      src={shoputils.stgimageurl + pdata.image2}
                      alt="..."
                    />
                    <img
                      className="w-25  border p-1 shadow-sm"
                      onClick={() => setCurrentImage(pdata.image3)}
                      src={shoputils.stgimageurl + pdata.image3}
                      alt="..."
                    />
                    <img
                      className="w-25 border p-1 shadow-sm"
                      onClick={() => setCurrentImage(pdata.image4)}
                      src={shoputils.stgimageurl + pdata.image4}
                      alt="..."
                    />
                  </div>
                </div>
                <div className="col-lg-6 bg-white p-2 rounded col-md-6 col-sm-12 col-12">
                  <div className="d-flex flex-row justify-content-between">
                    <div
                      onClick={downloadImage}
                      download
                      className="btn btn-danger shop_pd_btns  w-25 me-2 p-1"
                      style={{ width: "35%", lineHeight: "35px" }}>
                      <FontAwesomeIcon
                        className="me-1"
                        icon={faCloudDownload}
                      />
                      ডাউনলোড
                    </div>
                    <div
                      onClick={() => stockUpdate(pdata.ID)}
                      className="btn btn-warning shop_pd_btns  me-2 p-1"
                      style={{width: "30%", lineHeight: "35px"}}>
                      <FontAwesomeIcon className="me-1" icon={faShoppingCart} />
                      কার্টে যুক্ত করুন
                    </div>
                    <a
                      href="/shop/checkout"
                      className="btn selfbgcolor  shop_pd_btns flex-fill p-1 fs-5 text-white ">
                      <FontAwesomeIcon className="mt-2" icon={faShoppingBag} />{" "}
                      অর্ডার করুন
                    </a>
                  </div>
                  <div className="d-flex justify-content-between p-2 ps-0 ">
                    <div>
                      <p className="me-3 shop_product_name flex-fill ">
                        {pdata.Name}
                      </p>
                      <div className="d-flex flex-row justify-content-start">
                        <p className="m-0 me-1 align-self-center">
                          প্রোডাক্ট কোড :
                        </p>
                        <p className="selfcolor mb-0 fs-6 fw-bold">
                          {pdata.code}
                        </p>
                      </div>
                    </div>

                    <div className="pt-2 pb-2 text-end text-nowrap">
                      {/* <div className=" text-secondary ">
                        <div className="fs-6 text-decoration-line-through">
                          {pdata.price} টাকা
                        </div>
                      </div> */}
                      <div className="d-flex flex-row selfcolor justify-content-between">
                        <div className="fs-4">
                          {pdata.price} টাকা
                        </div>
                      </div>
                    </div>
                    {/* <div className='text-nowrap align-self-stretch self_pd_stock align-text-center pt-2'>
                                                <p className='m-0'>মজুদ আছে</p>
                                                <p className='selfcolor mb-0 fs-4 fw-bold'>{pdata.stock} টি</p>
                                                
                                            </div> */}
                  </div>

                  <div className="d-flex bg-lightgray p-2 mt-2">
                    <div className="flex-fill shop_pd_flex_content text-center border-end">
                      <p className="selfcolor m-0">ডেলিভারি সময়</p>
                      <p className=" m-0">{pdata.deliveryDuration} দিন</p>
                    </div>
                    {/* <div className="flex-fill shop_pd_flex_content text-center border-end">
                      <p className="selfcolor m-0">ডেলিভারি চার্জ</p>
                      <p className="small">
                        {pdata.vendorLocation} {pdata.deliveryCharge.inside}{" "}
                        টাকা
                        <br />
                        {pdata.vendorLocation} এর বাইরে{" "}
                        {pdata.deliveryCharge.outside} টাকা
                      </p>
                    </div> */}
                    <a
                      href={"/shop/review/" + productid}
                      className="flex-fill shop_pd_flex_content text-center border-end">
                      <p className="selfcolor m-0">রিভিউ দেখুন </p>
                      {/* <Stars stars= /> */}
                    </a>
                  </div>
                  <div className="text-nowrap ps-3 pe-3 d-flex flex-row justify-content-between  align-self-stretch self_pd_stock align-text-center pt-2">
                    <div>
                      <p className="m-0">মজুদ আছে</p>
                    </div>
                    <div>
                      <p className="selfcolor mb-0  fw-bold">
                        {pdata.stock} টি
                      </p>
                    </div>
                  </div>

                  <div className="d-flex bg-lightgray p-2 mt-2">
                    <div
                      data-bs-toggle="modal"
                      data-bs-target="#reports"
                      className="flex-fill shop_pd_flex_content text-center border-end">
                      <p className="selfcolor m-0">এই প্রোডাক্ট সম্পর্কে</p>
                      <p>অ্যাডমিন কে রিপোর্ট করুন</p>
                    </div>
                    <div
                      data-bs-toggle="modal"
                      data-bs-target="#refundPolicy"
                      className="flex-fill shop_pd_flex_content text-center">
                      <p className="selfcolor m-0">রিফান্ড পলিসি</p>
                      <p>দেখুন</p>
                    </div>

                    <div className="flex-fill shop_pd_flex_content text-center">
                      <p className="selfcolor m-0">গ্যারেন্টি / ওয়ারেন্টি</p>
                      <p>
                        {pdata.guarenty == "" ? (
                          <span>Not Available</span>
                        ) : (
                          <span>pdata.guarenty</span>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-none  mt-3 flex-row justify-content-between">
                    <a
                      href={"/shop/vendorsproduct/" + pdata.vendorid}
                      className="btn me-2 selfbgcolor flex-fill text-white">
                      স্টোর দেখুন
                    </a>
                    {loggedin == true ? (
                      favoritestatus == 1 ? (
                        <div
                          onClick={alreadyfevorite}
                          className="btn selfbgcolor text-white flex-fill ">
                          ফেভারিট করা হয়েছে
                        </div>
                      ) : (
                        <div
                          onClick={addToFevorite}
                          className="btn btn-primary flex-fill ">
                          ফেভারিট করে রাখুন 
                        </div>
                      )
                    ) : (
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#login_modal"
                        className="btn btn-primary flex-fill ">
                        ফেভারিট করে রাখুন
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="offset-lg-2 offset-md-2 bg-white p-2 col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
              <h5>প্রোডাক্টের বিবরণ</h5>
              <textarea
                className="w-100 border-0"
                style={{ height: 300 }}
                value={pdata.description}
                readOnly>
                {pdata.description}
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid   bg-lightgray pt-3 pb-5"
        style={{ marginBottom: 35 }}>
        <div className="container p-0">
          <h4 className="  selfbgcolor p-2 mb-0 rounded-top text-center text-white moreproduct_heading">
            আপনার প্রয়োজনীয় আরো পণ্য
          </h4>
        </div>
        <div className="container bg-white  shadow-sm  pt-4 pb-4">
          <div className="row">
            {justforyou?.map((item, index) => {
              return renderItem(item, index);
            })}
          </div>
          {loadMore == true ? (
            <div className="col-12 text-center mb-4 pb-4">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};

export default Body;
