import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'hover.css/css/hover.css'

import '../src/index.css';

import reportWebVitals from './reportWebVitals';
import ShopScreen from './screens/shop/ShopScreen';
import SingleProductScreen from './screens/shop/SingleProductScreen';
import SingleProductScreenDetails from './screens/shop/SingleProductScreenDetails';
import SingleProductDetailsNew from './screens/shop/SingleProductScreenDetailsNew';
import CheckoutScreen from './screens/shop/CheckoutScreen';
import AddressScreen from './screens/shop/AddressScreen';
import ShopPaymentScreen from './screens/shop/ShopPaymentScreen';
import ShopDashboard from './screens/shop/ShopDashboard';
import FevoriteProduct from './screens/shop/ShopFevoriteProduct';
import ShopVarifiedProduct from './screens/shop/ShopVarifiedProduct';
import ShopAllCategory from './screens/shop/ShopAllCategory';
import ShopVendorDashboard from './screens/shop/ShopVendorDashboard';
import ShopVendorSearch from './screens/shop/ShopVendorSearch';
import ShopAddProduct from './screens/shop/ShopAddProduct';
import ShopMyOrder from './screens/shop/ShopMyOrder';
import ShopMyOrderDetails from './screens/shop/ShopMyOrderDetails';
import ShopSectioned from './screens/shop/ShopSectioned';
import ShopDiscounted from './screens/shop/ShopDiscounted';
import ShopVendorsProduct from './screens/shop/ShopVendorsProduct';
import ShopFolderProducts from './screens/shop/ShopFolderProducts';
import ShopSearchScreen from './screens/shop/ShopSearchScreen';
import ShopProductReview from './screens/shop/ShopProductReview';
// ShopScreens end

//Airticket
import AirticketScreen from "./screens/Airticket/AirticketScreen";
import BlogPost from './screens/Airticket/BlogPost';
import TipsScreen from './screens/Airticket/TipsScreen';
import AirticketHistory from './screens/Airticket/AirticketHistory';
import OmrahHistory from './screens/Airticket/OmrahHistory';
//Airticket end

//stg start

//stg end


import LoginScreen from "./screens/LoginScreen";
import HomeScreen from './screens/HomeScreen';
import RechargeScreen from './screens/RechargeScreen';
import SpecialRecharge from './screens/SpecialRecharge';
import NewsFeedScreen from './screens/NewsFeedScreen';
import OnDemandScreen from './screens/OnDemandScreen';
import AboutUs from './screens/AboutUs';
import LogOut from './screens/LogOut';
import ProfileScreen from './screens/ProfileScreen';
import PrivecyPolicy from './screens/PrivecyPolicy';
import ContactUs from './screens/ContactUs';
import Managements from './screens/Managements';
import TermsCondition from './screens/TermsCondition';
import ReturnRefundPolicy from './screens/ReturnRefundPolicy';
import StgSingleProductScreen from './screens/Stg/StgSingleProductScreen';
import StgSingleProductDetails from './screens/Stg/StgSingleProductDetails';

import StgAddressScreen from './screens/Stg/StgAddressScreen';
import {CartContext} from './components/Context';
import StgPaymentScreen from './screens/Stg/StgPaymentScreen';
import AccountDelete from './screens/AccountDelete';
import ShopSuccess from './screens/shop/ShopSuccess';

const root = ReactDOM.createRoot(document.getElementById('root'));
function App() {
  const [cartitem,setcartitem] = useState([]);
  const [cart,setcart] = useState([]);
  return (
    <React.StrictMode>
    <BrowserRouter>
      <CartContext.Provider
      value={{cartitem,setcartitem}}
      >
      <Routes>
        <Route path="/">
          <Route index element={<HomeScreen />} />
          
          {/* shop screens */}
          <Route path='/shop' element={<ShopScreen />} />
          <Route path='/shop/product/:id/:name' element={<SingleProductScreen />} />
          <Route path='/shop/specialproduct/checkout/:id/:name' element={<SingleProductDetailsNew />} />
          <Route path='/shop/productdetail/:id/:name' element={<SingleProductScreenDetails />} />
          <Route path='/shop/checkout' element={<CheckoutScreen />} />
          <Route path='/shop/specialcheckout' element={<CheckoutScreen />} />
          <Route path='/shop/address' element={<AddressScreen />} />
          <Route path='/shop/payment' element={<ShopPaymentScreen />} />
          <Route path='/shop/success/:invoice' element={<ShopSuccess />} />
          <Route path='/shop/dashboard' element={<ShopDashboard />} />
          <Route path='/shop/dashboard' element={<ShopDashboard />} />
          <Route path='/shop/fevorite' element={<FevoriteProduct />} />
          <Route path='/shop/verified' element={<ShopVarifiedProduct />} />
          <Route path='/shop/allcategory' element={<ShopAllCategory />} />
          <Route path='/shop/vendordashboard' element={<ShopVendorDashboard />} />
          <Route path='/shop/vendorsearch' element={<ShopVendorSearch />} />
          <Route path='/shop/shopaddproduct' element={<ShopAddProduct />} />
          <Route path='/shop/myorder/:type' element={<ShopMyOrder />} />
          <Route path='/shop/myorder/details/:id' element={<ShopMyOrderDetails />} />
          <Route path='/shop/category/:pagetype/:name' element={<ShopSectioned />} />
          <Route path='/shop/discounted/:percent' element={<ShopDiscounted />} />
          <Route path='/shop/vendorsproduct/:vid' element={<ShopVendorsProduct />} />
          <Route path='/shop/folderproduct/:fid' element={<ShopFolderProducts />} />
          <Route path='/shop/search/:key' element={<ShopSearchScreen />} />
          <Route path='/shop/review/:pid' element={<ShopProductReview />} />
          {/* shop screens end */}
          {/* airticket screens */}
          <Route path='/airticket' element={<AirticketScreen />} />
          <Route path='/blogpost/:id' element={<BlogPost />} />
          <Route path='/tips/:id' element={<TipsScreen />} />
          <Route path='/airticketHistory' element={<AirticketHistory />} />
          <Route path='/OmrahHistory' element={<OmrahHistory />} />
          
          {/* airticket screens end */}
          
          {/* stg start */}
          <Route path='/stg/product/:id/:name' element={<StgSingleProductScreen />} />
          <Route path='/stg/productdetail/:id/:name' element={<StgSingleProductDetails />} />
          <Route path='/stg/address' element={<StgAddressScreen />} />
          <Route path='/stg/payment' element={<StgPaymentScreen />} />
          {/* stg end */}

          <Route path='/aboutus' element={<AboutUs />} />
          <Route path="/privecyPolicy" element={<PrivecyPolicy />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/recharge" element={<RechargeScreen />} />
          <Route path="/driveoffer" element={<SpecialRecharge />} />
          <Route path="/newsfeed/:id/:description" element={<NewsFeedScreen />} />
          <Route path="/ondemand/:id/:description" element={<OnDemandScreen />} />
          <Route path='/logout' element={<LogOut />} />
          <Route path='/Managements' element={<Managements />} />
          <Route path='/TermsCondition' element={<TermsCondition />} />
          <Route path='/ReturnRefundPolicy' element={<ReturnRefundPolicy />} />
          <Route path='/AccountDelete' element={<AccountDelete />} />
          
        </Route>
      </Routes>
      </CartContext.Provider>
    </BrowserRouter>
  </React.StrictMode>
  );
}

export default App;
