import React from 'react';
import Menu from './Menu';
const LoginScreen = () =>  {
    
    return(
        <div className="col-2 d-lg-block d-md-none d-sm-none d-none ps-0">
            <Menu />
        </div>
    )
}

export default LoginScreen;
                            