
import React,{useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../SideMenu'; 

import {  faTimes } from '@fortawesome/free-solid-svg-icons';


const Body = () =>  {
    
    const flatListRef = React.useRef();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [nodata, setNodata] = useState(false);
    const [category, setCategory] = useState('0');
    const [ResellerStatus,setResellerStatus] = useState(1);
    const shoputils = require('../../utils/shoputils.json');
    useEffect(() => {
        setCategory('0');
            if(nodata == false){
              fetchData()
              getResellerStatus();
            }
      }, []);
      const fetchData = async () => {
        
        try {
          setLoading(true);
          const users =  localStorage.getItem("userinfo");
          const user = JSON.parse(users);
          const random = Math.random();
          const response = await fetch(shoputils.url+'?random='+random+'&UserFevorite=0&auth='+user.auth);
          const json = await response.json();
              if(json.success == 1){
                  setData([...data,...json.fevorite])
                  
                  setNodata(true)
              }else{
                  setNodata(true);
              }
              setLoading(false);
              
          } catch (error) {
              console.error(error);
              setLoading(false);
          }  finally {
            setLoading(false)
          } 
        
    };
    const getResellerStatus = async() => {
  
        try {
          setLoading(true);
          
          const users = await localStorage.getItem("userinfo");
          if(users){
            const user = JSON.parse(users);
            const random = Math.random()
            const response = await fetch(shoputils.url+'?getResellerStatus=1&random='+random+'&auth='+user.auth)
            const json = await response.json();
          
            if(user.status == 1){
              setResellerStatus(json.ResellerStatus)
            }
          }
          setLoading(false);
         } catch (error) {
           console.error(error);
         }  finally {
           setLoading(false)
         }
      }
    const removeFevorite = async (value) => {
        setLoading(true);  
       
         
          const users =  localStorage.getItem("userinfo");
          const user = JSON.parse(users);
          const random = Math.random();
          const response = await fetch(shoputils.url+'?random='+random+'&removefevorite='+value+'&auth='+user.auth);
          fetchData()
        setLoading(false);
     
    }  
    const renderItem = (item,index) => {
        return (
            
            <div key={index} className="col-lg-3 text-dark col-md-3 col-sm-6 col-6  p-2 ">
                <div className='rounded border  shadow-sm rounded overflow-hidden' style={{position:'relative'}}>
                    <div onClick={() => removeFevorite(item.id)} className="favorite_remove">
                        <FontAwesomeIcon className='  mt-1 text-warning' icon={faTimes} />
                    </div>
                    <a href={'/shop/product/'+item.pid+'/'+item.name}>
                        <div className='overflow-hidden' style={{height:200}}>
                        <img src={shoputils.imageurl+item.img1} className="d-block w-100" alt="..."/>
                    </div>
                    <div className="bg-lightgray text-dark ps-2 pe-2">
                        <p className='m-0 text-left onlinetext'>{item.name} </p>
                        {
                            ResellerStatus == 0 ?
                            item.d == 'flash' ?
                                <div className='d-flex flex-row justify-content-between'>
                                <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                                <h6 className='fw-bold mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>
                                
                                </div>
                                
                                : item.d == 'discount' ?
                                <div className='d-flex flex-row justify-content-between'>
                                    <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                                    <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>
                                
                                </div>
                                
                                : item.d == 'none' ?
                                
                                
                                <div className='d-flex flex-row justify-content-between'>
                                    <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_price)}</h6>
                                </div >
                                
                                    :
                                    null
                            : item.d == 'flash' ?
                            <div className='d-flex flex-row justify-content-between'>
                                <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>
                            
                            </div>
                            
                            : item.d == 'discount' ?
                            <div className='d-flex flex-row justify-content-between'>
                                <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>
                            
                            </div >
                            
                            : item.d == 'none' ?
                            <div className='d-flex flex-row justify-content-between'>
                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.price)}</h6>
                            </div >
                                
                            
                            
                                :
                                null
                        }
                    </div>
                    </a>
                </div>
                
            </div>
        );
      }
   return(
    <div className="container-fluid bg-lightgray">
        <div className="container-fluid  ">
            <div className="container  pt-3 pb-3 checkout_container">
                <div className="row ">
                    <SideMenu/>
                    
                    <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                        <div className="selfbgcolor rounded-top p-2 text-center text-white fs-5">আমার ফেভারিট প্রোডাক্ট</div>
                        <div className="row">
                            {data.map((item,index) => {
                                return renderItem(item,index)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
   )
}

export default Body;