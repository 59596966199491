import React from 'react';


const LoginScreen = () =>  {
    
    return(
        <h1>count</h1>
    )
}

export default LoginScreen;