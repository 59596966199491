import React from 'react';
import Header from '../../components/Header';
import Body from '../../components/Stg/StgAddressBody';
import Footer from '../../components/Footer';

const StgAddressScreen = () =>  {
   
    return(
        <div className='container-fluid p-0'>
            <Header/>
            <Body/>
            <Footer/>
        </div>
    )
}

export default StgAddressScreen;