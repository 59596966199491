import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../SideMenu';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useParams } from 'react-router-dom';

const Body = () => {
    const shoputils = require('../../utils/shoputils.json');
    const params = useParams();
    const [orderid, setOrderId] = useState(params.id)
    const [data, setData] = useState([]);
    const [nodata, setNodata] = useState(false);
    const [loading, setLoading] = useState(false);
    const [step1cancelreason, setStep1CancelReason] = useState('');
    const [starcolor1, setstarcolor1] = useState('gray');
    const [starcolor2, setstarcolor2] = useState('gray');
    const [starcolor3, setstarcolor3] = useState('gray');
    const [starcolor4, setstarcolor4] = useState('gray');
    const [starcolor5, setstarcolor5] = useState('gray');
    const [reviewcomment, setreviewcomment] = useState(null)
    const [rated, setrated] = useState(0);
    const [alreadyrated, setalreadyrated] = useState(0)
    const [whatsapplinkshop, setWhatsAppLinkShop] = useState('');
    const [vendorname, setvendorname] = useState('');
    const [vendornumber, setvendornumber] = useState('');
    const [resellerstatus, setResellerStatus] = useState(0);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('')
    useEffect(() => {
        if (data.length == 0) {
            if (nodata == false) {
                getWhatAppLink()
                getData()
                getResellerStatus()

            }
        }
    },[])
    const showError = (msg) => {
        setIsError(true)
        setErrorMsg(msg)
        setTimeout(() => {
            setIsError(false)
        }, 3000);
    }
    const getWhatAppLink = async () => {
        setLoading(true);
        try {
            const random = Math.random()
            const response = await fetch(shoputils.tutorial + '?getWhatAppLink=1&random=' + random + '&webpost=1')
            const json = await response.json();

            setWhatsAppLinkShop(json.WhatsAppLinkShop)
            setLoading(false)
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }
    const getData = async () => {
        try {
            setLoading(true);

            const users = localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            const random = Math.random();
            const response = await fetch(shoputils.url + '?random=' + random + '&VendorOrderDetails=' + orderid + '&auth=' + user.auth);
            const json = await response.json();
            console.log(json)
            if (json.success == 1) {
                setData(json.orderdetails);
                setLoading(false)
                setalreadyrated(json.orderdetails.alreadyrated)
                setvendorname(json.orderdetails.vendorname);
                setvendornumber(json.orderdetails.vendornumber);


            } else {
                setLoading(false);
            }
            setNodata(true)





        } catch (error) {
            console.error(error);
            setLoading(false);
        } finally {
            setLoading(false)
        }
    }
    const orderAction = async (action, otherparams) => {
        setLoading(true);

        try {


            const users = localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            const random = Math.random();
            let formData = new FormData();
            formData.append('orderID', orderid);
            formData.append('actionType', action);
            formData.append('otherparams', otherparams);
            formData.append('webpost', 1);
            formData.append('token', user.token);
            const response = await fetch(shoputils.posturl + '?orderAction=1&random=' + random + '&auth=' + user.auth, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',

                },
                body: formData,

            });

            const json = await response.json();

            if (json.success == 1) {
                window.location.reload();

                showError(json.message)

                setLoading(true);
            } else {
                showError(json.message);
                setLoading(true);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        } finally {
            setLoading(false)

        }
    }
    const rateProduct = (rating) => {
        setrated(rating)
        if (rating == 1) {
            setstarcolor1('gold');
            setstarcolor2('gray');
            setstarcolor3('gray');
            setstarcolor4('gray');
            setstarcolor5('gray');
        } else if (rating == 2) {
            setstarcolor1('gold');
            setstarcolor2('gold');
            setstarcolor3('gray');
            setstarcolor4('gray');
            setstarcolor5('gray');

        } else if (rating == 3) {
            setstarcolor1('gold');
            setstarcolor2('gold');
            setstarcolor3('gold');
            setstarcolor4('gray');
            setstarcolor5('gray');
        } else if (rating == 4) {
            setstarcolor1('gold');
            setstarcolor2('gold');
            setstarcolor3('gold');
            setstarcolor4('gold');
            setstarcolor5('gray');

        } else if (rating == 5) {
            setstarcolor1('gold');
            setstarcolor2('gold');
            setstarcolor3('gold');
            setstarcolor4('gold');
            setstarcolor5('gold');

        }
    }
    // const renderItem = ({item})=>{
    //     return(            
    //         <View style={{...styles.os_od_details_box,width:'92%'}}>
    //             <View style={styles.os_od_image_box}>
    //                 <Text style={styles.os_od_details_name}>
    //                     {item.productname}
    //                 </Text>
    //                 <View style={{flex:3}}>
    //                 <TouchableOpacity >
    //                 <Image
    //                     style={{width:70,height:70}}
    //                     source={{uri:'https://self-shopping.com/shopapp/img/shop/'+item.image}}
    //                 />
    //                 </TouchableOpacity>
    //                 </View>
    //             </View>
    //             <View style={{...styles.os_od_amount_box,borderBottomColor:'#ddd',borderBottomWidth:1}}>
    //                 <View style={{...styles.os_od_details_amounts,flex:1}}>
    //                     <Text>কালার</Text>
    //                 </View>
    //                 <View style={{...styles.os_od_details_amounts,flex:1}}>
    //                     <Text>সাইজ</Text>
    //                 </View>
    //                 <View style={{...styles.os_od_details_amounts,flex:1}}>
    //                     <Text>পরিমাণ</Text>
    //                 </View>
    //                 <View style={{...styles.os_od_details_amounts,flex:1}}>
    //                     <Text>মূল্য</Text>
    //                 </View>

    //             </View>
    //             <View style={styles.os_od_amount_box}>
    //                 <View style={{...styles.os_od_details_amounts,flex:1}}>
    //                     <Text style={{fontWeight:'bold'}}>{item.color}</Text>
    //                 </View>
    //                 <View style={{...styles.os_od_details_amounts,flex:1}}>
    //                     <Text style={{fontWeight:'bold'}}>{item.size}</Text>
    //                 </View>
    //                 <View style={{...styles.os_od_details_amounts,flex:1}}>
    //                     <Text style={{fontWeight:'bold'}}>{item.qnty}</Text>
    //                 </View>
    //                 <View style={{...styles.os_od_details_amounts,flex:1}}>
    //                     <Text style={{fontWeight:'bold'}}>{item.discountedprice * item.qnty}</Text>
    //                 </View>

    //             </View>
    //             <Modal
    //                 animationType="slide"
    //                 transparent={true}
    //                 visible={modalVisibleImageZoom}

    //             >

    //                     <View style={styles.os_od_centeredView}>
    //                         <View style={{...styles.os_od_modalView,backgroundColor:'transparant',elevation:0}}>
    //                         <AntDesign onPress={() => setModalVisibleImageZoom(!modalVisibleImageZoom)} style={{position:'absolute',right:8,top:8,}} name="closecircleo" size={24} color="white" />
    //                         <Image
    //                             style={{width:300,height:300,resizeMode:'contain'}}
    //                             source={{uri:'https://self-shopping.com/shopapp/img/shop/'+item.image}}
    //                         />
    //                         </View>
    //                     </View>

    //             </Modal>
    //         </View>             
    //         );
    //       }

    //       const saveFile = async (fileUri) => {
    //         const mediaLibraryPermissions = await MediaLibrary.requestPermissionsAsync();
    //         if (mediaLibraryPermissions.granted) {
    //           const asset = await MediaLibrary.createAssetAsync(fileUri)
    //           await MediaLibrary.createAlbumAsync("SELF", asset, false);
    //           showError('স্লিপ ডাউনলোড হয়েছে')

    //         }
    //       }   
    // const submitreview = async() =>{
    //     if(reviewcomment !== null){
    //             setLoading(true)
    //             try {


    //                 const users =  localStorage.getItem("users");
    //                 const user = JSON.parse(users);
    //                 const random = Math.random();
    //                 const response = await fetch(shoputils.posturl+'?submitreview=1&reviewcomment='+reviewcomment+'&rated='+rated+'&random='+random+'&auth='+user.auth,{
    //                     method: 'POST',
    //                     headers: {
    //                         Accept: 'application/json',
    //                         'Content-Type': 'application/json'
    //                       },
    //                     body: JSON.stringify({
    //                         reviewcomment: reviewcomment,
    //                         rated:rated,
    //                         id:data.productinfo[0].pid,
    //                     }),
    //                 });
    //                 const json = await response.json();

    //                      if(json.success == 1){
    //                         window.location.reload();
    //                         setModalVisible(!modalVisible);
    //                         showError('রিভিউ সফলভাবে সাবমিট হয়েছে')

    //                           setLoading(false);    
    //                     }else{
    //                         showError(json.message)
    //                         setLoading(false);
    //                     }
    //                 } catch (error) {
    //                     console.error(error);
    //                     setLoading(false);
    //                 }  finally {
    //                     setLoading(false)
    //                 }     

    //     }else{
    //         showError('রিভিউ দিন');
    //     }
    // }
    const getResellerStatus = async () => {
        setLoading(true);
        try {

            const users = localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            const random = Math.random()
            const response = await fetch(shoputils.url + '?getResellerStatus=1&random=' + random + '&auth=' + user.auth)
            const json = await response.json();

            if (user.status == 1) {
                setResellerStatus(json.ResellerStatus)
            }

        } catch (error) {

        } finally {
            setLoading(false)
        }
    }
    const changeCancelReason = (e) => {
        setStep1CancelReason(e.target.value);
    }
    return (<div className="container-fluid bg-lightgray">
        {nodata == true ?
            <div className="container-fluid  ">
                <div className="container  pt-3 pb-3 checkout_container">
                    <div className="row ">
                        <SideMenu />

                        <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                            <div className="bg-white ">
                                <div className="selfbgcolor rounded-top p-2 fs-6 text-center text-white">অর্ডারের বিস্তারিত</div>
                                <div className="p-3">
                                    <div className="d-flex flex-row justify-content-between">
                                        <div>ইনভয়েস</div>
                                        <div>তারিখ</div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <div>{data.Invoice}</div>
                                        <div>{data.date}</div>
                                    </div>
                                    <div className="btn  rounded-pill w-100 mt-2 text-white" style={{ backgroundColor: 'darkblue' }}>{data.OrderStatus}</div>
                                    {data.productinfo.map((item, index) => {
                                        return <div key={index} className="border  mt-3 mb-3 p-3 rounded">
                                            <div className="d-flex flex-row justify-content-between">
                                                <p className='selfcolor myorder_text'>{item.productname}</p>
                                                <img className='myorderimage' src={shoputils.imageurl + item.image} alt="N/A" />

                                            </div>
                                            <div className="d-flex flex-row justify-content-between border-bottom mt-3 pb-3">
                                                <div className='flex-fill text-center w-25'>কালার</div>
                                                <div className='flex-fill text-center w-25'>সাইজ</div>
                                                <div className='flex-fill text-center w-25'>পরিমান</div>
                                                <div className='flex-fill text-center w-25'>মূল্য</div>
                                            </div>
                                            <div className="d-flex flex-row justify-content-between mt-3 pb-3">
                                                <div className='flex-fill text-center w-25'>{item.size !== 'undefined' ? item.size : 'N/A'}</div>
                                                <div className='flex-fill text-center w-25'>{item.color !== 'undefined' ? item.color : 'N/A'}</div>
                                                <div className='flex-fill text-center w-25'>{item.qnty}</div>
                                                <div className='flex-fill text-center w-25'>{item.discountedprice * item.qnty} TK</div>
                                            </div>
                                        </div>
                                    })}
                                    <div className='rounded d-flex p-2 flex-row justify-content-center ' style={{ backgroundColor: 'darkblue' }}>
                                        <p className='text-white'>ভেন্ডর : {data.vendorname}</p>
                                        {/* <div className="border transparant p-1 ps-2 pe-2 rounded text-white d-flex flex-row align-items-center">
                                    <FontAwesomeIcon className=' fs-5  text-white me-2' icon={faComment} /> চ্যাট
                                </div> */}
                                    </div>
                                    <div className='rounded border border-success d-flex mt-2 p-2 pt-1 pb-1 flex-row justify-content-between align-items-center' >
                                        <p className='text-success mb-0'>জরুরি প্রয়োজনে সাপোর্ট সেন্টার এ যোগাযোগ করুন</p>
                                        <div style={{ width: 30, height: 30 }} className="bg-success  rounded-pill  align-items-center justify-content-center text-center"><FontAwesomeIcon className=' fs-5 align-self-center mt-1  text-white' icon={faWhatsapp} /></div>

                                    </div>
                                    <div className="bg-lightgray p-2 mt-3">
                                        <p className="fs-6">মূল্য বিবরণী</p>
                                        <div className="d-flex flex-row justify-content-between">
                                            <div>সর্বমোট বিক্রয় মূল্য</div>
                                            <div>{data.purchaseAmount} টাকা</div>
                                        </div>

                                        <div className="d-flex flex-row justify-content-between">
                                            <div>ডেলিভারি চার্জ</div>
                                            <div>{Math.round(data.deliveryCharge)} টাকা</div>
                                        </div>

                                        <div className="d-flex flex-row justify-content-between border-bottom border-secondary pb-2">
                                            <div>অগ্রিম পরিশোধ করা হয়েছে</div>
                                            <div>- {Math.round(data.advancePaid)} টাকা</div>
                                        </div>

                                        <div className="d-flex fw-bold flex-row justify-content-between">
                                            <div>ক্যাশ অন ডেলিভারিতে পরিশোধযোগ্য</div>
                                            <div>{Math.round(data.cashOnDelivery)} টাকা</div>
                                        </div>
                                        {(() => {
                                            if (resellerstatus == 1) {
                                                if (data.status == 2) {

                                                } else if (data.status == 3) {

                                                } else if (data.status == 9) {

                                                } else if (data.status == 10) {

                                                } else if (data.status == 12) {

                                                } else {
                                                    return (
                                                        <div className="d-flex fw-bold flex-row justify-content-between">
                                                            <div>আমার আয় হয়েছে :</div>
                                                            <div>{Math.round(data.extraIncom)} টাকা</div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        })()}

                                    </div>
                                    <p className="text-center">গ্রাহকের ডেলিভারি এড্রেস</p>
                                    <div className="border mt-2 p-2">
                                        <div className="d-flex flex-row justify-content-between">
                                            <p className='selfcolor fw-bold'>{data.name}</p>
                                            <div className="btn btn-outline-secondary align-self-start btn-sm p-1">কপি করুন</div>
                                        </div>
                                        <div>

                                            <p className='m-0'>{data.address} {data.location}</p>
                                            <p className='m-0'>{data.contact1}, {data.contact2}</p>
                                        </div>

                                    </div>

                                </div>
                                {
                                    data.status == 0 ?
                                        <div className='mb-3 m-2'>

                                            <button data-bs-toggle='modal' data-bs-target="#cancelmodal" className='mb-2 col-12 col btn btn-danger' >
                                                <p className='m-0'>প্রয়োজনে অর্ডারটি বাতিল করুন</p>
                                            </button>

                                            <div className="modal fade  " id="cancelmodal">
                                                <div className="modal-dialog h-100 d-flex hidescroll">
                                                    <div className="modal-content align-self-center">
                                                        <div className="modal-body">

                                                            <textarea
                                                                className='form-control'
                                                                placeholder="অর্ডার বাতিলের কারণ লিখুন"
                                                                onChange={changeCancelReason}></textarea>
                                                            <p style={{ fontSize: 16, color: 'red', textAlign: 'center', marginBottom: 10 }}>{errorMsg}</p>

                                                            <button className='btn btn-danger w-100' onClick={() => orderAction('cancel_customer', step1cancelreason)} >
                                                                <p className='mb-0 '>বাতিল</p>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : data.status == 1 ?
                                            null
                                            : data.status == 8 ?
                                                <div className='d-flex flex-row justify-content-between m-3 pb-4' >
                                                    <button data-bs-toggle="modal" data-bs-target="#receivemodal" className='btn btn-success flex-fill me-3' >
                                                        <p className='m-0 text-white'>প্রোডাক্ট পেয়েছি</p>
                                                    </button>
                                                    <button data-bs-toggle="modal" data-bs-target="#returnmodal" className='btn btn-danger flex-fill' >
                                                        <p className='m-0 text-white'>প্রোডাক্ট রিটার্ন</p>
                                                    </button>
                                                    <div className="modal" id="receivemodal" >
                                                        <div className="modal-dialog h-100 d-flex">
                                                            <div className="modal-content align-self-center">

                                                                <div className='modal-body'>
                                                                    <div >

                                                                        <p style={{ fontSize: 16, textAlign: 'center', marginBottom: 0 }}> আপনার অর্ডারকৃত পণ্যটি সঠিকভাবে পেয়েছেন তা নিশ্চিত করুন। প্রাপ্তি স্বীকারের পর আর কোন অভিযোগ গ্রহণযোগ্য হবে না। </p>
                                                                        <p style={{ fontSize: 16, color: 'red', textAlign: 'center', marginBottom: 10 }}>{errorMsg}</p>
                                                                        <button onClick={() => orderAction('receive_customer')} className="btn btn-secondary w-100">
                                                                            <p className='m-0'>প্রোডাক্ট সঠিকভাবে পেয়েছি</p>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="modal" id="returnmodal" >
                                                        <div className="modal-dialog h-100 d-flex">
                                                            <div className="modal-content align-self-center">
                                                                <div className="modal-body">
                                                                    <div >
                                                                        <div >
                                                                            <p style={{ fontSize: 16, textAlign: 'center', marginBottom: 0 }}>আপনার পণ্য উপযুক্ত না হলে প্রমাণ হিসেবে একটি ভিডিও ক্লিপ রাখুন। তারপর পণ্যটি ভেন্ডরের ঠিকানায় ফেরত দিন।</p>
                                                                            <p style={{ fontSize: 16, color: 'red', textAlign: 'center', marginBottom: 0 }}>{errorMsg}</p>
                                                                            <button onClick={() => orderAction('return_customer')} className="btn btn-secondary w-100">
                                                                                <p className='m-0'>রিটার্ন করুন</p>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                : data.status == 4 ?
                                                    null
                                                    : data.status == 5 ?
                                                        null
                                                        :
                                                        null
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            : null
        }
    </div>



    )
}

export default Body;