
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../SideMenu'; 
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faCloudArrowUp, faRetweet, faShapes, faSquare } from '@fortawesome/free-solid-svg-icons';
import {  faSquareCheck } from '@fortawesome/free-regular-svg-icons'
import {   faList } from '@fortawesome/free-solid-svg-icons';


const Body = () =>  {
    


   return(
    <div className="container-fluid">
        <div className="container-fluid  bg-lightgray">
                    <div className="container  pt-3 pb-3 checkout_container">
                        <div className="row ">
                            <SideMenu/>
                            
                            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                                <div className="selfbgcolor rounded-top p-2 text-center text-white fs-5">DEPARTMENTAL STORE</div>
                                <div style={{overflowY:'hidden'}} className="text-center  bg-dark">
                                    <img src="https://self-shopping.com/shopapp/img/shop/pimage11658414039156.jpg" className='w-100 m-auto' alt="" />
                                </div>   
                                <div className="container">
                                    <div className="row  bg-white pt-2">
                                        <div className="col-2 hvr-pop shop_icons_box  text-center" >
                                            <a href="/shop/shopaddproduct" className='text-decoration-none'>
                                                <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                                    <div className="radiousRound bg-danger homeRoundIconsInner shopRoundIconsInner">
                                                        <FontAwesomeIcon className=' fs-3  text-white' icon={faCloudArrowUp} />  
                                                    </div>
                                                </div>
                                                <p className='text-black' >নতুন পণ্য দিন </p>
                                            </a>
                                        </div>
                                        <a href="/shop/dashboard" className=" hvr-pop col-2 shop_icons_box text-decoration-none text-center">
                                            <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                                <div className="radiousRound bg-danger homeRoundIconsInner shopRoundIconsInner">
                                                    <FontAwesomeIcon className=' fs-3  text-white' icon={faList} />  
                                                </div>
                                            </div> 
                                            <p className='text-black'>ম্যানেজ প্রোডাক্ট</p>
                                        </a>
                                        <a href="/shop/fevorite" className="col-2 hvr-pop shop_icons_box text-decoration-none text-center">
                                            <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                                <div className="radiousRound bg-danger homeRoundIconsInner shopRoundIconsInner">
                                                    <FontAwesomeIcon className=' fs-3  text-white' icon={faSquareCheck} /> 
                                                </div> 
                                            </div>
                                            <p className='text-black'>ম্যানেজ অর্ডার</p>
                                        </a>
                                        <a href="/shop/verified" className="col-2 hvr-pop shop_icons_box text-decoration-none text-center">
                                            <div className='radiousRound  border border-danger  homeRoundIcons shopRadiusIcons' >
                                                <div className="radiousRound bg-danger homeRoundIconsInner shopRoundIconsInner">
                                                    <FontAwesomeIcon className=' fs-3  text-white' icon={faStar} /> 
                                                </div> 
                                            </div>
                                            <p className='text-black'>প্রোডাক্ট রিভিউ</p>
                                        </a>
                                        <a href="/shop/allcategory" className="col-2 hvr-pop shop_icons_box text-center">
                                            <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                                <div className="radiousRound bg-danger homeRoundIconsInner">
                                                    <FontAwesomeIcon className=' fs-3  text-white' icon={faRetweet} />  
                                                </div>
                                            </div>
                                            <p className='text-black'>ট্রান্সেকশন হিস্ট্রি </p>
                                        </a>
                                        <a href="/shop/vendordashboard"  className="col-2 hvr-pop shop_icons_box text-center">
                                            <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                                <div className="radiousRound bg-danger homeRoundIconsInner shopRoundIconsInner">
                                                    <FontAwesomeIcon className=' fs-3  text-white' icon={faShapes} />  
                                                </div>
                                            </div>
                                            <p className='text-black'>ভেন্ডর ড্যাশবোর্ড</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="mt-2 mb-2 bg-white p-2 fs-5 rounded border border-danger shadow-sm text-center">
                                    ভেন্ডরশিপ নীতিমালা ও শর্তাবলী
                                </div>
                                <div className="container p-0">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2  discount_boxex">
                                            <div className="discountbox  rounded p-4" style={{backgroundColor:'#DD3163',minHeight:140}}>
                                                <div className="absolutebox"></div>

                                                <div className="row">
                                                    <div className="col-8">
                                                        <h5 className='text-start text-white'>ভিডিও শপিং </h5>
                                                        <p className="m-0 small text-warning">
                                                            ফেইসবুক লাইভ অথবা রেকর্ডেড লাইভ ভিডিও শেয়ার করে প্রোডাক্ট বিক্রয় করুন
                                                        </p>
                                                    </div>
                                                    <div className="col-4">
                                                        <h2 className='text-end text-white'>৫% </h2>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                            
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2 discount_boxex">
                                            <div className="discountbox  rounded p-4"  style={{backgroundColor:'#4172FF',minHeight:140}}>
                                                <div className="absolutebox"></div>
                                                <div className="row">
                                                    <div className="col-8">
                                                        <h5 className='text-start text-white'>ফ্ল্যাশ সেল </h5>
                                                        <p className="m-0 text-warning">
                                                        নির্দিষ্ট প্রোডাক্টের স্টক দ্রুত খালি  করতে ফ্ল্যাশ সেল এ যুক্ত করুন
                                                        </p>
                                                    </div>
                                                    <div className="col-4">
                                                        <h2 className='text-end text-white'>১০% </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2  discount_boxex">
                                            <div className="discountbox   rounded p-4"  style={{backgroundColor:'#5E858C',minHeight:140}}>
                                                <div className="absolutebox"></div>
                                                <div className="row">
                                                    <div className="col-8">
                                                        <h5 className='text-start text-white'>ট্রেন্ডিং প্রোডাক্ট</h5>
                                                        <p className="m-0 text-warning">
                                                        এই মুহূর্তের সবচেয়ে জনপ্রিয় প্রোডাক্ট গুলো ট্রেন্ডিং লিস্ট এ যুক্ত করুন 
                                                        </p>
                                                    </div>
                                                    <div className="col-4">
                                                        <h2 className='text-end text-white'>২০% </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2  discount_boxex">
                                            <div className="discountbox   rounded p-4"  style={{backgroundColor:'#32A584',minHeight:140}}>
                                                <div className="absolutebox"></div>
                                                <div className="row">
                                                    <div className="col-8">
                                                        <h5 className='text-start text-white'>প্রমোশন</h5>
                                                        <p className="m-0 text-warning">
                                                        নির্দিষ্ট প্রোডাক্ট এর বিক্রয় বৃদ্ধি করতে সেল্ফ এর মাধ্যমে প্রমোশন করুন
                                                        </p>
                                                    </div>
                                                    <div className="col-4">
                                                        <h2 className='text-end text-white'>২০% </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2  discount_boxex">
                                            <div className="discountbox   rounded p-4"  style={{backgroundColor:'#C9856E',minHeight:140}}>
                                                <div className="absolutebox"></div>
                                                <div className="row">
                                                    <div className="col-8">
                                                        <h5 className='text-start text-white'>ফেভরিট লিস্ট </h5>
                                                        <p className="m-0 text-warning">
                                                            আপনার কোন কোন প্রোডাক্ট গ্রাহকের ফেভরিট লিস্ট এ রয়েছে তা দেখুন
                                                        </p>
                                                    </div>
                                                    <div className="col-4">
                                                        <h2 className='text-end text-white'>২০% </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
   )
}

export default Body;