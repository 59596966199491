import React from 'react';
import Header from '../components/Header';
import Body from '../components/AboutUsBody';
import Footer from '../components/Footer';

const AboutUs = () =>  {
    
    
    return(
        <div className='container-fluid p-0'>
            <Header/>
                <Body/>
            <Footer/>
        </div>
    )
}

export default AboutUs;