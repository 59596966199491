
import React, { useState } from 'react';
import SideMenu from './SideMenu';
const Body = () => {

    return (
        <div className="container-fluid">
            <div className="container-fluid  bg-lightgray">
                <div className="container  pt-3 pb-3 checkout_container">
                    <div className="row ">
                        <SideMenu />

                        <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                            <div className="bg-white p-2">
                                <img src={require('../img/returnrefund.png')} className="w-100" alt="" />
                                <div style={{ paddingTop: 20 }}>
                                    <p className='mb-2' style={{ fontSize: 20, marginBottom: 10,color: '#f4511e' }}>রিফান্ড, রিটার্ন এবং ক্যান্সেলেশন পলিসি </p>

                                    <p style={{ marginBottom: 20, width: "100%", textAlign:"justify" }}>
                                        প্রোডাক্ট আনবক্সিং করার সময় মোবাইলে ভিডিও ধারণ করে রাখুন। এতে
                                        প্রোডাক্টে কোনো সমস্যা থাকলে সেটা আপনার প্রমাণ হিসেবে কাজ করবে।
                                        তারপর প্রোডাক্ট রিটার্ন দিয়ে দিন।{" "}
                                    </p>

                                    <p className='mt-2' style={{ marginBottom: 20, width: "100%", textAlign:"justify" }}>
                                        ভেন্ডর যদি প্রোডাক্ট রিটার্ন নিতে অস্বীকার করে অথবা তার প্রোডাক্ট
                                        ঠিক আছে দাবী করে তাহলে আপনার ধারণকৃত আনবক্সিং ভিডিওটি প্রমাণ হিসেবে
                                        চাওয়া হতে পারে। আনবক্সিং ভিডিও ছাড়া রিটার্ন গ্রহণযোগ্য হবে না।{" "}
                                    </p>

                                    <p className='mt-2' style={{ marginBottom: 20, width: "100%", textAlign:"justify" }}>
                                        প্রোডাক্টে সমস্যা প্রমাণিত হলে ১০০% রিটার্ন গ্রহণ করা হবে। এরপর
                                        প্রোডাক্ট রিপ্লেসমেন্ট অথবা রিফান্ড, যেকোনো একটি দাবী গ্রহণযোগ্য
                                        হবে।
                                    </p>

                                    <p className='mt-2' style={{ marginBottom: 20, width: "100%", textAlign:"justify" }}>
                                        গ্যারান্টি/ওয়ারেন্টি যুক্ত প্রোডাক্ট এর ক্ষেত্রে অবশ্যই
                                        গ্যারান্টি/ওয়ারেন্টি কার্ড সংরক্ষণ করবেন। গ্যারান্টি/ওয়ারেন্টির
                                        মেয়াদ থাকাকালীন সুনির্দিষ্ট শর্তের আলোকে গ্যারান্টি/ওয়ারেন্টি
                                        কার্যকর হবে।
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Body;