import React from 'react';
import { useEffect } from 'react';

const Footer = () =>  {
    const shoputils = require('../utils/shoputils.json');
    useEffect(() => {
        logoutNow();
        localStorage.removeItem('stgcartdetails')
        localStorage.removeItem('stgcart')
    },[]) 
    const logoutNow = async() =>{
        try {
            const random = Math.random()
            let userinfo = localStorage.getItem('userinfo');
            let user = JSON.parse(userinfo)
            //console.log(uid)
            let formData = new FormData();
            let token = localStorage.getItem('token');
            formData.append('logoutweb', user.auth);
            
            const response = await fetch(shoputils.webpost + '?random=' + random + '&webpost=1', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                //upload images
                body: formData
            });
            const json = await response.json();
            console.log(json)
            if (json.success == 1) {
                localStorage.removeItem('userinfo');
                localStorage.removeItem('devicetoken')
                localStorage.removeItem('cartdetails')
                localStorage.removeItem('stgcartdetails')
                localStorage.removeItem('stgcart')
                localStorage.removeItem('token')
                window.location.href = "/";
            }else{
                window.location.href = "/";
            }
        } catch (error) {
        } finally{
            window.location.href = "/";
        }
    }
    return(
        <>
        
        </>
    );
}

export default Footer;
                                