import React,{useState,useEffect} from 'react';
import SideMenu from '../SideMenu'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useParams} from 'react-router-dom';
import { faMagnifyingGlass,faMessage,faStar } from '@fortawesome/free-solid-svg-icons';
const Body = () =>  {    
    const [loading, setLoading] = useState(false);
    const params = useParams();
  const [data, setData] = useState([]);
  const [loadingMore,setLoadingMore] = useState(false);
  const [vendorid,setVendorid] = useState(params.vid);

  const shoputils = require('../../utils/shoputils.json');
  const [nodata, setNodata] = useState(false);
  const [album, setAlbum] = useState([]);
  const [search,setSearch] = useState('');
  const [vendorname,setVendorname] = useState('')
  const [loadMore,setLoadMore] = useState(false)
  const [phonenumber, setphonenumber] = useState('');
  const [rating,setrating] = useState(0);
  const [ResellerStatus,setResellerStatus] = useState(0);
  const [perticipentUser, setPerticipentUser] = useState(null);
  const [banner,setBanner] = useState('https://self-shopping.com/shopapp/img/shop/self-platfrom.png')
  const Stars = (props) => {
    var stars = props.stars
    if ((props.size)) {
      var size = props.size;
    } else {
      size = 10;
    }
    if (stars == 5) {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
      </div>
    } else if (stars == 4) {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
      </div>
    } else if (stars == 3) {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
      </div>
    } else if (stars == 2) {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
      </div>
    } else if (stars == 1) {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
      </div>
    } else if (stars == 0) {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1 ' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
      </div>
    } else {
      return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
        <FontAwesomeIcon className='  mt-1' icon={faStar} />
      </div>
    }
  }
  
  useEffect(() => {
    if(nodata == false){
      fetchData();
      getResellerStatus();
      getAlbum()
    }

    
  },[]);
  const getResellerStatus = async() => {
    setLoading(true);
    try {
      
    const users =  localStorage.getItem("userinfo");
    if(users){
      const user = JSON.parse(users);
    const random = Math.random()
    const response = await fetch(shoputils.url+'?getResellerStatus=1&random='+random+'&auth='+user.auth)
      const json = await response.json();
      
      if(user.status == 1){
        setResellerStatus(json.ResellerStatus)
      }
    }else{
      setResellerStatus(0)
    }
      
     } catch (error) {
       console.error(error);
     }  finally {
       setLoading(false)
     }
  }
  const getAlbum = async() => {
    try {
        setLoadingMore(true);
    const random = Math.random();
    const response = await fetch(shoputils.url+'?random='+random+'&vendoralbums=1&uid='+vendorid);
    const json = await response.json();
          
     
    if(json.success == 1){
            if(json.albums){
              setAlbum([...json.albums]);
            }
        }else{
            
           
              setNodata(true);
          
    }
        setLoadingMore(false);
        
    } catch (error) {
        console.error(error);
        setLoadingMore(false);
    }  finally {
        setLoadingMore(false)
        
    } 
}
  const fetchData = async () => {
          setLoading(true)
        try {
              const random = Math.random();
              const response = await fetch(shoputils.url+'?random='+random+'&vendorsproduct=0&vendorid='+vendorid);
              const json = await response.json();
            
              if(json.success == 1){
                if(json.products.length > 0){
                  
                  setData(json.products);
              }
                   setNodata(true)
                  setVendorname(json.vendorname);
                  setphonenumber(json.phonenumber);
                  setrating(json.rating);
                  if(json.banner){
                    setBanner('https://self-shopping.com/shopapp/img/shop/'+json.banner)
                  }
                
              }
              setLoading(false)
              
              
        } catch (e) {
          
            setLoading(false)
          
        } finally{
          
            setLoading(false)
          
        }
};
const renderItem = ( item, index ) => {
  console.log(item)
  return (
    <a href={'/shop/product/'+item.id+'/'+item.name}  className="col-lg-3 text-dark col-md-3 col-sm-6 col-6  p-2 ">
                <div className='rounded border  shadow-sm rounded overflow-hidden' style={{position:'relative'}}>
                    
                    
                        <div className='overflow-hidden' style={{height:200}}>
                        <img src={shoputils.imageurl+item.img1} className="d-block w-100" alt="..."/>
                    </div>
                    <div className="bg-lightgray text-dark ps-2 pe-2">
                        <p className='m-0 text-left onlinetext'>{item.name} </p>
                        {
                            ResellerStatus == 0 ?
                            item.d == 'flash' ?
                                <div className='d-flex flex-row justify-content-between'>
                                <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                                <h6 className='fw-bold mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>
                                
                                </div>
                                
                                : item.d == 'discount' ?
                                <div className='d-flex flex-row justify-content-between'>
                                    <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                                    <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>
                                
                                </div>
                                
                                : item.d == 'none' ?
                                
                                
                                <div className='d-flex flex-row justify-content-between'>
                                    <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_price)}</h6>
                                </div >
                                
                                    :
                                    null
                            : item.d == 'flash' ?
                            <div className='d-flex flex-row justify-content-between'>
                                <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>
                            
                            </div>
                            
                            : item.d == 'discount' ?
                            <div className='d-flex flex-row justify-content-between'>
                                <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>
                            
                            </div >
                            
                            : item.d == 'none' ?
                            <div className='d-flex flex-row justify-content-between'>
                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.price)}</h6>
                            </div >
                                
                            
                            
                                :
                                null
                        }
                    </div>
                    
                </div>
                
            </a>
  );
};
if(loadMore == false){
  window.onscroll = async function(ev) {
      if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
          setLoadMore(true)
          try {
            setLoadingMore(true);
            var index = data.length;
            var lastitem = data[index - 1]; //get 1st item in the array
            if (lastitem) {
              try {
                  
                    const random = Math.random();
                    const response = await fetch(shoputils.url+'?random='+random+'&vendorsproduct='+lastitem.id+'&vendorid='+vendorid);
                    const json = await response.json();
                    if(json.success == 1){
                      if(json.products.length > 0){
                        const responsedata = json.products;
                        let arry = [...data,...responsedata];
                        setData(arry);
                    }  
                    }
                setLoading(false);
              } catch (e) {
                setLoading(false);
              } finally{
                setLoading(false);
                setLoadingMore(false);
              }
            }
            setLoading(false);
          } catch (e) {
            setLoading(false);
          }
  }
};
} 
const onChangeSearch = async(value) => {
  
  if(value.length > 2){
      try {
      const random = Math.random();
      const response = await fetch(shoputils.url+'?random='+random+'&vendorsproduct='+value+'&vendorid='+vendorid);
      const json = await response.json();
      if(json.success == 1){
        setData(json.products)
      }
     
     } catch (error) {
       console.error(error);
     }  finally {
       
     }
  }

}

const renderfolder = (item)=>{
  return(            
       <a href={'/shop/folderproduct/'+item.id} className='w-25 text-center'
       
       >  
              <img
              style={{height:55,width:55,}}
              src='https://self-shopping.com/shopapp/img/categoryimage/album1.png'
              />   
          <div className='text-nowrap onlinetext' >
              <p >{item.name}</p>
          </div>
          
      </a>               
      );
  }

return (
  <div className="container-fluid bg-lightgray">
        <div className="container-fluid  ">
            <div className="container  pt-3 pb-3 checkout_container">
                <div className="row ">
                    <SideMenu/>
                    
                    <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                        <div className="selfbgcolor rounded-top p-2 text-center text-white fs-5">{vendorname}</div>
                        <img src={shoputils.imageurl+banner} alt="" />

                        <div className="container">
                          <div className="row">
                          
                          <div  className='col-3'>
                            <div className="bg-white p-2 text-center">
                              <div >
                                <p className='m-0 fs-4' >95%</p>
                              </div> 
                              <p >সময়মতো ডেলিভারি</p>
                              
                            </div>
                          </div>
                          <div   className='col-3'>
                            <div className="bg-white p-2 text-center">
                              <div >
                                <p className='m-0 fs-4'>90 Minute</p>
                              </div>
                              <p >রেসপন্স টাইম</p>
                              
                            </div>
                          </div>
                          <div  className='col-3'>
                            <div className="bg-white p-2 text-center">
                              <div >
                                <p className='m-0 fs-4'><Stars stars={rating}/></p>
                              </div>
                              <p >গড় রিভিউ</p>
                            
                            </div>
                          </div>
                          <div  className='col-3'>
                            <div className="bg-white p-2 text-center">
                              <div >
                                <p className='m-0 fs-4'><FontAwesomeIcon className=' fs-3  ' icon={faMessage} />  </p>
                              </div> 
                              <p >চ্যাট করুন</p>
                            </div>
                          
                          </div>
                      
                      {
                        album.length > 0 ?
                        <h3 className='mt-3'>ফোল্ডার</h3>
                        :null
                      }
                      {  album.map((item,index) => {
                            return renderfolder(item,index);
                        })
                      }
                      
                      <div className="clearfix mb-4"></div>
                      {
                        data.map((item,index) => {
                            return renderItem(item,index);
                        })
                      } 
                      
                            
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

)



}

export default Body;