import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";


export const ErrorToast = (message) => {
   return   <div>
    <div className="modal errormodal fade show d-block d-flex flex-column align-items-center justify-content-center" >
                <div className="modal-dialog modal-sm w-75">
                    <div className="modal-content text-center">
                        <div className="text-center align-self-center" style={{marginTop:-20,width:50,height:50,background:'#fff',padding:5,borderRadius:50}}>
                        <FontAwesomeIcon className=' text-warning ' style={{fontSize:38}} icon={faCircleExclamation} />
                        </div>
                        <div className="modal-body">
                            <p className="text-warning fs-3 fw-bold">Warning</p>
                            <p className="text-black">{message}</p>
                            <div className="btn btn-warning w-100 closeerrormodal fw-bold text-white">Close</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop errormodalbackdrop" style={{opacity:0.3}}></div>
   </div>
}


