import React, { useState, useEffect } from "react";
import SideMenu from "../SideMenu";

import { useParams } from "react-router-dom";
const Body = () => {
  const params = useParams();
  const shoputils = require("../../utils/shoputils.json");
  const [percent, setPercent] = useState(params.percent);

  const [loadMore, setLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nodata, setNodata] = useState(false);
  const [data, setData] = useState([]);
  const [ResellerStatus, setResellerStatus] = useState(0);
  useEffect(() => {
    if (nodata == false) {
      fetchData();
      getResellerStatus();
    }
  },[]);
  const getResellerStatus = async () => {
    try {
      setLoading(true);

      const users = localStorage.getItem("userinfo");
      if (users) {
        const user = JSON.parse(users);
        const random = Math.random();
        const response = await fetch(
          shoputils.url +
            "?getResellerStatus=1&random=" +
            random +
            "&webpost=1&uid=" +
            user.auth
        );
        const json = await response.json();

        if (user.status == 1) {
          setResellerStatus(json.ResellerStatus);
        }
      }
      setLoading(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const random = Math.random();
      const response = await fetch(
        shoputils.url + "?random=" + random + "&discountedproducts=" + percent
      );
      const json = await response.json();
      //console.log(json)
      if (json.success == 1) {
        setData(json.products);
        setNodata(true);
        setLoading(false);
        //console.log(json)
      } else {
        setNodata(true);
        setLoading(false);
      }
      setLoading(false);
      setNodata(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid bg-lightgray">
      <div className="container-fluid  ">
        <div className="container  pt-3 pb-3 checkout_container">
          <div className="row ">
            <SideMenu />

            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
              <div className="selfbgcolor rounded-top p-2 text-center text-white fs-5">
                ডিসকাউন্টেড প্রোডাক্টস
              </div>
              <div className="row">
                {data.map((item, index) => {
                  return (
                    <a
                      href={"/shop/product/" + item.id + "/" + item.name}
                      key={index}
                      className="col-lg-3 text-dark col-md-3 col-sm-6 col-6  p-2 "
                    >
                      <div className="rounded border  shadow-sm rounded overflow-hidden">
                        <div className="w-100 overflow-hidden justforyouitem">
                          <img
                            src={shoputils.imageurl + item.img1}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                        <div className="bg-lightgray ps-2 pe-2">
                          <p className="m-0 text-left onlinetext selfcolor">
                            {item.name}{" "}
                          </p>
                          {ResellerStatus == 0 ? (
                            item.d == "flash" ? (
                              <div className="d-flex flex-row justify-content-between">
                                <p className="font-small text-danger mb-2">
                                  <del>৳ {Math.round(item.c_price)}</del>
                                </p>
                                <h6 className="fw-bold mb-2">
                                  ৳ {Math.round(item.c_discountedprice)}
                                </h6>
                              </div>
                            ) : item.d == "discount" ? (
                              <div className="d-flex flex-row justify-content-between">
                                <p className="font-small text-danger mb-2">
                                  <del>৳ {Math.round(item.c_price)}</del>
                                </p>
                                <h6 className="fw-bold  mb-2">
                                  ৳ {Math.round(item.c_discountedprice)}
                                </h6>
                              </div>
                            ) : item.d == "none" ? (
                              <div className="d-flex flex-row justify-content-between">
                                <h6 className="fw-bold  mb-2">
                                  ৳ {Math.round(item.c_price)}
                                </h6>
                              </div>
                            ) : null
                          ) : item.d == "flash" ? (
                            <div className="d-flex flex-row justify-content-between">
                              <p className="font-small text-danger mb-2">
                                <del>৳ {Math.round(item.price)}</del>
                              </p>
                              <h6 className="fw-bold  mb-2">
                                ৳ {Math.round(item.discountedprice)}
                              </h6>
                            </div>
                          ) : item.d == "discount" ? (
                            <div className="d-flex flex-row justify-content-between">
                              <p className="font-small text-danger mb-2">
                                <del>৳ {Math.round(item.price)}</del>
                              </p>
                              <h6 className="fw-bold  mb-2">
                                ৳ {Math.round(item.discountedprice)}
                              </h6>
                            </div>
                          ) : item.d == "none" ? (
                            <div className="d-flex flex-row justify-content-between">
                              <h6 className="fw-bold  mb-2">
                                ৳ {Math.round(item.price)}
                              </h6>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
