import React, { useState, useEffect } from 'react';

import SideMenu from '../SideMenu';
import { useParams } from 'react-router-dom';

const Body = () => {
    const params = useParams();
    const [post, setPost] = useState(params.id);
    const [data, setData] = useState([])
    const shoputils = require('../../utils/shoputils.json');
    useEffect(() => {
        getData(post);
    }, [])
    const getData = async () => {
        try {
            const random = Math.random();
            const response = await fetch(shoputils.airticket + '?random=' + random + '&getTipsDetails=' + post);
            const json = await response.json();
            if (json.success == 1) {
                setData(json.tips)

            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }
    return (
        <div className="container-fluid">
            <div className="container-fluid  bg-lightgray">
                <div className="container  pt-3 pb-3 checkout_container">
                    <div className="row ">
                        <SideMenu />
                        <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                            <div className="bg-white p-2">
                                <div className="mt-5">
                                    <p className='selfcolor h5 p-3'>{data.title}</p>
                                    {data.text ?
                                        <textarea className='w-100 bg-white form-control border-0' disabled style={{ minHeight: 1000 }}>{data.text}</textarea>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Body;