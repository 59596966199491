
import React,{useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../SideMenu'; 
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import { faRectangleList, faSquare } from '@fortawesome/free-solid-svg-icons';
import category from '../../assets/category.png';
import subcategory from '../../assets/options.png';

const Body = () =>  {
    
    var initial_categories = [];
    
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [catid, setCatid] = useState('');
    const [loading, setLoading] = useState(false);
    const shoputils = require('../../utils/shoputils.json');
    const [nodata, setNodata] = useState(false);
    useEffect(() => {
        if(categories.length == 0){
          fetchData(); 
        }
        
        //setCategories(initial_categories);
      }, [])
    const fetchData = async () => {
        try {
          setLoading(true);
          const random = Math.random();
          const response = await fetch(shoputils.url+'?random='+random+'&category=0');
          const json = await response.json();
          //console.log(json.category)
          if(json.success == 1){
              setCategories(json.category);
              setCatid(json.category[0].catid)
              setNodata(true)
              setSubCategories([...json.subcategory]);
              setLoading(false);
          }else{
              setNodata(true);
              setLoading(false);
  
          }
          setLoading(false);
          setNodata(true)
      } catch (error) {
          console.error(error);
          setLoading(false);
      }  finally {
          setLoading(false)
      } 
    };
    
    function selectCategory(value,catid){
        setCatid(catid);
        //alert(value);
        initial_categories = categories;
        for(var i = 1; i < categories.length;i++){
          
          initial_categories[i-1].borderColor = '#004BD1';
          initial_categories[i-1].color = '#fff';
          if(i == value){
            initial_categories[i-1].borderColor = '#F4511E';
            initial_categories[i-1].color = '#efefef';
            setCategories(initial_categories);
          }
        }
      }
    const renderItem = (item,index) => {
        return (
        
                <div key={index} onClick={()=>selectCategory(item.id,item.catid)} className='rounded border bg-lightgray text-center m-2 w-100 shadow-sm rounded overflow-hidden' >
                    <img src={category} className="categoryImage" style={{width:50,height:50}} alt="..."/>
                    <div className=" ps-2 pe-2">
                        <p className='m-0 text-left onlinetext'>{item.name} </p>
                       
                    </div>
                </div>
        
        );
      }
    const renderItem2 = (item,index) => {
        var randomColor = Math.floor(Math.random()*16777215).toString(16);
        return (
        
                <a key={index} href={'/shop/category/category/'+item.name} className='text-dark rounded border text-center m-2 subcategory_box shadow-sm rounded overflow-hidden ' style={{width:'22%',position:'relative'}} >
                    <div className="category_absolute p-2" style={{backgroundColor:'#'+randomColor}}></div>
                    <img src={subcategory} className="categoryImage m-2" style={{width:50,height:50}} alt="..."/>
                    <div className=" ps-2 pe-2">
                        <p className='m-0 text-left onlinetext'>{item.name} </p>
                       
                    </div>
                </a>
        
        );
      }
      let height = window.innerHeight;
   return(
    <div className="container-fluid bg-lightgray">
        <div className="container-fluid  ">
            <div className="container  pt-3 pb-3 checkout_container">
                <div className="row ">
                    <SideMenu/>
                    
                    <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                        <div className="selfbgcolor  rounded-top p-2 text-center text-white fs-5">প্রোডাক্ট ক্যাটাগরি</div>
                        <div className="row">
                            <div className="overflow-scroll hidescroll category_box col-4" style={{height:height}}>
                                <div className="d-flex flex-column ">
                                    {categories.map((item,index) => {
                                        return renderItem(item,index)
                                    })}
                                </div>
                            </div>
                            <div className="overflow-scroll hidescroll ps-0 pe-0 col-8">
                                <div className="d-flex flex-wrap ">
                                    {subCategories.map((item,index) => {
                                        if(item.catid == catid){
                                            return renderItem2(item,index)
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    
   )
}

export default Body;