
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../SideMenu';
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import { faRectangleList, faSquare, faTimes, faMagnifyingGlass, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { useParams } from 'react-router-dom';
const Body = () => {
    const params = useParams();
    const shoputils = require("../../utils/shoputils.json");
    const [invoice, setInvoice] = useState(params.invoice)
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false)
    const [cart,setcart] = useState([])
    useEffect(() => {
        fetchData();
    }, [])
    const fetchData = async () => {
        setLoading(true);
        const random = Math.random();
        try {
            let response = await fetch(
                shoputils.url +
                "/getOrderDetails?random=" +
                random +
                "&invoice=" +
                invoice

            );
            const json = await response.json();
            setData(json?.orderDetails);
            setcart(json?.carts)
            console.log(json?.carts)
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            
        }

        setLoading(false);
    };
    return (
        <div>


            

            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-6 shadow mb-2">
                        <div className="mt-2 p-2 overflow-hidden object-fit-xxl-contain confirm_image_parent">
                            <img className="p-2 w-100 confirm_image" src="https://www.stgindustry.com/checkout/order-confirm.jpg" />
                            <h6 className="text-center text-success">
                                ধন্যবাদ! আপনার অর্ডারটি সফল হয়েছে।
                            </h6>
                        </div>
                    </div>

                    <div className="col-md-6 mb-2 shadow invoice_section">
                        <div className="d-flex justify-content-between border-bottom">
                            <h6 className="mt-2 text-center"><img src="https://www.stgindustry.com/checkout/schedule.png" style={{ fontSize: 15 }} />sdf</h6>
                            <h6 className="mt-2 text-center">ইনভয়েস : <span className="text-success fw-bold">{invoice}</span></h6>
                        </div>


                        <table className="table">
                            <thead>
                                <tr className="border-bottom-2 border-secondary">
                                    <th scope="col">Product</th>
                                    <th scope="col"></th>
                                    <th scope="col" className="text-center">Quantity</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img className="rounded pr-2" height="50px" width="60px" src={"https://shopapp.self-shopping.com/img/shop/"+cart?.image} /></td>
                                    <td> </td>
                                    <td className="text-center"></td>
                                    <td><span className="mt-2 ml-2 pl-4"> </span> </td>
                                </tr>
                                <tr>
                                    <td>Subtotal</td>
                                    <td></td>
                                    <td></td>
                                    <td><span className="mt-2 ml-2 pl-4 multiplyproduct"> ৳{data.cod}  </span></td>
                                </tr>
                                <tr className="">
                                    <td>Shipping</td>
                                    <td></td>
                                    <td></td>
                                    <td>৳{data.advance}</td>
                                </tr>
                                <tr className="border-bottom-1">
                                    <td className="fw-bold">Total</td>
                                    <td></td>
                                    <td></td>
                                    <td className="fw-bold"><span className="mt-2 ml-2 pl-4 total_amount"> ৳{data.bagtotal} </span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-12 mt-2">
                        <p className="text-center text-danger fs-4 call_center"> আমাদের কল সেন্টার থেকে আগামী ১২ ঘন্টার মধ্যে ফোন করে আপনার অর্ডারটি কনফার্ম করা হবে অথবা এই <span className="text-success fw-bold">01968 - 153605</span>- নম্বরে কল করে এখনই অর্ডার কন্ফার্ম করে নিন। </p>
                        <p className="text-center text-danger fs-3 call_center"> (সকাল ১০ টা থেকে রাত ১০ টা ) </p>
                    </div>
                </div>
            </div>



            <div className="d-flex flex-row justify-content-center mt-2" >
                <p className="text-white mt-2">© 2024 STG Industry. All rights reserved.</p>
            </div>
        </div>






        // <div classNameName="container-fluid bg-lightgray">
        //     <div classNameName="container-fluid  ">
        //         <div className="container  pt-3 pb-3 checkout_container">
        //             <div className="row text-center mt-5">
        //                 <FontAwesomeIcon className=' text-success ordersuccesscheck' icon={faCircleCheck} />
        //                 <p className='text-secondary mt-5'>আপনার অর্ডার সফল হয়েছে  </p>
        //                 <p className='text-secondary mb-5'>ইনভয়েস নং <span className='text-dark'>{invoice}</span></p>
        //                 <a className='btn btn-danger w-50 m-auto' href="/">হোম এ ফিরে যান</a>
        //             </div>
        //         </div>
        //     </div>
        // </div>

    )
}

export default Body;