import React,{useState,useEffect} from 'react';
import SideMenu from '../SideMenu'; 

import {useParams} from 'react-router-dom';


const Body = () =>  {
    const shoputils = require('../../utils/shoputils.json');
    const params = useParams();
    const [type,setType] = useState(params.type);
    const [nodata,setNodata] = useState(false)
    const [loadMore,setLoadMore] = useState(false);
    const [loadingMore,setLoadingMore] = useState(false);
    const [ordertype,setOrderType] = useState('UProcessingOrder');
    const [activestatus1,setActiveStatus1] = useState('');
    const [activestatus2,setActiveStatus2] = useState('');
    const [activestatus3,setActiveStatus3] = useState('');
    const [activestatus4,setActiveStatus4] = useState('');
    const [data,setData] = useState([]) 
    useEffect(() => {
        if(type == 'pending'){
            setActiveStatus1('active');
            setOrderType('UProcessingOrder')
        }else if(type == 'processing'){
            setActiveStatus2('active');
            setOrderType('Order1')
        }else if(type == 'cancel'){
            setActiveStatus3('active');
            setOrderType('Order2')
        }else if(type == 'complete'){
            setActiveStatus4('active');
            setOrderType('Order3')
        }
        if(nodata == false){
            getData()
        }
    },[])
    const getData = async () => {
        try {
            setLoadingMore(true);
        
        const users =  localStorage.getItem("userinfo");
        const user = JSON.parse(users);
        const random = Math.random();
       
        const response = await fetch(shoputils.url+'?random='+random+'&'+ordertype+'=0&auth='+user.auth);
        const json = await response.json();
           
            if(json.success == 1){
                
                setData(json.orders);
                
                setNodata(true)
                
            }else{
                setData([]);
                setNodata(true);
            }
            setLoadingMore(false);
            
        } catch (error) {
            
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
            
        } 
    }
    if(loadMore == false){
        window.onscroll = async function(ev) {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
                setLoadMore(true)
                try {
                setLoadingMore(true);
                var index = data.length;
                var lastitem = data[index - 1]; //get 1st item in the array
                
                if (lastitem) {
                    
                    
                    const users =  localStorage.getItem("userinfo");
                    const user = JSON.parse(users);
                    const random = Math.random();
                    const response = await fetch(shoputils.url+'?random='+random+'&'+ordertype+'='+lastitem.id+'&auth='+user.auth);
                    const json = await response.json();
                    const responsedata = json.orders;
                    if(json.orders.length > 0){
                        let arry = [...data, ...responsedata];
                        setData(arry);
                        setLoadMore(false)
                    }
                    
                    
                    
                }
                setLoadingMore(false);
            } catch (e) {
                setLoadingMore(false);
            } finally {
                
            }
            }
        };
    }  
   return(
    <div className="container-fluid bg-lightgray">
        <div className="container-fluid  ">
            <div className="container  pt-3 pb-3 checkout_container">
                <div className="row ">
                    <SideMenu/>
                    <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                        <div className="selfbgcolor rounded-top p-2 text-center text-white fs-5">আমার অর্ডার</div>
                        <div className="bg-white p-2">
                            <ul className="nav nav-tabs d-flex flex-row" id="myTab" role="tablist">
                                <a href='/shop/myorder/pending' className="nav-item flex-fill " role="presentation">
                                    <button className={'nav-link w-100 myordertab fs-6 '+ activestatus1} id="pending-tab" data-bs-toggle="tab" data-bs-target="#pending" type="button" role="tab" aria-controls="pending" aria-selected="true">পেন্ডিং</button>
                                </a>
                                <a href='/shop/myorder/processing' className="nav-item flex-fill" role="presentation">
                                    <button className={'nav-link w-100 myordertab fs-6 '+ activestatus2} id="processing-tab" data-bs-toggle="tab" data-bs-target="#processing" type="button" role="tab" aria-controls="processing" aria-selected="false">প্রক্রিয়াধীন</button>
                                </a>
                                <a href='/shop/myorder/cancel' className="nav-item flex-fill" role="presentation">
                                    <button className={'nav-link w-100 myordertab fs-6 '+ activestatus3} id="cancel-tab" data-bs-toggle="tab" data-bs-target="#cancel" type="button" role="tab" aria-controls="cancel" aria-selected="false">বাতিল</button>
                                </a>
                                <a href='/shop/myorder/complete' className="nav-item flex-fill" role="presentation">
                                    <button className={'nav-link w-100 myordertab fs-6 '+ activestatus4} id="complete-tab" data-bs-toggle="tab" data-bs-target="#complete" type="button" role="tab" aria-controls="complete" aria-selected="false">সম্পন্ন</button>
                                </a>
                            </ul>
                            
                            {
                                nodata == true ?
                                data.map((item,index) => {
                                    return <div key={index} className="p-2 border shadow-sm mb-3" >
                                                <div className="d-flex flex-row justify-content-start">
                                                    <img className='myorderimage align-self-center me-3' src={shoputils.imageurl+item.img1} alt="" />
                                                    <div className='float-start flex-grow-1'>
                                                        <p className="m-0 selfcolor myorder_text me-2">{item.productname}</p>
                                                        <p className='m-0 text-secondary'>Invoice : {item.invoice}</p>
                                                        <p className='m-0 text-secondary'>{item.date} {item.time}</p>
                                                    </div>
                                                    <div className='align-self-center align-items-end text-end' >
                                                        <a href={"/shop/myorder/details/"+item.id} className="btn btn-danger btn-sm">বিস্তারিত</a>
                                                        <p>{item.status}</p>
                                                    </div>
                                            
                                                </div>
                                            </div>
                                })
                                :null
                            }

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    
   )
}

export default Body;