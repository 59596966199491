import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../components/SideMenu'; 

import { faCamera, faChevronRight, faHandPointer, faMobile, faMobileScreenButton, faPaperPlane, faShoppingBag, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
const Body = () =>  {
    
    return(
    <div className="container-fluid">
        <div className="container-fluid  bg-lightgray">
                    <div className="container  pt-3 pb-3 checkout_container">
                        <div className="row ">
                            <SideMenu/>
                            
                            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                                <div className="bg-white p-2">
                                    <img src={require('../img/about.png')} className="w-100" alt="" />
                                    <div className="mt-5">
                                        
                                        <p style={{ color: '#f4511e'.primary, fontSize: 18, marginBottom: 10 }}>ABOUT US</p>
                                        <div style={{ color: '#f4511e'.text,  fontSize: 15,  }}>
                                            <p style={{fontWeight:'bold'}}>"SELF - Digital Business Platform"</p> is the first and only SUPER APP and Complete Business Solution in Bangladesh. This is the project of "Self-employments Technologies Lld." which is registered by Join Stock in 2018 under the company act of 1994. The company is a sister concern of SELFTECH GROUP.
                                        </div>
                                        <div style={{ marginTop: 15, color: '#f4511e'.text,  fontSize: 15,  }}>
                                        <p style={{fontWeight:'bold'}}>"SELF - Digital Business Platform"</p> is an Ultimate Marketplace which provides verious types of digital services and gives opportunities to the users to buy and sell their products and services on Marketplace with B2B, B2C and C2C model.
                                        </div>
                                        <p style={{ marginTop: 15, color: '#f4511e'.text,  fontSize: 15,  }}>
                                            We strongly believe in our users satisfaction, security and convenience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
   )
}

export default Body;