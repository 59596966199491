import React, { useState, useEffect } from 'react';
import SideMenu from '../../components/SideMenu';



const Body = () => {
    const [data, setData] = useState([])

    const shoputils = require('../../utils/shoputils.json');
    const [loadMore, setLoadMore] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false)
    const [refreshing, setRefreshing] = useState(false);
    const [viewdata,setviewdata] = useState([])
    const [modalVisible, setModalVisible] = useState(false);
    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const users = localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            const random = Math.random();
            const response = await fetch(shoputils.airticket + '?getAirticketRequestUser=1&auth=' + user.auth + '&random=' + random);
            const json = await response.json();
            if (json.success == 1) {
                setData(json.data)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setRefreshing(false)
        }
    }
    if (loadMore == false) {
        window.onscroll = async function (ev) {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
                setLoadMore(true)
                try {
                    setLoadingMore(true);
                    var index = data.length;
                    var lastitem = data[index - 1]; //get 1st item in the array

                    if (lastitem) {


                        const users = localStorage.getItem("userinfo");
                        const user = JSON.parse(users);
                        const random = Math.random();
                        const response = await fetch(shoputils.url + '?random=' + random + '&lastid=' + lastitem.id + '&auth=' + user.auth);
                        const json = await response.json();
                        const responsedata = json.orders;
                        if (json.orders.length > 0) {
                            let arry = [...data, ...responsedata];
                            setData(arry);
                            setLoadMore(false)
                        }



                    }
                    setLoadingMore(false);
                } catch (e) {
                    setLoadingMore(false);
                } finally {

                }
            }
        };
    }
    return (
        <div className="container-fluid bg-lightgray">
            <div className="container-fluid  ">
                <div className="container  pt-3 pb-3 checkout_container">
                    <div className="row ">
                        <SideMenu />
                        <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                            <div className="selfbgcolor rounded-top p-2 text-center text-white fs-5">টিকেট রিকোয়েস্ট হিস্ট্রি</div>
                            {
                                modalVisible == true ?
                                <div className="modal d-block">
                                    <div className="modal-dialog modal-dialog-scrollable">
                                        <div className="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">টিকেট রিকোয়েস্ট হিস্ট্রি</h5>
                                                <button type="button" class="btn-close" onClick={() => setModalVisible(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <p style={{fontSize:16,color:'#fff',flexDirection:'row',justifyContent:'space-between'}}>টিকেটের বিবরণ
                                                    
                                                </p>
                                                
                                            
                                            
                                            
                                                {
                                                    viewdata.status == 0 ?
                                                    null
                                                    :
                                                    <>
                                                    <div style={{width:'100%',marginTop:10,padding:5}}>
                                                        <p style={{color:'#999'}}>যাত্রীর নাম </p>
                                                        <p>{viewdata.passengername}</p>
                                                    </div>
                                                    <div style={{width:'100%',marginTop:10,padding:5}}>
                                                        <p style={{color:'#999'}}>এয়ার এর নাম </p>
                                                        <p>{viewdata.airlinesname}</p>
                                                    </div>
                                                    
                                                    <div style={{width:'100%',marginTop:10,padding:5}}>
                                                        <p style={{color:'#999'}}>টিকেটের মূল্য </p>
                                                        <p>{viewdata.ticketprice}</p>
                                                    </div>
                                                    <div style={{width:'100%',marginTop:10,padding:5}}>
                                                        <p style={{color:'#999'}}>ক্যাশব্যাক</p>
                                                        <p>{viewdata.cashback}</p>
                                                    </div>
                                                    <div style={{width:'100%',marginTop:10,padding:5}}>
                                                        <p style={{color:'#999'}}>টিকেটের তারিখ </p>
                                                        <p>{viewdata.date}</p>
                                                    </div>
                                                    <div style={{width:'100%',marginTop:10,padding:5}}>
                                                        <p style={{color:'#999'}}>এয়ার ছাড়ার সময়</p>
                                                        <p>{viewdata.journeytime}</p>
                                                    </div>
                                                    </>
                                                }
                                                <div style={{width:'100%',marginTop:10,padding:5}}>
                                                    <p style={{color:'#999'}}>কোন এয়ারপোর্ট থেকে  </p>
                                                    <p style={{fontSize:18}}>{viewdata.fromcity}</p>
                                                </div>
                                                <div style={{width:'100%',marginTop:10,padding:5}}>
                                                    <p style={{color:'#999'}}>কোন এয়ারপোর্টে </p>
                                                    <p style={{fontSize:18}}>{viewdata.tocity}</p>
                                                </div>
                                                <div style={{width:'100%',marginTop:10,padding:5}}>
                                                    <p style={{color:'#999'}}>ভ্রমণের ধরণ </p>
                                                    <p style={{fontSize:18}}>{viewdata.triptype}</p>
                                                </div>
                                                <div style={{width:'100%',marginTop:10,padding:5}}>
                                                    <p style={{color:'#999'}}>টিকেটের ক্লাস </p>
                                                    <p style={{fontSize:18}}>{viewdata.classtype}</p>
                                                </div>
                                                <div style={{width:'100%',marginTop:10,padding:5}}>
                                                    <p style={{color:'#999'}}>ভ্রমণকারীর সংখ্যা  </p>
                                                    <p style={{fontSize:18}}>{viewdata.traveller} জন</p>
                                                </div>
                                            
                                                {
                                                viewdata.status !== 0 ?
                                                    <div style={{width:'100%',marginTop:10,marginBottom:10,padding:5}}>
                                                        <p style={{color:'#999'}}>নোট  </p>
                                                        <p style={{fontSize:18,color:'red'}}>{viewdata.note}</p>
                                                    </div>
                                                :null
                                                }
                                                
                                            
                                            <div style={{padding:10,width:'100%'}}>
                                            
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :null
                            }
                            


                                {
                                    data.length > 0 ?
                                        data.map((item, index) => {
                                            return <div key={index} className="bg-white p-2">
                                                <div
                                                onClick={() => {
                                                    setviewdata(item)
                                                    setModalVisible(!modalVisible)
                                                }}
                                                className='d-flex flex-row justify-content-between align-items-center'
                                                >
                                                    
 
                                                <div style={{ marginEnd: 10, flex: 10 }}>
                                                    <p>{item.username}</p>
                                                    <p>{item.number}</p>
                                                    <p numberOfLines={1} ellipsizeMode='tail' style={{ fontWeight: "bold" }}>From: {item.fromcity}</p>
                                                    <p numberOfLines={1} ellipsizeMode='tail' style={{ fontWeight: "bold" }}>To : {item.tocity}</p>
                                                    <p style={{ color: '#999', marginTop: 5 }}>{item.date} {item.time}</p>
                                                </div>
                                                <div className='text-center'>

                                                    <p className='btn btn-warning'>বিস্তারিত</p>
                                                    {
                                                        item.status == 0 ?
                                                            <p>পেন্ডিং</p>
                                                            : item.status == 1 ?
                                                                <p>গৃহীত</p>
                                                                : item.status == 3 ?
                                                                    <p>সম্পন্ন</p>
                                                                    : item.status == 2 ?
                                                                        <p>বাতিল</p>
                                                                        : null
                                                    }

                                                </div>
                                            </div>
                                            </div>
                                        })
                                        : null
                                }

                            

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Body;