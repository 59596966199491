import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../SideMenu';
import { useParams } from 'react-router-dom';
import { faCamera, faChevronRight, faHandPointer, faMobile, faMobileScreenButton, faPaperPlane, faShoppingBag, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faStar, faCircleUser } from '@fortawesome/free-regular-svg-icons';
const Body = () => {
    const params = useParams();
    const productid = params.pid;
    const arraydata = [];
    const ratinginfoarray = [
        {
            productid: '1',
            avaragerating: '4.5/5',
            totalCount: '250',
            star1: '5',
            star2: '5',
            star3: '14',
            star4: '19',
            star5: '53',

        },


    ];
    const [data, setData] = useState(arraydata);
    const [data1, setData1] = useState(arraydata);
    const [data2, setData2] = useState(arraydata);
    const [data3, setData3] = useState(arraydata);
    const [data4, setData4] = useState(arraydata);
    const [data5, setData5] = useState(arraydata);
    const [nodataavailable, setnodataavailable] = useState(false);
    const [ratinginfo, setRatingInfo] = useState(ratinginfoarray);
    const [nodata, setNodata] = useState(false);
    const [loading, setLoading] = useState(false);
    const shoputils = require('../../utils/shoputils.json');
    const [comment, setcomment] = useState('')
    const [commentid, setcommentid] = useState('')
    useEffect(() => {
        if (nodata == false) {
            getData();
        }
    },[]);
    const Stars = (props) => {
        var stars = props.stars
        if ((props.size)) {
            var size = props.size;
        } else {
            size = 10;
        }
        if (stars == 5) {
            return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
            </div>
        } else if (stars == 4) {
            return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
            </div>
        } else if (stars == 3) {
            return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
            </div>
        } else if (stars == 2) {
            return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
            </div>
        } else if (stars == 1) {
            return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
                <FontAwesomeIcon className='  mt-1 text-warning' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
            </div>
        } else if (stars == 0) {
            return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
                <FontAwesomeIcon className='  mt-1 ' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
            </div>
        } else {
            return <div style={{ flexDirection: 'row', marginRight: 10, marginTop: 5 }}>
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
                <FontAwesomeIcon className='  mt-1' icon={faStar} style={{ fontSize: size }} />
            </div>
        }
    }
    const getData = async () => {

        try {
            setLoading(true);

            const users = localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            const random = Math.random();

            const response = await fetch(shoputils.url + '?random=' + random + '&getproductratings=' + productid + '&auth=' + user.auth);

            const json = await response.json();

            if (json.success == 1) {

                setData(json.data);
                setData1(json.data1);
                setData2(json.data2);
                setData3(json.data3);
                setData4(json.data4);
                setData5(json.data5);
                setRatingInfo(json.RatingInfo);
                setNodata(true)
                setLoading(false)
            } else {
                setLoading(false);
                setNodata(false)
                setnodataavailable(true)
            }

            setLoading(false);
            setNodata(true)
        } catch (error) {
            console.error(error);
            setLoading(false);
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="container-fluid">
            <div className="container-fluid  bg-lightgray">
                <div className="container  pt-3 pb-3 ps-0 pe-0">
                    <div className="row ">
                        <SideMenu />

                        <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                            <div className="selfbgcolor p-2 mb-0 text-center text-white rounded-top h4">রেটিং ও রিভিউ</div>
                            <div className="text-center bg-white mb-3 p-3">
                                <p className='h1'>{ratinginfo.avaragerating} ({ratinginfo.totalCount})</p>

                                <Stars stars={Math.round(ratinginfo.average)} size={18} />

                            </div>
                            <ul className="nav nav-tabs d-flex flex-row justify-content-between" id="myTab" role="tablist">
                                <li className="nav-item w-20" role="presentation">
                                    <div className="nav-link active p-0 text-center" id="all-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="home" aria-selected="true">
                                        <div className="f-flex flex-row justify-content-center" style={{ fontSize: 12 }}>
                                            সকল
                                        </div>
                                        <span className='m-0'>{ratinginfo.all}</span>
                                    </div>
                                </li>
                                <li className="nav-item w-20" role="presentation">
                                    <div className="nav-link  text-center   text-center" id="ontstar-tab" data-bs-toggle="tab" data-bs-target="#ontstar" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                        <div className="d-flex flex-row justify-content-center">
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                        </div>
                                        <span>{ratinginfo.star1}</span>
                                    </div>
                                </li>
                                <li className="nav-item w-20" role="presentation">
                                    <div className="nav-link  text-center" id="twostar-tab" data-bs-toggle="tab" data-bs-target="#twostar" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                        <div className="d-flex flex-row justify-content-center">
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                        </div>
                                        <span>{ratinginfo.star2}</span>
                                    </div>
                                </li>
                                <li className="nav-item w-20" role="presentation">
                                    <div className="nav-link   text-center" id="threestar-tab" data-bs-toggle="tab" data-bs-target="#threestar" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                        <div className="d-flex flex-row justify-content-center">
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                        </div>
                                        <span>{ratinginfo.star3}</span>
                                    </div>
                                </li>
                                <li className="nav-item w-20" role="presentation">
                                    <div className="nav-link text-center" id="fourstar-tab" data-bs-toggle="tab" data-bs-target="#fourstar" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                        <div className="d-flex flex-row justify-content-center">
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                            <FontAwesomeIcon className='  text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                        </div>
                                        <span>{ratinginfo.star4}</span>
                                    </div>
                                </li>
                                <li className="nav-item w-20" role="presentation">
                                    <div className="nav-link  text-center" id="fivestar-tab" data-bs-toggle="tab" data-bs-target="#fivestar" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                        <div className="d-flex flex-row justify-content-center">
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                            <FontAwesomeIcon className='   text-danger' style={{ fontSize: 8 }} icon={faStar} />
                                        </div>
                                        <span>{ratinginfo.star5}</span>
                                    </div>
                                </li>


                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                                    {data.map((item, index) => {
                                        return <div key={index} className="box-shadow bg-white p-3 mt-2 mb-2">
                                            <div className="d-flex flex-row justify-content-center align-items-center ">
                                                <div className="img me-3" style={{ width: 30 }}>
                                                    {
                                                        item.image ?
                                                            <img src={'http://103.36.103.164:8080/self_digital/s_p_r/api/downloadFile/profile/' + item.image} className='w-100 rounded-pill' alt="" />
                                                            :
                                                            <FontAwesomeIcon className='   text-black fs-2' icon={faCircleUser} />
                                                    }
                                                </div>
                                                <div className="review_name flex-fill me-2">{item.name} <span>{item.date}</span></div>
                                                <Stars stars={item.rating} size={8} />
                                            </div>
                                            <div className="comment flex-grow-1 mt-3">
                                                {item.rating_desc}
                                            </div>
                                        </div>
                                    })}
                                </div>
                                <div className="tab-pane fade" id="ontstar" role="tabpanel" aria-labelledby="onestar-tab">
                                    {data1.map((item, index) => {
                                        return <div key={index} className="box-shadow bg-white p-3 mt-2 mb-2">
                                            <div className="d-flex flex-row justify-content-center align-items-center ">
                                                <div className="img me-3" style={{ width: 30 }}>
                                                    {
                                                        item.image ?
                                                            <img src={'http://103.36.103.164:8080/self_digital/s_p_r/api/downloadFile/profile/' + item.image} className='w-100 rounded-pill' alt="" />
                                                            :
                                                            <FontAwesomeIcon className='   text-black fs-2' icon={faCircleUser} />
                                                    }
                                                </div>
                                                <div className="review_name flex-fill me-2">{item.name} <span>{item.date}</span></div>
                                                <Stars stars={1} size={8} />
                                            </div>
                                            <div className="comment flex-grow-1 mt-3">
                                                {item.rating_desc}
                                            </div>
                                        </div>
                                    })}
                                </div>
                                <div className="tab-pane fade" id="twostar" role="tabpanel" aria-labelledby="twostar-tab">
                                    {data2.map((item, index) => {
                                        return <div key={index} className="box-shadow bg-white p-3 mt-2 mb-2">
                                            <div className="d-flex flex-row justify-content-center align-items-center ">
                                                <div className="img me-3" style={{ width: 30 }}>
                                                    {
                                                        item.image ?
                                                            <img src={'http://103.36.103.164:8080/self_digital/s_p_r/api/downloadFile/profile/' + item.image} className='w-100 rounded-pill' alt="" />
                                                            :
                                                            <FontAwesomeIcon className='   text-black fs-2' icon={faCircleUser} />
                                                    }
                                                </div>
                                                <div className="review_name flex-fill me-2">{item.name} <span>{item.date}</span></div>
                                                <Stars stars={2} size={8} />
                                            </div>
                                            <div className="comment flex-grow-1 mt-3">
                                                {item.rating_desc}
                                            </div>
                                        </div>
                                    })}
                                </div>
                                <div className="tab-pane fade" id="threestar" role="tabpanel" aria-labelledby="threestar-tab">
                                    {data3.map((item, index) => {
                                        return <div key={index} className="box-shadow bg-white p-3 mt-2 mb-2">
                                            <div className="d-flex flex-row justify-content-center align-items-center ">
                                                <div className="img me-3" style={{ width: 30 }}>
                                                    {
                                                        item.image ?
                                                            <img src={'http://103.36.103.164:8080/self_digital/s_p_r/api/downloadFile/profile/' + item.image} className='w-100 rounded-pill' alt="" />
                                                            :
                                                            <FontAwesomeIcon className='   text-black fs-2' icon={faCircleUser} />
                                                    }
                                                </div>
                                                <div className="review_name flex-fill me-2">{item.name} <span>{item.date}</span></div>
                                                <Stars stars={3} size={8} />
                                            </div>
                                            <div className="comment flex-grow-1 mt-3">
                                                {item.rating_desc}
                                            </div>
                                        </div>
                                    })}
                                </div>
                                <div className="tab-pane fade" id="fourstar" role="tabpanel" aria-labelledby="fourstar-tab">
                                    {data4.map((item, index) => {
                                        return <div key={index} className="box-shadow bg-white p-3 mt-2 mb-2">
                                            <div className="d-flex flex-row justify-content-center align-items-center ">
                                                <div className="img me-3" style={{ width: 30 }}>
                                                    {
                                                        item.image ?
                                                            <img src={'http://103.36.103.164:8080/self_digital/s_p_r/api/downloadFile/profile/' + item.image} className='w-100 rounded-pill' alt="" />
                                                            :
                                                            <FontAwesomeIcon className='   text-black fs-2' icon={faCircleUser} />
                                                    }
                                                </div>
                                                <div className="review_name flex-fill me-2">{item.name} <span>{item.date}</span></div>
                                                <Stars stars={4} size={8} />
                                            </div>
                                            <div className="comment flex-grow-1 mt-3">
                                                {item.rating_desc}
                                            </div>
                                        </div>
                                    })}
                                </div>
                                <div className="tab-pane fade" id="fivestar" role="tabpanel" aria-labelledby="fivestar-tab">
                                    {data5.map((item, index) => {
                                        return <div key={index} className="box-shadow bg-white p-3 mt-2 mb-2">
                                            <div className="d-flex flex-row justify-content-center align-items-center ">
                                                <div className="img me-3" style={{ width: 30 }}>
                                                    {
                                                        item.image ?
                                                            <img src={'http://103.36.103.164:8080/self_digital/s_p_r/api/downloadFile/profile/' + item.image} className='w-100 rounded-pill' alt="" />
                                                            :
                                                            <FontAwesomeIcon className='   text-black fs-2' icon={faCircleUser} />
                                                    }
                                                </div>
                                                <div className="review_name flex-fill me-2">{item.name} <span>{item.date}</span></div>
                                                <Stars stars={5} size={8} />
                                            </div>
                                            <div className="comment flex-grow-1 mt-3">
                                                {item.rating_desc}
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Body;