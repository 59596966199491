
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SideMenu from "../SideMenu";
import { useParams } from "react-router-dom";
import {
  faCamera,
  faChevronRight,
  faCloudDownload,
  faHandPointer,
  faMobile,
  faMobileScreenButton,
  faPaperPlane,
  faShare,
  faShoppingBag,
  faShoppingCart,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { ErrorToast } from "../../utils/Utils";
const SingleProductBodyDetailsNew = () => {
  const shoputils = require("../../utils/shoputils.json");
  const params = useParams();
  const [productid, setProductId] = useState(params.id);
  const [loggedin, setLoggedin] = useState(false);
  const [pdata, setPdata] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentimage, setCurrentImage] = useState(null);
  const [refreshing, setRefreshing] = useState(null);
  const [favoritestatus, setFevoriteStatus] = useState(0);
  const [userstatus, setuserstatus] = useState(null);
  const [ResellerStatus, setResellerStatus] = useState(0);
  const [justforyou, setJustForYou] = useState([]);
  const [nodata, setNodata] = useState(false);
  const [reports, setReports] = useState([]);
  const [inputvisible, setInputvisible] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [data, setData] = useState(false);
  const [reportselected, setreportselected] = useState("");
  const [inputvalue, setinputvalue] = useState("");
  const [isspecialproduct, setisspecialproduct] = useState(false)
  const [showprice, setShowprice] = useState(0);
  const [currentImage, setCurrentImages] = useState('')
  const [qnty, setQnty] = useState(1);
  const [size, setSize] = useState(null)
  const [color, setColor] = useState(null)
  const [totalproductprice, setTotalproductprice] = useState(0)
  const [totalorderamount, setTotalorderamount] = useState(0)
  const [errors, setErrors] = useState(null)
  const [cashback, setCashback] = useState(0)
  const [inputprice, setInputprice] = useState(0)
  const [incometotal, setIncometotal] = useState(0)
  const [incomesingle, setIncomesingle] = useState(0)
  const [sizes, setSizes] = useState([])
  const [colors, setColors] = useState([])
  const [maxprice, setMaxprice] = useState(0);
  const [formname, setformname] = useState('');
  const [formaddress, setformaddress] = useState('');
  const [formcontact, setformcontact] = useState('');
  const [formcontact2, setformcontact2] = useState('01');
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [showconfirmmodal, setshowconfirmmodal] = useState(false);
  const [districtlist, setDistrictlist] = useState([])
  const [district, setdistrict] = useState('');
  useEffect(() => {
    fetchData();
    getData()
  }, []);
  const getData = async () => {
    try {
      const random = Math.random();
      const response = await fetch(shoputils.url + '?random=' + random + '&getdistricts=1&auth=' + random);
      const json = await response.json();
      //console.log(json.districts);
      setDistrictlist(json.districts);
    } catch (error) {
      console.error(error);
    } finally {

    }
  }
  const showError = (msg) => {
    setIsError(true);
    setErrorMsg(msg);
    setTimeout(() => {
      setIsError(false);
    }, 3000);
  };
  const proceedtonext = () => {
    console.log(formname,formaddress,formcontact,formcontact2,district)
    if (formname !== '' && formaddress !== '' && formcontact !== ''  && district !== '') {
      if (formcontact.length == 11) {
        
          if (formcontact !== formcontact2) {
            confirmOrder()
          } else {
            showError('গ্রাহকের কন্টাক্ট নম্বর এবং বিকল্প নম্বর ভিন্ন হতে হবে')
          }
        
      } else {
        showError('কন্টাক্ট নম্বর ১১ সংখ্যার হতে হবে')
      }
    } else {
      showError('সকল তথ্য প্রয়োজন')
    }
  }
  const fetchData = async () => {
    try {
      setLoading(true);
      const random = Math.random();
      const users = localStorage.getItem("userinfo");
      var response;
      if (users) {
        var user = JSON.parse(users);
        response = await fetch(
          shoputils.url +
          "?random=" +
          random +
          "&justforyoumorenew=0&getSingleProductDetails=" +
          productid +
          "&auth=" +
          user.auth
        );
        let token = localStorage.getItem("token");
        if (token) {
          setLoggedin(true);
        }
      } else {
        //console.log(isspecialproduct)
        let url = window.location.href;
        if (url.includes('specialproduct') == true) {
          response = await fetch(
            shoputils.url +
            "?random=" +
            random +
            "&justforyoumorenewspecial=" + 15 + "&getSingleProductDetails=" +
            productid
          );
        } else {
          response = await fetch(
            shoputils.url +
            "?random=" +
            random +
            "&justforyoumorenew=0&getSingleProductDetails=" +
            productid
          );
        }
      }

      const json = await response.json();
      //console.log(json?.justforyou)
      setPdata(json?.singleproduct);
      setFevoriteStatus(json?.fevoritestatus);
      setJustForYou(json?.justforyou);
      setCurrentImage(json?.singleproduct.image1);
      setuserstatus(json?.usertype);
      //setPhoneNumber(json.vendornumber)
      setReports(json?.reports);
      setLoading(false);
      setNodata(true);
      setTotalproductprice(json?.singleproduct.offerprice)
      setTotalorderamount(parseInt(json?.singleproduct.offerprice) + parseInt(json.singleproduct.deliveryCharge.outside))
      setShowprice(json?.singleproduct.offerprice)
      setDeliveryCharge(json.singleproduct.deliveryCharge.outside)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }

    setLoading(false);
  };
  function qntyminus() {
    let oldqnty = qnty;
    let newqnty = oldqnty - 1;
    //alert(newqnty);
    if (newqnty > 0) {
      setQnty(newqnty);
      setTotalproductprice(parseInt(pdata.offerprice) * parseInt(newqnty));
      setIncometotal(incomesingle * newqnty);
      setTotalorderamount(parseInt(pdata.offerprice * newqnty) )
      setCashback(pdata.cashback * newqnty)
    }
  }
  function qntyplus() {
    let oldqnty = qnty;
    let newqnty = oldqnty + 1;
    //alert(newqnty);
    if (newqnty > 0) {
      setQnty(newqnty);
      setTotalproductprice(parseInt(pdata.offerprice) * parseInt(newqnty));
      setIncometotal(incomesingle * newqnty);
      setTotalorderamount(parseInt(pdata.offerprice * newqnty) )
      setCashback(pdata.cashback * newqnty)
    }
  }
  const Stars = (props) => {
    var stars = props.stars;
    if (props.size) {
      var size = props.size;
    } else {
      size = 8;
    }
    if (stars == 5) {
      return (
        <div className="justify-content-center" style={{ flexDirection: "row",display: 'flex', marginRight: 10, marginTop: 5,flexWrap:'no-wrap' }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
        </div>
      );
    } else if (stars == 4) {
      return (
        <div className="justify-content-center" style={{ flexDirection: "row",display: 'flex', marginRight: 10, marginTop: 5,flexWrap:'no-wrap' }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else if (stars == 3) {
      return (
        <div className="justify-content-center" style={{ flexDirection: "row",display: 'flex', marginRight: 10, marginTop: 5,flexWrap:'no-wrap' }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else if (stars == 2) {
      return (
        <div className="justify-content-center" style={{ flexDirection: "row",display: 'flex', marginRight: 10, marginTop: 5,flexWrap:'no-wrap' }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else if (stars == 1) {
      return (
        <div className="justify-content-center" style={{ flexDirection: "row",display: 'flex', marginRight: 10, marginTop: 5,flexWrap:'no-wrap' }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else if (stars == 0) {
      return (
        <div className="justify-content-center" style={{ flexDirection: "row",display: 'flex', marginRight: 10, marginTop: 5,flexWrap:'no-wrap' }}>
          <FontAwesomeIcon className="  mt-1 " icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else {
      return (
        <div className="justify-content-center" style={{ flexDirection: "row",display: 'flex', marginRight: 10, marginTop: 5,flexWrap:'no-wrap' }}>
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    }
  };

  const changeformname = (e) => {
    setformname(e.target.value);
  }
  const changeformaddress = (e) => {
    setformaddress(e.target.value);
  }
  const changeformdistrict = (e) => {
    setdistrict(e.target.value);

    if (pdata.vendorLocation == e.target.value) {
      setDeliveryCharge(pdata.deliveryCharge.inside)
    } else {
      setDeliveryCharge(pdata.deliveryCharge.outside)
    }
  }
  const changeformcontact = (e) => {
    setformcontact(e.target.value);
  }
  const changeformcontact2 = (e) => {
    setformcontact2(e.target.value);
  }
  const confirmOrder = async () => {


    try {
      const random = Math.random();
      let cartdatas = localStorage.getItem('cartdetails');
      let cartdata = JSON.parse(cartdatas);
      let formData = new FormData();

      formData.append('specialorder', 1);
      formData.append('totalproductpricespecial', totalorderamount);
      formData.append('orderamount', totalorderamount);
      formData.append('totalincome', 0);
      formData.append('qnty', qnty);
      formData.append('district', district);
      formData.append('formname', formname);
      formData.append('formaddress', formaddress);
      formData.append('formcontact', formcontact);
      formData.append('formcontact2', formcontact2);
      formData.append('id', productid);
      formData.append('deliverycharge', deliveryCharge);
      //formData.append('paytype', paytype);
      formData.append('inputprice', 0);
      formData.append('size', size);
      formData.append('color', color);
      formData.append('cashback', 0);
      formData.append('currentImage', currentImage);
      const response = await fetch(shoputils.posturl + '?random=' + random + '&specialorder=0', {
        method: 'POST',
        headers: {
          Accept: 'application/json',

        },
        body: formData
      });

      const json = await response.json();

      if (json.success == 1) {
        window.location.href = '/shop/success/' + json.invoice;
      } else {
        showError(json.message)
      }
      setLoading(false)
      setNodata(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }
  //console.log(pdata)
  return (
    <div className="text-center">
      {
        loading == true ?
          <div className="spinner-border align-self-center m-auto mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          :
          <div>
            <div className="bg-success px-2 py-2">
              <div className="container">
                <div className="d-flex justify-content-center">
                  <h1 className="fw-bold text-success text-center text-white pt-3">{pdata?.Name}</h1>
                </div>
              </div>
            </div>
            <div className="container px-4 py-1">



              <div className="col-lg-6  text-center m-auto rounded col-md-6 col-sm-12 col-12 pt-2">
                <div className="modal" id="imagepreview">
                  <div className="modal-dialog modal-md">
                    <div className="modal-content">
                      <FontAwesomeIcon
                        className=" me-2 mt-1 border border-danger text-danger rounded-pill p-2 "
                        data-bs-dismiss="modal"
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          right: 5,
                          top: 5,
                          width: 10,
                          height: 10,
                        }}
                        icon={faTimes}
                      />
                      <div className="modal-body p-0">
                        <img
                          className="w-100"
                          src={shoputils.imageurl + currentimage}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-bs-toggle="modal"
                  data-bs-target="#imagepreview"
                  className=" shop_pd_mainimage_container overflow-hidden p-1 text-center border shadow-sm mb-3 w-100 m-auto">
                  <img
                    className="shop_pd_mainimage w-100"
                    src={shoputils.imageurl + currentimage}
                    alt=""
                  />
                </div>
                <div className="d-flex flex-row justify-content-between">
                  <div className=" ratio ratio-1x1 overflow-hidden">
                    <img
                      className="w-100   border p-1 shadow-sm"
                      onClick={() => setCurrentImage(pdata.image1)}
                      src={shoputils.imageurl + pdata.image1}
                      alt="..."
                    />
                  </div>
                  <div className=" ratio ratio-1x1 overflow-hidden">
                    <img
                      className="w-100   border p-1 shadow-sm"
                      onClick={() => setCurrentImage(pdata.image2)}
                      src={shoputils.imageurl + pdata.image2}
                      alt="..."
                    />
                  </div>
                  <div className=" ratio ratio-1x1 overflow-hidden">
                    <img
                      className="w-100   border p-1 shadow-sm"
                      onClick={() => setCurrentImage(pdata.image3)}
                      src={shoputils.imageurl + pdata.image3}
                      alt="..."
                    />
                  </div>
                  <div className=" ratio ratio-1x1 overflow-hidden">
                    <img
                      className="w-100   border p-1 shadow-sm"
                      onClick={() => setCurrentImage(pdata.image4)}
                      src={shoputils.imageurl + pdata.image4}
                      alt="..."
                    />
                  </div>
                </div>
              </div>

            </div>
            <div className="b-example-divider"></div>
            <div className="container  px-4 py-3">
              <div className="d-flex flex-row justify-content-center">
                <a href="#order_form" className="fontsize fw-bold text-white order_button"><img src="https://stgindustry.com/landingpage/pointing-right.png" /> অর্ডার করতে ক্লিক করুন  </a>
              </div>
            </div>
            <div className="b-example-divider"></div>
            <div className="container  px-4 py-3">
              <div className="d-flex flex-column justify-content-center align-items-center border p-2 rounded" style={{ boxShadow: '0px 0px 10px 0px #000' }}>
                <p className="fontsize pricefont fw-bold text-success my-2">প্রোডাক্ট রেগুলার মূল্য - <span className="text-danger"> <del> {pdata.price} টাকা </del></span></p>
                <p className="fontsize pricefont fw-bold text-success my-2">চলমান অফার মূল্য -  <span className="text-success">  {pdata.offerprice} টাকা  </span></p>
              </div>
            </div>
            <div style={{ backgroundColor: '#CE6D33' }}>
              <div className="container mt-3 pt-3 d-none">
                <div className="d-flex flex-column justify-content-center align-items-center bg-white  rounded m-4 ps-3" style={{ boxShadow: '0px 0px 10px 0px #000' }}>
                  <p className="fontsize fw-bold text-center py-4" style={{ color: '#00531A' }}>উপাদান, কার্যকারিতা, বিশেষত্ব, ব্যবহারবিধি, সতর্কতা</p>
                </div>
              </div>
              <div className="bg-white w-100 d-flex flex-fill d-none">
                <div className="container d-lg-flex flex-row justify-content-center bg-white">
                  <div className="p-3 m-2">
                    <div className="m-2 rounded overflow-hidden">
                      <img className="w-100" src="../images/landingpage_products/" />
                    </div>
                  </div>
                  <div className="p-3 m-2">
                  </div>
                </div>
              </div>

              <div className="container d-flex text-start ">
                <div className="p-3 m-2 bg-white text-start rounded w-100" style={{ boxShadow: '0px 0px 10px 0px #000' }}>
                  <textarea className="w-100 fs-4" defaultValue={
                    pdata.description
                  } style={{ minHeight: 500 }} disabled id="" cols="30" rows="10">

                  </textarea>
                </div>
              </div>
              <div className="container  px-2 py-3">
                <div className="d-flex flex-row justify-content-center">
                  <a href="#order_form" className="fontsize fw-bold text-white order_button"><img src="https://stgindustry.com/landingpage/pointing-right.png" /> অর্ডার করতে ক্লিক করুন  </a>
                </div>
              </div>
            </div>
            <div className="container px-2 py-3">
              <div className="d-flex flex-row justify-content-center">
                <h1 className="btn btn-lg btn-success fontsize hover fw-bold flex-fill" data-bs-target='#refundPolicy' data-bs-toggle="modal" style={{ boxShadow: '0px 0px 10px 0px #000' }}> রিফান্ড পলিসি </h1>
              </div>
            </div>
            <div className="">
              <div className="accordion" id="accordionExample" style={{ background: '#d5d8dc' }}>
                <div className="modal" id="refundPolicy">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div style={{ padding: 20, alignItems: "center" }}>
                          <p className="h3 mb-3">রিফান্ড পলিসি</p>

                          <p className="mb-4">
                            প্রোডাক্ট আনবক্সিং করার সময় মোবাইলে ভিডিও ধারণ করে রাখুন। এতে
                            প্রোডাক্টে কোনো সমস্যা থাকলে সেটা আপনার প্রমাণ হিসেবে কাজ
                            করবে। তারপর প্রোডাক্ট রিটার্ন দিয়ে দিন।{" "}
                          </p>

                          <p className="mb-4">
                            ভেন্ডর যদি প্রোডাক্ট রিটার্ন নিতে অস্বীকার করে অথবা তার
                            প্রোডাক্ট ঠিক আছে দাবী করে তাহলে আপনার ধারণকৃত আনবক্সিং
                            ভিডিওটি প্রমাণ হিসেবে চাওয়া হতে পারে। আনবক্সিং ভিডিও ছাড়া
                            রিটার্ন গ্রহণযোগ্য হবে না।{" "}
                          </p>

                          <p className="mb-4">
                            প্রোডাক্টে সমস্যা প্রমাণিত হলে ১০০% রিটার্ন গ্রহণ করা হবে।
                            এরপর প্রোডাক্ট রিপ্লেসমেন্ট অথবা রিফান্ড, যেকোনো একটি দাবী
                            গ্রহণযোগ্য হবে।
                          </p>

                          <p className="mb-4">
                            গ্যারান্টি/ওয়ারেন্টি যুক্ত প্রোডাক্ট এর ক্ষেত্রে অবশ্যই
                            গ্যারান্টি/ওয়ারেন্টি কার্ড সংরক্ষণ করবেন।
                            গ্যারান্টি/ওয়ারেন্টির মেয়াদ থাকাকালীন সুনির্দিষ্ট শর্তের আলোকে
                            গ্যারান্টি/ওয়ারেন্টি কার্যকর হবে।
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-0">
              <div className="mt-0 pt-2 pb-2" style={{ background: '#00531A' }}>
                <div className="mt-4 mb-4" style={{ background: '#D3E2D1' }}>
                  <div className="container d-flex flex-row justify-content-center">
                    <p className="fs-4 fw-bold pt-5 pb-6 w-75 text-center" style={{ color: '#00531A' }}>
                      শুধুমাত্র সীমিত সময়ের জন্য <span className="text-danger"> 0 টাকা </span> ডিসকাউন্ট অফারে নিতে পারছেন
                      এবং সারাদেশে ফ্রি হোম ডেলিভারী সুযোগ
                    </p>
                  </div>

                  <div className="container  px-2 py-3">
                    <div className="d-flex flex-row justify-content-center">
                      <a href="#order_form" className="fontsize fw-bold text-white order_button"><img src="https://stgindustry.com/landingpage/pointing-right.png" /> অর্ডার করতে ক্লিক করুন  </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="mb-4 pt-2 pb-4" style={{ color: '#CE6D33' }} >
                {/* <div className="container px-2 py-3">
                  <div className="d-flex flex-row justify-content-center">
                    <h1 className="btn btn-lg  bg-white fontsize hover fw-bold flex-fill  text-center" style={{ boxShadow: '0px 0px 10px 0px #000' }}>  কাস্টমারের মন্তব্য </h1>
                  </div>
                </div> */}

                <div className="container">
                  <div className="row">
                    <div className="col-md-4 col-sm-6 col-6">
                      <div className="m-1 border rounded overflow-hidden bg-white " style={{ boxShadow: '0px 0px 10px 0px #000' }}>
                        <div className="card" style={{ height: 150 }}>
                          <div className="card-header">
                            <p>ডেলিভারি সময়</p>
                          </div>
                          <div className="card-body align-items-center justify-content-center d-flex">

                            <p className="text-danger fontsize durationtext">{pdata?.deliveryDuration} দিন</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                      <div className="m-1 border rounded overflow-hidden bg-white" style={{ boxShadow: '0px 0px 10px 0px #000' }}>
                        <div className="card" style={{ height: 150 }}>
                          <div className="card-header  align-items-center justify-content-center d-flex">
                            <p className="selfcolor m-0">রিভিউ দেখুন </p>
                          </div>
                          <div className="card-body fontsize">


                            <Stars stars={pdata?.rating?.overall} />

                          </div>
                        </div>



                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="m-1 mt-2 border rounded overflow-hidden bg-white  " style={{ boxShadow: '0px 0px 10px 0px #000' }}>
                        <div className="card" style={{ height: 150 }}>
                          <div className="card-header">
                            <p>ডেলিভারি চার্জ</p>
                          </div>
                          <div className="card-body  align-items-center justify-content-center d-flex">

                            <p className="text-danger fs-5">
                              {pdata.vendorLocation} {pdata?.deliveryCharge?.inside}{" "}
                              টাকা
                              <br />
                              {pdata.vendorLocation} এর বাইরে{" "}
                              {pdata?.deliveryCharge?.outside} টাকা
                            </p>
                          </div>
                        </div>


                      </div>
                    </div>

                    
                  </div>

                </div>

              </div>
            </div>
            <div className="container d-flex flex-row justify-content-center">


              <div className="card flex-fill rounded-top border border-2 border-success" id="order_form" style={{ borderTop: '10px solid green !important' }}>
                <div className="card-body">

                  <div className="m-2 p-2">
                    <h1 className="text-center fw-bold">অর্ডার কনফার্ম করতে নিচের ফর্মটি পূরণ করুন</h1>
                    <h6 className="fw-bold text-center "> (আপনার সমস্যার সমাধান চাইলে তবেই অর্ডার করুন, না বুঝে অর্ডার দিয়ে কেন্সেল করলে ডেলিভারী চার্জ দিতে হবে) </h6>
                  </div>
                  <div className="border p-4 w-lg-50 w-sm-100 rounded">
                    <div className="d-flex flex-start align-items-center">
                      <input className="form-check-input mt-0 me-2 border bg-warning" type="checkbox" defaultValue="" aria-label="Checkbox" />
                      <p className="fw-bold text-center"> {pdata.Name}</p>

                    </div>


                    <div className="d-flex flex-start justify-content-between">
                      <div className="btn-group w-25 me-3" role="group" aria-label="Basic mixed styles example">
                        <button type="button" onClick={qntyminus} className="btn btn-danger decrement-btn btn-lg w-50">-</button>
                        <p className="form-control w-75" style={{ lineHeight: 2 }}>{qnty}</p>
                        <button type="button" onClick={qntyplus} className="btn btn-success increment-btn btn-lg w-50">+</button>
                      </div>
                      <p style={{ marginLeft: 20 }} className="mt-2 ml-2 pl-4 multiplyproduct" data-amount="1080"> ৳ {Math.round(totalproductprice)} </p>

                    </div>
                  </div>
                  {isError == true ? ErrorToast(errorMsg) : null}
                  <div className="d-lg-flex justify-content-around">
                    <div className="p-3 m-2 flex-fill shadow text-start">
                      <h4>Billing Details</h4>
                      <form id="checkout_form" action="#">
                        <div className="form-group mt-2">
                          <label  >গ্রাহকের নাম</label>
                          <span style={{ color: 'red', fontWeight: 'bold', float: 'right', marginTop: 5 }}>*</span>
                          <input type="text" placeholder="নাম" required="required" onChange={changeformname} name="name" className="form-control border-success" />
                        </div>

                        <div className="form-group mt-2">
                          <label  >গ্রাহকের ফোন নম্বর</label>
                          <span style={{ color: 'red', fontWeight: 'bold', float: 'right', marginTop: 5 }}>*</span>
                          <input type="text" placeholder="ফোন নম্বর" onChange={changeformcontact} id="mobile1" required="required" name="number" className="form-control border-success" />
                        </div>
                        {/* <div className="form-group mt-2">
                          <label  >বিকল্প ফোন নম্বর</label>
                          <span style={{ color: 'red', fontWeight: 'bold', float: 'right', marginTop: 5 }}>*</span>
                          <input type="text" placeholder="বিকল্প ফোন নম্বর" id="mobile2" onChange={changeformcontact2} required="required" name="number_other" className="form-control ch_mobile border-success" />
                          <div id="show" style={{ color: 'red', fontWeight: 'bold' }}></div>
                        </div> */}

                        <div className="form-group mt-2">
                          <label  >গ্রাহকের জেলা</label>
                          <span style={{ color: 'red', fontWeight: 'bold', float: 'right', marginTop: 5 }}>*</span>
                          <select className="form-select" value={district} onChange={changeformdistrict} placeholder='গ্রাহকের জেলা' id="inputGroupSelect03" aria-label="Example select with button addon">
                            <option selected>নির্বাচন করুন...</option>
                            {
                              districtlist.length > 0 ?
                                districtlist.map((item, index) => {
                                  return <option key={index} value={item.name}>{item.name}</option>
                                })
                                : null
                            }

                          </select>
                        </div>

                        <div className="form-group mt-2">
                          <label  >গ্রাহকের সম্পূর্ণ ঠিকানা</label>
                          <span style={{ color: 'red', fontWeight: 'bold', float: 'right', marginTop: 5 }}>*</span>
                          <input type="text" placeholder="সম্পূর্ণ ঠিকানা" required="required" onChange={changeformaddress} name="address" className="form-control border-success" />
                        </div>


                        <div className="form-group mt-2">
                          <label>
                            <input type="checkbox" checked="checked" required="required" style={{ marginRight: 5 }} /> Return &amp; Refund Policy
                          </label>
                        </div>

                      </form>
                    </div>

                    <div className="p-3 m-2 flex-fill shadow text-start">
                      <h4>Your Order</h4>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><img className="rounded pr-2" height="50px" width="60px" src={shoputils.imageurl + currentimage} /> {pdata.Name} </td>
                            <td><input style={{ width: 30 }} type="text" name="1" className="qty-input text-center" disabled="" value={qnty} defaultValue={qnty} /> * <span className="mt-2 ml-2 pl-4"> ৳ {showprice} </span> </td>
                          </tr>
                          <tr>
                            <td>সর্বমোট অর্ডার মূল্য</td>
                            <td className="text-end"><span className="mt-2 ml-2 pl-4 multiplyproduct" data-amount="1080"> ৳ {totalproductprice} </span></td>
                          </tr>
                          <tr className="border-bottom-2 border-secondary">
                            <td>ডেলিভারি চার্জ</td>
                            <td  className="text-end">৳ {deliveryCharge}</td>
                          </tr>
                          <tr className="border-bottom-2 border-secondary">
                            <td>অগ্রিম পরিশোধ</td>
                            <td  className="text-end">৳ {deliveryCharge}</td>
                          </tr>
                          <tr className="border-bottom-1">
                            <td className="fw-bold">ক্যাশ অন ডেলিভারিতে পরিশোধ করবেন</td>
                            <td className="fw-bold text-end" ><span className="mt-2 ml-2 pl-4 total_amount"> ৳ {totalproductprice} </span></td>
                          </tr>
                        </tbody>
                      </table>


                      <div className="clearfix"></div>
                      <div className="error_msg"></div>
                      <div className="d-flex justify-content-center mt-2">
                        <button onClick={proceedtonext} className="btn btn-lg btn-success fs-1" id="submit_status"><img src="https://stgindustry.com/landingpage/pointing-right.png" /> অর্ডার  করুন </button>
                      </div>
                    </div>
                  </div >
                </div >
              </div >
              <div className="clearfix"></div>
            </div >
            {
              showconfirmmodal == true ?
                <div className="modal d-block" id="confirmmodal">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body" >
                        {/* onClick={() => confirmOrder()} */}
                        <p style={{ fontSize: 16, textAlign: 'justify', marginBottom: 10 }}>আপনি কি অর্ডার টি কনফার্ম করতে চান ? </p>
                        <div className="d-flex flex-row justify-content-between" >
                          <div data-bs-dismiss="modal" className="btn btn-danger clearform flex-fill me-2" >
                            <p className="m-0" >বাতিল করুন</p>
                          </div>
                          <div onClick={() => confirmOrder()} className="btn btn-success flex-fill">
                            <p className="m-0" >কনফার্ম করুন</p>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                : null
            }
            <div className="container pt-4">
            <div className="d-flex justify-content-center">
              <h1 className="fw-bold"> প্রয়োজনে যোগাযেগ করুন ️</h1> 
            </div>

            <div className="d-flex justify-content-center">
              <h4 className="fw-bold btn btn-primary"><a className="text-white text-decoration-none" href="https://m.me/161197107087529">ম্যাসেন্জারে ম্যাসেজ করুন ️</a></h4> 
            </div>
            <div className="d-flex justify-content-center">
              <h4 className="fw-bold btn btn-success"><a className="text-white text-decoration-none" href="https://wa.me/+8809647020202"> হোয়াটসঅ্যাপ করুন- 01968 - 153605 ️</a></h4> 
            </div>
              <div className="d-flex justify-content-center">
              <h4 className="fw-bold btn btn-danger"><a className="text-white text-decoration-none" href="tel:01968153605"> কল করুন- 01968 - 153605</a></h4> 
            </div>
            </div>
            <div className="d-flex flex-row justify-content-center mt-2" style={{ backgroundColor: '#ff4c10' }}>
              <p className="text-white mt-2 mb-2">© 2024 self-shopping.com. All rights reserved.</p>
            </div>
          </div >
      }
    </div >
  )

}


export default SingleProductBodyDetailsNew

