
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../SideMenu';
import { ErrorToast } from '../../utils/Utils';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
const Body = () => {
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('')
    const [flightdisplay, setFlightDisplay] = useState("flex");
    const [omrahdisplay, setomrahdisplay] = useState("none");
    const [hajjdisplay, sethajjdisplay] = useState("none");
    const [showFromDate, setShowFromDate] = useState(false);
    const [showFromDateReturn, setShowFromDateReturn] = useState(false);
    const [fromcity, setFromcity] = useState('Dhaka');
    const [fromcityairport, setFromcityairport] = useState('DAC (Dhaka International Airport)');
    const [tocity, setTocity] = useState('Chittagong');
    const [tocityairport, setTocityairport] = useState('CGP (Shah Amanat International Airport)');
    const [flightbtn, setflightbtn] = useState("#fff");
    const [flighttext, setflighttext] = useState("yellow");
    const [omrahbtn, setomrahbtn] = useState("#ddd");
    const [omrahtext, setomrahtext] = useState("#fff");
    const [hajjbtn, sethajjbtn] = useState("#ddd");
    const [hajjtext, sethajjtext] = useState("#fff");
    const [triptype, settriptype] = useState('trip1');
    const [triptypename, settriptypename] = useState('One Way')
    const [triptype1, settriptype1] = useState("orange");
    const [triptype2, settriptype2] = useState("#fff");
    const [triptype3, settriptype3] = useState("#fff");
    const [triptypetext1, settriptypetext1] = useState("#000");
    const [triptypetext2, settriptypetext2] = useState("#000");
    const [triptypetext3, settriptypetext3] = useState("#000");
    const [omrahtype1, setOmrahtype1] = useState('orange');
    const [omrahtype2, setOmrahtype2] = useState("#fff");
    const [omrahtype3, setOmrahtype3] = useState("#fff");
    const [omrahtypetext1, setOmrahtypetext1] = useState('#000');
    const [omrahtypetext2, setOmrahtypetext2] = useState("#000");
    const [omrahtypetext3, setOmrahtypetext3] = useState("#000");
    const [hajjtype1, sethajjtype1] = useState('orange');
    const [hajjtype2, sethajjtype2] = useState('#fff');
    const [hajjtype3, sethajjtype3] = useState('#fff');
    const [hajjtypetext1, sethajjtypetext1] = useState('#000');
    const [hajjtypetext2, sethajjtypetext2] = useState('#000');
    const [hajjtypetext3, sethajjtypetext3] = useState('#000');
    const [classtype, setClasstype] = useState('ইকোনোমি');
    const [modalvisiblecities, setModalVisibleCities] = useState(false)
    const [modalvisiblecitiesto, setModalVisibleCitiesTo] = useState(false)
    const [modalVisiblePreview, setModalVisiblePreview] = useState(false)
    const [journeydate, setJourneyDate] = useState("Select");
    const [returndate, setReturndate] = useState("Select");
    const [travellers, setTravellers] = useState(1);
    const [omraheconomy, setomraheconomy] = useState('flex')
    const [omrahbusiness, setomrahbusiness] = useState('none')
    const [omrahvip, setomrahvip] = useState('none')
    const [hajjeconomy, sethajjeconomy] = useState('flex')
    const [hajjbusiness, sethajjbusiness] = useState('none')
    const [hajjvip, sethajjvip] = useState('none')
    const [omrahname, setomrahname] = useState('');
    const [omrahaddress, setomrahaddress] = useState('');
    const [hajjname, sethajjname] = useState('');
    const [returndisplay, setreturndisplay] = useState('none');
    const [hajjaddress, sethajjaddress] = useState('');
    const [postitem, setPostItem] = useState([])
    const [selectedOmrahPackage, setSelectedOmrahPackage] = useState('economy')
    const [selectedhajjPackage, setSelectedhajjPackage] = useState('economy')
    const [modalVisibleHajj, setModalVisibleHajj] = useState(false)
    const [modalVisibleOmrah, setModalVisibleOmrah] = useState(false)
    const [travellercount, setTravellersCount] = useState([
        { 'id': 1 },
        { 'id': 2 },
        { 'id': 3 },
        { 'id': 4 },
        { 'id': 5 },
        { 'id': 6 },
        { 'id': 7 },
        { 'id': 8 },
        { 'id': 9 },
    ])
    const [classtypes, setClasstypes] = useState([
        { 'id': 1, 'type': 'ইকোনোমি' },
        { 'id': 2, 'type': 'বিজনেস' },
        { 'id': 3, 'type': 'প্রিমিয়াম' },
        { 'id': 4, 'type': 'ফার্স্ট ক্লাস' },
    ])

    const [packages, setPackages] = useState([])
    const [tips, setTips] = useState([]);
    const [blogpost, setBlogPost] = useState([])
    const [cities, setCities] = useState([])
    const shoputils = require('../../utils/shoputils.json');
    const [nodata, setNodata] = useState(false)

    const [loading, setLoading] = useState(false)
    const [notice, setNotice] = useState(null);
    const [noticeVisible, setNoticeVisible] = useState(false);
    const [whatsapplink, setWhatsAppLink] = useState('');
    const [fromcitylist, setfromcitylist] = useState('flex')
    const [fromcityadd, setfromcityadd] = useState('none')
    const [customairport, setCustomairport] = useState('');
    const [tocitylist, settocitylist] = useState('flex')
    const [tocityadd, settocityadd] = useState('none')
    const [customairportto, setCustomairportto] = useState('');
    useEffect(() => {
        if (nodata == false) {
            getData();

        }
    },[])




    const getData = async () => {
        try {
            const random = Math.random();
            const response = await fetch(shoputils.airticket + '?random=' + random + '&getCities=1&getPosts=1&getTips=1&getPackages=1');
            const json = await response.json();
            if (json.success == 1) {
                setCities(json.cities);
                setBlogPost(json.posts);
                //console.log(json.posts);
                setTips(json.tips);
                setPackages(json.packages)
                setNodata(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }
    const ChangeTravellers = (e) => {
        setTravellers(e.target.value)
    }
    const ChangeCustomAirport = (e) => {
        setCustomairport(e.target.value)
    }
    const SelectPart = (display) => {
        if (display == 'flightdisplay') {
            setFlightDisplay('flex');
            setomrahdisplay('none');
            sethajjdisplay('none');
            setflightbtn("#fff")
            setomrahbtn("#ddd")
            sethajjbtn("#ddd")
            setflighttext("yellow")
            setomrahtext("#fff")
            sethajjtext("#fff")
        } else if (display == 'omrahdisplay') {

            setFlightDisplay('none');
            setomrahdisplay('flex');
            sethajjdisplay('none');
            setflightbtn("#ddd")
            setomrahbtn("#fff")
            sethajjbtn("#ddd")
            setflighttext("#fff")
            setomrahtext("yellow")
            sethajjtext("#fff")
        } else if (display == 'hajjdisplay') {

            setFlightDisplay('none');
            setomrahdisplay('none');
            sethajjdisplay('flex');
            setflightbtn("#ddd")
            setomrahbtn("#ddd")
            sethajjbtn("#fff")
            setflighttext("#fff")
            setomrahtext("#fff")
            sethajjtext("yellow")

        }
    }
    const selectTrip = (tryptype) => {
        settriptype(tryptype)
        if (tryptype == 'trip1') {
            settriptypename('One Way')
            settriptype1("orange")
            settriptype2("#fff")
            settriptype3("#fff")
            settriptypetext1("#fff")
            settriptypetext2("#000")
            settriptypetext3("#000")
            setreturndisplay('none')
        } else if (tryptype == 'trip2') {
            settriptypename('Round Trip')
            settriptype1("#fff")
            settriptype2("orange")
            settriptype3("#fff")
            settriptypetext1("#000")
            settriptypetext2("#fff")
            settriptypetext3("#000")
            setreturndisplay('flex')
        } else if (tryptype == 'trip3') {
            settriptypename('Multi City')
            settriptype1("#fff")
            settriptype2("#fff")
            settriptype3("orange")
            settriptypetext1("#000")
            settriptypetext2("#000")
            settriptypetext3("#fff")
            setreturndisplay('none')
        }
    }
    const selectOmrahtype = (type) => {

        if (type == 'omrahtype1') {
            setOmrahtype1("orange")
            setOmrahtype2("#fff")
            setOmrahtype3("#fff")
            setOmrahtypetext1("#000")
            setOmrahtypetext2("#000")
            setOmrahtypetext3("#000")
            setomraheconomy("flex")
            setomrahbusiness("none")
            setomrahvip("none")
            setSelectedOmrahPackage('economy')
        } else if (type == 'omrahtype2') {
            setOmrahtype1("#fff")
            setOmrahtype2("orange")
            setOmrahtype3("#fff")
            setOmrahtypetext1("#000")
            setOmrahtypetext2("#000")
            setOmrahtypetext3("#000")
            setomraheconomy("none")
            setomrahbusiness("flex")
            setomrahvip("none")
            setSelectedOmrahPackage('business')
        } else if (type == 'omrahtype3') {
            setOmrahtype1("#fff")
            setOmrahtype2("#fff")
            setOmrahtype3("orange")
            setOmrahtypetext1("#000")
            setOmrahtypetext2("#000")
            setOmrahtypetext3("#000")
            setomraheconomy("none")
            setomrahbusiness("none")
            setomrahvip("flex")
            setSelectedOmrahPackage('vip')
        }
    }

    const selectedFromCity = (id, name, airport) => {
        setFromcity(name)
        setFromcityairport(airport)
        setModalVisibleCities(false)
    }
    const selectedToCity = (id, name, airport) => {
        setTocity(name)
        setTocityairport(airport)
        setModalVisibleCitiesTo(false)
    }
    const showFromDatepicker = () => {
        setShowFromDate(true);
    };
    const showFromDatepickerReturn = () => {
        setShowFromDateReturn(true);
    };
    const onChangeFromDate = (e) => {
        const currentDate = e.target.value;
        //setShowFromDate(false);
        setJourneyDate(currentDate)
        //dayCalculate(currentDate, toDate);
    };
    const onChangeReturnDate = (event, selectedDate) => {
        const currentDate = selectedDate;
        setShowFromDateReturn(false);
        setReturndate(currentDate)
        //dayCalculate(currentDate, toDate);
    };
    const selectTripClass = (e) => {
        setClasstype(e.target.value);

    }
    const checkForm = () => {
        if (journeydate == 'Select') {
            showError('সকল তথ্য প্রয়োজন ')
            //alert(fromcity + ' ' + tocity + ' ' + triptype + ' ' + classtype + ' ' + journeydate + ' ' + travellers)
        } else {
            //alert(fromcity + ' ' + tocity + ' ' + triptype + ' ' + classtype + ' ' + journeydate + ' ' + travellers)
            setModalVisiblePreview(true)
        }

    }
    const showError = (msg) => {
        setIsError(true)
        setErrorMsg(msg)
        setTimeout(() => {
            setIsError(false)
        }, 3000);
    }
    const submitFlightRequest = async () => {
        try {
            const users = localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            const random = Math.random();
            let formData = new FormData();

            formData.append('auth', user.auth);
            formData.append('webpost', 1);
            formData.append('from', fromcityairport);
            formData.append('to', tocityairport);
            formData.append('travellercount', travellers);
            formData.append('triptype', triptype);
            formData.append('tripclass', classtype);
            formData.append('date', journeydate);
            formData.append('return', returndate);

            const response = await fetch(shoputils.airticket + '?random=' + random,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',

                    },
                    //upload images
                    body: formData
                });

            const json = await response.json();

            if (json.success == 1) {
                showError(json.message2)
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                setTravellers(0)
                settriptypename('One Way')
                settriptype('trip1')
                setClasstype('select')
                setJourneyDate('select')
            } else {

                setModalVisiblePreview(false)
                showError(json.message)
            }
        } catch (error) {
            console.error(error);

        } finally {
            //setModalVisiblePreview(false)
        }
    }
    const submitOmrahRequest = async () => {
        try {
            const users = localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            const random = Math.random();
            let formData = new FormData();

            formData.append('auth', user.auth);
            formData.append('webpost', 1);
            formData.append('omrahname', omrahname);
            formData.append('omrahaddress', omrahaddress);
            const response = await fetch(shoputils.airticket + '?random=' + random,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',

                    },
                    //upload images
                    body: formData
                });

            const json = await response.json();

            if (json.success == 1) {

                showError(json.message2)
                setModalVisibleOmrah(false)
            } else {

                showError(json.message)
            }
        } catch (error) {
            console.error(error);

        } finally {
            //setModalVisiblehajj(false)
            //setModalVisible(false)
        }
    }
    const submithajjRequest = async () => {
        try {
            const users = localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            const random = Math.random();
            let formData = new FormData();

            formData.append('auth', user.auth);
            formData.append('webpost', 1);
            formData.append('hajjname', hajjname);
            formData.append('hajjaddress', hajjaddress);
            const response = await fetch(shoputils.airticket + '?random=' + random,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',

                    },
                    //upload images
                    body: formData
                });

            const json = await response.json();

            if (json.success == 1) {

                showError(json.message2)
                setModalVisibleHajj(false)
            } else {

                showError(json.message)
            }
        } catch (error) {
            console.error(error);

        } finally {
            //setModalVisiblehajj(false)
            //setModalVisible(false)
        }
    }
    const hajjnamechange = (e) => {
        sethajjname(e.target.value)
    }
    const hajjaddresschange = (e) => {
        sethajjaddress(e.target.value)
    }
    const omrahnamechange = (e) => {
        setomrahname(e.target.value)
    }
    const omrahaddresschange = (e) => {
        setomrahaddress(e.target.value)
    }
    const selectCustomCity = (type) => {

        if (type == 'from') {
            setfromcitylist('none')
            setfromcityadd('block')
        } else if (type == 'to') {
            settocitylist('none')
            settocityadd('block')
        }
    }
    return (
        <div className="container-fluid">
            <div className="container-fluid  bg-lightgray">
                <div className="container  pt-3 pb-3">
                    <div className="row ">
                        <SideMenu />
                        <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                            {
                                isError == true ?
                                    ErrorToast(errorMsg)
                                    : null
                            }
                            {/* modals */}
                            {
                                modalVisiblePreview == true ?
                                    <>

                                        <div className="modal d-block">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title">রিকোয়েস্ট প্রিভিউ</h5>
                                                        <button type="button" class="btn-close" onClick={() => setModalVisiblePreview(false)} aria-label="Close"></button>
                                                    </div>

                                                    <div className="modal-body">
                                                        {
                                                            isError == true ?
                                                                ErrorToast(errorMsg)
                                                                : null
                                                        }
                                                        <div style={{ marginBottom: 10 }}>
                                                            <p style={{ color: '#999' }}>কোন এয়ারপোর্ট থেকে</p>
                                                            <p>{fromcity}</p>
                                                            <p>{fromcityairport}</p>
                                                        </div>
                                                        <div style={{ marginBottom: 10 }}>
                                                            <p style={{ color: '#999' }}>কোন এয়ারপোর্টে</p>
                                                            <p>{tocity}</p>
                                                            <p>{tocityairport}</p>
                                                        </div>
                                                        <div style={{ marginBottom: 10 }}>
                                                            <p style={{ color: '#999' }}>ভ্রমণ এর তারিখ</p>
                                                            <p>{journeydate}</p>
                                                        </div>
                                                        <div style={{ marginBottom: 10 }}>
                                                            <p style={{ color: '#999' }}>ভ্রমণ এর ধরণ</p>
                                                            <p>{triptypename}</p>
                                                        </div>
                                                        <div style={{ marginBottom: 10 }}>
                                                            <p style={{ color: '#999' }}>টিকেটের ধরণ</p>
                                                            <p>{classtype}</p>
                                                        </div>
                                                        <div style={{ marginBottom: 10 }}>
                                                            <p style={{ color: '#999' }}>ভ্রমণকারীর সংখ্যা</p>
                                                            <p>{travellers} জন</p>
                                                        </div>
                                                        <div onClick={() => submitFlightRequest()} className="selfbgcolor btn w-100">
                                                            <p style={{ color: '#fff', fontSize: 18 }}>আবেদন করুন</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-backdrop show"></div>
                                    </>
                                    : null
                            }
                            {
                                modalVisibleHajj == true ?
                                    <>
                                        <div className="modal d-block ">
                                            <div className="modal-dialog">
                                                <div className="modal-content mt-5">
                                                    <div class="modal-header">
                                                        <h6 class="modal-title me-3">বিস্তারিত জানতে আপনার তথ্য দিয়ে আবেদন করুন</h6>
                                                        <button type="button" class="btn-close" onClick={() => setModalVisibleHajj(false)} aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div style={{ width: '100%' }}>
                                                            <input
                                                                placeholder='গ্রাহকের নাম'
                                                                defaultValue={hajjname}
                                                                className='form-control mt-2'
                                                                onChange={hajjnamechange}

                                                            />

                                                            <input
                                                                placeholder='গ্রাহকের ঠিকানা'
                                                                defaultValue={hajjaddress}
                                                                className='form-control mt-2'
                                                                onChange={hajjaddresschange}


                                                            />
                                                            <div onClick={() => submithajjRequest()} className="btn btn-danger w-100 mt-2" >
                                                                <p style={{ color: '#fff', fontSize: 18 }}>আবেদন করুন</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-backdrop show"></div>
                                    </>
                                    : null
                            }
                            {
                                modalVisibleOmrah == true ?
                                    <>
                                        <div className="modal d-block ">
                                            <div className="modal-dialog">
                                                <div className="modal-content mt-5">
                                                    <div class="modal-header">
                                                        <h6 class="modal-title me-3">বিস্তারিত জানতে আপনার তথ্য দিয়ে আবেদন করুন</h6>
                                                        <button type="button" class="btn-close" onClick={() => setModalVisibleOmrah(false)} aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div style={{ width: '100%' }}>
                                                            <input
                                                                placeholder='গ্রাহকের নাম'
                                                                defaultValue={omrahname}
                                                                className='form-control mt-2'
                                                                onChange={omrahnamechange}

                                                            />

                                                            <input
                                                                placeholder='গ্রাহকের ঠিকানা'
                                                                defaultValue={omrahaddress}
                                                                className='form-control mt-2'
                                                                onChange={omrahaddresschange}


                                                            />
                                                            <div onClick={() => submitOmrahRequest()} className="btn btn-danger w-100 mt-2" >
                                                                <p style={{ color: '#fff', fontSize: 18 }}>আবেদন করুন</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-backdrop show"></div>
                                    </>
                                    : null
                            }
                            {
                                modalvisiblecities == true ?
                                    <div className="modal d-block">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-body">

                                                    <p style={{ marginBottom: 20 }}>
                                                        সিটি সিলেক্ট করুন
                                                    </p>

                                                    <div className='list-group' style={{ width: '100%', display: fromcitylist }}>
                                                        {cities.map((item, index) => {
                                                            return <div className='list-group-item mt-2 border rounded p-2' onClick={() => selectedFromCity(item.id, item.name, item.airport)} key={index} >
                                                                <p>{item.name}</p>
                                                                <p>{item.airport}</p>
                                                            </div>
                                                        })}
                                                        <div className='list-group-item border rounded p-2 mt-2' onClick={() => selectCustomCity('from')} key={cities.length} >
                                                            <p>Other Airport</p>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: fromcityadd, width: '100%' }}>
                                                        <p className='mt-3 text-secondary'>আপনার নিকটস্থ এয়ারপোর্টের নাম লিখুন</p>
                                                        <input
                                                            type="text"
                                                            value={customairport}
                                                            onChange={ChangeCustomAirport}
                                                            placeholder="এয়ারপোর্টের নাম"
                                                            className='form-control'
                                                        />
                                                        <div onClick={() => {
                                                            setFromcity(customairport)
                                                            setFromcityairport(customairport);
                                                            setModalVisibleCities(false)
                                                            setfromcitylist('block')
                                                            setfromcityadd('none')
                                                        }} style={{ borderRadius: 5, alignItems: 'center', marginTop: 10, backgroundColor: 'darkblue', padding: 10, width: '100%' }}>
                                                            <p style={{ color: '#fff' }}>সাবমিট করুন</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                modalvisiblecitiesto == true ?
                                    <div className="modal d-block">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-body">

                                                    <p style={{ marginBottom: 20 }}>
                                                        সিটি সিলেক্ট করুন
                                                    </p>

                                                    <div className='list-group' style={{ width: '100%', display: tocitylist }}>
                                                        {cities.map((item, index) => {
                                                            return <div className='list-group-item mt-2 border rounded p-2' onClick={() => selectedToCity(item.id, item.name, item.airport)} key={index} >
                                                                <p>{item.name}</p>
                                                                <p>{item.airport}</p>
                                                            </div>
                                                        })}
                                                        <div className='list-group-item border rounded p-2 mt-2' onClick={() => selectCustomCity('to')} key={cities.length} >
                                                            <p>Other Airport</p>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: tocityadd, width: '100%' }}>
                                                        <p className='mt-3 text-secondary'>আপনার নিকটস্থ এয়ারপোর্টের নাম লিখুন</p>
                                                        <input
                                                            type="text"
                                                            value={customairport}
                                                            onChange={ChangeCustomAirport}
                                                            placeholder="এয়ারপোর্টের নাম"
                                                            className='form-control'
                                                        />
                                                        <div onClick={() => {
                                                            setTocity(customairport)
                                                            setTocityairport(customairport);
                                                            setModalVisibleCitiesTo(false)
                                                            settocitylist('block')
                                                            settocityadd('none')
                                                        }} style={{ borderRadius: 5, alignItems: 'center', marginTop: 10, backgroundColor: 'darkblue', padding: 10, width: '100%' }}>
                                                            <p style={{ color: '#fff' }}>সাবমিট করুন</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            {/* modals */}
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active fs-5" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">এয়ার টিকেট</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link fs-5" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">হজ্জ্ব</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link fs-5" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">ওমরাহ </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <img src={require('../../assets/banner/air-ticket-banner.png')} className="rounded w-100" alt="" />


                                    <ul class="nav nav-tabs mt-3 d-flex flex-row justify-content-between" id="myTab" role="tablist">
                                        <li class="nav-item flex-fill" role="presentation">
                                            <button class="nav-link w-100 active" id="oneway-tab" data-bs-toggle="tab" data-bs-target="#oneway-tab-pane" type="button" role="tab" aria-controls="oneway-tab-pane" aria-selected="true">ওয়ান ওয়ে</button>
                                        </li>
                                        <li class="nav-item flex-fill" role="presentation">
                                            <button class="nav-link w-100" id="round-tab" data-bs-toggle="tab" data-bs-target="#round-tab-pane" type="button" role="tab" aria-controls="round-tab-pane" aria-selected="false">রাউন্ড ট্রিপ</button>
                                        </li>
                                        <li class="nav-item flex-fill" role="presentation">
                                            <button class="nav-link w-100" id="multi-tab" data-bs-toggle="tab" data-bs-target="#multi-tab-pane" type="button" role="tab" aria-controls="multi-tab-pane" aria-selected="false">মাল্টি সিটি</button>
                                        </li>

                                    </ul>
                                    <div className="d-flex flex-row justify-content-between pt-3 pb-3">
                                        <div onClick={() => setModalVisibleCities(true)} className="bg-white flex-fill me-3 p-2 rounded overflow-hidden">
                                            <p className="text-secondary">কোন এয়ারপোর্ট থেকে</p>
                                            <p className="fw-bold">
                                                {fromcity}
                                            </p>
                                            <p className='onlinetext'>{fromcityairport}</p>
                                        </div>
                                        <div onClick={() => setModalVisibleCitiesTo(true)} className="bg-white flex-fill p-2 rounded overflow-hidden">
                                            <p className="text-secondary">কোন এয়ারপোর্টে</p>
                                            <p className="fw-bold">
                                                {tocity}
                                            </p>
                                            <p className='onlinetext'>{tocityairport}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between  pb-3">
                                        <div className="bg-white flex-fill me-3 p-2 rounded overflow-hidden">
                                            <p className="text-dark">ভ্রমণের তারিক</p>
                                            <input type="date" onChange={onChangeFromDate} defaultValue={journeydate} className='form-control' />
                                        </div>
                                        <div className="bg-white flex-fill p-2 rounded overflow-hidden">
                                            <p className="text-secondary">টিকেটের ধরণ </p>
                                            <select name="" onChange={selectTripClass} className='form-control' id="">
                                                <option value="ইকোনমি">ইকোনমি</option>
                                                <option value="বিজনেস">বিজনেস</option>
                                                <option value="প্রিমিয়াম">প্রিমিয়াম</option>
                                                <option value="ফার্স্টক্লাস">ফার্স্টক্লাস</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between  pb-3">
                                        <div className="bg-white flex-fill p-2 rounded overflow-hidden">
                                            <p className="text-secondary">ভ্রমণকারীর সংখ্যা </p>
                                            <select name="" onChange={ChangeTravellers} className='form-control' id="">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div onClick={checkForm} className="btn selfbgcolor text-white w-100">
                                        সাবমিট করুন
                                    </div>
                                    <a href='/airticketHistory' className="btn btn-primary mt-3 text-white w-100">
                                        আপনার রিকোয়েস্ট সমূহ দেখুন
                                    </a>
                                </div>
                                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <img src={require('../../assets/banner/hajj-banner.png')} className="rounded w-100" alt="" />
                                    <ul class="nav nav-tabs mt-3 d-flex flex-row justify-content-between" id="myTab" role="tablist">
                                        <li class="nav-item flex-fill" role="presentation">
                                            <button class="nav-link w-100 active" id="hajjeco-tab" data-bs-toggle="tab" data-bs-target="#hajjeco-tab-pane" type="button" role="tab" aria-controls="hajjeco-tab-pane" aria-selected="true">ইকোনমি</button>
                                        </li>
                                        <li class="nav-item flex-fill" role="presentation">
                                            <button class="nav-link w-100" id="hajjbis-tab" data-bs-toggle="tab" data-bs-target="#hajjbis-tab-pane" type="button" role="tab" aria-controls="hajjbis-tab-pane" aria-selected="false">বিজনেস</button>
                                        </li>
                                        <li class="nav-item flex-fill" role="presentation">
                                            <button class="nav-link w-100" id="hajjvip-tab" data-bs-toggle="tab" data-bs-target="#hajjvip-tab-pane" type="button" role="tab" aria-controls="hajjvip-tab-pane" aria-selected="false">ভি এই পি</button>
                                        </li>
                                    </ul>
                                    {
                                        packages.length > 0 ?
                                            <div class="tab-content" id="myTabContent">
                                                <div class="tab-pane fade show active" id="hajjeco-tab-pane" role="tabpanel" aria-labelledby="hajjeco-tab" tabindex="0">
                                                    <p className="text-white p-3 selfbgcolor text-center">{packages[1].hajj[0].title}</p>
                                                    <textarea className="w-100 border-0 p-3" disabled style={{ minHeight: 600 }}>{packages[1].hajj[0].text}</textarea>
                                                    <div
                                                        onClick={() => {
                                                            setSelectedhajjPackage('Economy')
                                                            setModalVisibleHajj(true)
                                                        }}
                                                        className='btn bg-danger w-100 '>
                                                        <p style={{ color: '#fff', fontSize: 18 }}>মূল্য জিজ্ঞাসা করুন</p>
                                                        
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="hajjbis-tab-pane" role="tabpanel" aria-labelledby="hajjbis-tab" tabindex="0">
                                                    <p className="text-white p-3 selfbgcolor text-center">{packages[1].hajj[1].title}</p>
                                                    <textarea className="w-100 border-0 p-3" disabled style={{ minHeight: 600 }}>{packages[1].hajj[1].text}</textarea>
                                                    <div
                                                        onClick={() => {
                                                            setSelectedhajjPackage('Business')
                                                            setModalVisibleHajj(true)
                                                        }}
                                                        className='btn bg-danger w-100 '>
                                                        <p style={{ color: '#fff', fontSize: 18 }}>মূল্য জিজ্ঞাসা করুন</p>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="hajjvip-tab-pane" role="tabpanel" aria-labelledby="hajjvip-tab" tabindex="0">
                                                    <p className="text-white p-3 selfbgcolor text-center">{packages[1].hajj[2].title}</p>
                                                    <textarea className="w-100 border-0 p-3" disabled style={{ minHeight: 600 }}>{packages[1].hajj[2].text}</textarea>
                                                    <div
                                                        onClick={() => {
                                                            setSelectedhajjPackage('VIP')
                                                            setModalVisibleHajj(true)
                                                        }}
                                                        className='btn bg-danger w-100 '>
                                                        <p style={{ color: '#fff', fontSize: 18 }}>মূল্য জিজ্ঞাসা করুন</p>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                    <div className="container-fluid  ps-0 pe-0 bg-lightgray pt-3">
                                        <div className="container pin_slider hidescroll bg-white  shadow-sm  rounded pt-4 pb-4">
                                            <div className=" pin_slider_inner " style={{ width: 1800 }}>
                                                {
                                                    tips.map((item, index) => {
                                                        return <a href={'/tips/' + item.id} key={index} className="single_post hvr-pop p-1 text-decoration-none text-black">
                                                            <div className='rounded border d-flex p-2 rounded-pill flex-row align-items-center justify-content-center shadow-sm '>
                                                                <FontAwesomeIcon className=' fs-3 me-2 selfcolor' icon={faQuestionCircle} />
                                                                <p className='  font-size-small onlinetext'  >{item.title}</p>
                                                            </div>
                                                        </a>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                    <img src={require('../../assets/banner/hajj-banner.png')} className="rounded w-100" alt="" />
                                    <ul class="nav nav-tabs mt-3 d-flex flex-row justify-content-between" id="myTab" role="tablist">
                                        <li class="nav-item flex-fill" role="presentation">
                                            <button class="nav-link w-100 active" id="omraheco-tab" data-bs-toggle="tab" data-bs-target="#omraheco-tab-pane" type="button" role="tab" aria-controls="omraheco-tab-pane" aria-selected="true">ইকোনমি</button>
                                        </li>
                                        <li class="nav-item flex-fill" role="presentation">
                                            <button class="nav-link w-100" id="omrahbis-tab" data-bs-toggle="tab" data-bs-target="#omrahbis-tab-pane" type="button" role="tab" aria-controls="omrahbis-tab-pane" aria-selected="false">বিজনেস</button>
                                        </li>
                                        <li class="nav-item flex-fill" role="presentation">
                                            <button class="nav-link w-100" id="omrahvip-tab" data-bs-toggle="tab" data-bs-target="#omrahvip-tab-pane" type="button" role="tab" aria-controls="omrahvip-tab-pane" aria-selected="false">ভি এই পি</button>
                                        </li>
                                    </ul>
                                    {packages.length > 0 ?
                                        <div class="tab-content" id="myTabContent">
                                            <div class="tab-pane fade show active" id="omraheco-tab-pane" role="tabpanel" aria-labelledby="omraheco-tab" tabindex="0">
                                                <p className="text-white p-3 selfbgcolor text-center">{packages[0].omrah[0].title}</p>
                                                <textarea className="w-100 border-0 p-3" disabled style={{ minHeight: 600 }}>{packages[0].omrah[0].text}</textarea>
                                                <div
                                                    onClick={() => {
                                                        setSelectedOmrahPackage('Economy')
                                                        setModalVisibleOmrah(true)
                                                    }}
                                                    className='btn bg-danger w-100 '>
                                                    <p style={{ color: '#fff', fontSize: 18 }}>মূল্য জিজ্ঞাসা করুন</p>
                                                    
                                                </div>
                                                <a href='/OmrahHistory' className="btn btn-primary mt-3 text-white w-100">
                                                    আপনার রিকোয়েস্ট সমূহ দেখুন
                                                </a>
                                            </div>
                                            <div class="tab-pane fade" id="omrahbis-tab-pane" role="tabpanel" aria-labelledby="omrahbis-tab" tabindex="0">
                                                <p className="text-white p-3 selfbgcolor text-center">{packages[0].omrah[1].title}</p>
                                                <textarea className="w-100 border-0 p-3" disabled style={{ minHeight: 600 }}>{packages[0].omrah[1].text}</textarea>
                                                <div
                                                    onClick={() => {
                                                        setSelectedOmrahPackage('Business')
                                                        setModalVisibleOmrah(true)
                                                    }}
                                                    className='btn bg-danger w-100 '>
                                                    <p style={{ color: '#fff', fontSize: 18 }}>মূল্য জিজ্ঞাসা করুন</p>
                                                    
                                                </div>
                                                <a href='/OmrahHistory' className="btn btn-primary mt-3 text-white w-100">
                                                    আপনার রিকোয়েস্ট সমূহ দেখুন
                                                </a>
                                            </div>
                                            <div class="tab-pane fade" id="omrahvip-tab-pane" role="tabpanel" aria-labelledby="omrahvip-tab" tabindex="0">
                                                <p className="text-white p-3 selfbgcolor text-center">{packages[0].omrah[2].title}</p>
                                                <textarea className="w-100 border-0 p-3" disabled style={{ minHeight: 600 }}>{packages[0].omrah[2].text}</textarea>
                                                <div
                                                    onClick={() => {
                                                        setSelectedOmrahPackage('VIP')
                                                        setModalVisibleOmrah(true)
                                                    }}
                                                    className='btn bg-danger w-100 '>
                                                    <p style={{ color: '#fff', fontSize: 18 }}>মূল্য জিজ্ঞাসা করুন</p>
                                                   
                                                </div>
                                                <a href='/OmrahHistory' className="btn btn-primary mt-3 text-white w-100">
                                                    আপনার রিকোয়েস্ট সমূহ দেখুন
                                                </a>
                                            </div>
                                        </div>
                                        : null
                                    }
                                    <div className="container-fluid  ps-0 pe-0 bg-lightgray pt-3">
                                        <div className="container pin_slider hidescroll bg-white  shadow-sm  rounded pt-4 pb-4">
                                            <div className=" pin_slider_inner " style={{ width: 1800 }}>
                                                {
                                                    tips.map((item, index) => {
                                                        return <a href={'/tips/' + item.id} key={index} className="single_post hvr-pop p-1 text-decoration-none text-black">
                                                            <div className='rounded border d-flex p-2 rounded-pill flex-row align-items-center justify-content-center shadow-sm '>
                                                                <FontAwesomeIcon className=' fs-3 me-2 selfcolor' icon={faQuestionCircle} />
                                                                <p className='  font-size-small onlinetext'  >{item.title}</p>
                                                            </div>
                                                        </a>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btn btn-danger mt-3 ps-5 pe-5 rounded-pill m-auto">ব্লগ পোস্ট</div>
                            <div className="container-fluid  ps-0 pe-0 bg-lightgray pt-3">
                                <div className="container pin_slider hidescroll bg-white  shadow-sm  rounded pt-4 pb-4">
                                    <div className=" pin_slider_inner " style={{ width: 1800 }}>
                                        {
                                            blogpost.length > 0 ?
                                                blogpost.map((item, index) => {
                                                    return <a href={'/blogpost/' + item.id} key={index} className="single_post hvr-pop p-1 text-decoration-none text-black">
                                                        <div className='rounded border  shadow-sm '>
                                                            <img src={'https://shopapp.self-shopping.com/img/airticket/' + item.image} className="rounded d-block w-100" style={{ height: 150 }} alt="..." />
                                                            <div className="row p-2 pb-0  ">
                                                                <div className="col-12 selfcolor">
                                                                    <p className='m-0  font-size-small twolinetext' style={{ height: 45 }} >{item.title}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row p-2 pt-0">
                                                                <div className="col-12 font-small mb-2 text-secondary overflow-hidden" style={{ height: 40 }}>{item.text}</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                })
                                                : null
                                        }



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Body;