import React from 'react';
import Header from '../../components/Header';
import Body from '../../components/Shop/ShopAllCategoryBody';
import Footer from '../../components/Footer';

const ShopAllCategory = () =>  {
    return(
        <div className='container-fluid p-0'>
            <Header/>
            <Body/>
            <Footer/>
        </div>
    )
}
export default ShopAllCategory;