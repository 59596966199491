
import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../components/SideMenu'; 

import { faCheckCircle, faHandPointer, faMobile, faMobileScreenButton, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import {ErrorToast} from '../utils/Utils';
const Body = () =>  {
    
const icons = [
    require("../assets/operator/robi.png"),
    require("../assets/operator/airtel.png"),
    require("../assets/operator/banglalink.png"),
    require("../assets/operator/gp.png"),
    require("../assets/operator/teletalk.png"),
  ];
  const [isError,setIsError] = useState(false);
    const [errorMsg,setErrorMsg] =useState('')
    const [numbererror,setNumbererror] = useState(null);
  const [trxId, setTrxId] = useState('');
  const [userId, setUserId] = useState('');
  const [userstatus, setUserStatus] = useState('');
  const [operator, setOperator] = useState("gp");
  const [opt,setOpt] = useState('Grameenphone')
  const [operatorType, setOperatorType] = useState("Prepaid");
  const [recharge, setRecharge] = useState("");
  const [cashback, setCashback] = useState(0);
  const [number, setNumber] = useState("");
  const [fevname, setFevName] = useState("");
  const [minite, setMinite] = useState("");
  const [iconi, setIconi] = useState(0);
  const [duration, setDuration] = useState('');
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [modalonoff, setModalOnOff] = useState(false);
  const [onoffinfo, setOnOffInfo] = useState([]);
  const [rechargecomm, setRechargeComm] = useState([]);
  const [rechargefirstlevelcommbusiness, setRechargeFirstLevelCommBusiness] = useState([]);
  const [rechargefirstlevelcommcustomer, setRechargeFirstLevelCommCustomer] = useState([]);
  const [rpstatus, setRPstatus] = useState('');
  const [loadPreview,setLoadPreview] = useState(false)
  const shoputils = require('../utils/shoputils.json');
  const [showpreview,setShowpreview] = useState(false)
  const [showSuccess,setShowSuccess] = useState(false)
  const [showDetails,setShowDetails] = useState(false)
  const [loadingMore,setLoadingMore] = useState(false);
  const [data,setData] = useState([]);
  const [nodata,setNodata] = useState();
  const [loadMore,setLoadMore] = useState(false);
  const [dnumber,setdnumber] = useState('')
  const [dtrxid,setdtrxid] = useState('')
  const [drecharge,setdrecharge] = useState('')
  const [doldbalance,setdoldbalance] = useState('')
  const [dnewbalance,setdnewbalance] = useState('')
  const [doperator,setdoperator] = useState('')
  const [doperatortype,setdoperatortype] = useState('')
  useEffect(() => {
    getRechargeOnOffStatus()
    getData();
  },[])
  const getData = async () => {
        try {
            setLoadingMore(true);
            const users =  localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            const random = Math.random();
            
            const response = await fetch(shoputils.recharge+'?random='+random+'&getRechargeHistory=0&webpost=1&auth='+user.auth);
            const json = await response.json();
            
            if(json.success == 1){
                
                setData(json.data);
                
                setNodata(true)
                
            }else{
                setData([]);
                setNodata(true);
            }
            setLoadingMore(false);
            
        } catch (error) {
            
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
            
        } 
    }
    if(loadMore == false){
        window.onscroll = async function(ev) {
            
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
                setLoadMore(true)
                
                try {
                setLoadingMore(true);
                var index = data.length;
                var lastitem = data[index - 1]; //get 1st item in the array
                
                if (index > 19) {
                    
                    
                    const users =  localStorage.getItem("userinfo");
                    const user = JSON.parse(users);
                    const random = Math.random();
                    const response = await fetch(shoputils.recharge+'?random='+random+'&getRechargeHistory='+lastitem.id+'&webpost=1&auth='+user.auth);
                    const json = await response.json();
                    
                    if(json.data.length > 0){
                        const responsedata = json.data;
                        let arry = [...data, ...responsedata];
                        setData(arry);
                        setLoadMore(false)
                    }else{
                        setLoadMore(false)
                    }
                }
                setLoadMore(false);
            } catch (e) {
                setLoadingMore(false);
            } finally {
                setLoadMore(false);
            }
            }
        };
    } 
  const getRechargeOnOffStatus = async () => {
    setLoading(true)
    try {
        const users =  localStorage.getItem("userinfo");
        const user = JSON.parse(users);
      const random = Math.random();
      //console.log(shoputils.recharge + '?random=' + random + '&getRechargeOnOffStatus=1&auth='+user.auth+'&webpost=1&type=recharge')
      const response = await fetch(shoputils.recharge + '?random=' + random + '&getRechargeOnOffStatus=1&auth='+user.auth+'&webpost=1&type=recharge');
      const json = await response.json();
     
      if (json.success == 1) {        
        let notice_status = json.rechargeonoff[0]['notice_status'];  
        setOnOffInfo(json.rechargeonoff[0]);  
        setRechargeComm(json.rechargecomm[0]);   
        setRPstatus(json.rpstatus)
        setUserId(json.authcode)
        setRechargeFirstLevelCommBusiness(json.rechargefirstlevelcommbusiness[0]);
        setRechargeFirstLevelCommCustomer(json.rechargefirstlevelcommcustomer[0]);
        if(notice_status == 1){     
        setModalOnOff(true);
        //RechargeOnOffModal();
        //setData([]);
        }else{          
          setModalOnOff(false);
        }

      }
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }
  const handleRechargeNumber = (e) => {
    const value = e.target.value;
    setNumber(value)
    let NumFirst3 = value.substring(0, 3);
    let change1 = value.replace("+88", '');
    let NewVal = change1.replace("-", '');
    let NewVale = NewVal.replace("+", '');
    if ((NumFirst3 == '013') || (NumFirst3 == '014') || (NumFirst3 == '015') || (NumFirst3 == '016') || (NumFirst3 == '017') || (NumFirst3 == '018') || (NumFirst3 == '019')) {
      setNumber(NewVale);
      setNumbererror(null)
    } else {
        
        setNumbererror('প্রদত্ত মোবাইল নাম্বারটি সঠিক নয়!') 
    //   Toast.show({
    //     type: 'info',
    //     text1: 'Invalid!',
    //     text2: 'প্রদত্ত মোবাইল নাম্বারটি সঠিক নয়!',
    //     visibilityTime: 4000,
    //     autoHide: false,
    //     topOffset: 280,
    //     bottomOffset: 280,
    //     onShow: () => { },
    //     onHide: () => { },
    //   });
    }
  }
    const showError = (msg) => { 
        setIsError(true)
        setErrorMsg(msg) 
        setShowpreview(false)
        setTimeout(() => {
            setIsError(false)
            
        }, 3000);
    }
  const ValidationCheck = () => {    
    //console.log(rpstatus);
      if(onoffinfo['status'] == 0){
        if (number.trim().length == 11) {
          if (recharge !== '') {
            if (operator !== '') {
              if (operatorType !== '') {
                if(rpstatus == 0){
                  if (userstatus == 1) {
                    if(operator == 'rb' || operator == 'at'){
                      const cashBack = recharge * rechargecomm['robi_business'] / 100;
                      const cashpay = cashBack * rechargefirstlevelcommbusiness['comm'] / 100;
                      setCashback(cashpay);
                    }else{
                      const cashBack = recharge * rechargecomm['smart_pay_business'] / 100;
                      const cashpay = cashBack * rechargefirstlevelcommbusiness['comm'] / 100;
                      setCashback(cashpay);
                    }
                  } else {
                    if(operator == 'rb' || operator == 'at'){
                      const cashBack = recharge * rechargecomm['robi_customer'] / 100;
                      const cashpay = cashBack * rechargefirstlevelcommcustomer['comm'] / 100;
                      setCashback(cashpay);
                    }else{
                      const cashBack = recharge * rechargecomm['smart_pay_customer'] / 100;
                      const cashpay = cashBack * rechargefirstlevelcommcustomer['comm'] / 100;
                      setCashback(cashpay);
                    }
                  }} else {
                  setCashback(0);
                }
                // const cashBack = recharge * 2.3 / 100;
                // setCashback(cashBack);
                //setModalRechargeInfo(true);
                setShowpreview(true)
                let opt = "Robi";
                  if (operator == "bl") {
                    setOpt("Banglalink");
                  } else if (operator == "gp") {
                    setOpt("Grameenphone");
                  } else if (operator == "tt") {
                    setOpt("Teletalk");
                  } else if (operator == "rb") {
                    setOpt("Robi");
                  } else if (operator == "at") {
                    setOpt("Airtel");
                  } else if (operator == "sk") {
                    setOpt("Skitto");
                  }
              } else {
                
                showError('Select Operator Type Prepaid/Postpaid!')
                // Toast.show({
                //   type: 'info',
                //   text1: 'Required!',
                //   text2: 'Select Operator Type Prepaid/Postpaid!',
                //   visibilityTime: 4000,
                //   autoHide: false,
                //   topOffset: 280,
                //   bottomOffset: 280,
                //   onShow: () => { },
                //   onHide: () => { },
                // });
              }
            } else {
                
                showError('Select Mobile Operator!')
            //   Toast.show({
            //     type: 'info',
            //     text1: 'Operator!',
            //     text2: 'Select Mobile Operator!',
            //     visibilityTime: 4000,
            //     autoHide: false,
            //     topOffset: 280,
            //     bottomOffset: 280,
            //     onShow: () => { },
            //     onHide: () => { },
            //   });
            }
          } else {
            
                
                showError('Recharge Amount is required!')
            // Toast.show({
            //   type: 'info',
            //   text1: 'Amount!',
            //   text2: 'Recharge Amount is required!',
            //   visibilityTime: 4000,
            //   autoHide: false,
            //   topOffset: 280,
            //   bottomOffset: 280,
            //   onShow: () => { },
            //   onHide: () => { },
            // });
          }
        }
        else {
                
                showError('১১ ডিজিটের মোবাইল নাম্বার দিন')
        //   Toast.show({
        //     type: 'info',
        //     text1: 'Number!',
        //     text2: '১১ ডিজিটের মোবাইল নাম্বার দিন',
        //     visibilityTime: 4000,
        //     autoHide: false,
        //     topOffset: 280,
        //     bottomOffset: 280,
        //     onShow: () => { },
        //     onHide: () => { },
        //   });
        } } else {
                
                showError(onoffinfo['title'])
        //   Toast.show({
        //     type: 'info',
        //     text1: 'Warning!',
        //     text2: onoffinfo['title'],
        //     visibilityTime: 4000,
        //     autoHide: false,
        //     topOffset: 280,
        //     bottomOffset: 280,
        //     onShow: () => { },
        //     onHide: () => { },
        //   });
        }
      }
    const rechargeAmountChange = (e) =>{
        setRecharge(e.target.value)
    }  
    const operatorChange = (e) =>{
        
        setOperator(e.target.value)
    }  
    const operatorTypeChange = (e) =>{
        
        setOperatorType(e.target.value)
    }  
    const loadDetails = (item) => {
        setdnumber(item.number)
        setdtrxid(item.paywelcode)
        setdrecharge(item.amount)
        setdoldbalance(item.old_bl)
        setdnewbalance(item.new_bl)
        setdoperator(item.operator)
        setdoperatortype(item.type)
        setShowDetails(true)
    }
    const confirmRecharge = async() => {
        const timer = setTimeout(async() => {
            try {
                const random = Math.random();
                
                const users =  localStorage.getItem("userinfo");
                const user = JSON.parse(users);
                let formData = new FormData();
                
                  formData.append('auth',userId);
                  formData.append('webpost',1);
                  formData.append('token',user.token);

                  formData.append('number',number);
                  formData.append('amount',recharge);
                  formData.append('operator',operator);
                  formData.append('type',operatorType);
                  
                  
                  const response = await fetch('https://self-shopping.com/java/api/recharges_app_to_web_connect_api_test_web.php',{
                    method: 'POST',
                    headers: {
                      Accept: 'application/json',
                    },
                    body: formData
                });
                
                const json = await response.json();
                
                if(json.success  == 'rbox_success'){
                    setTrxId(json.ridtrx);
                    setShowSuccess(true)
                    setShowpreview(false)
                }else if(json.success  == 'robi_success'){
                    setTrxId(json.ridtrx);
                    setShowSuccess(true)
                    setShowpreview(false)
                }else if(json.success  == 'airtel_successnull'){
                    setTrxId(json.ridtrx);
                    setShowSuccess(true)
                    setShowpreview(false)
                }else{
                    showError(json.message)
                }
                setLoading(false)
                
              } catch (error) {
                
              }  finally {
                setLoading(false)
              }
          }, 3000);
          return () => clearTimeout(timer);
        
    }
   return(
    <div className="container-fluid">
        <div className="container-fluid  bg-lightgray">
                    <div className="container  pt-3 pb-3">
                        <div className="row ">
                            <SideMenu/>
                            
                            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                            {
                                isError == true ? 
                                ErrorToast(errorMsg)
                                :null
                            }
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active fs-5" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">রিচার্জ</button>
                                </li>
                                {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link fs-5" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">নিয়মিত অফার</button>
                                </li> */}
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link fs-5" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">রিচার্জ হিস্ট্রি</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <img src={require('../img/banner/mobile-recharge.jpg')} className="rounded w-100" alt="" />
                                <div className="bg-light p-2 mt-3 col-12 rounded shadow-sm">
                                    <h5>রিচার্জ করুন</h5>
                                    <hr />
                                    <input onChange={handleRechargeNumber} className='form-control mb-2' value={number} placeholder='মোবাইল নাম্বার'/>
                                    <p>{numbererror !== null ? numbererror : null}</p>
                                    <input className='form-control mb-2' onChange={rechargeAmountChange} value={recharge}  placeholder='টাকার পরিমান'/>
                                    
                                    <select onChange={operatorChange} className='form-control mb-2' >
                                        <option value="gp">গ্রামীনফোনে</option>
                                        <option value="rb">রবি</option>
                                        <option value="at">এয়ারটেল</option>
                                        <option value="bl">বাংলালিংক</option>
                                        <option value="tt">টেলিটক</option>
                                        <option value="sk">স্কিটো</option>
                                    </select>
                                    <select onChange={operatorTypeChange} className='form-control mb-2' >
                                        <option value={'Prepaid'}>প্রিপেইড</option>
                                        <option value={'Postpaid'}>পোস্টপেইড</option>
                                    </select>
                                    <button type="submit" onClick={() => {
                                        setLoadPreview(true)
                                        ValidationCheck()
                                        
                                    }} data-bs-toggle="modal" data-bs-target="#PreviewModal" className='btn btn-primary text-white col-12' >
                                        <FontAwesomeIcon className=' fs-3 me-2 text-white' icon={faPaperPlane} />  
                                        পরের ধাপে যান
                                    </button>
                                    {showpreview == true ?
                                    <>
                                    <div className="modal d-block"  id="PreviewModal" >
                                        <div className="modal-dialog">
                                            <div className="modal-content overflow-hidden  mt-5 ps-3 pe-3 pt-3 bg-light" style={{paddingBottom:80}}>
                                            <div class="modal-header text-center">
                                                <h1 class="modal-title fs-5" id="exampleModalLabel">মোবাইল রিচার্জ প্রিভিউ</h1>
                                                <button type="button" onClick={() => setShowpreview()} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>

                                                {/* <p className='text-center'>মোবাইল রিচার্জ প্রিভিউ</p> */}
                                              
                                                    <>
                                                    <div className="d-flex align-items-center m-auto">
                                                        <div className="rounded-pill me-3 shadow-sm bg-white align-items-center d-flex justify-content-center" style={{width:50,height:50}}>
                                                            <FontAwesomeIcon className=' fs-3  ' icon={faMobileScreenButton} />  
                                                        </div>
                                                        <div>
                                                            <p className="m-0">মোবাইল নাম্বার</p>
                                                            <p className="m-0">{number}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-3  justify-content-between">
                                                        <div className="border w-50 me-2 shadow-sm p-2 rounded">
                                                            <p className="m-0">রিচার্জের পরিমান</p>
                                                            <p className="m-0">{recharge} taka</p>
                                                        </div>
                                                        <div className="border w-50 shadow-sm p-2 rounded">
                                                            <p className="m-0">ক্যাশব্যাক</p>
                                                            <p className="m-0">{cashback}</p>
                                                        </div>
                                                    
                                                    </div>
                                                    <div className="d-flex mt-3 justify-content-between">
                                                        <div className="border w-50 me-2 shadow-sm p-2 rounded">
                                                            <p className="m-0">অপারেটর</p>
                                                            <p className="m-0">
                                                            {operator == "bl" ? "Banglalink" : ""}
                                                            {operator == "gp" ? "Grameen Phone" : ""}
                                                            {operator == "tt" ? "Teletalk" : ""}
                                                            {operator == "rb" ? "Robi" : ""}
                                                            {operator == "at" ? "Airtel" : ""}
                                                            {operator == "sk" ? "Skitto" : ""}</p>
                                                        </div>
                                                        <div className="border w-50  shadow-sm p-2 rounded">
                                                            <p className="m-0">ধরণ</p>
                                                            <p className="m-0">
                                                            {operatorType}
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                    <div onClick={confirmRecharge} className="rec_absolute_tap_btn text-center ">
                                                        <FontAwesomeIcon className='fs-3' icon={faHandPointer} />  
                                                        <br/>
                                                        চাপ দিয়ে ধরে রাখুন
                                                    </div>
                                                    <div className="rec_rotator_box">
                                                        <div className="rec_absolute_tap_btn2 text-center "></div>
                                                        <div className="rec_absolute_tap_btn3 text-center "></div>
                                                    </div>
                                                    </>
                                                    
                                            
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-backdrop show" ></div>
                                    </>
                                    :null
                                    }
                                    {showSuccess == true ?
                                    <div className="modal d-block"  id="PreviewModal" >
                                        <div className="modal-dialog">
                                            <div className="modal-content overflow-hidden  mt-5 ps-3 pe-3 pt-3 bg-light" style={{paddingBottom:80}}>
                                            <div className="rounded-pill  shadow-sm bg-white align-self-center text-success align-items-center d-flex justify-content-center" style={{width:50,height:50}}>
                                                        <FontAwesomeIcon className=' fs-1  ' icon={faCheckCircle} />
                                                </div>
                                                
                                                <p className='text-center text-success'>রিচার্জ সফল হয়েছে</p>
                                                
                                                    <>
                                                    <div className="d-flex align-items-center m-auto">
                                                        
                                                        <div>
                                                            <p className="m-0">মোবাইল নাম্বার</p>
                                                            <p className="m-0">{number}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-3  justify-content-between">
                                                        <div className="border w-50 me-2 shadow-sm p-2 rounded">
                                                            <p className="m-0">রিচার্জের পরিমান</p>
                                                            <p className="m-0">{recharge} taka</p>
                                                        </div>
                                                        <div className="border w-50 shadow-sm p-2 rounded">
                                                            <p className="m-0">ট্রান্জেকশন আইডি</p>
                                                            <p className="m-0">{trxId}</p>
                                                        </div>
                                                    
                                                    </div>
                                                    <div className="d-flex mt-3 justify-content-between">
                                                        <div className="border w-50 me-2 shadow-sm p-2 rounded">
                                                            <p className="m-0">অপারেটর</p>
                                                            <p className="m-0">{operator == "bl" ? "Banglalink" : ""}
                                                            {operator == "gp" ? "Grameen Phone" : ""}
                                                            {operator == "tt" ? "Teletalk" : ""}
                                                            {operator == "rb" ? "Robi" : ""}
                                                            {operator == "at" ? "Airtel" : ""}
                                                            {operator == "sk" ? "Skitto" : ""}</p>
                                                        </div>
                                                        <div className="border w-50  shadow-sm p-2 rounded">
                                                            <p className="m-0">ধরণ</p>
                                                            <p className="m-0">
                                                            {operatorType}
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                    <div onClick={() => {
                                                        
                                                            setNumber('');
                                                            setRecharge('');
                                                            setOperator('');
                                                            setOperatorType('');
                                                            setShowSuccess(false)
                                                          
                                                    }} className="btn btn-success mt-3">
                                                        ওকে
                                                    </div>
                                                    </>
                                                    
                                            
                                                
                                            </div>
                                        </div>
                                    </div>
                                    :null
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="border p-2 border-danger rounded mb-3">
                                    <h4 className='text-center w-100 mb-4 select_operator'>অপারেটর নির্বাচন করুন</h4>
                                    <div className="d-flex flex-row justify-content-evenly">
                                        <div className="rec_iconround">
                                            <img src={require('../img/operator/robi.png')} className="w-100" alt="" />
                                        </div>
                                        <div className="rec_iconround">
                                            <img src={require('../img/operator/gp.png')} className="w-100" alt="" />
                                        </div>
                                        <div className="rec_iconround">
                                            <img src={require('../img/operator/banglalink.png')} className="w-100" alt="" />
                                        </div>
                                        <div className="rec_iconround">
                                            <img src={require('../img/operator/airtel.png')} className="w-100" alt="" />
                                        </div>
                                        <div className="rec_iconround">
                                            <img src={require('../img/operator/teletalk.png')} className="w-100" alt="" />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-evenly mt-3">
                                        <div className="btn btn-outline-danger w-25">
                                            মিনিট 
                                        </div>
                                        <div className="btn btn-outline-danger w-25">
                                            ইন্টারনেট 
                                        </div>
                                        <div className="btn btn-outline-danger w-25">
                                            বান্ডেল
                                        </div>
                                       
                                    </div>
                                </div>
                                <div className="rec_scroll_operator hidescroll">
                                    <div  className="shadow-sm bg-white align-items-center w-100  mt-2 p-2 rounded d-inline-flex flex-row justify-content-between" >
                                        <img className='rec_operator_icon' src={require('../img/operator/robi.png')} alt="" />
                                        <div className='w-50 offer_details_text'>
                                            <b>1600 Minutes, 5gb</b>
                                            <b>30 days</b>
                                        </div>
                                        <div className='w-25 text-end justify-content-center'>
                                            <div className="badge bg-danger fs-6">256</div>
                                            <p className='m-0'>Prepaid</p>
                                        </div>
                                    </div>
                                    <div  className="shadow-sm bg-white align-items-center w-100  mt-2 p-2 rounded d-inline-flex flex-row justify-content-between" >
                                        <img className='rec_operator_icon' src={require('../img/operator/robi.png')}  alt=""/>
                                        <div className='w-50 offer_details_text'>
                                            <b>1600 Minutes, 5gb</b>
                                            <b>30 days</b>
                                        </div>
                                        <div className='w-25 text-end justify-content-center'>
                                            <div className="badge bg-danger fs-6">256</div>
                                            <p className='m-0'>Prepaid</p>
                                        </div>
                                    </div>
                                    <div  className="shadow-sm bg-white align-items-center w-100  mt-2 p-2 rounded d-inline-flex flex-row justify-content-between" >
                                        <img className='rec_operator_icon' src={require('../img/operator/robi.png')}  alt=""/>
                                        <div className='w-50 offer_details_text'>
                                            <b>1600 Minutes, 5gb</b>
                                            <b>30 days</b>
                                        </div>
                                        <div className='w-25 text-end justify-content-center'>
                                            <div className="badge bg-danger fs-6">256</div>
                                            <p className='m-0'>Prepaid</p>
                                        </div>
                                    </div>
                                    <div  className="shadow-sm bg-white align-items-center w-100  mt-2 p-2 rounded d-inline-flex flex-row justify-content-between" >
                                        <img className='rec_operator_icon' src={require('../img/operator/robi.png')}  alt=""/>
                                        <div className='w-50 offer_details_text'>
                                            <b>1600 Minutes, 5gb</b>
                                            <b>30 days</b>
                                        </div>
                                        <div className='w-25 text-end justify-content-center'>
                                            <div className="badge bg-danger fs-6">256</div>
                                            <p className='m-0'>Prepaid</p>
                                        </div>
                                    </div>
                                    <div  className="shadow-sm bg-white align-items-center w-100  mt-2 p-2 rounded d-inline-flex flex-row justify-content-between" >
                                        <img className='rec_operator_icon' src={require('../img/operator/robi.png')}  alt=""/>
                                        <div className='w-50 offer_details_text'>
                                            <b>1600 Minutes, 5gb</b>
                                            <b>30 days</b>
                                        </div>
                                        <div className='w-25 text-end justify-content-center'>
                                            <div className="badge bg-danger fs-6">256</div>
                                            <p className='m-0'>Prepaid</p>
                                        </div>
                                    </div>
                                    <div  className="shadow-sm bg-white align-items-center w-100  mt-2 p-2 rounded d-inline-flex flex-row justify-content-between" >
                                        <img className='rec_operator_icon' src={require('../img/operator/robi.png')}  alt=""/>
                                        <div className='w-50 offer_details_text'>
                                            <b>1600 Minutes, 5gb</b>
                                            <b>30 days</b>
                                        </div>
                                        <div className='w-25 text-end justify-content-center'>
                                            <div className="badge bg-danger fs-6">256</div>
                                            <p className='m-0'>Prepaid</p>
                                        </div>
                                    </div>
                                    <div  className="shadow-sm bg-white align-items-center w-100  mt-2 p-2 rounded d-inline-flex flex-row justify-content-between" >
                                        <img className='rec_operator_icon' src={require('../img/operator/robi.png')}  alt=""/>
                                        <div className='w-50 offer_details_text'>
                                            <b>1600 Minutes, 5gb</b>
                                            <b>30 days</b>
                                        </div>
                                        <div className='w-25 text-end justify-content-center'>
                                            <div className="badge bg-danger fs-6">256</div>
                                            <p className='m-0'>Prepaid</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                {
                                    showDetails == true ? 
                                    <>
                                    <div className="modal d-block">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                            <div class="modal-header text-center">
                                                <h1 class="modal-title fs-5" id="exampleModalLabel">মোবাইল রিচার্জ হিস্ট্রি</h1>
                                                <button type="button" onClick={() => setShowDetails(false)} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                                <div className="modal-body">
                                                    
                                                    <div className="border mb-2  shadow-sm p-2 rounded">
                                                        <p className="m-0">মোবাইল নাম্বার</p>
                                                        <p className="m-0">{dnumber}</p>
                                                    </div>
                                                    <div className="border mb-2  shadow-sm p-2 rounded">
                                                        <p className="m-0">ট্রান্জেকশন আইডি</p>
                                                        <p className="m-0">{dtrxid}</p>
                                                    </div>
                                                    <div className="border mb-2   shadow-sm p-2 rounded">
                                                        <p className="m-0">রিচার্জের পরিমান</p>
                                                        <p className="m-0">{drecharge} taka</p>
                                                    </div>
                                                    <div className="border mb-2  shadow-sm p-2 rounded">
                                                        <p className="m-0">পূর্বের ব্যালান্স</p>
                                                        <p className="m-0">{doldbalance}</p>
                                                    </div>
                                                    <div className="border  mb-2  shadow-sm p-2 rounded">
                                                        <p className="m-0">বর্তমান ব্যালান্স</p>
                                                        <p className="m-0">{dnewbalance} taka</p>
                                                    </div>
                                                    <div className="border  mb-2  shadow-sm p-2 rounded">
                                                        <p className="m-0">অপারেটর</p>
                                                        <p className="m-0">{doperator}</p>
                                                    </div>
                                                    <div className="border   mb-2 shadow-sm p-2 rounded">
                                                        <p className="m-0">ধরণ</p>
                                                        <p className="m-0">
                                                        {doperatortype}
                                                        </p>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-backdrop show"></div>
                                    </>
                                    :null
                                }
                                <div className="  history_scroll">
                                    {
                                        data.length > 0 ?
                                        data.map((item,index)  => {
                                            return <div key={index} className="bg-white rounded p-3 mb-2 shadow-sm ">
                                                <div className="d-flex flex-row justify-content-between" >
                                                    <p className='m-0'>রিচার্জের পরিমান</p>
                                                    <p className='m-0'>৳ {item.amount}</p>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between">
                                                    <p className='m-0'>রিচার্জ নম্বর</p>
                                                    <p className='m-0'>{item.number}</p>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between">
                                                    <p className='m-0'>স্ট্যাটাস</p>
                                                    <p className='m-0'>{item?.status == 0 ? 'পেন্ডিং...' : item?.status == 1 ? 'সফল হয়েছে' : 'রিফান্ড হয়েছে'}</p>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between">
                                                    <p className='m-0'>{item.date}</p>
                                                    <div onClick={() => loadDetails(item)}><div className="badge bg-danger p-1 fs-6">বিস্তারিত</div></div>
                                                </div>
                                            </div>
                                        })
                                        :null
                                    }
                                </div>
                                {
                                    loadMore == true ?
                                    <div className="col-12 text-center mb-4 pb-4">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    :null
                                }
                            </div>
                            </div>

                                
                                
                            </div>
                           
                           
                        </div>
                    </div>
                </div>
    </div>
   )
}

export default Body;