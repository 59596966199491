import React from 'react';
import Header from '../../components/Header';
import Body from '../../components/Shop/ShopMyOrderBody';
import Footer from '../../components/Footer';

const ShopScreen = () =>  {
   
    return(
        <div className='container-fluid p-0'>
            <Header/>
            <Body/>
            <Footer/>
        </div>
    )
}

export default ShopScreen;