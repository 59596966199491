
import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../SideMenu'; 

import { faCamera, faChevronRight, faHandPointer, faMobile, faMobileScreenButton, faPaperPlane, faShoppingBag, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
const Body = () =>  {
    


   return(
    <div className="container-fluid">
        <div className="container-fluid  bg-lightgray">
                    <div className="container  pt-3 pb-3 checkout_container">
                        <div className="row ">
                            <SideMenu/>
                            
                            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                                <div className="selfbgcolor rounded-top p-2 text-center text-white">নতুন প্রোডাক্ট যুক্ত করুন</div>
                                    
                                <div className="bg-white p-2 rounded">
                                    <div className="mb-3">
                                        <label for="formFile" className="form-label">প্রোডাক্টের নাম</label>
                                        <input className="form-control" type="text" placeholder='গ্রাহকের নাম' id="formFile"/>
                                    </div>
                                    <div className="mb-3">
                                        <label for="formFile" className="form-label">প্রোডাক্টের বিবরণ</label>
                                        <textarea name="" className='form-control' id="" cols="30" placeholder='প্রোডাক্টের বিস্তারিত বিবরণ লিখুন' rows="10"></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label for="formFileMultiple" className="form-label">গ্রাহকের সম্পূর্ণ ঠিকানা</label>
                                        <input className="form-control" placeholder='গ্রাহকের সম্পূর্ণ ঠিকানা' type="text" />
                                    </div>
                                    
                                    <div className="input-group mb-3">
                                        <button className="btn btn-outline-secondary" type="button">ক্যাটাগরি </button>
                                        <select className="form-select" placeholder='গ্রাহকের জেলা' id="inputGroupSelect03" aria-label="Example select with button addon">
                                            <option selected>সিলেক্ট করুন...</option>
                                            <option value="1">Dhaka</option>
                                            <option value="2">Chittagong</option>
                                            <option value="3">Sylhet</option>
                                        </select>
                                    </div>
                                    <div className="input-group mb-3">
                                        <button className="btn btn-outline-secondary" type="button">সাব-ক্যাটাগরি</button>
                                        <select className="form-select" placeholder='গ্রাহকের জেলা' id="inputGroupSelect03" aria-label="Example select with button addon">
                                            <option selected>সিলেক্ট করুন...</option>
                                            <option value="1">Dhaka</option>
                                            <option value="2">Chittagong</option>
                                            <option value="3">Sylhet</option>
                                        </select>
                                    </div>
                                    <div className="input-group mb-3">
                                        <button className="btn btn-outline-secondary" type="button">কো-ক্যাটাগরি</button>
                                        <select className="form-select" placeholder='গ্রাহকের জেলা' id="inputGroupSelect03" aria-label="Example select with button addon">
                                            <option selected>সিলেক্ট করুন...</option>
                                            <option value="1">Dhaka</option>
                                            <option value="2">Chittagong</option>
                                            <option value="3">Sylhet</option>
                                        </select>
                                    </div>
                                    <div className="btn btn-danger col-12 mt-2">
                                        <FontAwesomeIcon className=' fs-5 me-3  text-white' icon={faYoutube} />   পণ্যের ইউটিউব ভিডিও লিংক দিন (যদি থাকে)
                                    </div>
                                    <div className="btn btn-primary col-12 mt-2">
                                    <FontAwesomeIcon className=' fs-5 me-3 text-white' icon={faFacebook} />পণ্যের ফেইসবুক ভিডিও লিংক দিন (যদি থাকে)
                                    </div>
                                    <div className="mb-3">
                                        <label for="formFileSm" className="form-label">গ্রাহকের ফোন নম্বর</label>
                                        <input className="form-control form-control" placeholder='গ্রাহকের ফোন নম্বর'  type="text"/>
                                    </div>
                                    <div>
                                        <label for="formFileLg" className="form-label">বিকল্প ফোন নম্বর</label>
                                        <input className="form-control form-control" placeholder='গ্রাহকের ফোন নম্বর'  type="text"/>
                                    </div>
                                    <a href='/shop/payment' className="mt-3 btn btn-light selfbgcolor w-100 text-white">
                                        পরবর্তী ধাপে যান
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
   )
}

export default Body;