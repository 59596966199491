import React, { useState, useEffect } from 'react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import SideMenu from '../SideMenu';

import { faHeart, faSquareCheck } from '@fortawesome/free-regular-svg-icons'
import { faAnglesRight, faCaretLeft, faCaretRight, faChevronRight, faList, faMagnifyingGlass, faShoppingCart, faTableColumns } from '@fortawesome/free-solid-svg-icons';

const Body = () => {
    const shoputils = require('../../utils/shoputils.json');
    const [cartitem, setCartitem] = useState([])
    const newproducts = [
        { id: 1, name: 'Product Name' },
        { id: 2, name: 'Product Name' },
        { id: 3, name: 'Product Name' },
        { id: 4, name: 'Product Name' },
        { id: 5, name: 'Product Name' },
        { id: 6, name: 'Product Name' },
        { id: 7, name: 'Product Name' },
        { id: 8, name: 'Product Name' },
        { id: 9, name: 'Product Name' },
        { id: 10, name: 'Product Name' },
    ]

    let initial_categories = [
        {
            "id": "0",
            "image": require("../../assets/SelfShopWork/smartwatch.jpg"),
            "name": "All",
            "bordercolor": "#FDB83D",
        },

    ];
    const [loadingmore, setLoadingMore] = useState(false)
    const [PopularCat, SetPopularCat] = useState([
        { 'id': '349', 'name': 'থ্রি-পিস', 'image': '3_Piece' },
        { 'id': '53', 'name': 'মোবাইল এক্সেসরিস', 'image': 'Mobile_Accessories' },
        { 'id': '341', 'name': 'চশমা', 'image': 'Sun_Glass' },
        { 'id': '385', 'name': 'ঘড়ি', 'image': 'Watch' },
        { 'id': '324', 'name': 'টি-শার্ট', 'image': 'T-Shirt' },
        { 'id': '334', 'name': 'জুতা', 'image': 'Shoes' },
        { 'id': '347', 'name': 'শাড়ি', 'image': 'Sharee' },
        { 'id': '323', 'name': 'শার্ট', 'image': 'Shirt' },
        { 'id': '102', 'name': 'ল্যাপটপ', 'image': 'Laptop' },
        { 'id': '325', 'name': 'পেন্ট', 'image': 'Pant' },
        { 'id': '411', 'name': 'ইলেকট্রনিক্স', 'image': 'Electronics' },
        { 'id': '50', 'name': 'স্মার্ট ফোন', 'image': 'Mobile' },
        { 'id': '382', 'name': 'কিডস', 'image': 'Kids' },
        { 'id': '101', 'name': 'ডেস্কটপ', 'image': 'Desktop' },
        { 'id': '4', 'name': 'কসমেটিক্স', 'image': 'Cosmetics' },
        { 'id': '410', 'name': 'গিফট', 'image': 'Gift_Box' },

    ])
    const [loading, setLoading] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const [flashitem, setFlashItem] = useState([])
    const [nodata, setNodata] = useState(false)
    const [newarrival, setNewArrival] = useState([])
    const [newarrivalwidth, setNewArrivalwidth] = useState(0)
    const [featured, setFeatured] = useState([])
    const [featuredwidth, setFeaturedwidth] = useState(0)
    const [discounts, setDiscounts] = useState([])
    const [fevorite, setFevorite] = useState([])
    const [fevoritewidth, setFevoritewidth] = useState(0)
    const [bestselling, setBestSelling] = useState([])
    const [bestsellingwidth, setBestSellingwidth] = useState(0)
    const [exclusive, setExclusive] = useState([])
    const [exclusivewidth, setExclusivewidth] = useState(0)
    const [keywords, setKeywords] = useState([])
    const [trending, setTrending] = useState([])
    const [trendingwidth, setTrendingwidth] = useState(0)
    const [categories, setCategories] = useState(initial_categories);
    const [justforyou, setJustForYou] = useState([]);
    const [justforyouwidth, setJustForYouwidth] = useState([]);
    const [popular, setPopular] = useState([]);
    const [popularwidth, setPopularwidth] = useState(0);
    const [ResellerStatus, setResellerStatus] = useState(0)
    const [loggedin, setLoggedin] = useState(0)
    const [userstatus, setuserstatus] = useState(0)
    const [search, setSearch] = useState('')
    const [searchstring, setSearchstring] = useState('')
    const [loadMore, setLoadMore] = useState(false);
    const [stgproductwidth, setstgproductwidth] = useState(0);
    const [stgproduct, setstgproduct] = useState([]);
    const [specialproducts, setspecialproducts] = useState([])
    const [specialcategory, setspecialcategory] = useState([])
    const [specialname, setspecialname] = useState('');
    const [spselcetedcategory, setspselcetedcategory] = useState(null)
    useEffect(() => {

        fetchData();
        fetchSpecialProducts()
    }, []);
    const searchnow = async (e) => {
        let searchstrings = e.target.value;
        setSearchstring(e.target.value)
        if (searchstring.length > 1) {
            try {
                const random = Math.random();
                const response = await fetch(shoputils.url + '?random=' + random + '&searchproduct=' + searchstrings + '&auth=' + random);
                const json = await response.json();
                setSearch([]);
                if (json.success == 1) {
                    setSearch(json.searchproduct)
                }

            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)
            }
        } else {
            setSearch([]);
        }
    }
    const fetchData = async () => {
        setLoading(true)
        try {
            const users = localStorage.getItem("userinfo");
            var user = JSON.parse(users);
            if (user) {
                let token = localStorage.getItem('token');
                if (token) {
                    setLoggedin(true);
                }
                setuserstatus(user.status)
                getResellerStatus()
            }
            const random = Math.random();
            const response = await fetch(shoputils.url + '?random=' + random + '&ShopMainScreenContent=1&auth=' + random);
            setLoading(false)
            setRefreshing(false)
            const json = await response.json();
            setFlashItem(json.flashitem)
            //console.log(json)
            //setspecialproducts(json.specialproducts)
            setstgproduct(json.stgproducts)
            if (json.stgproducts.length > 0) {
                let cartarr = [];
                json.stgproducts.map((item, index) => {
                    cartarr[item.id] = item;
                })
                localStorage.setItem('pdata', JSON.stringify(cartarr))
            }
            setstgproductwidth(json.stgproducts.length * 180)
            setNodata(true);
            if (json.success == 1) {
                try {
                    const random = Math.random();
                    const response = await fetch(shoputils.url + '?random=' + random + '&ShopMainScreenContent=2&auth=' + random);
                    const json = await response.json();
                    setNewArrival(json.newarrival)
                    setNewArrivalwidth(json.newarrival.length * 180)
                    setFeatured(json.featured)

                    setFeaturedwidth(json.featured.length * 180)
                    setDiscounts(json.discountpercent)

                    if (json.success == 1) {
                        try {
                            const random = Math.random();
                            const response = await fetch(shoputils.url + '?random=' + random + '&ShopMainScreenContent=3&auth=' + random);
                            const json = await response.json();

                            setFevorite(json.fevorite)
                            setFevoritewidth(json.fevorite.length * 180)
                            setExclusive(json.exclusive)
                            setExclusivewidth(json.exclusive.length * 180)
                            setBestSelling(json.bestselling)
                            setBestSellingwidth(json.bestselling.length * 180)
                            if (json.success == 1) {
                                try {
                                    const random = Math.random();
                                    const response = await fetch(shoputils.url + '?random=' + random + '&ShopMainScreenContent=4&auth=' + random);
                                    const json = await response.json();


                                    setTrending(json.trending)
                                    setTrendingwidth(json.trending.length * 180)


                                    setCategories(json.popularcat);
                                    initial_categories = json.popularcat;

                                    setJustForYou(json.justforyou)

                                    setPopular(json.popular)
                                    setPopularwidth(json.popular.length * 180)
                                } catch (error) {
                                    console.error(error);
                                } finally {

                                }


                            }
                        } catch (error) {
                            console.error(error);
                        } finally {
                            setLoading(false)
                        }


                    }

                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false)
                }
            }

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
        setLoading(false);
    };
    const fetchSpecialProducts = async () => {
        setLoading(true)
        try {
            const users = localStorage.getItem("userinfo");
            var user = JSON.parse(users);
            const random = Math.random();
            const response = await fetch(shoputils.shopspecial + '/products?random=' + random);
            const json = await response.json();
            setspecialproducts(json.products)
            setspecialcategory(json.category)
            setspecialname(json.name)

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
        setLoading(false);
    };
    const stockUpdate = async (index) => {
        //onOpen()
        //alert(index)
        //await AsyncStorage.removeItem('cartqnty');
        localStorage.setItem('cartitem' + index, JSON.stringify({ pid: index, qnty: 1 }))

        let stgcart = localStorage.getItem('stgcart');
        if (stgcart) {
            let temp_cart = JSON.parse(stgcart);
            let found = 0;
            let foundindex = null;
            temp_cart.map((items, indexs) => {
                if (items == index) {
                    found = 1;
                    foundindex = indexs;
                }
            })
            if (found == 0) {
                temp_cart.push(index)
                localStorage.setItem('stgcart', JSON.stringify(temp_cart))
                setCartitem(temp_cart)
            } else {
                let newtmp = temp_cart.slice(foundindex + 1)
                //alert(newtmp);
                localStorage.setItem('stgcart', JSON.stringify(newtmp))
                setCartitem(newtmp)
            }
        } else {
            let nstgcart = [index];
            localStorage.setItem('stgcart', JSON.stringify(nstgcart))
            setCartitem(nstgcart)
        }
        //let cartnow = localStorage.getItem('stgcart')
        //console.log(cartnow)
    }
    const getResellerStatus = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            const users = localStorage.getItem("users");
            if (users) {
                const user = JSON.parse(users);
                const random = Math.random()
                const response = await fetch(shoputils.url + '?getResellerStatus=1&random=' + random + '&uid=' + user.id + '&auth=' + user.id)
                const json = await response.json();
                if (user.status == 1) {
                    setResellerStatus(json.ResellerStatus)
                    setLoading(false);
                }
            }

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }

    const renderItem = (item, index) => {
        return (
            <a key={index} href={'/shop/product/' + item.id + '/' + item.name.replace('/', '-')} className="text-dark col-lg-3 col-md-3 hvr-pop col-sm-6 col-6 ms-0 p-2 ">
                <div className='rounded border  shadow-sm rounded overflow-hidden'>

                    <div className='w-100 overflow-hidden justforyouitem'>
                        <img src={shoputils.imageurl + item.img1} className="d-block w-100" alt="..." />
                    </div>
                    <div className="bg-lightgray ps-2 pe-2">
                        <p className='m-0 onlinetext fw-bold selfcolor'>{item.name}</p>


                        {
                            ResellerStatus == 0 ?
                                item.d == 'flash' ?
                                    <div className='d-flex flex-row justify-content-between'>
                                        <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                                        <h6 className='fw-bold mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>

                                    </div>

                                    : item.d == 'discount' ?
                                        <div className='d-flex flex-row justify-content-between'>
                                            <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                                            <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>

                                        </div>

                                        : item.d == 'none' ?


                                            <div className='d-flex flex-row justify-content-between'>
                                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_price)}</h6>
                                            </div >

                                            :
                                            null
                                : item.d == 'flash' ?
                                    <div className='d-flex flex-row justify-content-between'>
                                        <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                                        <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>

                                    </div>

                                    : item.d == 'discount' ?
                                        <div className='d-flex flex-row justify-content-between'>
                                            <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                                            <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>

                                        </div >

                                        : item.d == 'none' ?
                                            <div className='d-flex flex-row justify-content-between'>
                                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.price)}</h6>
                                            </div >



                                            :
                                            null
                        }

                    </div>
                </div>

            </a>
        );
    }
    const subcategoryclicked = (item) => {
        localStorage.setItem('subcatid', item.id)
        window.location.href = '/shop/category/subcatwise/' + item.name;
    }
    const checkadded = (item) => {
        var stgcart = JSON.parse(localStorage.getItem('stgcart'));
        let found = false;
        stgcart?.map((items, index) => {
            if (items == item) {
                found = true;
            }
        })
        return found;
    }

    const uri = 'https://self-shopping.com/shopapp/img/categoryimage/';
    const SpecialProductsSection = ({ item, index }) => {
        return <div key={index} className="text-dark  hvr-float   align-items-center single_post">
            <a href={'/shop/specialproduct/checkout/' + item.id + '/' + item.name.replace('/', '-')} className="d-block m-2 mb-0 ms-0 p-0 shadow-sm border rounded border ">
                <div className='overflow-hidden w-100' style={{ height: 150 }}>
                    <img src={shoputils.imageurl + item.img1} className="d-block w-100" alt="..." />
                </div>
                <div className="p-2">
                    <p className='m-0 onlinetext fw-bold selfcolor'>{item.name}</p>
                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.price)}</h6>

                </div>
            </a>
            

        </div>
    }
    return (
        <div className='w-100 bg-lightgray'>
            <div className="container-fluid bg-lightgray">
                <div className="container pt-3 pb-3 mobile_container">
                    <div className="row">
                        <SideMenu />
                        <div className="col-sm-10 col-12  d-lg-block d-md-none d-sm-block d-block">
                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={require('../../assets/banner/self-platfrom.png')} className="d-block rounded w-100" alt="..." />
                                    </div>

                                </div>

                            </div>
                            <div href="/shop/search/search" className='w-100 d-lg-none d-md-none d-sm-block d-block d-flex justify-content-center' style={{ marginTop: -20 }}>
                                <div className="input-group align-self-center" style={{ width: '85%' }}>
                                    <input list='searchlist' type="text" onChange={searchnow} autoComplete="false" className="form-control" placeholder="প্রোডাক্ট সার্চ" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                    <a href={'/shop/search/' + searchstring} className="btn btn-warning justify-content-center d-flex" type="button" id="button-addon2">
                                        <FontAwesomeIcon className=' fs-4  text-white' icon={faMagnifyingGlass} />
                                    </a>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container-fluid bg-lightgray pt-3 pb-3">
                <div className="container">
                    <div className="row">
                        {
                            loggedin == true ?
                                <>
                                    <div className="col-2 hvr-pop shop_icons_box  text-center" >
                                        <Link to="/shop/myorder/pending" className='text-decoration-none'>
                                            <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                                <div className="radiousRound selfbgcolor homeRoundIconsInner shopRoundIconsInner">
                                                    <FontAwesomeIcon className=' fs-3  text-white' icon={faTableColumns} />
                                                </div>
                                            </div>
                                            <p className='text-black d-lg-block d-md-block d-sm-block d-none' >আমার অর্ডার </p>
                                            <p className='text-black  d-lg-none d-md-none d-sm-none d-block' style={{ fontSize: 12 }} >আমার<br />অর্ডার </p>
                                        </Link>
                                    </div>
                                    <a href="/shop/dashboard" className=" hvr-pop col-2 shop_icons_box text-decoration-none text-center">
                                        <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                            <div className="radiousRound selfbgcolor homeRoundIconsInner shopRoundIconsInner">
                                                <FontAwesomeIcon className=' fs-3  text-white' icon={faList} />
                                            </div>
                                        </div>
                                        <p className='text-black d-lg-block d-md-block d-sm-block d-none' >আমার ড্যাশবোর্ড </p>
                                        <p className='text-black d-lg-none d-md-none d-sm-none d-block' style={{ fontSize: 12 }} >আমার<br />ড্যাশবোর্ড </p>

                                    </a>
                                    <a href="/shop/fevorite" className="col-2 hvr-pop shop_icons_box text-decoration-none text-center">
                                        <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                            <div className="radiousRound selfbgcolor homeRoundIconsInner shopRoundIconsInner">
                                                <FontAwesomeIcon className=' fs-3  text-white' icon={faHeart} />
                                            </div>
                                        </div>

                                        <p className='text-black d-lg-block d-md-block d-sm-block d-none' >ফেভারিট প্রোডাক্ট</p>
                                        <p className='text-black d-lg-none d-md-none d-sm-none d-block' style={{ fontSize: 12 }} >ফেভারিট<br />প্রোডাক্ট </p>
                                    </a></>
                                :
                                <>
                                    <div data-bs-toggle="modal" data-bs-target="#login_modal" className="col-2 hvr-pop shop_icons_box  text-center" >
                                        <div className='text-decoration-none'>
                                            <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                                <div className="radiousRound selfbgcolor homeRoundIconsInner shopRoundIconsInner">
                                                    <FontAwesomeIcon className=' fs-3  text-white' icon={faTableColumns} />
                                                </div>
                                            </div>
                                            <p className='text-black d-lg-block d-md-block d-sm-block d-none' >আমার অর্ডার </p>
                                            <p className='text-black d-lg-none d-md-none d-sm-none d-block' style={{ fontSize: 12 }} >আমার<br />অর্ডার </p>
                                        </div>
                                    </div>
                                    <div data-bs-toggle="modal" data-bs-target="#login_modal" className=" hvr-pop col-2 shop_icons_box text-decoration-none text-center">
                                        <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                            <div className="radiousRound selfbgcolor homeRoundIconsInner shopRoundIconsInner">
                                                <FontAwesomeIcon className=' fs-3  text-white' icon={faList} />
                                            </div>
                                        </div>
                                        <p className='text-black d-lg-block d-md-block d-sm-block d-none' >আমার ড্যাশবোর্ড </p>
                                        <p className='text-black d-lg-none d-md-none d-sm-none d-block' style={{ fontSize: 12 }} >আমার<br />ড্যাশবোর্ড </p>

                                    </div>
                                    <div data-bs-toggle="modal" data-bs-target="#login_modal" className="col-2 hvr-pop shop_icons_box text-decoration-none text-center">
                                        <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                            <div className="radiousRound selfbgcolor homeRoundIconsInner shopRoundIconsInner">
                                                <FontAwesomeIcon className=' fs-3  text-white' icon={faHeart} />
                                            </div>
                                        </div>

                                        <p className='text-black d-lg-block d-md-block d-sm-block d-none' >ফেভারিট প্রোডাক্ট</p>
                                        <p className='text-black d-lg-none d-md-none d-sm-none d-block' style={{ fontSize: 12 }} >ফেভারিট<br />প্রোডাক্ট </p>
                                    </div>
                                </>
                        }
                        <a href="/shop/verified" className="col-2 hvr-pop shop_icons_box text-decoration-none text-center">
                            <div className='radiousRound  border border-danger  homeRoundIcons shopRadiusIcons' >
                                <div className="radiousRound selfbgcolor homeRoundIconsInner shopRoundIconsInner">
                                    <FontAwesomeIcon className=' fs-3  text-white' icon={faSquareCheck} />
                                </div>
                            </div>

                            <p className='text-black d-lg-block d-md-block d-sm-block d-none' >ভ্যারিফাইড প্রোডাক্ট</p>
                            <p className='text-black d-lg-none d-md-none d-sm-none d-block' style={{ fontSize: 12 }} >ভ্যারিফাইড<br />প্রোডাক্ট </p>
                        </a>
                        <a href="/shop/allcategory" className="col-2 hvr-pop shop_icons_box text-center">
                            <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                <div className="radiousRound selfbgcolor homeRoundIconsInner">
                                    <FontAwesomeIcon className=' fs-3  text-white' icon={faTableColumns} />
                                </div>
                            </div>

                            <p className='text-black d-lg-block d-md-block d-sm-block d-none' >সকল ক্যাটাগরি</p>
                            <p className='text-black d-lg-none d-md-none d-sm-none d-block' style={{ fontSize: 12 }} >সকল<br />ক্যাটাগরি </p>
                        </a>
                        <a href="/shop/vendorsearch" className="col-2 hvr-pop shop_icons_box text-center">
                            <div className='radiousRound border border-danger   homeRoundIcons shopRadiusIcons' >
                                <div className="radiousRound selfbgcolor homeRoundIconsInner shopRoundIconsInner">
                                    <FontAwesomeIcon className=' fs-3  text-white' icon={faMagnifyingGlass} />
                                </div>
                            </div>

                            <p className='text-black d-lg-block d-md-block d-sm-block d-none' >ভেন্ডর সার্চ</p>
                            <p className='text-black d-lg-none d-md-none d-sm-none d-block' style={{ fontSize: 12 }} >ভেন্ডর<br />সার্চ </p>
                        </a>

                    </div>
                </div>
            </div>
            <div className='container-fluid   bg-lightgray pt-3'>
                <div className="container bg-white  shadow-sm  rounded pt-2 pb-2 ">
                    <p className="pb-2 pt-2">বিশেষ ক্যাটাগরি</p>
                    <div className="d-flex justify-content-between align-items-center overflow-scroll remove-scrollbar" >
                        {
                            specialcategory.map((item, index) => {
                                let bgcolor = '';
                                let textcolor = 'selfcolor';
                                if (item.id == spselcetedcategory) {
                                    let bgcolor = 'selfbggradient';
                                    let textcolor = 'text-white';
                                }
                                return <div key={index} onClick={() => setspselcetedcategory(item.id)} className={"m-1 categorybuttons text-nowrap px-3  me-1 shadow  p-2 shadow-sm rounded text-white " + (item.id == spselcetedcategory ? 'selfbggradient' : 'selfcolor')  }>
                                    {item.name}
                                </div>
                            })
                        }
                    </div>
                    <div id='pin_slider' className="container ps-0 pin_slider hidescroll bg-white    rounded pt-2 pb-2  ps-0">

                        <div className=" d-flex ps-0 " >
                            {
                                specialproducts.map((item, index) => {
                                    if (spselcetedcategory !== null) {
                                        if (spselcetedcategory == item.special) {
                                            return <SpecialProductsSection key={index} item={item} index={index} />
                                        }
                                    } else {

                                        return <SpecialProductsSection key={index} item={item} index={index} />

                                    }
                                })
                            }

                        </div>

                    </div>
                </div>
            </div>

            <div className="container-fluid  d-none bg-lightgray pt-3">
                <div className="container bg-white  shadow-sm  rounded pt-2 pb-4">
                    <h4 className='overflow-hidden d-flex justify-content-between mb-0 align-items-center' style={{ marginLeft: -12 }}>
                        <div className="bg-warning float-start p-2 rounded-pill ps-5 pe-3" style={{ marginLeft: -30 }}>এসটিজি প্রোডাক্ট </div>
                        <a href='/shop/category/newarrival/নতুন পণ্য' className="float-end fs-6 selfcolor">সকল প্রোডাক্ট <FontAwesomeIcon className=' ms-1  ' icon={faCaretRight} />  </a>
                    </h4>
                    <div id='pin_slider' className="container ps-0 pin_slider hidescroll bg-white    rounded pt-2 pb-2  ps-0">

                        <div className=" pin_slider_inner ps-0 " style={{ width: stgproductwidth }}>
                            {
                                stgproduct.map((item, index) => {
                                    return <div key={index} className="text-dark  hvr-float   align-items-center single_post">
                                        <a href={'/stg/product/' + item.id + '/' + item.name.replace('/', '-')} className="d-block m-2 mb-0 ms-0 p-0 shadow-sm border rounded border ">
                                            <div className='overflow-hidden w-100' style={{ height: 150 }}>
                                                <img src={shoputils.stgimageurl + item.img1} className="d-block w-100" alt="..." />
                                            </div>
                                            <div className="p-2">
                                                <p className='m-0 onlinetext fw-bold selfcolor'>{item.name}</p>
                                                <h6 className='fw-bold mb-0'>৳ {Math.round(item.price)}</h6>

                                            </div>
                                        </a>
                                        <button className={`  ${checkadded(item.id) == true ? `btn btn-success` : `btn btn-danger`} border-0 rounded-0  p-2 rounded-bottom me-2 mt-0 text-center    text-white`} style={{ width: 172 }} onClick={() => stockUpdate(item.id)}>
                                            <FontAwesomeIcon className='  align-self-center ' icon={faShoppingCart} />{checkadded(item.id) == false ? ' Add To Cart' : ' Cart Added'}
                                        </button>

                                    </div>
                                })
                            }

                        </div>

                    </div>
                    {/* //  <div className='scroller float-end d-flex flex-row justify-content-between p-1 selfbgcolor rounded-pill'>
                    //             <div className='scrollerleft rounded-pill   float-start d-flex justify-content-center '>
                    //                 <FontAwesomeIcon className='  align-self-center selfcolor' icon={faCaretLeft} />
                    //             </div>
                    //             <div className='scrollerright rounded-pill   float-end d-flex justify-content-center '>
                    //                 <FontAwesomeIcon className='  align-self-center selfcolor' icon={faCaretRight} />
                    //             </div>
                    //         </div>  */}
                </div>

            </div>

            <div className="container-fluid   bg-lightgray pt-3">
                <div className="container bg-white  shadow-sm  rounded pt-2 pb-4">
                    <h4 className='overflow-hidden d-flex justify-content-between mb-0 align-items-center' style={{ marginLeft: -12 }}>
                        <div className="bg-warning float-start p-2 rounded-pill ps-5 pe-3" style={{ marginLeft: -30 }}>নতুন পণ্য</div>
                        <a href='/shop/category/newarrival/নতুন পণ্য' className="float-end fs-6 selfcolor">সকল প্রোডাক্ট <FontAwesomeIcon className=' ms-1  ' icon={faCaretRight} />  </a>
                    </h4>
                    <div id='pin_slider' className="container ps-0 pin_slider hidescroll bg-white    rounded pt-2 pb-2  ps-0">

                        <div className=" pin_slider_inner ps-0 " style={{ width: newarrivalwidth }}>
                            {
                                newarrival.map((item, index) => {
                                    return <a key={index} href={'/shop/product/' + item.id + '/' + item.urlname.replace('/', '-')} className="text-dark single_post hvr-float">
                                        <div className=" rounded border m-2 ms-0 shadow-sm p-0">
                                            <div className='overflow-hidden w-100' style={{ height: 150 }}>
                                                <img src={shoputils.imageurl + item.img1} className="d-block w-100" alt="..." />
                                            </div>
                                            <div className="p-2">
                                                <p className='m-0 onlinetext fw-bold selfcolor'>{item.name}</p>
                                                {
                                                    ResellerStatus == 0 ?
                                                        item.d == 'flash' ?
                                                            <div className='d-flex flex-row justify-content-between'>
                                                                <p className='font-small text-danger'><del>৳ {Math.round(item.c_price)}</del></p>
                                                                <h6 className='fw-bold mb-0'>৳ {Math.round(item.c_discountedprice)}</h6>

                                                            </div>

                                                            : item.d == 'discount' ?
                                                                <div className='d-flex flex-row justify-content-between'>
                                                                    <p className='font-small text-danger'><del>৳ {Math.round(item.c_price)}</del></p>
                                                                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.c_discountedprice)}</h6>

                                                                </div>

                                                                : item.d == 'none' ?

                                                                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.c_price)}</h6>


                                                                    :
                                                                    null
                                                        : item.d == 'flash' ?
                                                            <div className='d-flex flex-row justify-content-between'>
                                                                <p className='font-small text-danger'><del>৳ {Math.round(item.price)}</del></p>
                                                                <h6 className='fw-bold mb-0'>৳ {Math.round(item.discountedprice)}</h6>

                                                            </div>

                                                            : item.d == 'discount' ?
                                                                <div className='d-flex flex-row justify-content-between'>
                                                                    <p className='font-small text-danger'><del>৳ {Math.round(item.price)}</del></p>
                                                                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.discountedprice)}</h6>

                                                                </div >

                                                                : item.d == 'none' ?

                                                                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.price)}</h6>


                                                                    :
                                                                    null
                                                }
                                            </div>
                                        </div>
                                    </a>
                                })
                            }

                        </div>

                    </div>
                    {/* <div className='scroller float-end d-flex flex-row justify-content-between p-1 selfbgcolor rounded-pill'>
                                <div className='scrollerleft rounded-pill   float-start d-flex justify-content-center '>
                                    <FontAwesomeIcon className='  align-self-center selfcolor' icon={faCaretLeft} />
                                </div>
                                <div className='scrollerright rounded-pill   float-end d-flex justify-content-center '>
                                    <FontAwesomeIcon className='  align-self-center selfcolor' icon={faCaretRight} />
                                </div>
                            </div> */}
                </div>

            </div>
            <div className="container-fluid   bg-lightgray pt-3">
                <div className="card container p-0">
                    <div className="card-header selfbgcolor text-white p-3">
                        <div className="row">
                            <div className="col-3"><p className='mb-0 fw-bold'> ফ্ল্যাশ সেল</p></div>
                            <div className="container col-5 ps-0 text-center ms-0 text-nowrap">

                                <div id="countdown" className=''>
                                    <ul className='d-inline '>
                                        <li className='d-inline d-none'><span id="days" className='fw-bold'></span> Day </li>
                                        <li className='d-inline'><span id="hours" className='fw-bold'></span> Hour </li>
                                        <li className='d-inline'><span id="minutes" className='fw-bold'></span> Min </li>
                                        <li className='d-inline'><span id="seconds" className='fw-bold'></span> Sec </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-3 text-end"><a href="/shop/category/flashsell/ফ্ল্যাশ সেল" className='mb-0 fw-bold text-white'>সকল পণ্য</a></div>
                        </div>
                    </div>
                    <div className="card-body  overflow-scroll hidescroll" style={{ backgroundColor: '#f28754' }}>
                        <div className="d-inline-flex">
                            {flashitem.map((item, index) => {
                                return <a key={index} href={'/shop/product/' + item.id + '/' + item.urlname.replace('/', '-')} className="text-dark flash_item hvr-float rounded border-0 bg-white m-2 shadow-sm p-0">
                                    <div className="w-100 overflow-hidden rounded-top mb-2  ms-0" style={{ height: 150 }}>
                                        <img src={shoputils.imageurl + item.image} className="d-block w-100" alt="..." />
                                    </div>
                                    <div className="p-2">
                                        <p className='m-0 onlinetext'>{item.name}</p>

                                        {
                                            ResellerStatus == 0 ?
                                                item.d == 'flash' ?
                                                    <div className='d-flex flex-row justify-content-between'>
                                                        <p className='font-small text-danger'><del>৳ {Math.round(item.c_price)}</del></p>
                                                        <h6 className='fw-bold mb-0'>৳ {Math.round(item.c_discountedprice)}</h6>

                                                    </div>

                                                    : item.d == 'discount' ?
                                                        <div className='d-flex flex-row justify-content-between'>
                                                            <p className='font-small text-danger'><del>৳ {Math.round(item.c_price)}</del></p>
                                                            <h6 className='fw-bold mb-0'>৳ {Math.round(item.c_discountedprice)}</h6>

                                                        </div>

                                                        : item.d == 'none' ?

                                                            <h6 className='fw-bold mb-0'>৳ {Math.round(item.c_price)}</h6>


                                                            :
                                                            null
                                                : item.d == 'flash' ?
                                                    <div className='d-flex flex-row justify-content-between'>
                                                        <p className='font-small text-danger'><del>৳ {Math.round(item.price)}</del></p>
                                                        <h6 className='fw-bold mb-0'>৳ {Math.round(item.discountedprice)}</h6>

                                                    </div>

                                                    : item.d == 'discount' ?
                                                        <div className='d-flex flex-row justify-content-between'>
                                                            <p className='font-small text-danger'><del>৳ {Math.round(item.price)}</del></p>
                                                            <h6 className='fw-bold mb-0'>৳ {Math.round(item.discountedprice)}</h6>

                                                        </div >

                                                        : item.d == 'none' ?

                                                            <h6 className='fw-bold mb-0'>৳ {Math.round(item.price)}</h6>


                                                            :
                                                            null
                                        }



                                        <div className="progress" style={{ height: 10 }}>
                                            <div className="progress-bar bg-warning" role="progressbar" aria-label="Danger example" style={{ height: 10, width: '70%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </a>
                            })}



                        </div>
                    </div>
                </div>

            </div>
            <div className="container-fluid   bg-lightgray pt-3">
                <div className="container bg-white  shadow-sm  rounded pt-2 pb-4">
                    <h4 className='overflow-hidden d-flex justify-content-between mb-0 align-items-center' style={{ marginLeft: -12 }}>
                        <div className="bg-warning float-start p-2 rounded-pill ps-5 pe-3" style={{ marginLeft: -30 }}>বিশেষ পণ্য</div>
                        <a href='shop/category/featured/বিশেষ পণ্য' className="float-end fs-6 selfcolor">সকল প্রোডাক্ট <FontAwesomeIcon className=' ms-1  ' icon={faCaretRight} />  </a>
                    </h4>


                    <div className="container pin_slider hidescroll bg-white    rounded pt-2 pb-2 ps-0">

                        <div className=" pin_slider_inner " style={{ width: featuredwidth }}>
                            {
                                featured.map((item, index) => {
                                    return <a href={'/shop/product/' + item.id + '/' + item.urlname.replace('/', '-')} key={index} className="text-dark single_post  hvr-pop">
                                        <div className="col rounded border m-2  ms-0 shadow-sm p-0">
                                            <div className="w-100 overflow-hidden mb-2" style={{ height: 150 }}>
                                                <img src={shoputils.imageurl + item.image} className="d-block w-100" alt="..." />
                                            </div>
                                            <p className='m-0 p-2 onlinetext'>{item.name}</p>

                                        </div>
                                    </a>
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-lightgray pt-3 pb-3">
                <div className="container">
                    <div className="row ">

                        {

                            discounts.map((item, index) => {
                                return <a key={index} href={'/shop/discounted/' + item.discount} className="col-lg-4 text-dark col-md-4 col-sm-12 col-12 p-2 ps-0 discount_boxex">
                                    <div className="discountbox  rounded p-4" style={{ backgroundColor: item.color1 }}>
                                        <div className="absolutebox"></div>

                                        <div className="row">
                                            <div className="col-6">
                                                <h3 className='text-start text-white'>ডিসকাউন্ট </h3>
                                            </div>
                                            <div className="col-6">
                                                <h2 className='text-end text-white'>{item.discount}% </h2>
                                            </div>
                                        </div>
                                        <p>ডিসকাউন্ট পেতে ক্লিক করুন</p>
                                    </div>
                                </a>
                            })
                        }
                        {/* <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2 ps-0 discount_boxex">
                                <div className="discountbox bg-primary rounded p-4">
                                    <div className="absolutebox"></div>

                                    <div className="row">
                                        <div className="col-6">
                                            <h3 className='text-start text-white'>ডিসকাউন্ট </h3>
                                        </div>
                                        <div className="col-6">
                                            <h2 className='text-end text-white'>৫% </h2>
                                        </div>
                                    </div>
                                    <p>ডিসকাউন্ট পেতে ক্লিক করুন</p>
                                </div>
                            </div>
                       
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2 discount_boxex">
                                <div className="discountbox bg-warning rounded p-4">
                                    <div className="absolutebox"></div>
                                    <div className="row">
                                        <div className="col-6">
                                            <h3 className='text-start text-white'>ডিসকাউন্ট </h3>
                                        </div>
                                        <div className="col-6">
                                            <h2 className='text-end text-white'>১০% </h2>
                                        </div>
                                    </div>
                                    <p>ডিসকাউন্ট পেতে ক্লিক করুন</p>
                                </div>
                            </div>
                        
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 p-2 pe-0 discount_boxex">
                                <div className="discountbox bg-danger  rounded p-4">
                                    <div className="absolutebox"></div>
                                    <div className="row">
                                        <div className="col-6">
                                            <h3 className='text-start text-white'>ডিসকাউন্ট </h3>
                                        </div>
                                        <div className="col-6">
                                            <h2 className='text-end text-white'>২০% </h2>
                                        </div>
                                    </div>
                                    <p>ডিসকাউন্ট পেতে ক্লিক করুন</p>
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-lightgray pt-3 pt-3 pb-3">
                <div className="card container p-0">
                    <div className="card-body pt-0">
                        <div className="row align-items-center">
                            <div className="popularcat_title border-bottom pb-2 pt-3 d-lg-none d-md-none d-sm-block d-block">
                                <h4 className='fw-bold text-secondary'>জনপ্রিয় ক্যাটাগরি
                                    <span className="float-end fs-6 selfcolor">সকল প্রোডাক্ট <FontAwesomeIcon className=' ms-1  ' icon={faCaretRight} />  </span>
                                </h4>
                            </div>
                            <div className="col-4 d-lg-block d-md-block d-sm-none d-none" >

                                <div className="row">
                                    <div className="col-9">
                                        <div >
                                            <h4 className='fw-bold text-danger mb-4'>জনপ্রিয় ক্যাটাগরি</h4>
                                            <h4 className='text-secondary'>জনপ্রিয় সব ক্যাটাগরি সমূহ থেকে বেছে নিন আপনার পছন্দের পণ্য টি   </h4>
                                        </div>
                                    </div>
                                    <div className="col-3 pt-5 text-start">
                                        <FontAwesomeIcon className='ms-4 fs-3  text-danger' icon={faAnglesRight} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12 col-12 ">
                                <div className="row">
                                    {PopularCat.map((item, index) => {
                                        return <div onClick={() => subcategoryclicked(item)} key={index} className="popular_cat hvr-grow col-lg-2 col-md-2 col-sm-3 col-3  " >
                                            <div className=" text-center  rounded p-2 mt-3">
                                                <img src={uri + item.image + '.png'} alt="" className='m-auto w-100' style={{ height: 70, width: 70 }} />
                                                <p className='onlinetext mb-0'>{item.name}</p>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container-fluid   bg-lightgray pt-3">
                <div className="container pin_slider hidescroll bg-white  shadow-sm  rounded pt-2 pb-4">

                    <h4 className='overflow-hidden d-flex justify-content-between mb-0 align-items-center' style={{ marginLeft: -12 }}>
                        <div className="bg-warning float-start p-2 rounded-pill ps-5 pe-3" style={{ marginLeft: -30 }}>ফেভারিট প্রোডাক্ট</div>
                        <a href='/shop/category/fevorite/ফেভারিট প্রোডাক্ট' className="float-end fs-6 selfcolor">সকল প্রোডাক্ট <FontAwesomeIcon className=' ms-1  ' icon={faCaretRight} />  </a>
                    </h4>
                    <div className="container pin_slider hidescroll bg-white   rounded pt-2 pb-2 ps-0">
                        <div className=" pin_slider_inner " style={{ width: fevoritewidth }}>
                            {
                                fevorite.map((item, index) => {
                                    return <a key={index} href={'/shop/product/' + item.id + '/' + item.urlname.replace('/', '-')} className=" hvr-pop text-decoration-none single_post">
                                        <div className="  me-2  ms-0">
                                            <div className='w-100 overflow-hidden' style={{ height: 160 }} >
                                                <img src={shoputils.imageurl + item.image} className="d-block w-100" alt="..." />
                                            </div>
                                            <p className='text-dark onlinetext'>{item.name}</p>
                                        </div>
                                    </a>
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid   bg-lightgray pt-3">
                <div className="container bg-white  shadow-sm  rounded pt-2 pb-4">

                    <h4 className='overflow-hidden d-flex justify-content-between mb-0 align-items-center' style={{ marginLeft: -12 }}>
                        <div className="bg-warning float-start p-2 rounded-pill ps-5 pe-3" style={{ marginLeft: -30 }}>এক্সক্লুসিভ প্রোডাক্ট</div>
                        <a href='shop/category/exclusive/এক্সক্লুসিভ প্রোডাক্ট' className="float-end fs-6 selfcolor">সকল প্রোডাক্ট <FontAwesomeIcon className=' ms-1  ' icon={faCaretRight} />  </a>
                    </h4>
                    <div className="container pin_slider hidescroll bg-white   rounded pt-2 pb-2 ps-0">

                        <div className=" pin_slider_inner " style={{ width: exclusivewidth }}>
                            {
                                exclusive.map((item, index) => {
                                    return <a key={index} href={'/shop/product/' + item.id + '/' + item.urlname.replace('/', '-')} className="text-dark single_post hvr-float">
                                        <div className=" rounded border m-2  ms-0 shadow-sm p-0">
                                            <div className='overflow-hidden w-100' style={{ height: 150 }}>
                                                <img src={shoputils.imageurl + item.image} className="d-block w-100" alt="..." />
                                            </div>
                                            <div className="p-2">
                                                <p className='m-0 onlinetext fw-bold selfcolor'>{item.name}</p>
                                                {
                                                    ResellerStatus == 0 ?
                                                        item.d == 'flash' ?
                                                            <div className='d-flex flex-row justify-content-between'>
                                                                <p className='font-small text-danger'><del>৳ {Math.round(item.c_price)}</del></p>
                                                                <h6 className='fw-bold mb-0'>৳ {Math.round(item.c_discountedprice)}</h6>

                                                            </div>

                                                            : item.d == 'discount' ?
                                                                <div className='d-flex flex-row justify-content-between'>
                                                                    <p className='font-small text-danger'><del>৳ {Math.round(item.c_price)}</del></p>
                                                                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.c_discountedprice)}</h6>

                                                                </div>

                                                                : item.d == 'none' ?

                                                                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.c_price)}</h6>


                                                                    :
                                                                    null
                                                        : item.d == 'flash' ?
                                                            <div className='d-flex flex-row justify-content-between'>
                                                                <p className='font-small text-danger'><del>৳ {Math.round(item.price)}</del></p>
                                                                <h6 className='fw-bold mb-0'>৳ {Math.round(item.discountedprice)}</h6>

                                                            </div>

                                                            : item.d == 'discount' ?
                                                                <div className='d-flex flex-row justify-content-between'>
                                                                    <p className='font-small text-danger'><del>৳ {Math.round(item.price)}</del></p>
                                                                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.discountedprice)}</h6>

                                                                </div >

                                                                : item.d == 'none' ?

                                                                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.price)}</h6>


                                                                    :
                                                                    null
                                                }
                                            </div>
                                        </div>
                                    </a>
                                })
                            }

                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid   bg-lightgray pt-3">
                <div className="container bg-white  shadow-sm  rounded pt-2 pb-4">

                    <h4 className='overflow-hidden d-flex justify-content-between mb-0 align-items-center' style={{ marginLeft: -12 }}>
                        <div className="bg-warning float-start p-2 rounded-pill ps-5 pe-3" style={{ marginLeft: -30 }}>সর্বাধিক বিক্রিত</div>
                        <a href='/shop/category/bestselling/সর্বাধিক বিক্রিত' className="float-end fs-6 selfcolor">সকল প্রোডাক্ট <FontAwesomeIcon className=' ms-1  ' icon={faCaretRight} />  </a>
                    </h4>
                    <div className="container pin_slider hidescroll bg-white    rounded pt-2 pb-2 ps-0">

                        <div className=" pin_slider_inner " style={{ width: bestsellingwidth }}>
                            {
                                bestselling.map((item, index) => {
                                    return <a href={'/shop/product/' + item.id + '/' + item.urlname.replace('/', '-')} key={index} className="text-dark single_post  hvr-pop">
                                        <div className="col rounded border m-2  ms-0 shadow-sm p-0">
                                            <div className="w-100 overflow-hidden mb-2" style={{ height: 150 }}>
                                                <img src={shoputils.imageurl + item.image} className="d-block w-100" alt="..." />
                                            </div>
                                            <p className='m-0 p-2 onlinetext'>{item.name}</p>

                                        </div>
                                    </a>
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid   bg-lightgray pt-3">
                <div className="container bg-white  shadow-sm  rounded pt-2 pb-4">

                    <h4 className='overflow-hidden d-flex justify-content-between mb-0 align-items-center' style={{ marginLeft: -12 }}>
                        <div className="bg-warning float-start p-2 rounded-pill ps-5 pe-3" style={{ marginLeft: -30 }}>ট্রেন্ডিং প্রোডাক্ট</div>
                        <a href='/shop/category/trending/ট্রেন্ডিং প্রোডাক্ট' className="float-end fs-6 selfcolor">সকল প্রোডাক্ট <FontAwesomeIcon className=' ms-1  ' icon={faCaretRight} />  </a>
                    </h4>
                    <div className="container pin_slider hidescroll bg-white   rounded pt-2 pb-2 ps-0">

                        <div className=" pin_slider_inner " style={{ width: trendingwidth }}>
                            {
                                trending.map((item, index) => {
                                    return <a key={index} href={'/shop/product/' + item.id + '/' + item.urlname.replace('/', '-')} className="text-dark single_post hvr-float">
                                        <div className=" rounded border m-2  ms-0 shadow-sm p-0">
                                            <div className='overflow-hidden w-100' style={{ height: 150 }}>
                                                <img src={shoputils.imageurl + item.image} className="d-block w-100" alt="..." />
                                            </div>
                                            <div className="p-2">
                                                <p className='m-0 onlinetext fw-bold selfcolor'>{item.name}</p>
                                                {
                                                    ResellerStatus == 0 ?
                                                        item.d == 'flash' ?
                                                            <div className='d-flex flex-row justify-content-between'>
                                                                <p className='font-small text-danger'><del>৳ {Math.round(item.c_price)}</del></p>
                                                                <h6 className='fw-bold mb-0'>৳ {Math.round(item.c_discountedprice)}</h6>

                                                            </div>

                                                            : item.d == 'discount' ?
                                                                <div className='d-flex flex-row justify-content-between'>
                                                                    <p className='font-small text-danger'><del>৳ {Math.round(item.c_price)}</del></p>
                                                                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.c_discountedprice)}</h6>

                                                                </div>

                                                                : item.d == 'none' ?

                                                                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.c_price)}</h6>


                                                                    :
                                                                    null
                                                        : item.d == 'flash' ?
                                                            <div className='d-flex flex-row justify-content-between'>
                                                                <p className='font-small text-danger'><del>৳ {Math.round(item.price)}</del></p>
                                                                <h6 className='fw-bold mb-0'>৳ {Math.round(item.discountedprice)}</h6>

                                                            </div>

                                                            : item.d == 'discount' ?
                                                                <div className='d-flex flex-row justify-content-between'>
                                                                    <p className='font-small text-danger'><del>৳ {Math.round(item.price)}</del></p>
                                                                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.discountedprice)}</h6>

                                                                </div >

                                                                : item.d == 'none' ?

                                                                    <h6 className='fw-bold mb-0'>৳ {Math.round(item.price)}</h6>


                                                                    :
                                                                    null
                                                }
                                            </div>
                                        </div>
                                    </a>
                                })
                            }

                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid   bg-lightgray pt-3">
                <div className="container bg-white  shadow-sm  rounded pt-2 pb-4">
                    <h4 className='overflow-hidden d-flex justify-content-between mb-0 align-items-center' style={{ marginLeft: -12 }}>
                        <div className="bg-warning float-start p-2 rounded-pill ps-5 pe-3" style={{ marginLeft: -30 }}>জনপ্রিয় পণ্য</div>
                        <a href='/shop/category/popular/জনপ্রিয় পণ্য' className="float-end fs-6 selfcolor">সকল প্রোডাক্ট <FontAwesomeIcon className=' ms-1  ' icon={faCaretRight} />  </a>
                    </h4>
                    <div className="container pin_slider hidescroll bg-white    rounded pt-2 pb-2 ps-0">

                        <div className=" pin_slider_inner " style={{ width: popularwidth }}>
                            {
                                popular.map((item, index) => {
                                    return <a href={'/shop/product/' + item.id + '/' + item.urlname.replace('/', '-')} key={index} className="text-dark single_post  hvr-pop">
                                        <div className="col rounded border m-2  ms-0 shadow-sm p-0">
                                            <div className="w-100 overflow-hidden mb-2" style={{ height: 150 }}>
                                                <img src={shoputils.imageurl + item.image} className="d-block w-100" alt="..." />
                                            </div>
                                            <p className='m-0 p-2 onlinetext'>{item.name}</p>

                                        </div>
                                    </a>
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid   bg-lightgray pt-3 pb-5" style={{ marginBottom: 35 }}>
                <div className="container p-0">
                    <h4 className='  selfbgcolor p-2 mb-0 rounded-top text-center text-white moreproduct_heading'>আপনার প্রয়োজনীয় আরো পণ্য</h4>
                </div>
                <div className="container bg-white  shadow-sm  pt-4 pb-4">

                    <div className="row">
                        {justforyou.map((item, index) => {
                            return renderItem(item, index)
                        })}
                    </div>
                    {
                        loadMore == true ?
                            <div className="col-12 text-center mb-4 pb-4">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            : null
                    }
                </div>

            </div>

        </div>
    )
}

export default Body;



