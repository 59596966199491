import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'hover.css/css/hover.css'

import '../src/index.css';


import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App/>
);




