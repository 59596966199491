
import React, { useEffect, useState, useRef } from 'react';

import SideMenu from '../SideMenu';

import { ErrorToast } from '../../utils/Utils';
import { useContext } from 'react';
import { CartContext } from '../Context';
const Body = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [deliveryCharge, setDeliveryCharge] = useState()
  const [paytype, setPaytype] = useState('')
  const shoputils = require('../../utils/shoputils.json');

  let cartdatas = localStorage.getItem('stgcartdetails');
  let cartdata = JSON.parse(cartdatas);

  let totalproductprice = cartdata.totalproductprice;
  let orderamount = cartdata.orderamount;
  let cashback = 0;
  let totalincome = cartdata.totalincome;
  const [votp, setvotp] = useState(null);
  const [uotp, setuotp] = useState(null);
  const [isguest, setisguest] = useState(false);
  const [size, setSize] = '';
  const { color } = '';
  // const    {size} = cartdata?.size
  // const    {color} = cartdata?.color
  const [qnty, setQnty] = useState(cartdata.qnty);
  const [district, setDistrict] = useState(cartdata.formdistrict);
  const [inputprice, setInputprice] = useState(cartdata.inputprice);
  const [formname, setformname] = useState(cartdata.formname);
  const [formaddress, setformaddress] = useState(cartdata.formaddress);
  const [formcontact, setformcontact] = useState(cartdata.formcontact);
  const [formcontact2, setformcontact2] = useState(cartdata.formcontact2);
  const [currentImage] = '';
  //const [cartitem,setCartITem] = useState([])
  const {cartitem,setcartitem} = useContext(CartContext);
  const [cart,setcart] = useState([])
  const [intruducer,setintruducer] = useState(1)
  
  cashback = Math.round(cashback);
  totalproductprice = Math.round(totalproductprice);
  orderamount = Math.round(orderamount);
  const [vendorid,setvendorid] = useState(1)
  const [vendorcity, setVendorcity] = useState('')
  const [nodata, setNodata] = useState(false);
  const [loggedin, setLoggedin] = useState(false);
  const [userstatus, setuserstatus] = useState(0)
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')
  const [ResellerStatus, setResellerStatus] = useState(0);
  const [error, seterror] = useState(null);
  const [isCheckedTerm, setIsCheckedTerm] = useState(false)
  const [isCheckedPolicy, setIsCheckedPolicy] = useState(false)
  const [points,setpoints] = useState(0)
  const [packagepoint,setpackagepoint] = useState(0)
  const [stgbalance,setstgbalance] = useState(0)
  const [pdata,setPdata] = useState([]);
  useEffect(() => {
    let lcart = localStorage.getItem('stgcart');
    setcart(JSON.parse(lcart));
    let ldata = JSON.parse(localStorage.getItem('pdata'))
    setPdata(ldata.filter(n => n))
    let storage = localStorage.getItem('stgcartdetails')
    
    let storedata = JSON.parse(storage);
    let cartitems = [];
    let lscart =  JSON.parse(lcart);
    
    if(lscart){
      lscart?.map((item,index)=>{
        let qnties = localStorage.getItem('cartitem' + item)  
        cartitems.push(qnties);
      })
    }
    setcartitem(cartitems)
    const users = localStorage.getItem("userinfo");
    if (users) {
      let token = localStorage.getItem('token');
      if (token) {
        setLoggedin(true);
      }
    }
    setData(storedata)
    //console.log(storedata.totalpoint)
    if (storedata.totalpoint <= 25) {
      setDeliveryCharge(100)
    } else if (storedata.totalpoint > 25 && storedata.totalpoint < 50) {
      setDeliveryCharge(60)
    } else if (storedata.totalpoint >= 50 && storedata.totalpoint < 100) {
      setDeliveryCharge(40)
    } else if (storedata.totalpoint >= 100) {
      setDeliveryCharge(0)
    }


    
  }, [])
  
  const showError = (msg) => {
    setIsError(true)
    setErrorMsg(msg)
    setTimeout(() => {
      setIsError(false)
    }, 3000);
  }

  const confirmOrder = async () => {
    //console.log(paytype)
    let neworderamount = 0;
    //console.log(cartitem)
    if (paytype == 'stgbalance') {
      if (stgbalance >= neworderamount) {
        const users = localStorage.getItem("userinfo");
        const user = JSON.parse(users);
        setLoading(true)
        if (cartitem.length > 0) {
          //console.log(cartitem)
          try {
            const random = Math.random();
            const response = await fetch(shoputils.stgapi + '/placeorder?random=' + random, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                
              },
              body: JSON.stringify({
                orderamount: neworderamount,
                vendorid: vendorid,
                data: pdata,
                cart: cart,
                uid: user.id,
                cartitem: cartitem,
                delivery_charge: deliveryCharge,
                paytype: paytype,
                intruducer: intruducer,
                district: district,
                address: formname + ' ' + formaddress + ' ' + formcontact + ' ' + formcontact2,
                contact:formcontact,
              }),
            });
            const json = await response.json();
            //console.log(json)

            if (json.success == 1) {
              showError('আপনার অর্ডার সফল হয়েছে')
              
            } else {
              showError(json.message)
              
            }


          } catch (e) {

            setLoading(false)

          } finally {

            setLoading(false)

          }
        }
      } else {
        showError('পর্যাপ্ত এসটিজি ব্যালেন্স নেই')
        
      }
    } else if (paytype == 'bkash') {
      try {
            const random = Math.random();
            const users = localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            let cartdatas = localStorage.getItem('stgcartdetails');
            let cartdata = JSON.parse(cartdatas);
            let formData = new FormData();
            formData.append('orderamount', (cartdata.totalproductprice+ + +deliveryCharge));
            formData.append('vendorid', vendorid);
            formData.append('data',JSON.stringify(pdata))
            formData.append('cart',cart)
            formData.append('uid',user.auth)
            formData.append('cartitem',JSON.stringify(cartitem))
            formData.append('delivery_charge',deliveryCharge)
            formData.append('paytype',paytype)
            formData.append('intruducer',intruducer)
            formData.append('contact',cartdata.formcontact)
            formData.append('address', cartdata.formname + ' ' + cartdata.formaddress + ' ' + cartdata.formcontact + ' ' + cartdata.formcontact2);
            
            //console.log(shoputils.stgapi + '/placeorder');
            
            const response = await fetch(shoputils.stgapi + '/placeorder?random=' + random+'&webpost=1', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
              },
              body: formData
            });


            const json = await response.json();
            if (json.success == 1) {
              window.location.href = json.geturl;
            } else {
              console.log(json.return)
              showError(json.message)

            }
            setLoading(false)
            setNodata(true);


          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false)
          }
    } else if (paytype == 'nagad') {
      try {
            const random = Math.random();
            const users = localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            let cartdatas = localStorage.getItem('stgcartdetails');
            let cartdata = JSON.parse(cartdatas);
            let formData = new FormData();

            
            formData.append('orderamount', (cartdata.totalproductprice+ + +deliveryCharge));
            formData.append('vendorid', vendorid);
            formData.append('data',JSON.stringify(pdata))
            formData.append('cart',cart)
            formData.append('uid',user.auth)
            formData.append('cartitem',JSON.stringify(cartitem))
            formData.append('delivery_charge',deliveryCharge)
            formData.append('paytype',paytype)
            formData.append('intruducer',intruducer)
            formData.append('contact',cartdata.formcontact)
            formData.append('address', cartdata.formname + ' ' + cartdata.formaddress + ' ' + cartdata.formcontact + ' ' + cartdata.formcontact2);
            
            //console.log(shoputils.stgapi + '/placeorder');
            
            const response = await fetch(shoputils.stgapi + '/placeorder?random=' + random+'&webpost=1', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
              },
              body: formData
            });


            const json = await response.json();
            if (json.success == 1) {
              window.location.href = json.geturl;
            } else {
              console.log(json.return)
              showError(json.message)

            }
            setLoading(false)
            setNodata(true);


          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false)
          }
    }

  }
  const changepaytype = (value) => {
    setPaytype(value);
  }
  const changeuotp = (e) => {
    setuotp(e.target.value);
  }

  const Sendotp = async () => {
    setisguest(true);

    try {
      let otp = Math.floor(100000 + Math.random() * 900000);
      setvotp(otp)
      const random = Math.random()
      const response = await fetch(shoputils.url + '?sendnumberverifyotp=1&random=' + random + '&contact=' + formcontact + '&otp=' + otp)
      const json = await response.json();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }

  }
  const paymentMustSelect = () => {
    showError('পেমেন্ট মেথড নির্বাচন করুন')
  }
  const bkashbutton = () => {
    return <div className='btn btn-outline-danger flex-fill shop_online_payment bkash_pay me-3' onClick={() => changepaytype('bkash')} data-type="bkash" >বিকাশ এবং অন্যান্য পেমেন্ট</div>
  }
  return (

    <div className="container-fluid">
      <div className="container-fluid  bg-lightgray">
        <div className="container  pt-3 pb-3 checkout_container">
          <div className="row ">
            <SideMenu />
            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
              <div className="selfbgcolor rounded-top p-2 text-center text-white">ডেলিভারি চার্জ অগ্রিম পরিশোধ করুন</div>
              <div className="bg-white p-2 rounded">
                <div className="border border-secondery dotted rounded p-2">
                  <p className="selfcolor m-0" >{data.formname}</p>
                  <p className='m-0'>{data.formaddress}</p>
                  <p className='m-0'>{data.formdistrict}</p>
                  <p className='m-0'>{data.formcontact},{data.formcontact2}</p>
                  <a href="/stg/address">এড্রেস পরিবর্তন</a>
                </div>
                <div className="bg-lightgray p-3 mt-2">
                  <div className='d-flex flex-row justify-content-between'>
                    <p className='m-0'>সর্বমোট অর্ডার মূল্য</p>
                    <p className='m-0'>{data.totalproductprice} টাকা</p>
                  </div>
                  <div className='d-flex flex-row justify-content-between'>
                    <p className='m-0'>ডেলিভারি চার্জ</p>
                    <p className='m-0'>{
                      deliveryCharge
                    } টাকা</p>
                  </div>
                  <hr className='m-0' />
                  <div className='d-flex flex-row justify-content-between fw-bold'>
                    <p className='m-0'>অগ্রিম পরিশোধ</p>
                    <p className='m-0'>{data.totalproductprice + + +deliveryCharge} টাকা</p>
                  </div>





                </div>
                <div className='d-flex float-left align-items-center mt-3'>
                  {/* <input type="checkbox" onChange={(e) => {
                   setIsCheckedTerm(e.target.checked)
                  }} className='m-2 float-left' /> <a href='/privecypolicy'> গোপনীয়তা নীতি </a> */}
                  <input type="checkbox" onChange={(e) => {
                    setIsCheckedPolicy(e.target.checked)
                  }} className='m-2 float-left' />I Agree With <a className="m-1" href='/returnrefundpolicy'> Return, Refund, Cancellation</a>, <a className="m-1" href='/privecypolicy'> Privecy Policy </a> and <a className="m-1" href='/termscondition'> Term condition</a>
                </div>
                <div className='mt-2'>
                  <p>পেমেন্ট মেথড নির্বাচন করুন :</p>
                  <div className='d-flex flex-row justify-content-between mt-2 mb-3'>
                    {/* <div className='btn btn-danger flex-fill me-3'>ভাউচার পেমেন্ট</div> */}
                    <div className='btn btn-outline-danger flex-fill shop_online_payment nagad_pay me-2' onClick={() => changepaytype('nagad')} data-type="nagad" >নগদ পেমেন্ট</div>
                    {bkashbutton()}
                  </div>
                </div>


                {
                  isError == true ?
                    ErrorToast(errorMsg)
                    : null
                }

                {

                  isCheckedPolicy == true ?

                    loggedin == false ?

                      <div data-bs-target="#login_modal" data-bs-toggle="modal" className="mt-3  fs-5 btn btn-light selfbgcolor w-100 text-white">
                        অর্ডার কনফার্ম করুন
                      </div>

                      :
                      paytype == '' ?
                        <div onClick={paymentMustSelect} className="mt-3 fs-5 btn btn-light selfbgcolor w-100 text-white">
                          অর্ডার কনফার্ম করুন
                        </div>
                        :
                        <div data-bs-target="#confirmmodal" data-bs-toggle="modal" className="mt-3  fs-5 btn btn-light selfbgcolor w-100 text-white">
                          অর্ডার কনফার্ম করুন
                        </div>
                    :

                    <div onClick={() => showError('রিটার্ন এবং রিফান্ড  পলিসি তে সম্মতি দিন  ')} className="mt-3  fs-5 btn btn-light selfbgcolor w-100 text-white">
                      অর্ডার কনফার্ম করুন
                    </div>

                }

                <div className="modal fade" id="confirmmodal">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body" >
                        {/* onClick={() => confirmOrder()} */}


                        {
                          ResellerStatus == 0 ?

                            loggedin == true ?
                              <p style={{ fontSize: 16, textAlign: 'justify', marginBottom: 10 }}>আপনি কি অর্ডার টি কনফার্ম করতে চান ? </p>
                              :
                              <>

                                <p style={{ fontSize: 16, textAlign: 'justify', marginBottom: 10 }}>যাচাইকরণ এর জন্য আপনার এই নাম্বারে ({formcontact}) একটি ৬ ডিজিটের ওটিপি কোড পাঠানো হয়েছে। নিচের ফর্মে কোড নাম্বার টি লিখুন।</p>

                                <input type="number" id="otpinput" onChange={changeuotp} style={{ borderWidth: 1, borderColor: '#ddd', padding: 5, borderRadius: 5, width: '100%', fontSize: 14, textAlign: 'center' }} placeholder="৬ ডিজিটের ওটিপি টি লিখুন" />

                              </>
                            :
                            <p style={{ fontSize: 16, textAlign: 'justify', marginBottom: 10 }}>রিসেলিং করার ক্ষেত্রে অবশ্যই গ্রাহক থেকে ডেলিভারি চার্জ এর টাকা এডভান্স পেমেন্ট হিসেবে নিয়ে তারপর অর্ডার করুন। অন্যথায় বেশিরভাগ প্রোডাক্ট গ্রাহক কুরিয়ার থেকে না তোলার কারণে ফেরত আসে। এতে গ্রাহক এর হয়ে আপনি নিজে থেকে যে এডভান্স পেমেন্ট প্রদান করছেন, তা আর ফেরত পাবেন না। ফলে আপনি অর্ডারও হারালেন, লসও দিতে হলো। তাই অবশ্যই গ্রাহক থেকে এডভান্স পেমেন্ট নিয়ে তারপর অর্ডার নিশ্চিত করুন।</p>
                        }
                        <p style={{ color: 'red', padding: 10, textAlign: 'center' }}>{error}</p>
                        <div className="d-flex flex-row justify-content-between" >
                          <div data-bs-dismiss="modal" className="btn btn-danger clearform flex-fill me-2" >
                            <p className="m-0" >বাতিল করুন</p>
                          </div>
                          <div onClick={() => confirmOrder()} className="btn btn-success flex-fill">
                            <p className="m-0" >কনফার্ম করুন</p>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Body;