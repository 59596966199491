import React from 'react';
import Header from '../components/Header';
import Body from '../components/ReturnRefundPolicyBody';
import Footer from '../components/Footer';

const ReturnRefundPolicy = () =>  {
    return(
        <div className='container-fluid p-0'>
            <Header/>
                <Body/>
            <Footer/>
        </div>
    )
}

export default ReturnRefundPolicy;