
import React,{useState,useEffect} from 'react';
import SideMenu from '../SideMenu'; 


import {useParams} from 'react-router-dom';
const Body = () =>  {
    const params = useParams();
    const shoputils = require('../../utils/shoputils.json');
    const [pagetype, setPagetype] = useState(params.pagetype)
    const [name, seName] = useState(params.name)
    const [loadMore,setLoadMore] = useState(false)
    const [loading,setLoading] = useState(false)
    const [nodata,setNodata] = useState(false);
    const [data,setData] =useState([])
    const [loadingmore,setLoadingMore] = useState(false)
    const [ResellerStatus,setResellerStatus] = useState(0)
    useEffect(() => {
        if (nodata == false) {
            fetchData();
            getResellerStatus();
          }
    })
    const getResellerStatus = async() => {
   
        try {
          setLoading(true);
          
          const users =  localStorage.getItem("userinfo");
          if(users){
            const user = JSON.parse(users);
            const random = Math.random()
            const response = await fetch(shoputils.url+'?getResellerStatus=1&random='+random+'&webpost=1&uid='+user.auth)
            const json = await response.json();
            
            if(user.status == 1){
              setResellerStatus(json.ResellerStatus)
            }
          }
          setLoading(true);
         } catch (error) {
           console.error(error);
         }  finally {
           setLoading(false)
         }
    }
      const fetchData = async () => {
        try {
          setLoading(true);
          const random = Math.random();
          if(pagetype == 'subcatwise'){
            const subcatid = localStorage.getItem('subcatid');
            
            const response = await fetch(shoputils.url+'?random='+random+'&sectioned='+pagetype+'&subcatid='+subcatid+'&name='+name);
            
            const json = await response.json();
            
            if(json.success == 1){
              
              //
             
             
                setData(json.products)
                setNodata(true)
                setLoading(false);
            }else{
                setNodata(true);
                setLoading(false);
    
            }
            setLoading(false);
            setNodata(true)
          }else if(pagetype == 'category'){
            
            
            const response = await fetch(shoputils.url+'?random='+random+'&sectioned='+pagetype+'&subcatid='+name+'&name='+name);
            
            const json = await response.json();
            
            if(json.success == 1){
              
              //
             
             
                setData(json.products)
                setNodata(true)
                setLoading(false);
            }else{
                setNodata(true);
                setLoading(false);
    
            }
            setLoading(false);
            setNodata(true)
          }else {
            const response = await fetch(shoputils.url+'?random='+random+'&sectioned='+pagetype);
            const json = await response.json();
            if(json.success == 1){
                setData(json.products)
                setNodata(true)
                setLoading(false);
            }else{
                setNodata(true);
                setLoading(false);
    
            }
            setLoading(false);
            setNodata(true)
          }
          
      } catch (error) {
          console.error(error);
          setLoading(false);
      }  finally {
          setLoading(false)
      } 
        
    };
    
    
      if(loadMore == false){
        window.onscroll = async function(ev) {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
                setLoadMore(true)
               
                try {
                  setLoadingMore(true);
                  var index = data.length;
                  var lastitem = data[index - 1]; //get 1st item in the array
                  if (lastitem) {
                    const random = Math.random();
                    if(pagetype == 'subcatwise'){
                      const {subcatid} = params.subcatid;
                      const {name} = params.name;
                      //alert(lastitem.id)
                      const response = await fetch(shoputils.url+'?random='+random+'&lastid='+lastitem.id+'&sectionedmore='+pagetype+'&subcatid='+subcatid+'&name='+name);
                      //console.log(response);
                      const json = await response.json();
                      
                      if(json.success == 1){
                        if(json.products.length > 0){
                            const responsedata = json.products;
                            let arry = [...data,...responsedata];
                            setData(arry);
                            setLoadMore(false)
                        }  
                        
                          setNodata(true)
                          setLoading(false);
                      }else{
                          setNodata(true);
                          setLoading(false);
                      }
                      setLoading(false);
                      setNodata(true)
                    }else if(pagetype == 'category'){
                      //alert('request');
                     
                      //alert(lastitem.id)
                      const response = await fetch(shoputils.url+'?random='+random+'&lastid='+lastitem.id+'&sectionedmore='+pagetype+'&subcatid='+name);
                      console.log(response);
                      const json = await response.json();
                      
                      if(json.success == 1){
                        if(json.products.length > 0){
                            const responsedata = json.products;
                            let arry = [...data,...responsedata];
                            setData(arry);
                            setLoadMore(false)
                        }  
                        
                          setNodata(true)
                          setLoading(false);
                      }else{
                          setNodata(true);
                          setLoading(false);
                      }
                      setLoading(false);
                      setNodata(true)
                    }else{
                      const response = await fetch(shoputils.url+'?random='+random+'&lastid='+lastitem.id+'&sectionedmore='+pagetype);
                      const json = await response.json();
                      
                      if(json.success == 1){
                        if(json.products.length > 0){
                          const responsedata = json.products;
                          let arry = [...data,...responsedata];
                          setData(arry);
                          setLoadMore(false)
                      }  
                          setNodata(true)
                          setLoading(false);
                      }else{
                          setNodata(true);
                          setLoading(false);
              
                      }
                      setLoading(false);
                      setNodata(true)
                    }
                      
                      
                         
                  }
                  setLoadingMore(false);
                } catch (e) {
                  setLoadingMore(false);
                } finally{
                  setLoadingMore(false);
                }
            }
        };
    }
   return(
    <div className="container-fluid bg-lightgray">
        <div className="container-fluid  ">
            <div className="container  pt-3 pb-3 checkout_container">
                <div className="row ">
                    <SideMenu/>
                    
                    <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                        <div className="selfbgcolor rounded-top p-2 text-center text-white fs-5 text-uppercase">{name}</div>
                        <div className="row">
                            {data.map((item,index) => {
                                return <a href={'/shop/product/'+item.id+'/'+item.name} key={index} className="col-lg-3 text-dark col-md-3 col-sm-6 col-6  p-2 ">
                                          <div className='rounded border  shadow-sm rounded overflow-hidden'>
                                              <div className='w-100 overflow-hidden justforyouitem' >
                                                <img src={shoputils.imageurl+item.img1} className="d-block w-100" alt="..."/>
                                              </div>
                                              <div className="bg-lightgray ps-2 pe-2">
                                                  <p className='m-0 text-left onlinetext selfcolor'>{item.name} </p>
                                                  {
                                                      ResellerStatus == 0 ?
                                                      item.d == 'flash' ?
                                                          <div className='d-flex flex-row justify-content-between'>
                                                          <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                                                          <h6 className='fw-bold mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>
                                                          
                                                          </div>
                                                          
                                                          : item.d == 'discount' ?
                                                          <div className='d-flex flex-row justify-content-between'>
                                                              <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                                                              <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>
                                                          
                                                          </div>
                                                          
                                                          : item.d == 'none' ?
                                                          
                                                          
                                                          <div className='d-flex flex-row justify-content-between'>
                                                              <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_price)}</h6>
                                                          </div >
                                                          
                                                          :null
                                                      : item.d == 'flash' ?
                                                      <div className='d-flex flex-row justify-content-between'>
                                                          <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                                                          <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>
                                                      
                                                      </div>
                                                      
                                                      : item.d == 'discount' ?
                                                      <div className='d-flex flex-row justify-content-between'>
                                                          <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                                                          <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>
                                                      
                                                      </div >
                                                      
                                                      : item.d == 'none' ?
                                                      <div className='d-flex flex-row justify-content-between'>
                                                          <h6 className='fw-bold  mb-2'>৳ {Math.round(item.price)}</h6>
                                                      </div >
                                                          
                                                      
                                                      
                                                          :
                                                          null
                                                  }
                                              </div>
                                          </div>
                                          
                                      </a>
                            })}
                            {
                                loadMore == true ?
                                <div className="col-12 text-center mb-4 pb-4">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                :null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
   )
}

export default Body;