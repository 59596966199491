
import React,{useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../components/SideMenu'; 
import {ErrorToast} from '../utils/Utils';
import { faCopy, faHandPointer, faMobile, faMobileScreenButton, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
const Body = () =>  {
    const [data,setData] = useState([]);
    const shoputils = require('../utils/shoputils.json');
    const [loadingMore,setLoadingMore] = useState(false);
    const [currentoperator,setCurrentoperator] = useState('Robi')
    const [currenttype,setcurrenttype] = useState('voice')
    const [minuteactive,setMinuteactive] = useState('active')
    const [internetactive,setInternetactive] = useState('')
    const [bundleactive,setBundleactive] = useState('')
    const [searchword,setsearchword] = useState('');
    const [robiactive,setrobiactive] = useState('bg-active') 
    const [gpactive,setgpactive] = useState('')
    const [blactive,setblactive] = useState('')
    const [aractive,setaractive] = useState('')
    const [ttactive,setttactive] = useState('')
    const [loadMore,setLoadMore] = useState(false)
    const [modaltype, setModaltype] = useState('details')
    const [currentItem,setCurrentItem] = useState([])
    const [firstdigit,setfirstdigit] = useState('018')
    const [submitoperatortype,setsubmitoperatortype]  = useState('prepaid')
    const [submitdivision,setsubmitdivision] = useState('ঢাকা')
    const [isError,setIsError] = useState(false);
    const [errorMsg,setErrorMsg] =useState('')
    const [pending,setPending] = useState([])
    const [currenthistory,setCurrentHistory] = useState([])
    const [allhistory,setAllHistory]= useState([])
    useEffect(() => {
        getData(currentoperator,currenttype,searchword)
        getPending()
        getAll()
    },[])
    const getData = async(operator,type,search='') => {
        try {
            setLoadingMore(true);
            const random = Math.random();
                 const response = await fetch(shoputils.recharge+'?random='+random+'&getSpecialPacks=0&webpost=1&operator='+operator+'&type='+type+'&search='+search);
                 const json = await response.json();
                if(json.success == 1){
                    //console.log(json)
                    setData(json.data);
                    setCurrentoperator(operator)
                    setcurrenttype(type)
                }else{
                    setData([]);
                   
                }
            
            
            setLoadingMore(false);
        } catch (error) {
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
        } 
    }
    const getPending = async() => {
        try {
            
            const users =  localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            
            setLoadingMore(true);
            const random = Math.random();
            
                 const response = await fetch(shoputils.recharge+'?random='+random+'&getPendingSpecialPack=0&webpost=1&auth='+user.auth);
                
                 const json = await response.json();
                 
                 if(json.success == 1){
                    
                    setPending(json.data);
                    
                }else{
                    setPending([]);
                   
                }
            
            
            setLoadingMore(false);
        } catch (error) {
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
        } 
    }
    const getAll = async() => {
        try {
            
            const users =  localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            
            setLoadingMore(true);
            const random = Math.random();
            
                 const response = await fetch(shoputils.recharge+'?random='+random+'&getAllSpecialPack=0&webpost=1&auth='+user.auth);
                
                 const json = await response.json();
                 
                 if(json.success == 1){
                    
                    setAllHistory(json.data);
                    
                }else{
                    setAllHistory([]);
                   
                }
            
            
            setLoadingMore(false);
        } catch (error) {
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
        } 
    }
    if(loadMore == false){
        window.onscroll = async function(ev) {
            
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
                setLoadMore(true)
                
                try {
                setLoadingMore(true);
                var index = data.length;
                var lastitem = data[index - 1]; //get 1st item in the array
               
                if(data.length > 19 ){

                    const users =  localStorage.getItem("userinfo");
                    const user = JSON.parse(users);
                    const random = Math.random();
                    const response = await fetch(shoputils.webget+'?random='+random+'&getSpecialPacks='+lastitem.id+'&webpost=1&operator='+currentoperator+'&type='+currenttype+'&search='+searchword);
                    const json = await response.json();
                    
                    if(json.data.length > 0){
                        const responsedata = json.data;
                        let arry = [...data, ...responsedata];
                        setData(arry);
                        setLoadMore(false)
                    }else{
                        setLoadMore(false)
                    }
                }
                setLoadMore(false);
            } catch (e) {
                setLoadingMore(false);
            } finally {
                setLoadMore(false);
            }
            }
        };
    }
    const icons = [
        require('../assets/operator/robi.png'),
        require('../assets/operator/airtel.png'),
        require('../assets/operator/banglalink.png'),
        require('../assets/operator/gp.png'),
        require('../assets/operator/teletalk.png')
      ];
    const ChangeOperator = (operator) => {
        if( operator == 'Robi' ){
            setfirstdigit('018')
        setrobiactive('bg-active')
        setgpactive('')
        setblactive('')
        setaractive('')
        setttactive('')
        }else if(  operator == 'Airtel' ){
            setfirstdigit('016')
            setrobiactive('')
            setgpactive('')
            setblactive('')
            setaractive('bg-active')
            setttactive('')
    }else if(  operator == 'Banglalink' ){
        setfirstdigit('019')
        setrobiactive('')
        setgpactive('')
        setblactive('bg-active')
        setaractive('')
        setttactive('')
    }else if(  operator == 'Grameen Phone' ){
        setfirstdigit('017')
        setrobiactive('')
        setgpactive('bg-active')
        setblactive('')
        setaractive('')
        setttactive('')
    }else if( operator == 'Teletalk'){
        setfirstdigit('015')
        setrobiactive('')
        setgpactive('')
        setblactive('')
        setaractive('')
        setttactive('bg-active')
        }
        
        getData(operator,currenttype)
    }
    const ChangeType = (type) => {
        setcurrenttype(type)
        if(type == 'voice'){
            setMinuteactive('active');
            setInternetactive('');
            setBundleactive('');
        }else if(type == 'internet'){
            setMinuteactive('');
            setInternetactive('active');
            setBundleactive('');
        }else if(type == 'bundle'){
            setMinuteactive('');
            setInternetactive('');
            setBundleactive('active');
        }
        getData(currentoperator,type)
    }
    const searchPackage = (e) => {
        let value = e.target.value;
        if(value.length > 2){
            getData(currentoperator,currenttype,value)
        }else{
            getData(currentoperator,currenttype)
        }
    }
    const currentPack = (item) => {
        setCurrentItem(item);
        
    }
    const typechanged = (e) => {
        let value = e.target.value;
        setsubmitoperatortype(value)
    }
    const divisionchanged = (e) => {
        let value = e.target.value;
        setsubmitdivision(value)
    }   
    const numberchanged = (e) => {
        let value = e.target.value;
        setfirstdigit(value)
    }
    const amountchanged = (e) => {
        let value = e.target.value;
    }
    const nextStep = (e) => {
        if(firstdigit.length == 11){
            setModaltype('preview')
        }else{
            showError('নম্বর দিন')
        }
    }
    const showError = (msg) => { 
        setIsError(true)
        setErrorMsg(msg) 
        setTimeout(() => {
            setIsError(false)
        }, 3000);
    }
    const confirmRecharge = async() => {
        try {
            setLoadingMore(true);
            const random = Math.random();
            const users =  localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            let formData = new FormData();
            
            formData.append('auth',user.auth);
            formData.append('webpost',1);
            formData.append('token',user.token);
            formData.append('driverequest',currentItem.id);
            formData.append('number',firstdigit);
            formData.append('division',submitdivision);
            const response = await fetch(shoputils.rechargepostapi+'?random='+random,{
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                body: formData
            });
                const json = await response.json();
            if(json.success == 1){
                showError(json.message)
                
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }else{
                showError('রিকোয়েস্ট সফল হয়নি, আবার চেষ্টা করুন');
                
            }
            
            
            setLoadingMore(false);
        } catch (error) {
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
        }
    }
    const cancelOrder = async(orderid) => {
        try {
            setLoadingMore(true);
            const random = Math.random();
            const users =  localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            let formData = new FormData();
            
            formData.append('auth',user.auth);
            formData.append('token',user.token);
            formData.append('webpost',1);
            formData.append('cancelspecialrecharge',orderid);
           
            const response = await fetch(shoputils.rechargepostapi+'?random='+random,{
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                body: formData
            });
                const json = await response.json();
            if(json.success == 1){
                showError(json.message)
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }else{
                showError('রিকোয়েস্ট সফল হয়নি, আবার চেষ্টা করুন');
                
            }
            
            
            setLoadingMore(false);
        } catch (error) {
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
        }
    }
    const acceptOrder = async(item) => {
        try {
            setLoadingMore(true);
            const random = Math.random();
            const users =  localStorage.getItem("userinfo");
            const user = JSON.parse(users);
            
            console.log(JSON.stringify(item))
           
            const response = await fetch('http://103.36.103.164:8080/self_digital/s_p_r/api/userAcceptSpacialPack/save',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Basic YWRzY3RnQDIwMjE6QCQjQWRzQ3RnMjAyMUAjXiQq',
                },
                body: JSON.stringify(item)
            });
                const json = await response.json();
                console.log(json)
            // if(json.success == 1){
            //     showError(json.message)
            //     setTimeout(() => {
            //         window.location.reload();
            //     }, 3000);
            // }else{
            //     showError('রিকোয়েস্ট সফল হয়নি, আবার চেষ্টা করুন');
                
            // }
            
            
            setLoadingMore(false);
        } catch (error) {
            setLoadingMore(false);
        }  finally {
            setLoadingMore(false)
        }
    }
   return(
    <div className="container-fluid">
        <div className="container-fluid  bg-lightgray">
                    <div className="container  pt-3 pb-3">
                        <div className="row ">
                            <SideMenu/>
                            
                            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                            <div className="modal" id='offerdetails'>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body overflow-hidden">
                                        {
                                            isError == true ? 
                                            ErrorToast(errorMsg)
                                            :null
                                        }
                                        {
                                        modaltype == 'details'? 
                                        <>
                                        <div   className="shadow-sm bg-lightgray  w-100  mt-2 p-2 rounded d-inline-flex flex-row justify-content-between" >
                                            <img className='rec_operator_icon' src={
                                                currentItem.operator == 'Robi' ? 
                                                icons[0]
                                                : currentItem.operator == 'Airtel' ?
                                                icons[1]
                                                : currentItem.operator == 'Banglalink' ?
                                                icons[2]  
                                                : currentItem.operator == 'Grameen Phone' ?
                                                icons[3]
                                                : currentItem.operator == 'Teletalk' ?
                                                icons[4]  
                                                :null
                                            } alt="" />
                                            <div className='w-50 offer_details_text'>
                                                <b>{currentItem.details}</b>
                                                <p className='text-secondary'>Cashback: {currentItem.cashback}</p>
                                                <p className="text-success">{currentItem.location}</p>
                                            </div>
                                            <div className='w-25 text-end flex-column d-flex justify-content-center align-items-center'>
                                                <p className="text-secondary"> {
                                                    currentItem.code ?
                                                    'Code '+ currentItem.code
                                                    :null
                                                }</p>
                                                <div className="badge bg-danger fs-6">{Math.round(currentItem.amount)}</div>
                                                <p className='m-0'>{currentItem.type}</p>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group">
                                            <input type="text" onChange={numberchanged} value={firstdigit}  className="form-control mt-3" />
                                            <input type="text" disabled onChange={amountchanged}  value={Math.round(currentItem.amount)} className="form-control mt-3" />
                                            <input type="text" value={
                                                currentoperator == 'Robi' ? 
                                                    'রবি'
                                                : currentoperator == 'Airtel' ?
                                                    'এয়ারটেল'
                                                : currentoperator == 'Banglalink' ?
                                                    'বাংলালিংক'
                                                : currentoperator == 'Grameen Phone' ?
                                                    'গ্রামীণ ফোন'
                                                : currentoperator == 'Teletalk' ?
                                                    'টেলিটক'
                                                :null
                                            } className="form-control mt-3" />
                                            
                                            <select onChange={typechanged} defaultValue={'prepaid'} id="" className="form-control mt-2">
                                                <option value="prepaid">প্রিপেইড</option>
                                                <option value="postpaid">পোস্টপেইড</option>
                                            </select>
                                            <label  className='mt-2 ms-1'>বিভাগ নির্বাচন করুন</label>
                                            <select onChange={divisionchanged} defaultValue={'ঢাকা'} className="form-control mt-2">
                                                <option value="ঢাকা">ঢাকা</option>
                                                <option value="চট্টগ্রাম">চট্টগ্রাম</option>
                                                <option value="রাজশাহী">রাজশাহী</option>
                                                <option value="খুলনা">খুলনা</option>
                                                <option value="বরিশাল">বরিশাল</option>
                                                <option value="সিলেট">সিলেট</option>
                                                <option value="ময়মনসিংহ">ময়মনসিংহ</option>
                                                <option value="রংপুর">রংপুর</option>
                                            </select>
                                            <input type="submit" onClick={() => nextStep()} defaultValue="পরবর্তী ধাপে যান" className="text-white w-100 btn selfbgcolor mt-3" />
                                            <input type="button" defaultValue="বন্ধ করুন" data-bs-dismiss="modal" d className="w-100  btn btn-dark mt-3" />
                                        </div>
                                        </>
                                        : modaltype == 'preview' ? 
                                        <div className="">
                                            <div className="d-flex align-items-center m-auto">
                                                <div className="rounded-pill me-3 shadow-sm bg-white align-items-center d-flex justify-content-center" style={{width:50,height:50}}>
                                                    <FontAwesomeIcon className=' fs-3  ' icon={faMobileScreenButton} />  
                                                </div>
                                                <div>
                                                    <p className="m-0">মোবাইল নাম্বার</p>
                                                    <p className="m-0">{firstdigit}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt-3  justify-content-between">
                                                <div className="border w-50 me-2 shadow-sm p-2 rounded">
                                                    <p className="m-0">রিচার্জের পরিমান</p>
                                                    <p className="m-0">{currentItem.amount} taka</p>
                                                </div>
                                                <div className="border w-50 shadow-sm p-2 rounded">
                                                    <p className="m-0">ক্যাশব্যাক</p>
                                                    <p className="m-0">{currentItem.cashback}</p>
                                                </div>
                                            
                                            </div>
                                            <div className="d-flex mt-3 justify-content-between">
                                                <div className="border w-50 me-2 shadow-sm p-2 rounded">
                                                    <p className="m-0">অপারেটর</p>
                                                    <p className="m-0">{currentItem.operator}</p>
                                                </div>
                                                <div className="border w-50  shadow-sm p-2 rounded">
                                                    <p className="m-0">ধরণ</p>
                                                    <p className="m-0">
                                                    {currentoperator}
                                                    </p>
                                                </div>
                                            
                                            </div>
                                            <div className="mb-3 text-white"> .</div>
                                            <div className="mb-3 text-white"> .</div>
                                            <div onClick={() => confirmRecharge()} className="rec_absolute_tap_btn text-center ">
                                                <FontAwesomeIcon className='fs-3' icon={faHandPointer} />  
                                                <br/>
                                                চাপ দিয়ে ধরে রাখুন
                                            </div>
                                            <div className="rec_rotator_box">
                                                <div className="rec_absolute_tap_btn2 text-center "></div>
                                                <div className="rec_absolute_tap_btn3 text-center "></div>
                                            </div>
                                                      
                                        </div>
                                    
                                        :null
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active fs-5" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">ড্রাইভ প্যাক</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link fs-5" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">পেন্ডিং</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link fs-5" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">হিস্ট্রি </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <img src={require('../img/banner/special-recharge.jpg')} className="rounded w-100" alt="" />
                                <div className="input-group mb-3 mt-3">
                                    <input type="text" className="form-control" onChange={searchPackage} placeholder="ড্রাইভ প্যাক সার্চ করুন" aria-label="" aria-describedby="button-addon2"/>
                                    <button className="btn btn-danger" type="button" id="button-addon2">সার্চ </button>
                                </div>
                                <div className="border p-2 border-danger mt-3 rounded mb-3">
                                    <h4 className='text-center w-100 mb-4 select_operator'>অপারেটর নির্বাচন করুন</h4>
                                    <div className="d-flex flex-row justify-content-evenly">
                                        <div className={"rec_iconround "+robiactive} onClick={() => ChangeOperator('Robi')}>
                                            <img src={require('../img/operator/robi.png')} className="w-100" alt="" />
                                        </div>
                                        <div className={"rec_iconround "+gpactive} onClick={() => ChangeOperator('Grameen Phone')}>
                                            <img src={require('../img/operator/gp.png')} className="w-100" alt="" />
                                        </div>
                                        <div className={"rec_iconround "+blactive} onClick={() => ChangeOperator('Banglalink')}>
                                            <img src={require('../img/operator/banglalink.png')} className="w-100" alt="" />
                                        </div>
                                        <div className={"rec_iconround "+aractive} onClick={() => ChangeOperator('Airtel')}>
                                            <img src={require('../img/operator/airtel.png')} className="w-100" alt="" />
                                        </div>
                                        <div className={"rec_iconround "+ttactive} onClick={() => ChangeOperator('Teletalk')}>
                                            <img src={require('../img/operator/teletalk.png')} className="w-100" alt="" />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-evenly mt-3">
                                        <div className={"btn btn-outline-danger  w-25 "+ minuteactive}  onClick={() => ChangeType('voice')}>
                                            মিনিট
                                        </div>
                                        <div className={"btn btn-outline-danger w-25"+ internetactive}  onClick={() => ChangeType('internet')}>
                                            ইন্টারনেট 
                                        </div>
                                        <div className={"btn btn-outline-danger w-25" + bundleactive}  onClick={() => ChangeType('bundle')}>
                                            বান্ডেল
                                        </div>
                                       
                                    </div>
                                </div>
                                <div className=" ">
                                    {
                                        data.length > 0 ?
                                        data.map((item,index) => {
                                            return <div key={index} onClick={() => currentPack(item)} data-bs-toggle="modal" data-bs-target="#offerdetails" className="shadow-sm bg-white  w-100  mt-2 p-2 rounded d-inline-flex flex-row justify-content-between" >
                                                        <img className='rec_operator_icon' src={
                                                            currentoperator == 'Robi' ? 
                                                            icons[0]
                                                            : currentoperator == 'Airtel' ?
                                                            icons[1]
                                                            : currentoperator == 'Banglalink' ?
                                                            icons[2]  
                                                            : currentoperator == 'Grameen Phone' ?
                                                            icons[3]
                                                            : currentoperator == 'Teletalk' ?
                                                            icons[4]  
                                                            :null
                                                        } alt="" />
                                                        <div className='w-50 offer_details_text'>
                                                            <b>{item.details}</b>
                                                            <p className='text-secondary'>Cashback: {item.cashback}</p>
                                                            <p className="text-success">{item.location}</p>
                                                        </div>
                                                        <div className='w-25 text-end flex-column d-flex justify-content-center align-items-center'>
                                                            <p className="text-secondary"> {
                                                                item.code ?
                                                                'Code '+ item.code
                                                                :null
                                                            }</p>
                                                            <div className="badge bg-danger fs-6">{Math.round(item.amount)}</div>
                                                            <p className='m-0'>{item.type}</p>
                                                        </div>
                                                    </div>
                                        })
                                        :null
                                    }
                                     {
                                        loadMore == true ?
                                        <div className="col-12 text-center mb-4 pb-4">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        :null
                                    }
                                    
                                </div>
                                
                                    
                                
                            </div>
                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                
                                <div className="rec_scroll_operator hidescroll">
                                    {
                                        pending.length > 0 ?
                                        pending.map((item,index) => {
                                            return <div key={index} onClick={() => setCurrentHistory(item)} data-bs-toggle="modal" data-bs-target="#historyModal"  className="bg-white rounded p-3 shadow-sm d-flex flex-row justify-content-between">
                                                        <div className="w-75">
                                                            <p className='m-0 fw-bold'>{item.number}</p>
                                                            <p className='m-0 text-secondary'>{item.details}</p>
                                                            <p className='m-0 text-warning'>cashback {item.amount} taka</p>
                                                            <p className='m-0 text-success'>{item.status  == '0' ? <p className="text-secondary">পেন্ডিং</p>:null}</p>
                                                            <p className='m-0'>{item.date+' '+item.time}</p>
                                                        </div>
                                                        <div className="bg-lightgray   text-center  p-2 rounded shadow-sm" >
                                                            <p className='m-0 d-block'>code1251</p>
                                                            
                                                            <img className='rec_operator_icon d-block' src={
                                                                item.operator == 'Robi' ? 
                                                                icons[0]
                                                                : item.operator == 'Airtel' ?
                                                                icons[1]
                                                                : item.operator == 'Banglalink' ?
                                                                icons[2]  
                                                                : item.operator == 'Grameen Phone' ?
                                                                icons[3]
                                                                : item.operator == 'Teletalk' ?
                                                                icons[4]  
                                                                :null
                                                            }  alt=""/>
                                                            
                                                            <div className="btn btn-outline-danger d-block mt-2 btn-sm">
                                                                ৳ 50
                                                            </div>
                                                            
                                                           
                                                            
                                                            <div className='badge bg-danger d-block mt-2'>বিস্তারিত</div>
                                                        </div>
                                                    </div>
                                            
                                        })
                                        :
                                        <p className="text-center mt-3 ">কোনো ডাটা নেই</p>

                                    }
                                    
                                    
                                </div>
                            </div>
                            <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            
                                <div className="  history_scroll">
                                {
                                        allhistory.length > 0 ?
                                        allhistory.map((item,index) => {
                                            return <div key={index} onClick={() => setCurrentHistory(item)} data-bs-toggle="modal" data-bs-target="#historyModal"  className="bg-white rounded p-3 shadow-sm d-flex flex-row justify-content-between">
                                                        <div className="w-75">
                                                            <p className='m-0 fw-bold'>{item.number}</p>
                                                            <p className='m-0 text-secondary'>{item.details}</p>
                                                            <p className='m-0 text-warning'>cashback {item.amount} taka</p>
                                                            
                                                            <p className='m-0'>{item.date+' '+item.time}</p>
                                                        </div>
                                                        <div className="bg-lightgray   text-center  p-2 rounded shadow-sm" >
                                                            
                                                            
                                                            <img className='rec_operator_icon d-block' src={
                                                                item.operator == 'Robi' ? 
                                                                icons[0]
                                                                : item.operator == 'Airtel' ?
                                                                icons[1]
                                                                : item.operator == 'Banglalink' ?
                                                                icons[2]  
                                                                : item.operator == 'Grameen Phone' ?
                                                                icons[3]
                                                                : item.operator == 'Teletalk' ?
                                                                icons[4]  
                                                                :null
                                                            }  alt=""/>
                                                            <p className='m-0 text-success'>{
                                                            item.status  == '0' ? 
                                                            <p className="text-secondary">পেন্ডিং</p>
                                                            :item.status == '1' ? 
                                                            <p className="text-secondary">গৃহীত</p>
                                                            :item.status == '2' ?
                                                            <p className="text-secondary">বাতিল</p>
                                                            :item.status == '3' ?
                                                            <p className="text-secondary">সম্পন্ন</p>
                                                            :item.status == '4' ?
                                                            <p className="text-secondary">বাতিল</p>
                                                            :item.status == '5' ?
                                                            <p className="text-secondary">সম্পন্ন</p>
                                                            :item.status == '6' ?
                                                            <p className="text-secondary">বাতিল</p>
                                                            :null}</p>
                                                            <div className="btn btn-outline-danger d-block mt-2 btn-sm">
                                                                ৳ {item.amount}
                                                            </div>
                                                            
                                                           
                                                            
                                                            <div className='badge bg-danger d-block mt-2'>বিস্তারিত</div>
                                                        </div>
                                                    </div>
                                            
                                        })
                                        :
                                        <p className="text-center mt-3 ">কোনো ডাটা নেই</p>

                                    }
                                </div>
                            </div>
                            </div>

                            <div className="modal" id='historyModal'>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                        {
                                            isError == true ? 
                                            ErrorToast(errorMsg)
                                            :null
                                        }
                                            <div class="modal-header text-center">
                                                <h1 class="modal-title fs-5" id="exampleModalLabel">ড্রাইভ প্যাক হিস্ট্রি</h1>
                                                <button type="button"  class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                    <div className="border mb-2  shadow-sm p-2 rounded">
                                                        <p className="m-0">ড্রাইভ প্যাক</p>
                                                        <p className="m-0">{currenthistory.details}</p>
                                                    </div>
                                                    <div className="border mb-2  shadow-sm p-2 rounded">
                                                        <p className="m-0">মোবাইল নাম্বার</p>
                                                        <p className="m-0">{currenthistory.number}</p>
                                                    </div>
                                                    <div className="border mb-2  shadow-sm p-2 rounded">
                                                        <p className="m-0">ক্যাশব্যাক</p>
                                                        <p className="m-0">{currenthistory.cashback}</p>
                                                    </div>
                                                    <div className="border mb-2   shadow-sm p-2 rounded">
                                                        <p className="m-0">রিচার্জের পরিমান</p>
                                                        <p className="m-0">{currenthistory.amount} taka</p>
                                                    </div>
                                                    <div className="border mb-2  shadow-sm p-2 rounded">
                                                        <p className="m-0">পূর্বের ব্যালান্স</p>
                                                        <p className="m-0">{currenthistory.old_bl}</p>
                                                    </div>
                                                    <div className="border  mb-2  shadow-sm p-2 rounded">
                                                        <p className="m-0">বর্তমান ব্যালান্স</p>
                                                        <p className="m-0">{currenthistory.new_bl} taka</p>
                                                    </div>
                                                    <div className="border  mb-2  shadow-sm p-2 rounded">
                                                        <p className="m-0">তারিখ</p>
                                                        <p className="m-0">{currenthistory.date}</p>
                                                    </div>
                                                    <div className="border   mb-2 shadow-sm p-2 rounded">
                                                        <p className="m-0">সময়</p>
                                                        <p className="m-0">
                                                        {currenthistory.time}
                                                        </p>
                                                    </div>
                                                    {
                                                        currenthistory.status == '0' ?
                                                        <div onClick={() => cancelOrder(currenthistory.id)} className="btn btn-danger w-100">বাতিল করুন</div>
                                                        :currenthistory.status == '1' ?
                                                        <div className="d-flex">
                                                            <div onClick={() => cancelOrder(currenthistory.id)} className="btn btn-danger me-3 w-100">বাতিল করুন</div>
                                                            <div onClick={() => acceptOrder(currenthistory)} className="btn btn-success w-100">সম্পন্ন করুন</div>
                                                        </div>
                                                        :null
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                           
                           
                        </div>
                    </div>
                </div>
    </div>
   )
}

export default Body;