
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../components/SideMenu';

import { faChevronCircleRight, faCopy, faExclamationCircle, faFile, faHandPointer, faHome, faList, faMobile, faMobileScreenButton, faPaperPlane, faSquare, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
const Body = () => {
    const shoputils = require('../utils/shoputils.json');
    const [loading, setLoading] = useState('')
    const [data, setData] = useState([])
    const [nodata, setNodata] = useState(false);
    const [status, setStatus] = React.useState(0);
    const [showdetails, setShowDetails] = useState('main')
    const [basicStatus, setBasicStatus] = React.useState(0);
    const [personalStatus, setPersonalStatus] = React.useState(0);
    const [documentStatus, setDocumentStatus] = React.useState(0);
    const [addressStatus, setAddressStatus] = React.useState(0);
    const [nomineeStatus, setNomineeStatus] = React.useState(0);
    const [additionalStatus, setAdditionalStatus] = React.useState(0);
    useEffect(() => {
        if (nodata == false) {
            getData();
        }
    },[])
    const getData = async () => {
        try {
            setLoading(true);

            const users = localStorage.getItem('userinfo');

            const user = JSON.parse(users)
            console.log(user,"user")
            const random = Math.random()
            let formData = new FormData();
            formData.append('getProfileInfo', user.auth);
            const response = await fetch(shoputils.webpost + '?random=' + random + '&webpost=1', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                //upload images
                body: formData
            });
            const json = await response.json();
            setLoading(false);
                setStatus(profilePercentageState(json.profileinfo, 'all'));
             
            if (json.success == 1) {
             
                setData(json.profileinfo)
                setStatus(profilePercentageState(json.profileinfo, 'all'));
                setBasicStatus(profilePercentageState(json.profileinfo, 'basic'));
                setDocumentStatus(profilePercentageState(json.profileinfo, 'document'));
                setPersonalStatus(profilePercentageState(json.profileinfo, 'personal'));
                setAddressStatus(profilePercentageState(json.profileinfo, 'address'));
                setNomineeStatus(profilePercentageState(json.profileinfo, 'nominee'));
                setAdditionalStatus(profilePercentageState(json.profileinfo, 'additional'));
            }
            setNodata(true);
        } catch (error) {
            console.error(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    const profilePercentageState = (user, type) => {
        let result = 0;
        if (type == 'all') {
            result += profilePercentageState(user, 'basic');
            result += profilePercentageState(user, 'document');
            result += profilePercentageState(user, 'personal');
            result += profilePercentageState(user, 'address');
            result += profilePercentageState(user, 'nominee');
            result += profilePercentageState(user, 'additional');
            var divide = result / 10;
            result = divide * 100 / 60
            return Math.round(result);
        } else if (type == 'basic') {
            if (user.username && user.username.length) {
                result += 20;
            } if (user.number) {
                result += 20;
            } if (user.status == 1) {
                result += 20;
            } if (user.email && user.email.length) {
                result += 20;
            } if (user.pic && user.pic.length) {
                result += 20;
            }
            return result;
        } else if (type == 'document') {
            if (user.nid && user.nid.length) {
                result += 40;
            } if (user.nid_front && user.nid_front.length) {
                result += 30;
            } if (user.nid_back && user.nid_back.length) {
                result += 30;
            }
            return result;
        } else if (type == 'personal') {
            if (user.father_name && user.father_name.length) {
                result += 20;
            } if (user.mother_name && user.mother_name.length) {
                result += 20;
            } if (user.birth_date && user.birth_date.length) {
                result += 20;
            } if (user.gender && user.gender.length) {
                result += 10;
            } if (user.blood_group && user.blood_group.length) {
                result += 10;
            } if (user.religion && user.religion.length) {
                result += 10;
            } if (user.marital && user.marital.length) {
                result += 10;
            }
            return result;
        } else if (type == 'address') {
            if (user.country && user.country.length) {
                result += 5;
            } if (user.divisionid && user.divisionid > 0) {
                result += 15;
            } if (user.districtid && user.districtid > 0) {
                result += 15;
            } if (user.upazilaid && user.upazilaid > 0) {
                result += 15;
            } if (user.unionid && user.unionid > 0) {
                result += 15;
            } if (user.livingdistrictid && user.livingdistrictid > 0) {
                result += 15;
            } if (user.location && user.location.length) {
                result += 20;
            }
            return result;
        } else if (type == 'nominee') {
            if (user.nominee_name && user.nominee_name.length) {
                result += 30;
            } if (user.nominee_mobile && user.nominee_mobile.length) {
                result += 25;
            } if (user.nominee_nid && user.nominee_nid.length) {
                result += 25;
            } if (user.nominee_relation && user.nominee_relation.length) {
                result += 20;
            }
            return result;
        } else if (type == 'additional') {
            if (user.education && user.education.length) {
                result += 20;
            } if (user.profession && user.profession.length) {
                result += 20;
            } if (user.incomeLevel && user.incomeLevel.length) {
                result += 15;
            } if (user.hobby && user.hobby.length) {
                result += 15;
            } if (user.interest && user.interest.length) {
                result += 15;
            } if (user.lifestyle && user.lifestyle.length) {
                result += 15;
            }
            return result;
        }
        return result;
    }
    return (
        <div className="container-fluid">
            <div className="container-fluid  bg-lightgray">
                <div className="container  pt-3 pb-3">
                    <div className="row ">
                        <SideMenu />

                        <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                            <div className="selfbggradient rounded text-center p-5 pb-0">
                                <div className="imgprofilebox " >
                                    <img src={'https://shopapp.self-shopping.com/public/viewimage?filename=profile/' + data.pic} className='w-100' alt="" />
                                </div>
                                <div>
                                    <h4 className='fw-bold text-white'>{data.username}</h4>
                                    <p>Profile Not Verified</p>
                                </div>
                                <div className="btn rounded-pill w-75 mb-2 mt-2 p-2 m-auto btn_gradient" style={{ marginBottom: -50 }}>
                                    <p className="m-0 small text-white fw-bold">Profile Complete {status}%</p>
                                </div>
                            </div>
                            {
                                showdetails == 'main' ?
                                    <>
                                        <div onClick={() => setShowDetails('besic')} className="bg-white rounded shadow-sm p-2 d-flex flex-row justify-content-between mt-2">
                                            <div className='d-flex '>
                                                <p className='align-self-center m-0 fs-6'>
                                                    <FontAwesomeIcon className='me-2' icon={faExclamationCircle} />
                                                    Besic Information </p>
                                            </div>
                                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                                <p className='m-0'>Complete</p>
                                                <p className='text-success fw-bold'>{basicStatus}%</p>
                                            </div>
                                        </div>
                                        <div className="bg-white rounded shadow-sm p-2 d-flex flex-row justify-content-between mt-2">
                                            <div className='d-flex '>
                                                <p className='align-self-center m-0 fs-6'>
                                                    <FontAwesomeIcon className='me-2' icon={faUser} />
                                                    Personal Information </p>
                                            </div>
                                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                                <p className='m-0'>Complete</p>
                                                <p className='text-success fw-bold'>{personalStatus}%</p>
                                            </div>
                                        </div>
                                        <div className="bg-white rounded shadow-sm p-2 d-flex flex-row justify-content-between mt-2">
                                            <div className='d-flex '>
                                                <p className='align-self-center m-0 fs-6'>
                                                    <FontAwesomeIcon className='me-2' icon={faFile} />
                                                    Documents </p>
                                            </div>
                                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                                <p className='m-0'>Complete</p>
                                                <p className='text-success fw-bold'>{documentStatus}%</p>
                                            </div>
                                        </div>
                                        <div className="bg-white rounded shadow-sm p-2 d-flex flex-row justify-content-between mt-2">
                                            <div className='d-flex '>
                                                <p className='align-self-center m-0 fs-6'>
                                                    <FontAwesomeIcon className='me-2' icon={faHome} />
                                                    Address </p>
                                            </div>
                                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                                <p className='m-0'>Complete</p>
                                                <p className='text-success fw-bold'>{addressStatus}%</p>
                                            </div>
                                        </div>
                                        <div className="bg-white rounded shadow-sm p-2 d-flex flex-row justify-content-between mt-2">
                                            <div className='d-flex '>
                                                <p className='align-self-center m-0 fs-6'>
                                                    <FontAwesomeIcon className='me-2' icon={faUserPlus} />
                                                    Nominee </p>
                                            </div>
                                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                                <p className='m-0'>Complete</p>
                                                <p className='text-success fw-bold'>{nomineeStatus}%</p>
                                            </div>
                                        </div>
                                        <div className="bg-white rounded shadow-sm p-2 d-flex flex-row justify-content-between mt-2">
                                            <div className='d-flex '>
                                                <p className='align-self-center m-0 fs-6'>
                                                    <FontAwesomeIcon className='me-2' icon={faList} />
                                                    Additional Information </p>
                                            </div>
                                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                                <p className='m-0'>Complete</p>
                                                <p className='text-success fw-bold'>{additionalStatus}%</p>
                                            </div>
                                        </div>
                                    </>
                                    : showdetails == 'besic' ?
                                        <>
                                            <div className="bg-white rounded shadow-sm p-2 mb-3 mt-2">
                                                <div className="text-center fs-6 fw-bold">
                                                    Besic Information
                                                </div>
                                                <div className="d-flex flex-row justify-content-start  mb-4">
                                                    <div className="align-self-center">
                                                        <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                    </div>
                                                    <div>
                                                        <div className='fw-bold'>Name </div>
                                                        <div>{data.username}</div>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-content-start mb-4 ">
                                                    <div className="align-self-center">
                                                        <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                    </div>
                                                    <div>
                                                        <div className='fw-bold'>Mobile </div>
                                                        <div>{data.number}</div>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-content-start  mb-4">
                                                    <div className="align-self-center">
                                                        <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                    </div>
                                                    <div>
                                                        <div className='fw-bold'>Affiliate ID </div>
                                                        <div>{data.id + 112233}</div>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-content-start mb-4 ">
                                                    <div className="align-self-center">
                                                        <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                    </div>
                                                    <div>
                                                        <div className='fw-bold'>Email </div>
                                                        <div>{data.email}</div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div onClick={() => setShowDetails('personal')} className="btn btn-secondary float-end">Next <FontAwesomeIcon className='   text-white' icon={faChevronCircleRight} /> </div>
                                        </>
                                        : showdetails == 'personal' ?
                                            <>
                                                <div className="bg-white rounded shadow-sm p-2 mb-3 mt-2">
                                                    <div className="text-center fs-6 fw-bold">
                                                        Personal Information
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-start  mb-4">
                                                        <div className="align-self-center">
                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                        </div>
                                                        <div>
                                                            <div className='fw-bold'>Father Name </div>
                                                            <div>{data.father_name}</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-start mb-4 ">
                                                        <div className="align-self-center">
                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                        </div>
                                                        <div>
                                                            <div className='fw-bold'>Mother Name </div>
                                                            <div>{data.mother_name}</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-start  mb-4">
                                                        <div className="align-self-center">
                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                        </div>
                                                        <div>
                                                            <div className='fw-bold'>Birth Date </div>
                                                            <div>{data.birth_date}</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-start mb-4 ">
                                                        <div className="align-self-center">
                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                        </div>
                                                        <div>
                                                            <div className='fw-bold'>Gender </div>
                                                            <div>{data.gender}</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-start mb-4 ">
                                                        <div className="align-self-center">
                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                        </div>
                                                        <div>
                                                            <div className='fw-bold'>Religion </div>
                                                            <div>{data.religion}</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-start mb-4 ">
                                                        <div className="align-self-center">
                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                        </div>
                                                        <div>
                                                            <div className='fw-bold'>Blood Group </div>
                                                            <div>{data.blood_group}</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-start mb-4 ">
                                                        <div className="align-self-center">
                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                        </div>
                                                        <div>
                                                            <div className='fw-bold'>Marital Status</div>
                                                            <div>{data.marital}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div onClick={() => setShowDetails('document')} className="btn btn-secondary float-end">Next <FontAwesomeIcon className='   text-white' icon={faChevronCircleRight} /> </div>
                                            </>
                                            : showdetails == 'document' ?
                                                <>
                                                    <div className="bg-white rounded shadow-sm p-2 mb-3 mt-2">
                                                        <div className="text-center fs-6 fw-bold">
                                                            Documents Information
                                                        </div>
                                                        <div className="d-flex flex-row justify-content-start  mb-4">
                                                            <div className="align-self-center">
                                                                <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                            </div>
                                                            <div>
                                                                <div className='fw-bold'>NID Number</div>
                                                                <div>{data.nid}</div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div onClick={() => setShowDetails('address')} className="btn btn-secondary float-end">Next <FontAwesomeIcon className='   text-white' icon={faChevronCircleRight} /> </div>
                                                </>
                                                : showdetails == 'address' ?
                                                    <>
                                                        <div className="bg-white rounded shadow-sm p-2 mb-3 mt-2">
                                                            <div className="text-center fs-6 fw-bold">
                                                                Address Information
                                                            </div>
                                                            <div className="d-flex flex-row justify-content-start  mb-4">
                                                                <div className="align-self-center">
                                                                    <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                </div>
                                                                <div>
                                                                    <div className='fw-bold'>Country</div>
                                                                    <div>{data.country}</div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row justify-content-start  mb-4">
                                                                <div className="align-self-center">
                                                                    <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                </div>
                                                                <div>
                                                                    <div className='fw-bold'>Division</div>
                                                                    <div>{data.divisionid}</div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row justify-content-start  mb-4">
                                                                <div className="align-self-center">
                                                                    <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                </div>
                                                                <div>
                                                                    <div className='fw-bold'>District</div>
                                                                    <div>{data.districtid}</div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row justify-content-start  mb-4">
                                                                <div className="align-self-center">
                                                                    <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                </div>
                                                                <div>
                                                                    <div className='fw-bold'>Upazila/Thana</div>
                                                                    <div>{data.upazilaid}</div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row justify-content-start  mb-4">
                                                                <div className="align-self-center">
                                                                    <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                </div>
                                                                <div>
                                                                    <div className='fw-bold'>Union/Word</div>
                                                                    <div>{data.unionid}</div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row justify-content-start  mb-4">
                                                                <div className="align-self-center">
                                                                    <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                </div>
                                                                <div>
                                                                    <div className='fw-bold'>Location</div>
                                                                    <div>{data.location}</div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row justify-content-start  mb-4">
                                                                <div className="align-self-center">
                                                                    <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                </div>
                                                                <div>
                                                                    <div className='fw-bold'>Living District</div>
                                                                    <div>{data.livingdistrictid}</div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div onClick={() => setShowDetails('nominee')} className="btn btn-secondary float-end">Next <FontAwesomeIcon className='   text-white' icon={faChevronCircleRight} /> </div>
                                                    </>
                                                    : showdetails == 'nominee' ?
                                                        <>
                                                            <div className="bg-white rounded shadow-sm p-2 mb-3 mt-2">
                                                                <div className="text-center fs-6 fw-bold">
                                                                    Nominee Information
                                                                </div>
                                                                <div className="d-flex flex-row justify-content-start  mb-4">
                                                                    <div className="align-self-center">
                                                                        <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                    </div>
                                                                    <div>
                                                                        <div className='fw-bold'>Name</div>
                                                                        <div>{data.nominee_name}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-row justify-content-start  mb-4">
                                                                    <div className="align-self-center">
                                                                        <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                    </div>
                                                                    <div>
                                                                        <div className='fw-bold'>Mobile</div>
                                                                        <div>{data.nominee_mobile}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-row justify-content-start  mb-4">
                                                                    <div className="align-self-center">
                                                                        <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                    </div>
                                                                    <div>
                                                                        <div className='fw-bold'>NID</div>
                                                                        <div>{data.nominee_nid}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-row justify-content-start  mb-4">
                                                                    <div className="align-self-center">
                                                                        <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                    </div>
                                                                    <div>
                                                                        <div className='fw-bold'>Nominee Relation</div>
                                                                        <div>{data.nominee_relation}</div>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div onClick={() => setShowDetails('additional')} className="btn btn-secondary float-end">Next <FontAwesomeIcon className='   text-white' icon={faChevronCircleRight} /> </div>
                                                        </>
                                                        : showdetails == 'additional' ?
                                                            <>
                                                                <div className="bg-white rounded shadow-sm p-2 mb-3 mt-2">
                                                                    <div className="text-center fs-6 fw-bold">
                                                                        Additional Information
                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-start  mb-4">
                                                                        <div className="align-self-center">
                                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                        </div>
                                                                        <div>
                                                                            <div className='fw-bold'>Education</div>
                                                                            <div>{data.education}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-start  mb-4">
                                                                        <div className="align-self-center">
                                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                        </div>
                                                                        <div>
                                                                            <div className='fw-bold'>Profession</div>
                                                                            <div>{data.profession}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-start  mb-4">
                                                                        <div className="align-self-center">
                                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                        </div>
                                                                        <div>
                                                                            <div className='fw-bold'>Monthly Income Level</div>
                                                                            <div>{data.incomeLevel}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-start  mb-4">
                                                                        <div className="align-self-center">
                                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                        </div>
                                                                        <div>
                                                                            <div className='fw-bold'>Hobby</div>
                                                                            <div>{data.hobby}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-start  mb-4">
                                                                        <div className="align-self-center">
                                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                        </div>
                                                                        <div>
                                                                            <div className='fw-bold'>Interest</div>
                                                                            <div>{data.interest}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-start  mb-4">
                                                                        <div className="align-self-center">
                                                                            <FontAwesomeIcon className='me-4 fs-1 selfcolor' icon={faCopy} />
                                                                        </div>
                                                                        <div>
                                                                            <div className='fw-bold'>Livestyle</div>
                                                                            <div>{data.lifestyle}</div>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                                <div onClick={() => setShowDetails('main')} className="btn btn-secondary float-end">Next <FontAwesomeIcon className='   text-white' icon={faChevronCircleRight} /> </div>
                                                            </>
                                                            : null
                            }


                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Body;