import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SideMenu from "../SideMenu";
import { useParams } from "react-router-dom";
import {
  faCamera,
  faChevronRight,
  faCloudDownload,
  faHandPointer,
  faMobile,
  faMobileScreenButton,
  faPaperPlane,
  faShare,
  faShoppingBag,
  faShoppingCart,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { saveAs } from "file-saver";
// import { Helmet } from 'react-helmet';
import { ErrorToast } from "../../utils/Utils";
const Body = () => {
  const shoputils = require("../../utils/shoputils.json");
  const params = useParams();
  const [productid, setProductId] = useState(params.id);
  const [loggedin, setLoggedin] = useState(false);
  const [pdata, setPdata] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentimage, setCurrentImage] = useState(null);
  const [refreshing, setRefreshing] = useState(null);
  const [favoritestatus, setFevoriteStatus] = useState(0);
  const [userstatus, setuserstatus] = useState(null);
  const [ResellerStatus, setResellerStatus] = useState(0);
  const [justforyou, setJustForYou] = useState([]);
  const [nodata, setNodata] = useState(false);
  const [reports, setReports] = useState([]);
  const [inputvisible, setInputvisible] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [data, setData] = useState(false);
  const [reportselected, setreportselected] = useState("");
  const [inputvalue, setinputvalue] = useState("");
  const [isspecialproduct, setisspecialproduct] = useState(false)
  const [showprice, setShowprice] = useState(0);
  const [currentImage, setCurrentImages] = useState('')
  const [qnty, setQnty] = useState(1);
  const [size, setSize] = useState(null)
  const [color, setColor] = useState(null)
  const [totalproductprice, setTotalproductprice] = useState(0)
  const [totalorderamount, setTotalorderamount] = useState(0)
  const [errors, setErrors] = useState(null)
  const [cashback, setCashback] = useState(0)
  const [inputprice, setInputprice] = useState(0)
  const [incometotal, setIncometotal] = useState(0)
  const [incomesingle, setIncomesingle] = useState(0)
  const [sizes, setSizes] = useState([])
  const [colors, setColors] = useState([])
  const [maxprice, setMaxprice] = useState(0);
  const [formname, setformname] = useState('');
  const [formaddress, setformaddress] = useState('');
  const [formcontact, setformcontact] = useState('');
  const [formcontact2, setformcontact2] = useState('');
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [showconfirmmodal, setshowconfirmmodal] = useState(false);
  const [districtlist, setDistrictlist] = useState([])
  const [district, setdistrict] = useState('');
  useEffect(() => {
    if (nodata == false) {
      fetchData();
      fetchDataCheckout()
      getData();
      getResellerStatus();
      let products = [{ pid: params.id }];
      localStorage.setItem("product", JSON.stringify(products));
      let url = window.location.href;
      if (url.includes('specialproduct') == true) {
        //setisspecialproduct(url.includes('specialproduct'))
        localStorage.setItem('isspecialproduct', '1')
        setisspecialproduct(true)
      } else {
        localStorage.setItem('isspecialproduct', '0')
        setisspecialproduct(false)
      }
    }
  }, []);
  const fetchDataCheckout = async () => {
    try {
      setLoading(true)
      let products = localStorage.getItem('product');
      let product = JSON.parse(products)
      let productid = product[0].pid;
      const random = Math.random();
      const users = localStorage.getItem("userinfo");
      var response;
      if (users) {
        var user = JSON.parse(users);
        response = await fetch(shoputils.url + '?random=' + random + '&getSingleProductDetails=' + productid + '&auth=' + user.auth);
        let token = localStorage.getItem('token');
        if (token) {
          setLoggedin(true);
        }

      } else {
        response = await fetch(shoputils.url + '?random=' + random + '&getSingleProductDetails=' + productid);
      }


      const json = await response.json();

      setPdata(json.singleproduct)
      setDeliveryCharge(json.singleproduct.deliveryCharge.outside)
      setSizes(Object.values(json.singleproduct.size));
      setColors(Object.values(json.singleproduct.color));
      setInputprice(json.singleproduct.resellPrice)
      //console.log(json.singleproduct)
      //setFevoriteStatus(json.fevoritestatus)
      setCurrentImage(json.singleproduct.image1)
      setuserstatus(json.usertype);
      //setPhoneNumber(json.vendornumber)
      //setReports(json.reports)
      setLoading(false)
      setNodata(true);
      if (json.usertype == 1) {
        setShowprice(json.singleproduct.regularPrice - json.singleproduct.discountAmount)
        setInputprice(Math.round(json.singleproduct.resellPrice))
        setIncomesingle(Math.round(json.singleproduct.resellPrice - json.singleproduct.discountedPrice))
        setIncometotal((json.singleproduct.resellPrice - json.singleproduct.discountedPrice) * qnty)
        setTotalproductprice((json.singleproduct.regularPrice - json.singleproduct.discountAmount) * qnty)
        setTotalorderamount(json.singleproduct.offerprice * qnty)
        setMaxprice(json.singleproduct.resellPrice);
      } else {
        setShowprice(json.singleproduct.regularPrice - json.singleproduct.discountAmount)
        // setInputprice(productdatas.regularPrice - productdatas.discountAmount)
        setCashback(json.singleproduct.cashback);
        setIncometotal((json.singleproduct.regularPrice - json.singleproduct.regularPrice) * qnty)
        setTotalproductprice((json.singleproduct.regularPrice - json.singleproduct.discountAmount) * qnty)
        setTotalorderamount((json.singleproduct.offerprice) * qnty)
        // setMaxprice(productdatas.resellPrice - productdatas.discountAmount);
      }
    } catch (error) {

    } finally {
      setLoading(false)
      setRefreshing(false)
    }


    setLoading(false);
  }
  const getData = async () => {
    try {
      const random = Math.random();
      const response = await fetch(shoputils.url + '?random=' + random + '&getdistricts=1&auth=' + random);
      const json = await response.json();
      //console.log(json.districts);
      setDistrictlist(json.districts);
    } catch (error) {
      console.error(error);
    } finally {

    }
  }
  const [redirect, setRedirect] = useState(true);
  if (redirect == true) {
    window.location.href =
      "SELF://self-shopping.com/productdetails?pid=" + productid;
    setRedirect(false);
  }
  function qntyminus() {
    let oldqnty = qnty;
    let newqnty = oldqnty - 1;
    //alert(newqnty);
    if (newqnty > 0) {
      setQnty(newqnty);
      setTotalproductprice(pdata.offerprice * newqnty);
      setIncometotal(incomesingle * newqnty);
      setTotalorderamount((pdata.offerprice * newqnty) + (incomesingle * newqnty))
      setCashback(pdata.cashback * newqnty)
    }
  }
  const changeInputPrice = (e) => {
    setInputprice(e.target.value);
    if (e.target.value > maxprice) {
      setInputprice(e.target.value)
      setIncomesingle(0);
      setIncometotal(0)
      setErrors('You can set this amount between ' + pdata.offerprice + ' and ' + maxprice)
    } else if (e.target.value < pdata.offerprice) {
      setInputprice(e.target.value)
      setIncomesingle(0);
      setIncometotal(0)
      setErrors('You can set this amount between ' + pdata.offerprice + ' and ' + maxprice)
    } else {
      setErrors(null)
      setInputprice(e.target.value);
      setIncomesingle(e.target.value - pdata.offerprice);
      setIncometotal((e.target.value - pdata.offerprice) * qnty)
      setTotalproductprice(pdata.offerprice * qnty)
      setTotalorderamount(((e.target.value - pdata.offerprice) * qnty) + (pdata.offerprice * qnty))
    }

  }
  const changeformname = (e) => {
    setformname(e.target.value);
  }
  const changeformaddress = (e) => {
    setformaddress(e.target.value);
  }
  const changeformdistrict = (e) => {
    setdistrict(e.target.value);

    if (pdata.vendorLocation == e.target.value) {
      setDeliveryCharge(pdata.deliveryCharge.inside)
    } else {
      setDeliveryCharge(pdata.deliveryCharge.outside)
    }
  }
  const changeformcontact = (e) => {
    setformcontact(e.target.value);
  }
  const changeformcontact2 = (e) => {
    setformcontact2(e.target.value);
  }
  const proceedtonext = () => {
    if (formname !== '' && formaddress !== '' && formcontact !== '' && formcontact2 !== '' && district !== '') {
      if (formcontact.length == 11) {
        if (formcontact2.length == 11) {
          if (formcontact !== formcontact2) {
            setshowconfirmmodal(true)
          } else {
            showError('গ্রাহকের কন্টাক্ট নম্বর এবং বিকল্প নম্বর ভিন্ন হতে হবে')
          }
        } else {
          showError('বিকল্প নম্বর ১১ সংখ্যার হতে হবে')
        }
      } else {
        showError('কন্টাক্ট নম্বর ১১ সংখ্যার হতে হবে')
      }
    } else {
      showError('সকল তথ্য প্রয়োজন')
    }
  }
  function qntyplus() {
    let oldqnty = qnty;
    let newqnty = oldqnty + 1;
    //alert(newqnty);
    if (newqnty > 0) {
      setQnty(newqnty);
      setTotalproductprice(pdata.offerprice * newqnty);
      setIncometotal(incomesingle * newqnty);
      setTotalorderamount((pdata.offerprice * newqnty) + (incomesingle * newqnty))
      setCashback(pdata.cashback * newqnty)
    }
  }
  //console.log(isspecialproduct)
  const showError = (msg) => {
    setIsError(true);
    setErrorMsg(msg);
    setTimeout(() => {
      setIsError(false);
    }, 3000);
  };
  const downloadImage = () => {
    saveAs(shoputils.imageurl + currentimage, currentimage);
  };
  const getResellerStatus = async () => {
    try {
      setLoading(true);

      const users = localStorage.getItem("userinfo");
      if (users) {
        const user = JSON.parse(users);
        const random = Math.random();

        const response = await fetch(
          shoputils.url +
          "?getResellerStatus=1&random=" +
          random +
          "&auth=" +
          user.auth
        );
        const json = await response.json();

        if (user.status == 1) {
          setResellerStatus(json.ResellerStatus);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const addToFevorite = async () => {
    setLoading(true);

    const users = localStorage.getItem("userinfo");
    const user = JSON.parse(users);
    if (user) {
      setLoading(true);

      setLoading(false);

      const random = Math.random();
      const response = await fetch(
        shoputils.url +
        "?random=" +
        random +
        "&addtofevorite=" +
        productid +
        "&auth=" +
        user.auth
      );
      const json = response.json();
      showError("ফেভারিট করা হয়েছে");
      setTimeout(() => window.location.reload(), 3000);
    } else {
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const random = Math.random();
      const users = localStorage.getItem("userinfo");
      var response;
      if (users) {
        var user = JSON.parse(users);
        response = await fetch(
          shoputils.url +
          "?random=" +
          random +
          "&justforyoumorenew=0&getSingleProductDetails=" +
          productid +
          "&auth=" +
          user.auth
        );
        let token = localStorage.getItem("token");
        if (token) {
          setLoggedin(true);
        }
      } else {
        //console.log(isspecialproduct)
        let url = window.location.href;
        if (url.includes('specialproduct') == true) {
          response = await fetch(
            shoputils.url +
            "?random=" +
            random +
            "&justforyoumorenewspecial=" + 15 + "&getSingleProductDetails=" +
            productid
          );
        } else {
          response = await fetch(
            shoputils.url +
            "?random=" +
            random +
            "&justforyoumorenew=0&getSingleProductDetails=" +
            productid
          );
        }
      }

      const json = await response.json();
      //console.log(json?.justforyou)
      setPdata(json?.singleproduct);
      setFevoriteStatus(json?.fevoritestatus);
      setJustForYou(json?.justforyou);
      setCurrentImage(json?.singleproduct.image1);
      setuserstatus(json?.usertype);
      //setPhoneNumber(json.vendornumber)
      setReports(json?.reports);
      setLoading(false);
      setNodata(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }

    setLoading(false);
  };
  const renderItem = (item, index) => {
    let urlstring = '';
    if (isspecialproduct == true) {
      urlstring = 'specialproduct';
    } else {
      urlstring = 'product';
    }
    return (
      <a
        href={"/shop/" + urlstring + "/" + item.id + "/" + item.name}
        key={index}
        className="text-dark col-lg-3 col-md-3 hvr-pop col-sm-6 col-6  p-2 ">
        <div className="rounded border  shadow-sm rounded overflow-hidden">
          <div className="w-100 overflow-hidden justforyouitem">
            <img
              src={shoputils.imageurl + item.img1}
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="bg-lightgray ps-2 pe-2">
            <p className="m-0 onlinetext fw-bold selfcolor">{item.name}</p>

            {ResellerStatus == 0 ? (
              item.d == "flash" ? (
                <div className="d-flex flex-row justify-content-between">
                  <p className="font-small text-danger mb-2">
                    <del>৳ {Math.round(item.c_price)}</del>
                  </p>
                  <h6 className="fw-bold mb-2">
                    ৳ {Math.round(item.c_discountedprice)}
                  </h6>
                </div>
              ) : item.d == "discount" ? (
                <div className="d-flex flex-row justify-content-between">
                  <p className="font-small text-danger mb-2">
                    <del>৳ {Math.round(item.c_price)}</del>
                  </p>
                  <h6 className="fw-bold  mb-2">
                    ৳ {Math.round(item.c_discountedprice)}
                  </h6>
                </div>
              ) : item.d == "none" ? (
                <div className="d-flex flex-row justify-content-between">
                  <h6 className="fw-bold  mb-2">
                    ৳ {Math.round(item.c_price)}
                  </h6>
                </div>
              ) : null
            ) : item.d == "flash" ? (
              <div className="d-flex flex-row justify-content-between">
                <p className="font-small text-danger mb-2">
                  <del>৳ {Math.round(item.price)}</del>
                </p>
                <h6 className="fw-bold  mb-2">
                  ৳ {Math.round(item.discountedprice)}
                </h6>
              </div>
            ) : item.d == "discount" ? (
              <div className="d-flex flex-row justify-content-between">
                <p className="font-small text-danger mb-2">
                  <del>৳ {Math.round(item.price)}</del>
                </p>
                <h6 className="fw-bold  mb-2">
                  ৳ {Math.round(item.discountedprice)}
                </h6>
              </div>
            ) : item.d == "none" ? (
              <div className="d-flex flex-row justify-content-between">
                <h6 className="fw-bold  mb-2">৳ {Math.round(item.price)}</h6>
              </div>
            ) : null}
          </div>
        </div>
      </a>
    );
  };
  const Stars = (props) => {
    var stars = props.stars;
    if (props.size) {
      var size = props.size;
    } else {
      size = 10;
    }
    if (stars == 5) {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
        </div>
      );
    } else if (stars == 4) {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else if (stars == 3) {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else if (stars == 2) {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else if (stars == 1) {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1 text-warning" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else if (stars == 0) {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1 " icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    } else {
      return (
        <div style={{ flexDirection: "row", marginRight: 10, marginTop: 5 }}>
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
          <FontAwesomeIcon className="  mt-1" icon={faStar} />
        </div>
      );
    }
  };
  // if (loadMore == false) {
  //   window.onscroll = async function (ev) {
  //     if (
  //       window.innerHeight + window.scrollY >=
  //       document.body.offsetHeight - 100
  //     ) {
  //       setLoadMore(true);
  //       try {
  //         setLoadingMore(true);
  //         var index = justforyou.length;
  //         var lastitem = justforyou[index - 1]; //get 1st item in the array

  //         if (lastitem) {
  //           const random = Math.random();
  //           let response = null;
  //           if(isspecialproduct == true){
  //              response = await fetch(
  //               shoputils.url + "?random=" + random + '&justforyoumorenewspecial='+params.id
  //             );
  //           }else{
  //              response = await fetch(
  //               shoputils.url + "?random=" + random + '&justforyoumorenew=""'
  //             );
  //           }
  //           const json = await response.json();
  //           const responsedata = json?.justforyoumorenew;
  //           console.log(json)
  //           if (json?.justforyoumorenew.length > 0) {
  //             let arry = [...justforyou, ...responsedata];
  //             setJustForYou(arry);
  //             setLoadMore(false);
  //           }
  //         }
  //         setLoadingMore(false);
  //       } catch (e) {
  //         setLoadingMore(false);
  //       } finally {
  //         setLoadingMore(false);
  //       }
  //     }
  //   };
  // }
  const changeSelectValue = (e) => {
    setreportselected(e.target.value);
  };
  const submitreport = async () => {
    if (reportselected == "অন্যান্য") {
      try {
        const users = localStorage.getItem("userinfo");

        if (users) {
          const user = JSON.parse(users);
          const random = Math.random();
          const response = await fetch(
            shoputils.url +
            "?random=" +
            random +
            "&reportadmin=" +
            inputvalue +
            "&pid=" +
            productid +
            "&auth=" +
            user.auth
          );
          const json = await response.json();

          if (json?.success == 1) {
            showError(response.message);
          }
        } else {
          window.location.href = "/";
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const users = localStorage.getItem("userinfo");

        if (users) {
          const user = JSON.parse(users);
          const random = Math.random();
          const response = await fetch(
            shoputils.url +
            "?random=" +
            random +
            "&reportadmin=" +
            reportselected +
            "&pid=" +
            productid +
            "&auth=" +
            user.auth
          );
          const json = await response.json();
          if (json?.success == 1) {
            showError(response.message);
          }
        } else {
          window.location.href = "/";
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const alreadyfevorite = () => {
    showError("আপনি ইতিমধ্যে প্রোডাক্টটি ফেভারিট করে রেখেছেন");
  };
  const confirmOrder = async () => {


    try {
      const random = Math.random();
      let cartdatas = localStorage.getItem('cartdetails');
      let cartdata = JSON.parse(cartdatas);
      let formData = new FormData();

      formData.append('specialorder', 1);
      formData.append('totalproductpricespecial', totalorderamount);
      formData.append('orderamount', totalorderamount);
      formData.append('totalincome', 0);
      formData.append('qnty', qnty);
      formData.append('district', district);
      formData.append('formname', formname);
      formData.append('formaddress', formaddress);
      formData.append('formcontact', formcontact);
      formData.append('formcontact2', formcontact2);
      formData.append('id', productid);
      formData.append('deliverycharge', deliveryCharge);
      //formData.append('paytype', paytype);
      formData.append('inputprice', 0);
      formData.append('size', size);
      formData.append('color', color);
      formData.append('cashback', 0);
      formData.append('currentImage', currentImage);
      const response = await fetch(shoputils.posturl + '?random=' + random + '&specialorder=0', {
        method: 'POST',
        headers: {
          Accept: 'application/json',

        },
        body: formData
      });

      const json = await response.json();

      if (json.success == 1) {
        window.location.href = '/shop/success/' + json.invoice;
      } else {
        showError(json.message)
      }
      setLoading(false)
      setNodata(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }
  return nodata == true ? (
    <div className="container-fluid">


      <div className="modal" id="refundPolicy">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div style={{ padding: 20, alignItems: "center" }}>
                <p className="h3 mb-3">রিফান্ড পলিসি </p>

                <p className="mb-4">
                  প্রোডাক্ট আনবক্সিং করার সময় মোবাইলে ভিডিও ধারণ করে রাখুন। এতে
                  প্রোডাক্টে কোনো সমস্যা থাকলে সেটা আপনার প্রমাণ হিসেবে কাজ
                  করবে। তারপর প্রোডাক্ট রিটার্ন দিয়ে দিন।{" "}
                </p>

                <p className="mb-4">
                  ভেন্ডর যদি প্রোডাক্ট রিটার্ন নিতে অস্বীকার করে অথবা তার
                  প্রোডাক্ট ঠিক আছে দাবী করে তাহলে আপনার ধারণকৃত আনবক্সিং
                  ভিডিওটি প্রমাণ হিসেবে চাওয়া হতে পারে। আনবক্সিং ভিডিও ছাড়া
                  রিটার্ন গ্রহণযোগ্য হবে না।{" "}
                </p>

                <p className="mb-4">
                  প্রোডাক্টে সমস্যা প্রমাণিত হলে ১০০% রিটার্ন গ্রহণ করা হবে।
                  এরপর প্রোডাক্ট রিপ্লেসমেন্ট অথবা রিফান্ড, যেকোনো একটি দাবী
                  গ্রহণযোগ্য হবে।
                </p>

                <p className="mb-4">
                  গ্যারান্টি/ওয়ারেন্টি যুক্ত প্রোডাক্ট এর ক্ষেত্রে অবশ্যই
                  গ্যারান্টি/ওয়ারেন্টি কার্ড সংরক্ষণ করবেন।
                  গ্যারান্টি/ওয়ারেন্টির মেয়াদ থাকাকালীন সুনির্দিষ্ট শর্তের আলোকে
                  গ্যারান্টি/ওয়ারেন্টি কার্যকর হবে।
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        showconfirmmodal == false ?
          <div className="modal" id="ordermodal">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-body">
                  <div style={{ alignItems: "center" }}>
                    <div className="container-fluid">
                      <div className="container-fluid  bg-lightgray">
                        <div className="container d-lg-flex flex-sm-row-reverse pt-3 pb-3 checkout_container">
                          <div className="flex-grow-3 mb-2 mt-2  addressbox">
                            <div className="selfbgcolor rounded-top p-2 text-center text-white">সাইজ, কালার, পরিমাণ এবং মূল্য নির্ধারণ</div>
                            <div className="bg-white p-3">
                              <div className=" mb-3  d-flex flex-row justify-content-between">
                                <p className='onlinetext  shop_ck_name fs-4 selfcolor'>{pdata.Name}</p>
                                <p className='fs-4 w-50 text-end '>TK {pdata.offerprice}</p>
                              </div>
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 border-end text-center">
                                  <img className='w-50' src={shoputils.imageurl + currentimage} alt="" />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 ">
                                  <p>পরিমান</p>
                                  <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <div onClick={qntyminus} className="btn btn-outline-secondary p-1 flex-fill me-2 fs-3 shop_ck_count_btns">-</div>
                                    <div className="btn btn-danger p-1 flex-fill me-2 fs-3 shop_ck_count_btns">{qnty}</div>
                                    <div onClick={qntyplus} className="btn btn-outline-secondary p-1 flex-fill fs-3 shop_ck_count_btns">+</div>
                                  </div>

                                </div>
                              </div>
                              <div className="row">
                                <div className="d-flex mt-3 flex-row mt-2 justify-content-between">
                                  <select name="" className='flex-fill me-3 p-2 form-control' id="">
                                    <option value="">Select Size</option>
                                    {sizes.map((item, index) => {
                                      <option key={index} value="">{item}</option>
                                    })}

                                  </select>
                                  <select name="" className='flex-fill p-2 form-control' id="">
                                    <option value="">Select Color</option>
                                    {colors.map((item, index) => {
                                      <option value="">{item}</option>
                                    })}
                                  </select>
                                </div>
                              </div>



                            </div>
                          </div>
                          <div className="flex-shrink mb-2 mt-2 me-2">
                            <div className="selfbgcolor rounded-top p-2 text-center text-white">ডেলিভারি এড্রেস দিন</div>
                            <div className="bg-white p-2 rounded d-flex flex-row">
                              <div className="me-2">
                                <div className="mb-3">
                                  <label className="form-label">নাম</label>
                                  <input className="form-control" type="text" onChange={changeformname} value={formname} placeholder='গ্রাহকের নাম' id="formFile" />
                                </div>
                                <div className="mb-3">
                                  <label className="form-label">ফোন নম্বর</label>
                                  <input className="form-control  form-control" onChange={changeformcontact} value={formcontact} placeholder='গ্রাহকের ফোন নম্বর' type="number" />
                                </div>

                              </div>

                              <div>
                                <div className="mb-3">
                                  <label className="form-label">সম্পূর্ণ ঠিকানা</label>
                                  <input className="form-control" onChange={changeformaddress} value={formaddress} placeholder='গ্রাহকের সম্পূর্ণ ঠিকানা' type="text" />
                                </div>

                                <div>
                                  <label className="form-label">বিকল্প ফোন নম্বর</label>
                                  <input className="form-control form-control" onChange={changeformcontact2} value={formcontact2} placeholder='বিকল্প ফোন নম্বর' type="number" />
                                </div>
                              </div>

                            </div>
                            <div className="p-2 pt-0 bg-white">
                              <div className="input-group mb-3">
                                <button className="btn btn-outline-secondary" type="button">জেলা</button>
                                <select className="form-select" value={district} onChange={changeformdistrict} placeholder='গ্রাহকের জেলা' id="inputGroupSelect03" aria-label="Example select with button addon">
                                  <option selected>নির্বাচন করুন...</option>
                                  {
                                    districtlist.length > 0 ?
                                      districtlist.map((item, index) => {
                                        return <option key={index} value={item.name}>{item.name}</option>
                                      })
                                      : null
                                  }

                                </select>
                              </div>
                            </div>
                          </div>


                        </div>
                        <div>
                          <div className="bg-lightgray p-3 mt-2">
                            <div className='d-flex flex-row justify-content-between'>
                              <p className='m-0 fs-6'>সর্বমোট অর্ডার মূল্য</p>
                              <p className='m-0 fs-6'>{Math.round(totalorderamount)} টাকা</p>
                            </div>
                            <div className='d-flex flex-row justify-content-between'>
                              <p className='m-0 fs-6'>ডেলিভারি চার্জ</p>
                              <p className='m-0 fs-6'>{deliveryCharge} টাকা</p>
                            </div>
                            <div className='d-flex flex-row justify-content-between'>
                              <p className='m-0 fs-6'>অগ্রিম পরিশোধ</p>
                              <p className='m-0 fs-6'>{deliveryCharge} টাকা</p>
                            </div>
                            <hr className='m-0' />
                            <div className='d-flex flex-row justify-content-between'>
                              <p className='m-0 fs-6 fw-bold'>ক্যাশ অন ডেলিভারি</p>
                              <p className='m-0 fs-6 fw-bold'>
                                {Math.round(totalorderamount)} টাকা</p>
                            </div>



                          </div>
                          <button onClick={proceedtonext} className="btn btn-light selfbgcolor w-100 fs-4 text-white">
                            অর্ডার কনফার্ম করুন
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null
      }
      {
        showconfirmmodal == true ?
          <div className="modal d-block" id="confirmmodal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body" >
                  {/* onClick={() => confirmOrder()} */}
                  <p style={{ fontSize: 16, textAlign: 'justify', marginBottom: 10 }}>আপনি কি অর্ডার টি কনফার্ম করতে চান ? </p>
                  <div className="d-flex flex-row justify-content-between" >
                    <div data-bs-dismiss="modal" className="btn btn-danger clearform flex-fill me-2" >
                      <p className="m-0" >বাতিল করুন</p>
                    </div>
                    <div onClick={() => confirmOrder()} className="btn btn-success flex-fill">
                      <p className="m-0" >কনফার্ম করুন</p>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
          : null
      }
      <div className="modal" id="reports">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              {/* <AntDesign onPress={() => setModalVisible(!modalVisible)} style={{ position: 'absolute', right: 8, top: 8, }} name="closecircleo" size={24} color="black" /> */}
              <p
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  color: "red",
                  marginBottom: 10,
                  marginTop: 30,
                }}>
                এই প্রোডাক্ট সম্পর্কে আপনার কোন অভিযোগ থাকলে অ্যাডমিন কে তথ্য
                দিন{" "}
              </p>
              <div style={{ width: "100%" }}>
                <select
                  onChange={changeSelectValue}
                  className="form-control report_content mb-3"
                  style={{
                    marginBottom: 10,
                    borderColor: "#ddd",
                    borderWidth: 1,
                  }}>
                  {reports.map((item, index) => {
                    return (
                      <option
                        color="black"
                        key={index}
                        label={item}
                        value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                <textarea
                  className="form-control d-none report_orhters mb-3"
                  placeholder={"অন্য কোনো সমস্যা থাকলে লিখুন"}></textarea>
                <button
                  onClick={submitreport}
                  className="btn btn-warning w-100 text-dark">
                  <p style={{ color: "#222" }}>রিপোর্ট সাবমিট করুন</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isError == true ? ErrorToast(errorMsg) : null}
      <div className="container-fluid  bg-lightgray">
        <div className="container  pt-2 pb-3">
          <div className="row ">
            <div className="d-flex text-nowrap bg-white text-secondary small mb-2  align-items-baseline">
              <a href="/" className="m-0 me-2 text-secondary">
                Home
              </a>
              <FontAwesomeIcon className=" me-2 mt-1" icon={faChevronRight} />
              <a
                href={"/shop/category/category/" + pdata.cat}
                className="m-0 me-2 text-secondary">
                {pdata.cat}
              </a>
              <FontAwesomeIcon className=" me-2 mt-1" icon={faChevronRight} />
              <a
                href={"/shop/category/category/" + pdata.cocat}
                className="m-0 me-2 text-secondary">
                {pdata.cocat}
              </a>
              <FontAwesomeIcon className=" me-2 mt-1" icon={faChevronRight} />
              <a
                href={"/shop/category/category/" + pdata.subcat}
                className="m-0 me-2 text-secondary">
                {pdata.subcat}
              </a>
            </div>
            <SideMenu />

            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
              {
                isspecialproduct == false ?
                  <div
                    className="totorial_button fw-normal badge bg-warning rounded-0 pe-4 rounded-pill text-dark fs-6"
                    data-bs-toggle="modal"
                    data-bs-target="#tutorial_modal">
                    অর্ডার করার পদ্ধতি
                  </div>
                  : null
              }
              <div className="modal" id="tutorial_modal">
                <div className="modal-dialog">
                  <div
                    className="modal-content"
                    style={{ height: 500, width: "100%" }}>
                    <div className="modal-body p-0">
                      <div className="selfbgcolor  rounded-top text-center text-white fs-5 p-2">
                        অর্ডার করার পদ্ধতি
                        <FontAwesomeIcon
                          data-bs-dismiss="modal"
                          className=" me-2 mt-1 float-end"
                          icon={faTimes}
                        />
                      </div>
                      {/* <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/KM0rrxWR-4s"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 bg-white rounded col-md-6 col-sm-12 col-12 pt-2">
                  <div className="modal" id="imagepreview">
                    <div className="modal-dialog modal-md">
                      <div className="modal-content">
                        <FontAwesomeIcon
                          className=" me-2 mt-1 border border-danger text-danger rounded-pill p-2 "
                          data-bs-dismiss="modal"
                          style={{
                            position: "absolute",
                            zIndex: 1,
                            right: 5,
                            top: 5,
                            width: 10,
                            height: 10,
                          }}
                          icon={faTimes}
                        />
                        <div className="modal-body p-0">
                          <img
                            className="w-100"
                            src={shoputils.imageurl + currentimage}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#imagepreview"
                    className=" shop_pd_mainimage_container overflow-hidden p-1 text-center border shadow-sm mb-3 m-auto">
                    <img
                      className="shop_pd_mainimage"
                      src={shoputils.imageurl + currentimage}
                      alt=""
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <img
                      className="w-25  border p-1 shadow-sm"
                      onClick={() => setCurrentImage(pdata.image1)}
                      src={shoputils.imageurl + pdata.image1}
                      alt="..."
                    />
                    <img
                      className="w-25  border p-1 shadow-sm"
                      onClick={() => setCurrentImage(pdata.image2)}
                      src={shoputils.imageurl + pdata.image2}
                      alt="..."
                    />
                    <img
                      className="w-25  border p-1 shadow-sm"
                      onClick={() => setCurrentImage(pdata.image3)}
                      src={shoputils.imageurl + pdata.image3}
                      alt="..."
                    />
                    <img
                      className="w-25 border p-1 shadow-sm"
                      onClick={() => setCurrentImage(pdata.image4)}
                      src={shoputils.imageurl + pdata.image4}
                      alt="..."
                    />
                  </div>
                </div>
                <div className="col-lg-6 bg-white p-2 rounded col-md-6 col-sm-12 col-12">
                  <div className="d-flex flex-row justify-content-between">
                    <div
                      onClick={downloadImage}
                      download
                      className="btn btn-danger shop_pd_btns  w-25 me-2 p-1"
                      style={{ width: "35%", lineHeight: "35px" }}>
                      <FontAwesomeIcon
                        className="me-1"
                        icon={faCloudDownload}
                      />
                      ডাউনলোড
                    </div>
                    {/* <div
                      className="btn btn-warning shop_pd_btns  me-2 p-1"
                      style={{width: "30%", lineHeight: "35px"}}>
                      <FontAwesomeIcon className="me-1" icon={faShoppingCart} />
                      কার্টে যুক্ত করুন
                    </div> */}
                    {
                      isspecialproduct == true ?
                        <a
                          href="#"
                          data-bs-target='#ordermodal'
                          data-bs-toggle="modal"
                          className="btn selfbgcolor  shop_pd_btns flex-fill p-1 fs-5 text-white ">
                          <FontAwesomeIcon className="mt-2" icon={faShoppingBag} />{" "}
                          অর্ডার করুন
                        </a>
                        :
                        <a
                          href="/shop/checkout"
                          className="btn selfbgcolor  shop_pd_btns flex-fill p-1 fs-5 text-white ">
                          <FontAwesomeIcon className="mt-2" icon={faShoppingBag} />{" "}
                          অর্ডার করুন
                        </a>
                    }
                  </div>
                  <div className=" p-2 ps-0 ">
                    <div>
                      <p className="me-3 shop_product_name flex-fill ">
                        {pdata.Name}
                      </p>
                      <div className="d-flex flex-row justify-content-start">
                        <p className="m-0 me-1 align-self-center">
                          প্রোডাক্ট কোড :
                        </p>
                        <p className="selfcolor mb-0 fs-6 fw-bold">
                          {pdata.code}
                        </p>
                      </div>
                    </div>

                    {loggedin == true ? (
                      userstatus == 1 ? (
                        <div className="pt-2 pb-2 text-end text-nowrap">
                          <div className=" text-secondary ">
                            <div className="fs-6 text-decoration-line-through">
                              {pdata.regularPrice} টাকা
                            </div>
                          </div>
                          <div className="d-flex flex-row selfcolor justify-content-between">
                            <div className="fs-4">
                              {Math.round(
                                pdata.regularPrice - pdata.discountAmount
                              )}{" "}
                              টাকা
                            </div>
                          </div>
                          <div className="d-flex flex-row justify-content-between">
                            <div class="">
                              <span class="small" style={{ fontSize: 12 }}>
                                সর্বোচ্চ বিক্রয় মূল্য
                              </span>
                              <br />
                              <span className="fs-5">
                                {Math.round(pdata.price)} টাকা
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="pt-2 pb-2 text-end text-nowrap">
                          <div className=" text-secondary ">
                            <div className="fs-6 text-decoration-line-through">
                              {pdata.regularPrice} টাকা
                            </div>
                          </div>
                          <div className="d-flex flex-row selfcolor justify-content-between">
                            <div className="fs-4">
                              {pdata.regularPrice - pdata.discountAmount} টাকা
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      (

                        isspecialproduct == true ?
                          (
                            <div className="pt-2 pb-2 text-end text-nowrap">
                              <div className="d-flex flex-row justify-content-between  text-secondary ">
                                <div>রেগুলার প্রাইস</div>
                                <div className="fs-6 text-decoration-line-through">
                                  {pdata.price} টাকা
                                </div>
                              </div>
                              <div className="d-flex flex-row selfcolor  align-items-center justify-content-between">
                                <div>অফার প্রাইস</div>
                                <div className="fs-4">
                                  {pdata.offerprice} টাকা
                                </div>
                              </div>
                            </div>
                          )
                          :
                          (

                            <div className="pt-2 pb-2 text-end text-nowrap">

                              <div className=" text-secondary ">
                                <div className="fs-6 text-decoration-line-through">
                                  {pdata.regularPrice} টাকা
                                </div>
                              </div>
                              <div className="d-flex flex-row selfcolor justify-content-between">
                                <div className="fs-4">
                                  {pdata.regularPrice - pdata.discountAmount} টাকা
                                </div>
                              </div>
                            </div>

                          )

                      )
                    )}
                  </div>

                  <div className="d-flex bg-lightgray p-2 mt-2">
                    <div className="flex-fill shop_pd_flex_content text-center border-end">
                      <p className="selfcolor m-0">ডেলিভারি সময়</p>
                      <p className=" m-0">{pdata.deliveryDuration} দিন</p>
                    </div>
                    <div className="flex-fill shop_pd_flex_content text-center border-end">
                      <p className="selfcolor m-0">ডেলিভারি চার্জ</p>
                      <p className="small">
                        {pdata.vendorLocation} {pdata.deliveryCharge.inside}{" "}
                        টাকা
                        <br />
                        {pdata.vendorLocation} এর বাইরে{" "}
                        {pdata.deliveryCharge.outside} টাকা
                      </p>
                    </div>
                    <a
                      href={"/shop/review/" + productid}
                      className="flex-fill shop_pd_flex_content text-center border-end">
                      <p className="selfcolor m-0">রিভিউ দেখুন </p>
                      <Stars stars={pdata.rating.overall} />
                    </a>
                  </div>
                  <div className="text-nowrap ps-3 pe-3 d-flex flex-row justify-content-between  align-self-stretch self_pd_stock align-text-center pt-2">
                    <div>
                      <p className="m-0">মজুদ আছে</p>
                    </div>
                    <div>
                      <p className="selfcolor mb-0  fw-bold">
                        {pdata.stock} টি
                      </p>
                    </div>
                  </div>

                  <div className="d-flex bg-lightgray p-2 mt-2">
                    <div
                      data-bs-toggle="modal"
                      data-bs-target="#reports"
                      className="flex-fill shop_pd_flex_content text-center border-end">
                      <p className="selfcolor m-0">এই প্রোডাক্ট সম্পর্কে</p>
                      <p>অ্যাডমিন কে রিপোর্ট করুন</p>
                    </div>
                    <div
                      data-bs-toggle="modal"
                      data-bs-target="#refundPolicy"
                      className="flex-fill shop_pd_flex_content text-center">
                      <p className="selfcolor m-0">রিফান্ড পলিসি</p>
                      <p>দেখুন</p>
                    </div>

                    <div className="flex-fill shop_pd_flex_content text-center">
                      <p className="selfcolor m-0">গ্যারেন্টি / ওয়ারেন্টি</p>
                      <p>
                        {pdata.guarenty == "" ? (
                          <span>Not Available</span>
                        ) : (
                          <span>pdata.guarenty</span>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mt-3 flex-row justify-content-between">
                    <a
                      href={"/shop/vendorsproduct/" + pdata.vendorid}
                      className="btn me-2 selfbgcolor flex-fill text-white">
                      স্টোর দেখুন
                    </a>
                    {loggedin == true ? (
                      favoritestatus == 1 ? (
                        <div
                          onClick={alreadyfevorite}
                          className="btn selfbgcolor text-white flex-fill ">
                          ফেভারিট করা হয়েছে
                        </div>
                      ) : (
                        <div
                          onClick={addToFevorite}
                          className="btn btn-primary flex-fill ">
                          ফেভারিট করে রাখুন
                        </div>
                      )
                    ) : (
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#login_modal"
                        className="btn btn-primary flex-fill ">
                        ফেভারিট করে রাখুন
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="offset-lg-2 offset-md-2 bg-white p-2 col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
              <h5>প্রোডাক্টের বিবরণ</h5>
              <textarea
                className="w-100 border-0"
                style={{ height: 300 }}
                value={pdata.description}
                readOnly>
                {pdata.description}
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid   bg-lightgray pt-3 pb-5"
        style={{ marginBottom: 35 }}>
        <div className="container p-0">
          <h4 className="  selfbgcolor p-2 mb-0 rounded-top text-center text-white moreproduct_heading">
            আপনার প্রয়োজনীয় আরো পণ্য
          </h4>
        </div>
        <div className="container bg-white  shadow-sm  pt-4 pb-4">
          <div className="row">
            {justforyou?.map((item, index) => {
              return renderItem(item, index);
            })}
          </div>
          {loadMore == true ? (
            <div className="col-12 text-center mb-4 pb-4">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};

export default Body;
