import React from 'react';
import Header from '../components/Header';
import Body from '../components/RechargeBody';
import Footer from '../components/Footer';

const LoginScreen = () =>  {
    
    return(
        <div className='container-fluid p-0'>
            <Header/>
            <Body/>
            <Footer/>
        </div>
    )
}

export default LoginScreen;