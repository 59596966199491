
import React,{useState,useEffect} from 'react';
import SideMenu from '../SideMenu'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useParams} from 'react-router-dom';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
const Body = () =>  {
    const params = useParams();
    const shoputils = require('../../utils/shoputils.json');
    const [percent, setPercent] = useState(params.percent)
    const [search,setSearch] = useState('')
    const [loadMore,setLoadMore] = useState(false)
    const [loading,setLoading] = useState(false)
    const [nodata,setNodata] = useState(false);
    const [data,setData] =useState([])
    const [ResellerStatus,setResellerStatus] = useState(0)
    
    
      const fetchData = async () => {
        try {
            setLoading(true);
            const random = Math.random();
            const response = await fetch(shoputils.url+'?random='+random+'&searchedvendor='+search);
            const json = await response.json();
                
                if(json.success == 1){
                    if(json.searchedvendor.length > 0){
                        setData(json.searchedvendor)
                        setNodata(true)
                    }
                }else{
                    setNodata(true);
                }
                setLoading(false);
                //console.log(data);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }  finally {
              setLoading(false)
            } 
        
    };
    const searchVendor = () => {
        fetchData()
    } 
    const changeValue = (e) => {
        setSearch(e.target.value);
    }
     
   return(
    <div className="container-fluid bg-lightgray">
        <div className="container-fluid  ">
            <div className="container  pt-3 pb-3 checkout_container">
                <div className="row ">
                    <SideMenu/>
                    
                    <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                        <div className="selfbgcolor rounded-top p-2 text-center text-white fs-5">ডিসকাউন্টেড প্রোডাক্টস</div>
                        <div className="input-group mb-3 mt-3">
                            <input type="text" onChange={changeValue} className="form-control" placeholder="ভেন্ডরের নাম দিয়ে সার্চ করুন" aria-label="Recipient's username" aria-describedby="button-addon2"/>
                            <button onClick={() => searchVendor()} className="btn selfbgcolor" type="button" id="button-addon2">
                                <FontAwesomeIcon className=' fs-3  text-white' icon={faMagnifyingGlass} />  
                            </button>
                        </div>

                        <div className="row">
                            {data.map((item,index) => {
                                return <a href={'/shop/vendorsproduct/'+item.uid} key={index} className="col-lg-3 text-dark col-md-3 col-sm-6 col-6  p-2 ">
                                          <div className='rounded border  shadow-sm rounded overflow-hidden bg-black vendorbox'  >
                                              <div className='w-100 overflow-hidden ' style={{height:150}}>
                                                <img src={shoputils.imageurl+item.banner} className="d-block w-100" alt="..."/>
                                              </div>
                                              <div className="bg-lightgray ps-2 pe-2 pt-2 pb-2">
                                                  <p className='m-0 text-left onlinetext selfcolor'>{item.company} </p>
                                              </div>
                                          </div>
                                          
                                      </a>
                            })}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
   )
}

export default Body;