
import React,{useState,useEffect} from 'react';
import SideMenu from '../SideMenu'; 


const Body = () =>  {
    const [nodata, setNodata] = useState(false);
    const shoputils = require('../../utils/shoputils.json');
    const [loadingMore, setLoadingMore] = React.useState(false);
    const [ResellerStatus,setResellerStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadMore,setLoadMore] = useState(false);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState('0');

  const [search, setSearch] = useState('');
    
    useEffect(() => {
        setCategory('0');
        if(data.length == 0){
          if(nodata == false){
          fetchData();
          getResellerStatus();
          }
        }
      }, []);
      const getResellerStatus = async() => {
    
        try {
          setLoading(true);
          
          const users =  localStorage.getItem("userinfo");
          if(users){
            const user = JSON.parse(users);
            const random = Math.random()
            const response = await fetch(shoputils.url+'?getResellerStatus=1&random='+random+'&auth='+user.auth)
            const json = await response.json();
          
            if(user.status == 1){
              setResellerStatus(json.ResellerStatus)
            }
          }
          setLoading(false);
         } catch (error) {
           console.error(error);
         }  finally {
           setLoading(false)
         }
    }
    if(loadMore == false){
        window.onscroll = async function(ev) {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
                setLoadMore(true)
               
                try {
                    setLoadingMore(true);
                    var index = data.length;
                    var lastitem = data[index - 1]; //get 1st item in the array
                    
                    if (lastitem) {
                        console.log(lastitem.id);
                        const random = Math.random();
                        const response = await fetch(shoputils.url+'?random='+random+'&varifiedproducts='+lastitem.id);
                        const json = await response.json();
                        const responsedata = json.varifiedproducts;
                        if(json.varifiedproducts.length > 0){
                            let arry = [...data, ...responsedata];
                            setData(arry);
                        }
                    }
                    setLoadingMore(false);
                  } catch (e) {
                    setLoadingMore(false);
                  } finally {
                      
                  }
            }
        };
    } 
      const fetchData = async () => {
             
        try {
          setLoadingMore(true);
          const random = Math.random();
          const response = await fetch(shoputils.url+'?random='+random+'&varifiedproducts=0');
          const json = await response.json();
          
          if(json.success == 1){
              setData([...json.varifiedproducts]);
              setNodata(true)
              setLoadingMore(false);
          }else{
              setNodata(true);
              setLoadingMore(false);
          }
          setLoadingMore(false);
          
      } catch (error) {
          console.error(error);
          setLoadingMore(false);
      }  finally {
          setLoadingMore(false)
      } 
    };
    const renderItem = (item,index) => {
        return (
            <a key={index} href={'/shop/product/'+item.id+'/'+item.name}  className="col-lg-3 text-dark col-md-3 col-sm-6 col-6  p-2 ">
                <div className='rounded border  shadow-sm rounded overflow-hidden' style={{position:'relative'}}>
                    
                    
                        <div className='overflow-hidden' style={{height:200}}>
                        <img src={shoputils.imageurl+item.img1} className="d-block w-100" alt="..."/>
                    </div>
                    <div className="bg-lightgray text-dark ps-2 pe-2">
                        <p className='m-0 text-left onlinetext'>{item.name} </p>
                        {
                            ResellerStatus == 0 ?
                            item.d == 'flash' ?
                                <div className='d-flex flex-row justify-content-between'>
                                <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                                <h6 className='fw-bold mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>
                                
                                </div>
                                
                                : item.d == 'discount' ?
                                <div className='d-flex flex-row justify-content-between'>
                                    <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.c_price)}</del></p>
                                    <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_discountedprice)}</h6>
                                
                                </div>
                                
                                : item.d == 'none' ?
                                
                                
                                <div className='d-flex flex-row justify-content-between'>
                                    <h6 className='fw-bold  mb-2'>৳ {Math.round(item.c_price)}</h6>
                                </div >
                                
                                    :
                                    null
                            : item.d == 'flash' ?
                            <div className='d-flex flex-row justify-content-between'>
                                <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>
                            
                            </div>
                            
                            : item.d == 'discount' ?
                            <div className='d-flex flex-row justify-content-between'>
                                <p className='font-small text-danger mb-2'><del>৳ {Math.round(item.price)}</del></p>
                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.discountedprice)}</h6>
                            
                            </div >
                            
                            : item.d == 'none' ?
                            <div className='d-flex flex-row justify-content-between'>
                                <h6 className='fw-bold  mb-2'>৳ {Math.round(item.price)}</h6>
                            </div >
                                
                            
                            
                                :
                                null
                        }
                    </div>
                    
                </div>
                
            </a>
        );
      }
   return(
    <div className="container-fluid">
        <div className="container-fluid  bg-lightgray">
                    <div className="container  pt-3 pb-3 checkout_container">
                        <div className="row ">
                            <SideMenu/>
                            
                            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                                <div className="selfbgcolor rounded-top p-2 text-center text-white fs-5">ভ্যারিফাইড প্রোডাক্ট</div>
                                <div className="row">
                                    {data.map((item,index) => {
                                        return renderItem(item,index)
                                    })}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
    </div>
    
   )
}

export default Body;