
import React,{useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../SideMenu'; 
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import { faRectangleList, faSquare } from '@fortawesome/free-solid-svg-icons';


const Body = () =>  {
    const shoputils = require('../../utils/shoputils.json');
    const [data, setData] = useState([]);
    
    const [loading,setLoading] = useState(false);
    const [nodata, setNodata] = useState(false);

    useEffect(() => {
        if(nodata == false){
            getData()
        }
    },[])
    const getData = async () => {
        setLoading(true);
        try {
        const users = localStorage.getItem("userinfo");
        const user = JSON.parse(users);
        const random = Math.random();
        const response = await fetch(shoputils.url+'?random='+random+'&utype=user&vendorearnings=1&auth='+user.auth);
            const json = await response.json();
            console.log(json)
            setData(json.earnings);
            setLoading(false)
            setNodata(true)
        } catch (error){
            console.error(error);
        }  finally {
           
        }
    }
   return(
    <div className="container-fluid">
        {nodata == true ? 
        <div className="container-fluid  bg-lightgray">
        <div className="container  pt-3 pb-3 checkout_container">
            <div className="row ">
                <SideMenu/>
                
                <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                    <div className="selfbgcolor rounded-top p-2 text-center text-white fs-5">রিসেলার ড্যাশবোর্ড</div>
                    <div className="shop_gradient_top p-3 overflow-hidden">
                        <div className="d-flex justify-content-between">
                        <h4 className='text-white shop_dash_name'>{data[0].shopName}</h4>
                        <h4 className='shop_dash_text'>সর্বমোট আয়</h4>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="shop_dash_income align-self-end">{Math.round(data[0].totalSalesAmount)} টাকা</div>
                        </div>
                        <div className='text-white'>
                            {data[0].date}
                        </div>
                    </div>   
                    <div className='bg-lightgray pt-3'>
                        <h6 className='selfbgcolor p-2 rounded m-0 text-white'>আয়ের পরিসংখ্যান</h6>
                        <div className="bg-white p-3">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <FontAwesomeIcon className=' fs-4 me-3 ' icon={faSquare} />
                                    <div className="">
                                        <p className='m-0 text-secondary'>আয়ের পরিমান</p>
                                        <p className='m-0 fw-bold'>{data[0].salesAmount.today} টাকা</p>
                                    </div>  
                                </div>
                                <div>আজকে</div>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <FontAwesomeIcon className=' fs-4  me-3' icon={faSquare} />
                                    <div className="">
                                        <p className='m-0 text-secondary'>আয়ের পরিমান</p>
                                        <p className='m-0 fw-bold'>{data[0].salesAmount.yesterday}  টাকা</p>
                                    </div>  
                                </div>
                                <div>গতকাল</div>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <FontAwesomeIcon className=' fs-4 me-3 ' icon={faSquare} />
                                    <div className="">
                                        <p className='m-0 text-secondary'>আয়ের পরিমান</p>
                                        <p className='m-0 fw-bold'>{data[0].salesAmount.last7day} টাকা</p>
                                    </div>  
                                </div>
                                <div>শেষ ৭ দিন  </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <FontAwesomeIcon className=' fs-4 me-3 ' icon={faSquare} />
                                    <div className="">
                                        <p className='m-0 text-secondary'>আয়ের পরিমান</p>
                                        <p className='m-0 fw-bold'>{data[0].salesAmount.last30day} টাকা</p>
                                    </div>  
                                </div>
                                <div>শেষ ৩০ দিন  </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <FontAwesomeIcon className=' fs-4 me-3 ' icon={faSquare} />
                                    <div className="">
                                        <p className='m-0 text-secondary'>আয়ের পরিমান</p>
                                        <p className='m-0 fw-bold'>{data[0].salesAmount.total} টাকা</p>
                                    </div>  
                                </div>
                                <div>সর্বমোট </div>
                            </div>
                        





                        </div>
                    </div>
                    <div className='bg-lightgray pt-3'>
                        <h6 className='selfbgcolor p-2 rounded m-0 text-white'>অর্ডারের পরিমান</h6>
                        <div className="bg-white p-3">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <FontAwesomeIcon className=' fs-4 me-3 ' icon={faSquare} />
                                    <div className="">
                                        <p className='m-0 text-secondary'>অর্ডারের সংখ্যা</p>
                                        <p className='m-0 fw-bold'>{data[0].salescount.today} টাকা</p>
                                    </div>  
                                </div>
                                <div>আজকে</div>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <FontAwesomeIcon className=' fs-4  me-3' icon={faSquare} />
                                    <div className="">
                                        <p className='m-0 text-secondary'>অর্ডারের সংখ্যা</p>
                                        <p className='m-0 fw-bold'>{data[0].salescount.yesterday} টাকা</p>
                                    </div>  
                                </div>
                                <div>গতকাল</div>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <FontAwesomeIcon className=' fs-4 me-3 ' icon={faSquare} />
                                    <div className="">
                                        <p className='m-0 text-secondary'>অর্ডারের সংখ্যা</p>
                                        <p className='m-0 fw-bold'>{data[0].salescount.last7day} টাকা</p>
                                    </div>  
                                </div>
                                <div>শেষ ৭ দিন  </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <FontAwesomeIcon className=' fs-4 me-3 ' icon={faSquare} />
                                    <div className="">
                                        <p className='m-0 text-secondary'>অর্ডারের সংখ্যা</p>
                                        <p className='m-0 fw-bold'>{data[0].salescount.last30day} টাকা</p>
                                    </div>  
                                </div>
                                <div>শেষ ৩০ দিন  </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                    <FontAwesomeIcon className=' fs-4 me-3 ' icon={faSquare} />
                                    <div className="">
                                        <p className='m-0 text-secondary'>অর্ডারের সংখ্যা</p>
                                        <p className='m-0 fw-bold'>{data[0].salescount.total} টাকা</p>
                                    </div>  
                                </div>
                                <div>সর্বমোট </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    :null
        }
    </div>
   )
}

export default Body;