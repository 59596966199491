import React, { useEffect, useState, useRef,useContext } from "react";
import { CartContext } from "../Context";



const StgCheckoutContentbox = ({ data, status, total, settotal, totalpoint, settotalpoint }) => {
    const [income, setincome] = useState(data.price - data.price)
    const [componenttotalpoint, setcomponenttotalpoint] = useState(data.points)
    const [compprice, setcompprice] = useState(parseInt(data.price))
    const [inputprice, setinputprice] = useState(data.price)
    const [maxprice, setmaxprice] = useState(Math.round(data.price));
    const [qnty, setQnty] = useState(1)
    const [totalproductprice, setTotalproductprice] = useState(data.price);
    const [showprice, sethowprice] = useState(data.price)
    //const [sizes,setSizes] = useState(Object.values(data.size));
    const [size, setSize] = useState('')
    const {cartitem,setcartitem} = useContext(CartContext);
    const [color, setColor] = useState('')
    //const [colors,setColors] = useState(Object.values(data.color));

    
    
    async function qntyminus() {
        //await AsyncStorage.setItem('cartqnty', JSON.stringify(null));
        let oldqnty = qnty;
        let newqnty = oldqnty - 1;
        //alert(newqnty);
        if (newqnty > 0) {
            setQnty(newqnty);
            setTotalproductprice(inputprice * newqnty)
            localStorage.setItem('cartitem' + data.id, JSON.stringify({ pid: data.id, qnty: newqnty }))
            let newtotalpoint = newqnty * data.points
            setcomponenttotalpoint(newtotalpoint)
            settotalpoint(totalpoint - parseFloat(data.points))
            let nprice = parseFloat(data.price)
            settotal(total - nprice)
        }
    }
    async function qntyplus() {
        let oldqnty = qnty;
        let newqnty = oldqnty + 1;
        if (newqnty > 0) {
            setQnty(newqnty);
            setTotalproductprice(inputprice * newqnty)
            localStorage.setItem('cartitem' + data.id, JSON.stringify({ pid: data.id, qnty: newqnty }))
            let newtotalpoint = newqnty * data.points
            setcomponenttotalpoint(newtotalpoint)
            settotalpoint(parseFloat(data.points)+ + +totalpoint)
            let nprice = parseFloat(data.price)
            settotal(total+ + +nprice)
        }
    }
    return (
        <div className=" small">

            <div className="d-flex flex-row justify-content-between">
                <p className="fs-6 p-1">{data.name} </p>
                <p className="flex-grow text-end" style={{width:'40%'}}>TK {data.price}</p>
            </div>
            <div className="">
                <div className="d-flex flex-row justify-content-between">
                    <div className="me-3">
                        <img
                            width='50'
                            src={'https://self-shopping.com/shopapp/stgImage/products/' + data.img1}
                        />
                    </div>
                    <div className=" flex-fill text-end" >
                        <p style={{ color: 'darkblue', marginStart: 10 }}>পরিমান</p>
                        <div className="">
                            <div className="btn-group btn-group-sm">
                                <div className="btn btn-outline-secondary btn-sm" onClick={() => qntyminus()} >
                                    <p style={{ fontSize: 35, marginTop: -10 }}>-</p>
                                </div>
                                <div className="btn btn-outline-secondary btn-sm">
                                    <p style={{ color: '#222' }}>{qnty}</p>
                                </div>
                                <div className="btn btn-outline-secondary btn-sm" onClick={() => qntyplus()}>
                                    <p style={{ fontSize: 25 }}>+</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
            <div className="d-flex flex-row justify-content-between " style={{   }}>
                <p className="p-1 small" style={{ fontWeight: 'bold' }}>প্রোডাক্ট এর মোট মূল্য </p>
                <p className="p-1 small" style={{ fontWeight: 'bold' }}>{totalproductprice} TK</p>
            </div>
            <div className="d-flex flex-row justify-content-between" style={{  }}>
                <p className="p-1 small" style={{ fontWeight: 'bold' }}>মোট পয়েন্ট  </p>
                <p className="p-1 small" style={{ fontWeight: 'bold' }}>{componenttotalpoint}</p>
            </div>
            <hr/>
        </div>
    )
}

export default StgCheckoutContentbox;