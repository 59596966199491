import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../SideMenu';

const Body = () => {
    const [productid, setProductId] = useState(null)
    const [pdata, setPdata] = useState([]);
    const [loading, setLoading] = useState(false)
    const [nodata, setNodata] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const [loggedin, setLoggedin] = useState(false)
    const [currentImage, setCurrentImage] = useState('')
    const [userstatus, setuserstatus] = useState(0);
    const [sizes, setSizes] = useState([])
    const [colors, setColors] = useState([])
    const [maxprice, setMaxprice] = useState(0);
    const [showprice, setShowprice] = useState(0);
    const [incometotal, setIncometotal] = useState(0)
    const [incomesingle, setIncomesingle] = useState(0)
    const shoputils = require('../../utils/shoputils.json');
    const [totalproductprice, setTotalproductprice] = useState(0)
    const [totalorderamount, setTotalorderamount] = useState(0)
    const [qnty, setQnty] = useState(1);
    const [size, setSize] = useState(null)
    const [color, setColor] = useState(null)
    const [isError, setIsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [errors, setErrors] = useState(null)
    const [cashback, setCashback] = useState(0)
    const [inputprice, setInputprice] = useState(0)
    useEffect(() => {
        let products = localStorage.getItem('product');
        let product = JSON.parse(products)
        setProductId(product[0].pid);
        console.log(product[0].pid)
        if (nodata == false) {
            fetchData();
        }
    },[])
    const fetchData = async () => {
        try {
            setLoading(true)
            let products = localStorage.getItem('product');
            let product = JSON.parse(products)
            let productid = product[0].pid;
            const random = Math.random();
            const users = localStorage.getItem("userinfo");
            var response;
            if (users) {
                var user = JSON.parse(users);
                response = await fetch(shoputils.url + '?random=' + random + '&getSingleProductDetails=' + productid + '&auth=' + user.auth);
                let token = localStorage.getItem('token');
                if (token) {
                    setLoggedin(true);
                }

            } else {
                response = await fetch(shoputils.url + '?random=' + random + '&getSingleProductDetails=' + productid);
            }


            const json = await response.json();

            setPdata(json.singleproduct)
            setSizes(Object.values(json.singleproduct.size));
            setColors(Object.values(json.singleproduct.color));
            setInputprice(json.singleproduct.resellPrice)
            //console.log(json.singleproduct)
            //setFevoriteStatus(json.fevoritestatus)
            setCurrentImage(json.singleproduct.image1)
            setuserstatus(json.usertype);
            //setPhoneNumber(json.vendornumber)
            //setReports(json.reports)
            setLoading(false)
            setNodata(true);
            if (json.usertype == 1) {
                setShowprice(json.singleproduct.regularPrice - json.singleproduct.discountAmount)
                setInputprice(Math.round(json.singleproduct.resellPrice))
                setIncomesingle(Math.round(json.singleproduct.resellPrice - json.singleproduct.discountedPrice))
                setIncometotal((json.singleproduct.resellPrice - json.singleproduct.discountedPrice) * qnty)
                setTotalproductprice((json.singleproduct.regularPrice - json.singleproduct.discountAmount) * qnty)
                setTotalorderamount(json.singleproduct.resellPrice * qnty)
                setMaxprice(json.singleproduct.resellPrice);
            } else {
                setShowprice(json.singleproduct.regularPrice - json.singleproduct.discountAmount)
                // setInputprice(productdatas.regularPrice - productdatas.discountAmount)
                setCashback(json.singleproduct.cashback);
                setIncometotal((json.singleproduct.regularPrice - json.singleproduct.regularPrice) * qnty)
                setTotalproductprice((json.singleproduct.regularPrice - json.singleproduct.discountAmount) * qnty)
                setTotalorderamount((json.singleproduct.regularPrice) * qnty)
                // setMaxprice(productdatas.resellPrice - productdatas.discountAmount);
            }
        } catch (error) {

        } finally {
            setLoading(false)
            setRefreshing(false)
        }


        setLoading(false);
    }
    const showError = (msg) => {
        setIsError(true)
        setErrorMsg(msg)
        setTimeout(() => {
            setIsError(false)
        }, 3000);
    }
    function qntyminus() {
        let oldqnty = qnty;
        let newqnty = oldqnty - 1;
        //alert(newqnty);
        if (newqnty > 0) {
            setQnty(newqnty);
            setTotalproductprice(showprice * newqnty);
            setIncometotal(incomesingle * newqnty);
            setTotalorderamount((showprice * newqnty) + (incomesingle * newqnty))
            setCashback(pdata.cashback * newqnty)
        }
    }
    function qntyplus() {
        let oldqnty = qnty;
        let newqnty = oldqnty + 1;
        //alert(newqnty);
        if (newqnty > 0) {
            setQnty(newqnty);
            setTotalproductprice(showprice * newqnty);
            setIncometotal(incomesingle * newqnty);
            setTotalorderamount((showprice * newqnty) + (incomesingle * newqnty))
            setCashback(pdata.cashback * newqnty)
        }
    }
    const changeInputPrice = (e) => {
        setInputprice(e.target.value);
        if (e.target.value > maxprice) {
            setInputprice(e.target.value)
            setIncomesingle(0);
            setIncometotal(0)
            setErrors('You can set this amount between ' + showprice + ' and ' + maxprice)
        } else if (e.target.value < showprice) {
            setInputprice(e.target.value)
            setIncomesingle(0);
            setIncometotal(0)
            setErrors('You can set this amount between ' + showprice + ' and ' + maxprice)
        } else {
            setErrors(null)
            setInputprice(e.target.value);
            setIncomesingle(e.target.value - showprice);
            setIncometotal((e.target.value - showprice) * qnty)
            setTotalproductprice(showprice * qnty)
            setTotalorderamount(((e.target.value - showprice) * qnty) + (showprice * qnty))
        }

    }
    const proceedtonext = () => {
        if (userstatus == 1) {
            if (inputprice > maxprice || inputprice < showprice) {
                //errormsg('You can1 set this amount between '+showprice+' and '+maxprice)
                showError('প্রোডাক্ট টির মূল্য ' + Math.round(showprice) + ' থেকে ' + Math.round(maxprice) + ' এর মধ্যে রাখুন')
            } else {
                localStorage.setItem("cartdetails", JSON.stringify({
                    totalincome: incometotal,
                    orderamount: totalorderamount,
                    totalproductprice: totalproductprice,
                    qnty: qnty,
                    pid: pdata.ID,
                    size: size,
                    color: color,
                    inputprice: inputprice,
                    cashback: cashback,
                    currentImage: currentImage,
                    vendorlocation: pdata.vendorLocation,
                    chargeinside: pdata.deliveryCharge.inside,
                    chargeoutside: pdata.deliveryCharge.outside
                }));
                window.location.href = '/shop/address';
            }
        } else {
            localStorage.setItem("cartdetails", JSON.stringify({
                totalincome: incometotal,
                orderamount: totalorderamount,
                totalproductprice: totalproductprice,
                qnty: qnty,
                pid: pdata.ID,
                size: size,
                color: color,
                inputprice: inputprice,
                cashback: cashback,
                currentImage: currentImage,
                vendorlocation: pdata.vendorLocation,
                chargeinside: pdata.deliveryCharge.inside,
                chargeoutside: pdata.deliveryCharge.outside
            }));
            window.location.href = '/shop/address';
        }
    }
    return (

        nodata == true ?
            <div className="container-fluid">
                <div className="container-fluid  bg-lightgray">
                    <div className="container  pt-3 pb-3 checkout_container">
                        <div className="row ">
                            <SideMenu />

                            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2 mt-2">
                                <div className="selfbgcolor rounded-top p-2 text-center text-white">সাইজ, কালার, পরিমাণ এবং মূল্য নির্ধারণ</div>
                                <div className="bg-white p-3">
                                    <div className="  d-flex flex-row justify-content-between">
                                        <p className='onlinetext shop_ck_name fs-5 selfcolor'>{pdata.Name}</p>
                                        <p className='fs-3'>TK {showprice}</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 border-end text-center">
                                            <img className='w-50' src={shoputils.imageurl + currentImage} alt="" />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 ">
                                            <p>পরিমান</p>
                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                <div onClick={qntyminus} className="btn btn-outline-secondary p-1 flex-fill me-2 fs-3 shop_ck_count_btns">-</div>
                                                <div className="btn btn-danger p-1 flex-fill me-2 fs-3 shop_ck_count_btns">{qnty}</div>
                                                <div onClick={qntyplus} className="btn btn-outline-secondary p-1 flex-fill fs-3 shop_ck_count_btns">+</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="d-flex mt-3 flex-row mt-2 justify-content-between">
                                            <select name="" className='flex-fill me-3 p-2 form-control' id="">
                                                <option value="">Select Size</option>
                                                {sizes.map((item, index) => {
                                                    <option key={index} value="">{item}</option>
                                                })}

                                            </select>
                                            <select name="" className='flex-fill p-2 form-control' id="">
                                                <option value="">Select Color</option>
                                                {colors.map((item, index) => {
                                                    <option value="">{item}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {userstatus == 1 ?


                                        <div className="d-flex flex-row mt-3 justify-content-between">
                                            <div className='flex-fill me-3'>
                                                <p>বিক্রয় মূল্য (প্রতি আইটেম)</p>

                                                <input type="text" value={inputprice} onChange={changeInputPrice} className='form-control text-center' />
                                                {
                                                    errors !== null ?
                                                        <span className='text-danger'>{errors}</span>
                                                        : null
                                                }
                                            </div>
                                            <div className='flex-fill'>
                                                <p>আপনার আয় (প্রতি আইটেম)</p>
                                                <input type="text" value={incomesingle} className='form-control bg-primary text-white text-center' />
                                            </div>

                                        </div>

                                        : null
                                    }
                                    <div className="d-flex mt-3 fw-bold flex-row justify-content-between">
                                        <p>প্রোডাক্টের মোট মূল্য</p>
                                        <p>{Math.round(totalproductprice)} TK</p>

                                    </div>
                                    {userstatus == 1 ?
                                        <div className="d-flex  flex-row justify-content-between">
                                            <p>আপনার অতিরিক্ত আয়</p>
                                            <p>{Math.round(incometotal)} TK</p>
                                        </div>
                                        :
                                        null
                                    }

                                    <div className="d-flex fw-bold mt-1 mb-1 flex-row justify-content-between">
                                        <p>সর্বমোট মূল্য</p>
                                        <p>{Math.round(totalorderamount)} TK</p>

                                    </div>
                                    <button onClick={proceedtonext} className="btn btn-light selfbgcolor w-100 fs-4 text-white">
                                        পরবর্তী ধাপে যান
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : null

    )
}

export default Body;