
import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../components/SideMenu'; 

import { faCamera, faChevronRight, faHandPointer, faMobile, faMobileScreenButton, faPaperPlane, faShoppingBag, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
const Body = () =>  {
    
    return(
    <div className="container-fluid">
        <div className="container-fluid  bg-lightgray">
                    <div className="container  pt-3 pb-3 checkout_container">
                        <div className="row ">
                            <SideMenu/>
                            
                            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                                <div className="bg-white p-2">
                                    <img src={require('../img/about.png')} className="w-100" alt="" />
                                    <div style={{ paddingTop: 20 }}>
                                        <p style={{ color: '#f4511e', fontSize: 18, marginBottom: 10 }}>Privacy Policy</p>
                                        <p   >
                                            <p style={{ fontWeight: 'bold' }}>SELF-SHOPPING (“SELF - DIGITAL BUSINESS PLATFORM”)</p> provides this Privacy Policy to let you know our policies and procedures regarding the collection, use and disclosure of information through Self-shopping.com (the “Site”), and any other websites, features, applications, widgets or online services that are owned or controlled by SELF and that post a link to this Privacy Policy (together with the Site, the “Service”), as well as any information SELF SHOPPING collects outside of the Site in connection with the Service or where links to this Privacy Policy. It also describes the choices available to you regarding the use of, your access to, and how to update and correct your personal information.
                                        </p>
                                        <p >By accessing or using the Service, you consent to the information collection, disclosure and use practices described in this Privacy Policy. Please note that certain features or services referenced in this Privacy Policy may not be offered on the Service at all times. Please also review our Terms of Service, which governs your use of the Service.</p>
                                        <p >We’ve provided short summaries in this Privacy Policy to help you understand what information we collect, how we use it, and what choices you have. While these summaries help explain some of the concepts in a simple and clear way. We encourage you to read the entire Privacy Policy to understand our data practices.</p>

                                        <p style={{ color: '#222', fontWeight: 'bold', marginTop: 20 }}>1. INFORMATION COLLECTION</p>
                                        <p  >Information You Provide to Us When you use the Service, you may provide us with information about you. This may include your name and contact information, financial information to make or receive payment for services obtained through the SELF SHOPPING. When you use the Service, we may also collect information related to your use of the Service and aggregate this with information about other users. This helps us improve our Services for you. You may also provide us with information about your contacts or friends if, for example, you’d like to add those contacts to a message room. Agencies may also provide us with information about Freelancers associated with the Agency.</p>
                                        <p   >
                                            <p style={{ fontWeight: 'bold' }}>Personal Information:</p> In the course of using the Service (whether as a Client or Freelancer), we may require or otherwise collect information that identifies you as a specific individual and can be used to contact or identify you (“Personal Information”). Examples of Personal Information include your name, email address, company address, billing address, and phone number.
                                        </p>
                                        <p   >
                                            <p style={{ fontWeight: 'bold' }}>Payment Information:</p>  If you use the Service to make or receive payments, we will also collect certain payment information, such as Bank account, credit card, bKash, Rocket or other financial account information, and billing address.
                                        </p>
                                        <p   >
                                            <p style={{ fontWeight: 'bold' }}>Identity Verification:</p>  We may collect Personal Information, such as your date of birth or taxpayer identification number, to validate your identity or as may be required by law, such as to complete tax filings. We may request documents to verify this information, such as a copy of your government-issued identification or photo or a billing statement.
                                        </p>
                                        <p   >
                                            <p style={{ fontWeight: 'bold' }}>General Audience Service:</p>  The Service is general audience and intended for users 18 and older of Bangladeshi citizens. We do not knowingly collect Personal Information from anyone younger than age 18. If we become aware that a child younger than 18 has provided us with Personal Information, we will use commercially reasonable efforts to delete such information from our files. If you are the parent or legal guardian of a child younger than age 18 and believe that SELF SHOPPING has collected Personal Information from your child, please contact us immediately.
                                        </p>
                                        <p   >
                                            <p style={{ fontWeight: 'bold' }}>Non-Identifying Information or Usernames:</p>  We also may collect other information, such as zip codes, demographic data, information regarding your use of the Service, and general project-related data (“Non-Identifying Information”). We may aggregate information collected from SELF SHOPPING registered and non-registered users (“SELF SHOPPING users”). We consider usernames to be Non-Identifying Information.
                                        </p>
                                        <p   >
                                            <p style={{ fontWeight: 'bold' }}>Information Received from Third Parties:</p>  Third parties may also provide us information about you. If we combine that information with information about you collected through the Service, we will still treat that combined information as set forth in this Privacy Policy.
                                        </p>


                                        <p >SELF SHOPPING and its partners use cookies or similar technologies to analyze trends, administer the website, track users’ movement around the website, and to gather demographic information about our user base as a whole. The technology used to collect information automatically from SELF SHOPPING Users may include the following:</p>
                                        <p   >
                                            <p style={{ fontWeight: 'bold' }}>Cookies:</p>  Like many websites, we and our marketing partners, affiliates, analytics, and service providers use “cookies” to collect information. A cookie is a small data file that we transfer to your computer’s hard disk for record-keeping purposes. We use both persistent cookies that remain on your computer or similar device (such as to save your registration ID and login password for future logins to the Service and to track your compliance with the SELF SHOPPING Terms of Service) and session ID cookies, which expire at the end of your browser session.
                                        </p>
                                        <p   >
                                            <p style={{ fontWeight: 'bold' }}>Web Beacons:</p>  SELF SHOPPING and our marketing partners, affiliates, analytics, and service providers may also employ software technology known as “web beacons” and/or “tracking tags” to help us keep track of what content on our Service is effective and to serve relevant advertising to you.
                                        </p>

                                        <p   >
                                            <p style={{ fontWeight: 'bold' }}>Embedded Scripts:</p>  We and our marketing partners, affiliates, analytics, and service providers may also employ software technology known as an Embedded Script. In addition, we and our marketing partners, affiliates, analytics, and service providers may use a variety of other technologies (such as tags) that collect similar information for security and fraud detection purposes and we may use third parties to perform these services on our behalf.
                                        </p>
                                        <p  >HOW WE RESPOND TO DO NOT TRACK SIGNALS</p>
                                        <p  >SELF SHOPPING does not respond to Do-Not-Track signals.</p>

                                        <p style={{ color: '#222', fontWeight: 'bold', marginTop: 20 }}>Feedback</p>
                                        <p  >We collect feedback from SELF EMPLOYMENTS Users about their experience with other SELF EMPLOYMENTS Users of our Service. Please note that any feedback you provide via the Service or feedback provided about you is publicly viewable via the Service. On very rare occasions, we may remove feedback pursuant to the relevant provisions of our Terms of Service.</p>
                                        <p style={{ color: '#222', fontWeight: 'bold', marginTop: 20 }}>1. USE AND RETENTION OF INFORMATION</p>
                                        <p  >We use information collected during the Service to provide and improve the Service, process your requests, prevent fraud, provide you with information and advertising that may interest you, comply with the law, and as otherwise permitted with your sanction.</p>
                                        <p style={{ color: '#222', fontWeight: 'bold', marginTop: 20 }}>2. INFORMATION SHARING AND DISCLOSUREN</p>
                                        <p  >We may share information about you to provide the Services, for legal and investigative purposes, in connection with sweepstakes and promotions, or if we are part of a merger or acquisition. We may also share non-identifying information with third parties. You have choices as to whether we share your personal information with third parties for their own marketing purposes.</p>
                                        <p style={{ color: '#222', fontWeight: 'bold', marginTop: 20 }}>3. THIRD PARTY ANALYTICS PROVIDERS, AD SERVERS AND SIMILAR THIRD PARTIES</p>
                                        <p  >We may work with advertising agencies and vendors who use technology to help us understand how people use our Site. These vendors may use technologies to serve you advertisements that may interest you. You can choose to opt out of receiving interest-based advertising.</p>
                                        <p style={{ color: '#222', fontWeight: 'bold', marginTop: 20 }}>4. YOUR CHOICES AND OPTING OUTN</p>
                                        <p  >You have certain choices regarding how we may communicate with you. Registered SELF SHOPPING Users may update their choices regarding the types of communications you receive from us through your online account. You also may opt-out of receiving marketing emails from us by following the opt-out instructions provided in those emails. Please note that we reserve the right to send you certain communications relating to your account or use of the Service (for example, administrative and service announcements) via email and other means and these transactional account messages may be unaffected if you opt-out from receiving marketing communications.</p>
                                        <p style={{ color: '#222', fontWeight: 'bold', marginTop: 20 }}>5. CHANGING YOUR INFORMATION OR CLOSING YOUR ACCOUNT</p>
                                        <p  >You may request access to all personal information we have about you. You may also delete your account and/or request deletion of all personal information we have about you. We will honor the request to the extent we can reasonably do so, but some information will remain on the Services, such as information you posted publicly.</p>
                                        <p style={{ color: '#222', fontWeight: 'bold', marginTop: 20 }}>6. SECURITY</p>
                                        <p  >We take a number of steps to protect your data, but no security is guaranteed. SELF SHOPPING takes commercially reasonable steps to help protect and secure the information it collects and stores about SELF SHOPPING Users. All access to the Site is encrypted using industry-standard transport layer security technology (TLS). When you enter sensitive information (such as tax identification number), we encrypt the transmission of that information using secure socket layer technology (SSL). We also use HTTP strict transport security to add an additional layer of protection for our SELF SHOPPING Users. But remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. Thus, while we strive to protect your personal data, SELF SHOPPING cannot ensure and does not warrant the security of any information you transmit to us.</p>
                                        <p style={{ color: '#222', fontWeight: 'bold', marginTop: 20 }}>7. LINKS TO OTHER SITES</p>
                                        <p  >Our Service contains links to other websites. If you choose to click on a third party link, you will be directed to that third party’s website. The fact that we link to a website is not an endorsement, authorization or representation of our affiliation with that third party, nor is it an endorsement of their privacy or information security policies or practices. We do not exercise control over third party websites. These other websites may place their own cookies or other files on your computer, collect data or solicit Personal Information from you. We encourage you to read the privacy policies or statements of the other websites you visit.</p>
                                        <p style={{ color: '#222', fontWeight: 'bold', marginTop: 20 }}>8. CHANGES TO THIS POLICY</p>
                                        <p  >We may change this Privacy Policy. If we make material changes, we will provide notice.

                                            SELF SHOPPING may update this Privacy Policy at any time and any changes will be effective upon posting. In the event that there are material changes to the way we treat your Personal Information, we will display a notice through the Services prior to the change becoming effective. We may also notify you by email, in our discretion. However, we will use your Personal Information in a manner consistent with the Privacy Policy in effect at the time you submitted the information, unless you consent to the new or revised policy.</p>
                                        <p style={{ color: '#222', fontWeight: 'bold', marginTop: 20 }}>9. CONTACTING US</p>
                                        <p  >If you have any questions about this Privacy Policy, please contact us.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
   )
}

export default Body;