
import React,{useState} from 'react';
import SideMenu from './SideMenu'; 
import {ErrorToast} from '../utils/Utils';

const Body = () =>  {
    const shoputils = require('../utils/shoputils.json');
    const [loading, setLoading] = useState(false);
    const [username, setUserName] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [reason, setReason] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg,setErrorMsg] =useState('')
    const [isError,setIsError] = useState(false);

    const showError = (msg) => { 
        setIsError(true)
        setErrorMsg(msg) 
        setTimeout(() => {
            setIsError(false)
        }, 5000);
    }

    const ConfirmApplication = async() => {
        try {
            setLoading(true);
            const random = Math.random();
            let formData = new FormData();

            formData.append('webpost',1);
            formData.append('username',username);
            formData.append('number',number);
            formData.append('email',email);
            formData.append('password',password);
            formData.append('reason',reason);
            formData.append('accountDeleteApplication',1);

            const response = await fetch(shoputils.webpost+'?random='+random,{
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                body: formData
            });

            const json = await response.json();

            if(json.success == 1){
                showError(json.message);
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            }else{
                showError(json.message);
            }            
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }  finally {
            setLoading(false)
        }
    }

    const inputName = (e) => {
        let value = e.target.value;
        setUserName(value)
    }
    const inputNumber = (e) => {
        let value = e.target.value;
        setNumber(value)
    }
    const inputEmail = (e) => {
        let value = e.target.value;
        setEmail(value)
    }
    const inputPassword = (e) => {
        let value = e.target.value;
        setPassword(value)
    }
    const inputReason = (e) => {
        let value = e.target.value;
        setReason(value)
    }

    return(
    <div className="container-fluid">
        <div className="container-fluid  bg-lightgray">
                    <div className="container  pt-3 pb-3 checkout_container">
                        <div className="row ">
                            <SideMenu/>
                            
                            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                                <div className="bg-white p-2">
                                    <img src={require('../img/warning.png')} alt="" />
                                    <div>
                                        <div className="container">
                                            <div className="row">
                                            {
                                            isError == true ? 
                                            ErrorToast(errorMsg)
                                            :null
                                        }
                                                <div className="col-sm-12 mt-3 mb-3">
                                                    <h5 className='text-danger'>Delete Your SELF Account!!</h5>
                                                    <p className='text-info text-justify'>You can permanently delete your SELF Account and all your services and data. You are absolutely sure that you want to delete self-shopping.com user account? <span className='fw-bold text-warning'>Please Note that there is no option to restore the account or it's data once deleted!</span>
                                                    </p>
                                                </div>                                            
                                              
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 mt-2">
                                                    <input type="text" onChange={inputName} value={username} placeholder='Name' className="form-control" />
                                                </div>                                                
                                                <div className="col-sm-6 mt-2">
                                                    <input type="text" onChange={inputEmail} value={email} placeholder='Email' className="form-control" />
                                                </div>
                                                <div className="col-sm-6 mt-2">
                                                    <input type="text" onChange={inputNumber} value={number} placeholder='SELF Account Number' className="form-control" />
                                                </div>
                                                <div className="col-sm-6 mt-2">
                                                    <input type="text" onChange={inputPassword} value={password} placeholder='SELF Password' className="form-control" />
                                                </div>
                                                <div className="col-sm-12 mt-2">
                                                    <textarea placeholder="Delete Reason" onChange={inputReason} id="" cols="30" value={reason} rows="10" className="form-control">{reason}</textarea>
                                                </div>
                                                <div className="col-12 mt-2">
                                                    <input type="submit" onClick={()=>{
                                                        ConfirmApplication();
                                                    }} value="SEND REQUEST" className="w-100 btn btn-outline-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
   )
}

export default Body;