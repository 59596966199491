
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideMenu from '../components/SideMenu';
import { useParams } from 'react-router-dom';
import { faCamera, faComment, faFile, faHandPointer, faMobile, faMobileScreenButton, faPaperPlane, faShare, faUser } from '@fortawesome/free-solid-svg-icons';
import { faCommentAlt, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { ErrorToast } from '../utils/Utils';
const Body = () => {
    const params = useParams();
    const postid = params.id;
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('')
    const shoputils = require('../utils/shoputils.json');
    const [loadMore, setLoadMore] = useState(false);
    const [nodata, setNodata] = useState(false)
    const [data, setData] = useState([])
    const [loadingMore, setLoadingMore] = useState(false)
    const [username, setUsername] = useState('')
    const [loggedin, setLoggedin] = useState(false)
    const [userpic, setUserpic] = useState('')
    const [isVendor, setIsVendor] = useState(0)
    const [myproducts, setMyproducts] = useState([])
    const [currentPost, setCurrentpost] = useState(0)
    useEffect(() => {
        if (nodata == false) {
            getData();
        }

    }, [])
    const getData = async () => {
        try {
            setLoadingMore(true);
            const users = localStorage.getItem("userinfo");
            localStorage.removeItem('addproducts')
            const random = Math.random();
            let response = '';
            if (users) {
                let token = localStorage.getItem('token');
                if (token) {
                    setLoggedin(true);
                }
                const user = JSON.parse(users);
                response = await fetch(shoputils.webget + '?random=' + random + '&getUsername=' + user.auth + '&getOnDemands=0');
                const json = await response.json();
                if (json.success == 1) {
                    //console.log(json)
                    setUsername(json.username)
                    setIsVendor(json.isvendor)
                    setMyproducts(json.myproducts)
                    setUserpic(json.pic)
                    setData(json.data);
                    setNodata(true)
                } else {
                    setData([]);
                    setNodata(true);
                }
            } else {
                response = await fetch(shoputils.webget + '?random=' + random + '&getOnDemands=0');
                const json = await response.json();
                if (json.success == 1) {
                    //console.log(json)

                    setData(json.data);
                    setNodata(true)
                } else {
                    setData([]);
                    setNodata(true);
                }
            }

            setLoadingMore(false);
        } catch (error) {
            setLoadingMore(false);
        } finally {
            setLoadingMore(false)
        }
    }
    if (loadMore == false) {
        window.onscroll = async function (ev) {

            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100)) {
                setLoadMore(true)

                try {
                    setLoadingMore(true);
                    var index = data.length;
                    var lastitem = data[index - 1]; //get 1st item in the array

                    if (index > 19) {


                        const users = localStorage.getItem("userinfo");
                        const user = JSON.parse(users);
                        const random = Math.random();
                        const response = await fetch(shoputils.webget + '?random=' + random + '&getOnDemands=' + lastitem.id);
                        const json = await response.json();

                        if (json.data.length > 0) {
                            const responsedata = json.data;
                            let arry = [...data, ...responsedata];
                            setData(arry);
                            setLoadMore(false)
                        } else {
                            setLoadMore(false)
                        }
                    }
                    setLoadMore(false);
                } catch (e) {
                    setLoadingMore(false);
                } finally {
                    setLoadMore(false);
                }
            }
        };
    }
    const checkboxclicked = async (id) => {
        const addproducts = localStorage.getItem('addproducts');
        //console.log(addproducts)
        if (addproducts) {
            let products = JSON.parse(addproducts);
            let values = products.pid;
            //console.log(products);
            let found = 0;
            let indexs = null;
            values.map((item, index) => {
                //console.log(id)
                if (item == id) {
                    found = 1;
                    indexs = index
                }

            })
            //console.log(found)
            if (found == 0) {
                values.push(id);
                //console.log(values);
                localStorage.setItem('addproducts', JSON.stringify({ 'pid': values }))
            } else {
                values.splice(indexs, 1)
                //console.log(values)
                localStorage.setItem('addproducts', JSON.stringify({ 'pid': values }))
            }
        } else {
            let arr = [id];
            localStorage.setItem('addproducts', JSON.stringify({ 'pid': arr }));

        }
    }
    const showError = (msg) => {
        setIsError(true)
        setErrorMsg(msg)
        setTimeout(() => {
            setIsError(false)
        }, 3000);
    }
    const addProduct = async () => {

        try {
            setLoadingMore(true);
            const users = localStorage.getItem("userinfo");

            const products = localStorage.getItem('addproducts');
            if (products) {
                const product = JSON.parse(products);
                const user = JSON.parse(users);
                const random = Math.random();

                let formData = new FormData();

                formData.append('auth', user.auth);
                formData.append('webpost', 1);
                formData.append('currentpost', currentPost);
                formData.append('demandaddproducts', product.pid);
                console.log(formData)
                const response = await fetch(shoputils.webpost + '?random=' + random, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                    },
                    body: formData
                });
                const json = await response.json();
                if (json.success == 1) {
                    showError(json.message)
                    // setData(json.data);
                    // setNodata(true)
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else {
                    showError(json.message)
                }
            } else {
                showError('প্রোডাক্ট সিলেক্ট করুন')
            }

            setLoadingMore(false);
        } catch (error) {
            setLoadingMore(false);
        } finally {
            setLoadingMore(false)
        }
    }
    const currentPostClicked = (id) => {
        setCurrentpost(id)

    }
    return (
        <div className="container-fluid">
            <div className="container-fluid  bg-lightgray">
                <div className="container  pt-3 pb-3">
                    <div className="row ">
                        <SideMenu />
                        <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2">
                            <img src={require('../assets/banner/on-demand.png')} className="rounded w-100 mb-3" alt="" />

                            <div className='d-flex flex-row border-bottom pb-3 justify-content-start'>
                                <img src={require('../img/smartwatch.jpg')} style={{ width: 50, height: 50, borderRadius: 50 }} alt="" />
                                <div data-bs-target="#postmodal" data-bs-toggle="modal" className='align-text-center border border-danger text-start ps-4  rounded-pill w-100' style={{ lineHeight: '44px' }} >আপনার পোস্ট লিখুন</div>

                                <div className="modal" id="postmodal" >
                                    <div className="modal-dialog">
                                        <div className="modal-content p-3">
                                            <div className="modal-header">
                                                নতুন পোস্ট
                                            </div>
                                            <div className='d-flex bg-white flex-row p-3 mt-2 pb-3 justify-content-start '>
                                                <img src={require('../img/smartwatch.jpg')} className="me-3" style={{ width: 50, height: 50, borderRadius: 50 }} alt="" />
                                                <div className='align-virtical'>
                                                    <b className='text-uppercase' >Rana Borua</b>
                                                    <br />
                                                    sun Nov 6 2022 6:03 PM
                                                </div>

                                            </div>
                                            <textarea name="" className='form-control' id="" cols="30" rows="10" placeholder='আপনার পোস্ট লিখুন'></textarea>
                                            <div className="d-flex flex-row justify-content-between">
                                                <div className="w-75 bg-lightgray border-end p-2 text-center">
                                                    Upload Photo
                                                    <FontAwesomeIcon className='ms-2 fs-6  ' icon={faFile} />
                                                </div>
                                                <div className="w-25 bg-lightgray p-2 text-center">
                                                    Camera
                                                    <FontAwesomeIcon className='ms-2 fs-6  ' icon={faCamera} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal" id="myproducts">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                {
                                                    isError == true ?
                                                        ErrorToast(errorMsg)
                                                        : null
                                                }
                                                {
                                                    myproducts.map((item, index) => {
                                                        return <div key={index} className='border shadow-sm p-2 mt-2 d-flex flex-row justify-content-left align-items-center check_product' data-id={item.id}>
                                                            <img src={'https://shopapp.self-shopping.com/img/shop/' + item.image} style={{ marginRight: 20, width: 50, height: 50, borderRadius: 50 }} alt="" />
                                                            <p className=' w-75' style={{ marginLeft: 10 }}>{item.name} <input type="checkbox" onClick={() => checkboxclicked(item.id)} className='float-end' /></p>

                                                        </div>
                                                    })
                                                }
                                                <div className="btn btn-danger p-2 text-center w-100" onClick={() => addProduct()}>SUBMIT</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                data.length > 0 ?
                                    data.map((item, index) => {
                                        return <div key={index} className="singlepost">
                                            <div className='d-flex bg-white flex-row p-3 mt-2 pb-3 justify-content-start '>
                                                {item.user.pic !== 'null' ?
                                                    <img src={'http://103.36.103.164:8080/self_digital/s_p_r/api/downloadFile/profile/' + item.user.pic} className="me-3" style={{ width: 50, height: 50, borderRadius: 50 }} alt="" />
                                                    : <FontAwesomeIcon className='me-2 fs-5  text-dark' icon={faUser} />
                                                }
                                                <div className='align-virtical'>
                                                    <b className='text-uppercase' >{item.user.username}</b> Posted in on demand
                                                    <br />
                                                    {item.date}
                                                </div>

                                            </div>
                                            <div className='bg-white p-3'>

                                                <p>{item.description}</p>
                                            </div>
                                            <div className="bg-white p-3">
                                                <div className=" w-50 m-auto">
                                                    <img src={'http://103.36.103.164:8080/self_digital/s_p_r/api/downloadFile/ondemand/' + item.upload_file} className="w-100" alt="" />
                                                </div>
                                                <hr />
                                                {/* <div className='d-flex flex-row justify-content-between'>
                                                        <div>
                                                        <FontAwesomeIcon className='me-2 fs-6  ' icon={faCommentAlt} />
                                                        Comment
                                                        </div>  
                                                        <div>
                                                        <FontAwesomeIcon className='me-2 fs-6  ' icon={faThumbsUp} />
                                                        Like
                                                        </div>
                                                        <div>
                                                        <FontAwesomeIcon className='me-2 fs-6  ' icon={faShare} />
                                                        Share
                                                        </div>
                                                    </div> */}
                                            </div>
                                            <div className="pin_slider hidescroll">
                                                <div className="d-flex align-items-start flex-row " style={{ width: '200%' }}>
                                                    {
                                                        item.products.map((pitem, pindex) => {
                                                            return <div key={pindex} className='mt-2 mb-2 float-start me-2   ondemand_single_suggestion'>
                                                                <p className='w-100'>প্রোডাক্ট টি এখান থেকে কিনুন</p>
                                                                <a href={'/shop/product/' + pitem.id + '/' + pitem.title} className='d-flex border border-secondary p-2 rounded shadow-sm flex-row overflow-hidden pe-3'>
                                                                    <img src={'https://shopapp.self-shopping.com/img/shop/' + pitem.image} className="w-25 " alt="" />
                                                                    <div className='w-75 ps-2'>
                                                                        <p className='text-dark fw-bold onlinetext'>{pitem.name}</p>
                                                                        <p>৳ {pitem.price}</p>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            {
                                                isVendor == 1 ?
                                                    <div data-bs-target="#myproducts" onClick={() => currentPostClicked(item.id)} data-bs-toggle="modal" className="btn btn-danger rounded pill mt-1 w-100">আপনার কাছে এই প্রোডাক্ট থাকলে কমেন্ট করুন</div>
                                                    : null
                                            }
                                        </div>
                                    })
                                    : null

                            }
                            {
                                loadMore == true ?
                                    <div className="col-12 text-center mb-4 pb-4">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Body;