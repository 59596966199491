
import React,{useState,useEffect} from 'react';

import SideMenu from '../SideMenu'; 
import {ErrorToast} from '../../utils/Utils';
const Body = () =>  {
    const [formname, setformname] = useState('');
    const [formaddress, setformaddress] = useState('');
    const [formcontact, setformcontact] = useState('');
    const [formcontact2, setformcontact2] = useState('');
    const [isError,setIsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const shoputils = require('../../utils/shoputils.json');
    const [districtlist, setDistrictlist] = useState([])
    const [district,setdistrict] = useState('');
    useEffect(() => {
        if(districtlist.length == 0){
            getData()
        }
    },[]);
    const getData = async () => {
        try {
        const random = Math.random();
        const response = await fetch(shoputils.url+'?random='+random+'&getdistricts=1&auth='+random);
            const json = await response.json();
            console.log(json.districts);
            setDistrictlist(json.districts);
        } catch (error) {
            console.error(error);
        }  finally {
           
        }
    }
    const showError = (msg) => { 
        setIsError(true)
        setErrorMsg(msg) 
        setTimeout(() => {
            setIsError(false)
        }, 3000);
    }
    const changeformname = (e) => {
        setformname(e.target.value);
    }
    const changeformaddress = (e) => {
        setformaddress(e.target.value);
    }
    const changeformdistrict = (e) => {
        setdistrict(e.target.value);
    }
    const changeformcontact = (e) => {
        setformcontact(e.target.value);
    }
    const changeformcontact2 = (e) => {
        setformcontact2(e.target.value);
    }

    const proceedtonext = () => {
        if(formname !== '' && formaddress !== '' && formcontact !== '' && formcontact2 !== '' && district !== ''){
            if(formcontact.length == 11){
            if(formcontact2.length == 11){
                if(formcontact !== formcontact2){
                    let storage = localStorage.getItem('cartdetails')
                    let storedata = JSON.parse(storage);
                    storedata.formname = formname;
                    storedata.formaddress = formaddress;
                    storedata.formdistrict = district;
                    storedata.formcontact = formcontact;
                    storedata.formcontact2 = formcontact2;
                    localStorage.setItem('cartdetails',JSON.stringify(storedata));
                    
                    window.location.href="/shop/payment";
                }else{
                    showError('গ্রাহকের কন্টাক্ট নম্বর এবং বিকল্প নম্বর ভিন্ন হতে হবে')
                }
            }else{
                showError('বিকল্প নম্বর ১১ সংখ্যার হতে হবে')
            }
            }else{
                showError('কন্টাক্ট নম্বর ১১ সংখ্যার হতে হবে')
            }
        }else{
            showError('সকল তথ্য প্রয়োজন')
        }
    }
   return(
    <div className="container-fluid">
        <div className="container-fluid  bg-lightgray">
                    <div className="container  pt-3 pb-3 checkout_container">
                        <div className="row ">
                            <SideMenu/>
                            
                            <div className="col-lg-10 com-md-10 col-sm-12 col-12  mb-2 mt-2">
                                <div className="selfbgcolor rounded-top p-2 text-center text-white">গ্রাহকের ডেলিভারি এড্রেস দিন</div>
                                    
                                <div className="bg-white p-2 rounded">
                                    <div className="mb-3">
                                        <label  className="form-label">গ্রাহকের নাম</label>
                                        <input className="form-control" type="text" onChange={changeformname} value={formname} placeholder='গ্রাহকের নাম' id="formFile"/>
                                    </div>
                                    <div className="mb-3">
                                        <label  className="form-label">গ্রাহকের সম্পূর্ণ ঠিকানা</label>
                                        <input className="form-control" onChange={changeformaddress} value={formaddress} placeholder='গ্রাহকের সম্পূর্ণ ঠিকানা' type="text" />
                                    </div>
                                    
                                    <div className="input-group mb-3">
                                        <button className="btn btn-outline-secondary" type="button">গ্রাহকের জেলা</button>
                                        <select className="form-select" value={district} onChange={changeformdistrict} placeholder='গ্রাহকের জেলা' id="inputGroupSelect03" aria-label="Example select with button addon">
                                            <option selected>নির্বাচন করুন...</option>
                                            {
                                                districtlist.length > 0 ?
                                                districtlist.map((item,index)=>{
                                                    return <option key={index} value={item.name}>{item.name}</option>
                                                })
                                                :null
                                            }
                                           
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label  className="form-label">গ্রাহকের ফোন নম্বর</label>
                                        <input className="form-control  form-control" onChange={changeformcontact} value={formcontact} placeholder='গ্রাহকের ফোন নম্বর'  type="number"/>
                                    </div>
                                    <div>
                                        <label  className="form-label">বিকল্প ফোন নম্বর</label>
                                        <input className="form-control form-control" onChange={changeformcontact2} value={formcontact2} placeholder='বিকল্প ফোন নম্বর'  type="number"/>
                                    </div>
                                    {
                                        isError == true ? 
                                        ErrorToast(errorMsg)
                                        :null
                                    }
                                    <div onClick={proceedtonext} className="mt-3 fs-5 btn btn-light selfbgcolor w-100 text-white">
                                        পরবর্তী ধাপে যান
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
   )
}

export default Body;