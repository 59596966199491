import React, { useState } from 'react';
const Body = () => {

    return (
        <div className="container-fluid">
            <div className="container-fluid  bg-lightgray">
                <div className="container  pt-3 pb-3 checkout_container">
                    <div className="row ">

                        <div className="teamWrapper">
                            <div className="container">
                                <h1 align="center">Our Management</h1>
                                <p align="center">Our Mangement Body</p>
                                <div className="teamGrid">
                                    
                                    <div className="colmun">
                                        <div className="teamcol">
                                            <div className="teamcolinner">
                                                <div className="avatar">
                                                <img className="profileImg w-50" src={require('../img/shamim.jpeg')} alt="" />    
                                                </div>
                                                <div className="member-name"> <h2 align="center">Md. Rabiul Hossain </h2> </div>
                                                <div className="member-info"><p align="center">Director</p></div>
                                                {/* <div className="member-mail"> <p align="center"> <a href="mailto:@gmail.com">mailto:@gmail.com</a> </p> </div>
                                                <div className="member-social">
                                                    <ul className="social-listing">
                                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                        <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="colmun">
                                        <div className="teamcol">
                                            <div className="teamcolinner">
                                                <div className="avatar">
                                                    <img className="profileImg w-50" src={require('../img/shahin.jpeg')} alt="" />
                                                </div>
                                                <div className="member-name"> <h2 align="center">Md Sahadat Hossain Shahin
                                                </h2> </div>
                                                <div className="member-info"><p align="center">Managing Director</p></div>
                                                {/* <div className="member-mail"> <p align="center"> <a href="mailto:@gmail.com">adsctg@gmail.com</a> </p> </div>
                                                <div className="member-social">
                                                    <ul className="social-listing">
                                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                        <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="colmun">
                                        <div className="teamcol">
                                            <div className="teamcolinner">
                                            <div className="avatar">
                                            <img className="profileImg w-50" src={require('../img/sumon.jpeg')} alt="" />    
                                                </div>
                                                <div className="member-name"> <h2 align="center">Md. Sumon Hossain
                                                </h2> </div>
                                                <div className="member-info"><p align="center">Director</p></div>
                                                {/* <div className="member-mail"> <p align="center"> <a href="mailto:@gmail.com">mailto:@gmail.com</a> </p> </div>
                                                <div className="member-social">
                                                    <ul className="social-listing">
                                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                        <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Body;