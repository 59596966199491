import React from 'react';
import Header from '../../components/Header';
import Body from '../../components/Shop/SingleProductBodyDetailsNew';
import Footer from '../../components/Footer';

const ShopScreen = () =>  {
   
    return(
        <div className='container-fluid p-0'>
           
            <Body/>
            
        </div>
    )
}

export default ShopScreen;